import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, TableFooter, TableRow, TableCell } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import StyleClassComponenet from '../../../Style/StyleClassComponenet';
import StyleClassComponenet from '../../../StyleComponents/StyleClassComponenet';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
// import styles from "./Quotation/Style";
// import styles from "../../../Style/StyleClassComponenet";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import useStyles from "../../../../Khetigaadi/StyleComponents/StyleFunctionComponent";

// ###########################




const QuotationBookReport = () => {
    const history = useHistory();


    const [data, setData] = useState([]);
    const { id, name, email, phone, city, } = useParams();
    // const { id,  } = useParams();
    const [searchText, setSearchText] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);




    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate,]);


    useEffect(() => {
        applySearchFilter();
    }, [searchText]);


    const fetchData = async () => {
        try {
            const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            setData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (
                (!startDate || itemDate >= start) &&
                (!endDate || itemDate <= end)
            );
        });
        setFilteredData(filtered);
    };



    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
            // history.push("/app/QuotationBookReport");
            // history.push(history.location.pathname); // Push the current route path
            // const currentPath = history.location.pathname;
            // const newURL = `${}/app/QuotationBookReport`; // Modify the route path as needed
            // history.push(newURL);
        } catch (error) {
            console.error("Error deleting data:", error);
        }

    };

    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },


        {
            name: "phone",
            label: "Date",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Upload Type",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Enquiry ID",
        },
        {
            name: "name",
            label: "Brand",
        },
        {
            name: "name",
            label: "Model",
        },
        {
            name: "name",
            label: "Customer",
        },
        {
            name: "name",
            label: "Email",
        },
        {
            name: "name",
            label: "Mobile",
        },
        {
            name: "name",
            label: "State",
        },
        {
            name: "name",
            label: "City",
        },
        {
            name: "name",
            label: "Taluka",
        },
        {
            name: "name",
            label: "Request",
        },
        {
            name: "name",
            label: "Response",
        },

        // S/N	Date	Upload Type	Enquiry ID	Brand	Model	Customer	Email	Mobile	State	City	Taluka	Request	Response


    ];

    // const options = {
    //     selectableRows: "none",
    //     responsive: 'scroll',
    //     scrollY: 400, 
    //     scrollX: false,
    //     filter: true,
    //     filterType: "dropdown",
    // };

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 9999, 99999], // Use a large number to simulate "Select All"

    };

    const currentTheme = customTheme(true);
    const classes = useStyles();
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    JCB API Response

                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}  >
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2} lg={2} >
                            {/* <label for="birthday" className={classes.labelform} >Start Date</label><br /> */}
                            <input
                                placeholder='date'
                                type="date"
                                className={classes.inputform}
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={4} sm={2} lg={2}>
                            {/* <label for="birthday" className={classes.labelform}>End Date</label><br /> */}
                            <input
                                className={classes.inputform}
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={5}>
                            {/* <br /> */}
                            <Button
                                onClick={applyDateFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                            <Button
                                style={{ marginLeft: "10px" }}
                                onClick={applyDateFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>
                        </Grid>

                        <Grid item xs={4} sm={2} lg={2}>
                            {/* <label for="birthday" className={classes.labelform}>Search </label><br /> */}
                            <input
                                placeholder='Type To Search'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sm={1} lg={1}>
                            {/* <br /> */}
                            <Button
                                onClick={applySearchFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <br />

            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={
                        <Typography className={classes.tabletittle} >
                            JCB API Response

                        </Typography>
                    }
                    // data={data}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>
    );
};

export default QuotationBookReport;








