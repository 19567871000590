import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, InputLabel, Drawer, Menu, Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import StyleClassComponenet from '../../../Style/StyleClassComponenet';
import StyleClassComponenet from '../../../StyleComponents/StyleClassComponenet';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
// import styles from "./Quotation/Style";
// import styles from "../../../Style/StyleClassComponenet";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import FlightIcon from '@material-ui/icons/Flight';

import FilterListIcon from '@mui/icons-material/FilterAlt';
import ApprovalIcon from '@mui/icons-material/Approval';
import useStyles from "../../../../Khetigaadi/StyleComponents/StyleFunctionComponent";

// ###########################




const QuotationBookReport = () => {
    const history = useHistory();


    const [data, setData] = useState([]);
    const { id, name, email, phone, city, } = useParams();
    // const { id,  } = useParams();
    const [searchText, setSearchText] = useState('');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);


    const [openSidebar, setOpenSidebar] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [users, setUsers] = useState([]); // Define the "users" state variable



    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate,]);


    useEffect(() => {
        applySearchFilter();
    }, [searchText]);


    const fetchData = async () => {
        try {
            const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            setData(response.data);
            setFilteredData(response.data);
            setUsers(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (
                (!startDate || itemDate >= start) &&
                (!endDate || itemDate <= end)
            );
        });
        setFilteredData(filtered);
    };



    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
            // history.push("/app/QuotationBookReport");
            // history.push(history.location.pathname); // Push the current route path
            // const currentPath = history.location.pathname;
            // const newURL = `${}/app/QuotationBookReport`; // Modify the route path as needed
            // history.push(newURL);
        } catch (error) {
            console.error("Error deleting data:", error);
        }

    };

    // S/N	Date	Brand	Tyre Name	User Name	Mobile	Email	State	City	Taluka	From


    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Date",

        },

        {
            name: "phone",
            label: "Type",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "phone",
            label: "Brand",

        },
        {
            name: "phone",
            label: "Model",

        },
        {
            name: "phone",
            label: "User",

        },
        {
            name: "phone",
            label: "Mobile",

        },
        {
            name: "phone",
            label: "Email",

        },
        {
            name: "phone",
            label: "State",

        },
        {
            name: "phone",
            label: "City",

        },
        {
            name: "phone",
            label: "Taluka",

        },
        {
            name: "phone",
            label: "Enquiry From",

        },

    ];


    const CustomToolbarAction = ({ toggleFilter }) => (
        <IconButton aria-label="Filter list" onClick={toggleFilter}>
            <FilterListIcon onClick={toggleSidebar} />

            {/* <Button onClick={toggleSidebar} variant="contained" color="primary">
                Open Filter Sidebar
            </Button> */}
            {/* <AddCircleIcon style={{ color: "#026fe3" }} /> */}

            {/* <AddUpdateUser /> */}
        </IconButton>
    );


    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };



    const options = {
        customToolbar: () => <CustomToolbarAction />,
        // filter: true,
        filter: false, // Disable the filter icon

        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPage: 50, // Set the number of rows per page to 50
        rowsPerPageOptions: [10, 50, 999, 9999, { value: data.length, label: 'All' }], // Include 'All' option

        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <TextField
                    label="Filter Data"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    fullWidth
                />
                <Button onClick={applyNewFilter} variant="contained" color="primary">
                    Apply Filter
                </Button>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Cancel
                </Button>
            </div>
        ),



    };

    const currentTheme = customTheme(true);
    const classes = useStyles();
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    New Earth Mover
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={
                        <Typography className={classes.tabletittle} >
                            New Earth Mover
                        </Typography>
                    }
                    // data={data}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
            <Drawer anchor="right" open={openSidebar}

                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: 300,
                        padding: "10px"
                    }
                }}

                onClose={() => setOpenSidebar(false)}
            >

                <Grid container spacing={1}>
                    <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>

                        <ClearIcon style={{ color: '#c0392b' }} onClick={() => setOpenSidebar(false)} />
                    </Grid>
                    <Grid item xs={4} sm={2} lg={12} >
                        <br />
                        <Typography className={classes.tabletittle} >
                            Filter
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.root} style={{ paddingRight: "22px", paddingLeft: "22px" }}>
                    <div className={classes.root}>
                        <Grid container spacing={1}>
                            <Grid item xs={4} sm={2} lg={12} >
                                <input
                                    placeholder='Enter The Search'
                                    className={classes.inputform}
                                    type="text"
                                    label="Search"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2} lg={12} >
                                <input
                                    placeholder='date'
                                    type="date"
                                    className={classes.inputform}
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} sm={2} lg={12}>
                                <input
                                    className={classes.inputform}
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <br />
                <Grid >
                    <Grid item xs={4} sm={2} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                        <Button
                            style={{ marginRight: "10px" }}
                            onClick={applyDateFilter}
                            className={classes.buttonform}
                            variant="outlined" startIcon={<ApprovalIcon />}>
                            Apply
                        </Button>
                        <Button
                            style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                            className={classes.buttonform}
                            variant="outlined" startIcon={<ClearIcon />}>
                            Clear
                        </Button>
                    </Grid>
                </Grid>
                <br />
            </Drawer >

        </>
    );
};

export default QuotationBookReport;








