import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './BookLive.css';
import Button from '@mui/material/Button';
import axios from "axios";

import { useForm } from "react-hook-form";



const client = axios.create({
    // baseURL: 'https://jsonplaceholder.typicode.com/posts',


    method: 'post', //you can set what request you want to be

    // baseURL: 'http://hrm.inspireag.com/webApi/book-live-demo/',

    // data: {id: varID},
    // headers: {
    //     'Content-Type': 'application/json'
    // },
    // mode: "cors",



    baseURL: 'http://127.0.0.1:8000/webApi/book-live-demo/',
    // baseURL: 'http://app.hrvers.com/webApi/book-live-demo/',


    // credentials: true,
});

export default function FullWidthGrid() {
    const [company_name, setCompany] = useState('');
    const [employee_count, setEmployee] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact_number, setContact] = useState('');
    const [pan_number, setPan] = useState('');

    const [posts, setPosts] = useState([]);

    // GET with Axios
    // useEffect(() => {
    //     const fetchPost = async () => {
    //         try {
    //             let response = await client.get('?_limit=10');
    //             setPosts(response.data);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     fetchPost();
    // }, []);



    // DELETE with Axios
    // const deletePost = async (id) => {
    //     try {
    //         await client.delete(`${id}`);
    //         setPosts(
    //             posts.filter((post) => {
    //                 return post.id !== id;
    //             })
    //         );
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        addPosts(company_name, employee_count, name, email, contact_number, pan_number);
        console.log({ company_name });
        console.log({ employee_count });
        console.log({ name });
        console.log({ email });
        console.log({ contact_number });
        console.log({ pan_number });
    };

    // POST with Axios
    const addPosts = async (company_name, employee_count, name, email, contact_number, pan_number) => {
        try {
            let response = await client.post('', {
                company_name: company_name,
                employee_count: employee_count,
                name: name,
                email: email,
                contact_number: contact_number,
                pan_number: pan_number,

            });
            setPosts([response.data, ...posts]);
            setCompany('');
            setEmployee('');
            setName('');
            setEmail('');
            setContact('');
            setPan('');

        } catch (error) {
            console.log(error);
        }
    };

    // const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // const onSubmit = data => console.log(data);

    // console.log(watch("example")); 
    // watch input value by passing the name of it

    return (
        <Container>
            <form onSubmit={handleSubmit} method="post">

                <Box sx={{ flexGrow: 1, }}>
                    <Grid container spacing={1} >
                        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                            <h1 className='formheading_web' >Book Live Demo</h1>
                            <fieldset className='fieldset'>
                                <legend className='legend_web'>Company Name </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild_web' type="text" id="fname" name="fname" placeholder='Enter Company Name'
                                    required
                                    value={company_name}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                            </fieldset>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <fieldset>
                                <legend>Employee Count</legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild_web' type="number" id="fname" name="fname" placeholder='Enter number of employee'
                                    required
                                    value={employee_count}
                                    onChange={(e) => setEmployee(e.target.value)} />
                            </fieldset>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <fieldset>
                                <legend>Name</legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild_web' type="text" id="fname" name="fname" placeholder='Enter Name'
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                            </fieldset>
                        </Grid>
                        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
                            <fieldset>
                                <legend>Email </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild_web' type="email" id="fname" name="fname" placeholder='Enter Email'
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                            </fieldset>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <fieldset>
                                <legend>Contact Number </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild_web' type="number" id="fname" name="fname" placeholder='Enter Contact number '
                                    required
                                    value={contact_number}
                                    onChange={(e) => setContact(e.target.value)} />
                            </fieldset>
                        </Grid>
                        <Grid item sm={12} xs={12} md={6} lg={6} xl={6}>
                            <fieldset>
                                <legend>PAN / TAN of your organization </legend>
                                {/* <label for="fname">First name:</label> */}
                                <input className='inputfeild_web' type="text" id="fname" name="fname" placeholder=' Enter PAN / TAN of'
                                    required
                                    value={pan_number}
                                    onChange={(e) => setPan(e.target.value)} />
                            </fieldset>
                        </Grid>
                        <Grid item sm={12} xs={12} md={12}>
                            <button type='submit' className='formbutton' fullWidth variant="contained">Book Live Demo</button>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {/* <div className="posts-aw">
                <h2>Book Live Demo</h2>
                {posts.map((post) => {
                    return (
                        <div className="post-sa" key={post.id}>
                            <h2 className="post-sa"> company_{post.company_name}</h2>
                            <p className="post-s"> employee_{post.employee_count}</p>
                            <p className="post-s"> name_ {post.name}</p>
                            <p className="post-s"> email_ {post.email}</p>
                            <p className="post-s"> contact_ {post.contact_number}</p>
                            <p className="post-s">pan_ {post.pan_number}</p>
                            <div className="s">
                                <div style={{ cursor: "pointer" }} className="s-btn" onClick={() => deletePost(post.id)}>
                                    Delete
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div> */}

        </Container>

    );
}






