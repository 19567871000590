import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Container.css';
import { Link } from "react-router-dom";
import Startforfreecontent from '../../Pages/Home/Startforfree/Startforfreecontent';
// import Symboll from '../../../../Components/Image/sidecutimage.png';
import pricingcardsymboll from '../../Components/Image/Group 6330@2x.png';

import Symboll from '../../Components/Image/sidecutimage.png'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (

        <>
            {/* <br />
            <br />
            <br /> */}

            <div className='containerdemo'>
                <img width={140} height="auto" src={Symboll} className="symboll-img-free-demo" />


                <Box sx={{ flexGrow: 1 }} style={{ margin: "-50px", textAlign: "center" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} style={{ marginTop: "-30px", margin: "auto", padding: "auto", textAlign: "center" }}>

                            <div className='dem-positiion' style={{ margin: "auto", textAlign: "center" }}>


                                <img className='Free-pricingcardsymbollsssdsefdsfs' width={10} src={pricingcardsymboll} />
                                <h1 style={{ paddingLeft: "300px", paddingTop: '-50px' }} >Start for Free.</h1>


                            </div>





                        </Grid>
                        <Grid item xs={12} md={1} style={{ textAlign: "center", marginTop: "-40px" }}>
                            <div className="vl"></div>

                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* <br />
                            <br /> */}

                            <span className='   ' style={{ color: "#606062", }}>  Already a Member ? </span><Link to="/Login" style={{ color: "#606062", fontSize: "22px" }}>Sign in</Link>

                        </Grid>

                    </Grid>
                </Box>
            </div>


        </>
    );
}
