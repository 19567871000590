import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
// const primary = "#2c3e50";
const primary = "#065dff";

const bg_secondary = "#2196F3";




const drawerWidth = 230;
// const drawerWidth = 50;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 3,


  },
  hide: {
    display: "none",
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",

    // "&:hover, &:focus": {
    //   transition: "0.5s",
    //   width: drawerWidth,
    // },




  },

  drawerOpen: {
    backgroundColor: primary,


    // width: drawerWidth,
    // width: "85px",
    // width: "50px",
    width: "45px",
    // width: "100px",
    transition: "0.5s",

    // "&:hover, &:focus": {
    //   backgroundColor: bg_secondary,
    //   transition: "4.5s",
    //   width: "300PX"
    // },





    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

  },


  drawerClose: {
    backgroundColor: primary,

    "&:hover, &:focus": {
      transition: "0.5s",
      width: drawerWidth,
    },

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // transition: "6.5s",


    overflowX: "hidden",
    // width: theme.spacing(7) + 40,
    // width: theme.spacing(7) + 20,
    width: theme.spacing(6) + 5,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },

  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  sidebarList: {
    // marginTop: theme.spacing(6),
    // backgroundColor: "red"

  },




  mobileBackButton: {

    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

}));



