import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { red } from "@mui/material/colors";

export default makeStyles(theme => ({



    breadcrumbslink: {
        // color: "#00000099",
        color: "#64728c",
        // color: "red",
        fontSize: "11px",
        // fontFamily: 'robot',
        fontWeight: "100",

    },


    breadcrumbslinkHightlight: {
        // color: '#00000099',
        color: "#64728c",
        fontSize: '13px',
        fontFamily: 'sans-serif',
        fontWeight: '500'

    },
    labelform: {
        padding: "10px",
        fontFamily: "sans-serif",
        color: "#64728c",
        fontSize: "12px",
        fontWeight: "600",

    },

    inputform: {
        width: "100%",
        border: '1px solid #e8eaef',
        borderRadius: '4px',
        padding: "10px",
        height: "30px",
        marginTop: "4px",
        fontFamily: "sans-serif",
        color: "#64728c",
        fontSize: "12px",

    },

    selectform: {
        width: "100%",
        border: '1px solid #e8eaef',
        borderRadius: '4px',
        height: "30px",
        fontFamily: "sans-serif",
        marginTop: "4px",
        fontSize: "12px",
        color: "#64728c",

    },
    buttonform: {
        borderRadius: 6,
        backgroundColor: "#007bff",
        padding: "2px 12px",
        fontSize: "14px",
        borderColor: "#007bff",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            backgroundColor: '#026fe3'
        }
    },

    tabletittle: {
        fontSize: "14px",
        // opacity: "0.9",
        // fontFamily: "sans-serif",
        // fontWeight: "700",
        // color: "#64728c"
    },

    tablelink: {
        color: "#64728c",
        paddingRight: "3px",
        paddingLeft: "3px"

    },

    tableicon: {
        color: "#808080",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },

    pagetittle: {
        fontSize: "14px",
        fontFamily: "sans-serif",
        fontWeight: "700",
        color: "#00000099"
    },

    detaillabel: {
        fontSize: "16px",
        fontWeight: "600",
    },

    detailinfo: {
        fontSize: "14px",
        paddingLeft: "5px"
    },

    buttoncard: {

        backgroundColor: "#007bff",
        // backgroundColor: "red",
        fontSize: "12px",
        color: "#fff",
        fontWeight: "600",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            backgroundColor: '#026fe3'
        },
        // textTransform: "lowercase",
        textTransform: "uppercase",
        marginTop: "-20px",
        // opacity: "0.9",

    },


    cardtittle: {
        fontSize: "16px",
        // color: "red",
        fontWeight: 500,
        color: "#00000099",
        marginTop: "-10px",
        // opacity: "0.9",


    },


    cardparagraph: {
        fontSize: "13px",
        // color: "red",
        fontWeight: 500,
        color: "#00000099",
        marginTop: "-5px",
        opacity: "0.9",


    },

    // ####################### card style dashboard 
    pageheading: {
        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "20px",
        fontWeight: "700",

        // color: "#2c2c2c",
        // fontFamily: "sans-serif",
        // fontSize: "20px",
        // fontWeight: "500",

    },
    cardContent: {
        margin: "auto",
        padding: "auto",
        textAlign: "center",
        // backgroundColor: "red",

    },

    cardtittlecenter: {
        // color: "#4A4A4A",
        // fontFamily: "sans-serif",
        // fontSize: "22px",
        // fontWeight: "600",

        // color: "red",

        color: "#2c2c2c",
        fontSize: "25px",
        fontWeight: 500,
        fontFamily: "sans-serif",
        marginTop: "-10px",

    },

    rupeesymboll: {
        color: "#4A4A4A",
        // color: "red",
        fontSize: "12px",
        fontFamily: "sans-serif",

    },
    cardsubtittle: {
        // color: "red",
        // color: "#4A4A4A",
        // fontFamily: "sans-serif",
        // fontSize: "12px",
        // fontWeight: "500",

        color: "#2c2c2c",
        fontSize: "12px",
        fontFamily: "sans-serif",
        fontWeight: 300,
        opacity: "0.7",

    },


    detailmystyle: {
        color: "#4A4A4A",
        // color: "red",

        fontFamily: "sans-serif",
        fontSize: "13px",
        fontWeight: "500",
    },


    amontmystyle: {

        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "13px",
        fontWeight: "500",
        textAlign: "end"
    },

    amountittle: {
        color: "#4A4A4A",

        fontSize: "15px",
        fontWeight: "500",

    },
    amountstyle: {
        color: "#4A4A4A",
        fontSize: "15px",
        fontWeight: "500",
        textAlign: "end",

        // color: "red",

    },
    sectiontittle: {
        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "20px",
        fontWeight: "500",
    },

    middleboldtittle: {
        color: "#0F72CF",
        // color: "red",
        fontSize: "15px",
        fontFamily: "sans-serif",
        fontWeight: 600,
        opacity: "0.7"
    },

    // sale manu section Style 


    table_th: {
        verticalAlign: "middle",
        textAlign: "center",
        color: "#222222",
    },

    table_tr: {
        textAlign: "center",
        backgroundColor: "#8593ad !important",

    },

    table_td: {
        padding: "5px",
        height: "10px",
        textAlign: "center",

    },
    table_inter: {
        textAlign: "center",
        color: "#222222",

    },

    table_icon: {
        cursor: "pointer",
        color: "#222222"
    },

    table_footer: {
        textAlign: "center",
        color: "#222222",
        fontWeight: "700",
        // padding: "4px",
    },

    inputform_bg: {
        height: "92px !important",
        backgroundColor: "#e8f4fc",
        cursor: "not-allowed",
    },

    cursor_notallowed: {
        backgroundColor: "#e8f4fc",
        cursor: "not-allowed",
    },

    pagetittlestyle: {
        paddingBottom: "5px !important",
    },
    horizontalline: {
        marginTop: "-3px !important",
    },
    pagetittle_container: {
        paddingTop: "4px !important",
    },

    pagetittle_position: {
        position: "absolute",
        paddingTop: "20px !important",
    },

    amountweight: {
        fontWeight: "100 !important"
    },


    simpletable_tr: {
        fontWeight: "700",
        fontFamily: "sans-serif",
        fontSize: "14px",
        opacity: 0.9,
        borderRight: "none",
        borderLeft: "none",
    },

    simpletable_td: {
        fontSize: "14px",
        fontWeight: "300",
        fontFamily: "sans-serif",
        opacity: 0.9,
        borderRight: "none",
        borderLeft: "none",
    },




}));







