import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

const MyDataTable = () => {
    const [brandData, setBrandData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://khetigaadi.com/mobile/api/v1/new-tractor/brand/model');
            const brandModels = response.data.data;
            const formattedData = brandModels.map(brand => ({
                brand_id: brand.brand_id,
                brand_name: brand.brand_name,
                tractor_models: brand.tractor_models,
            }));
            setBrandData(formattedData);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const columns = [
        {
            name: 'brand_id',
            label: 'Brand ID',
        },
        {
            name: 'brand_name',
            label: 'Brand Name',
        },
        {
            name: 'tractor_models',
            label: 'Tractor Models',
            options: {
                customBodyRender: (tractorModels) => {
                    const modelNames = tractorModels.map(model => model.tractor_model_name).join(', ');
                    return modelNames;
                },
            },
        },
    ];

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading data...</p>
            ) : (
                <MUIDataTable
                    title="Tractor Brands and Models"
                    data={brandData}
                    columns={columns}
                    options={options}
                />
            )}
        </div>
    );
};

export default MyDataTable;
