
import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import { Button, Grid, Typography } from '@material-ui/core';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import LinearProgress from '@mui/material/LinearProgress';

import classNames from 'classnames';



import axios from 'axios';
import {
    API_BASE_URL,
    token,
    userLevel,
    adminId
} from "../../../../constants";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        minWidth: 350,
        maxWidth: 950,
    },

}));


export default function CustomizedDialogs() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [userLevels, setUserLevels] = useState([]);

    const [selectedState, setSelectedState] = useState("");
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [talukas, setTalukas] = useState([]);
    // const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    const [selectedTaluka, setSelectedTaluka] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: top100Films,
        getOptionLabel: (option) => option.title,
    });




    // Fetch states data
    useEffect(() => {
        axios
            .get("https://khetigaadi.com/webApi/states")
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error("Error fetching states:", error);
            });
    }, []);

    // // Fetch cities data based on selected state
    useEffect(() => {
        if (selectedState) {
            axios
                .get(`https://khetigaadi.com/webApi/cities/${selectedState}`)
                .then((response) => {
                    setCities(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching cities:", error);
                });
        }
    }, [selectedState]);

    // Fetch talukas data based on selected city
    useEffect(() => {
        if (selectedCity) {
            axios
                .get(`https://khetigaadi.com/webApi/taluka/${selectedCity}`)
                .then((response) => {
                    setTalukas(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching talukas:", error);
                });
        }
    }, [selectedCity]);

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity("");
        setSelectedTaluka("");
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setSelectedTaluka("");
    };

    const handleTalukaChange = (event) => {
        setSelectedTaluka(event.target.value);
    };



    const [formData, setFormData] = useState({
        state_id: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        follower_status: false,
        state: {
            state_id: "",
            state_name: "",
            short_name: ""
        },
        city: {
            city_id: ""
        },
        taluka: {
            id: "",
            name: ""
        },
        user_detail: {
            education_id: null,
            crop_details: [{
                crop: ""
            },
            {
                crop: ""
            }
            ],
            land: "",
            is_owner: 0,
            is_smartphone: 0,
            biography: null,
            awards: null,
            plan: "free",
            whatsapp: "",
            telegram: "",
            alternative_contact_number: null,
            village: "",
            dob: null,
            gender: null,
            purpose: "",
            user_level: {
                user_level_id: "",
                user_level_name: ""
            }
        }
    });

    // const handleChange = (e) => {
    //     const {
    //         name,
    //         value
    //     } = e.target;
    //     setFormData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Split the name into nested property parts
        const propertyNames = name.split(".");

        // Use the property names to traverse the nested structure and update the state
        setFormData((prevData) => {
            let updatedData = { ...prevData };

            // Traverse the nested structure
            let currentLevel = updatedData;
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];

                // Check if it's the last part of the property name
                if (i === propertyNames.length - 1) {
                    // Update the value at the last level
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = value; // Assuming value is directly updated in array
                    } else {
                        currentLevel[propertyName] = value;
                    }
                } else {
                    // Create nested objects if they don't exist
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = currentLevel[index] || {}; // Create empty object if not exists
                    } else {
                        currentLevel[propertyName] = currentLevel[propertyName] || {};
                    }
                    // Move to the next level
                    currentLevel = currentLevel[propertyName];
                }
            }

            return updatedData;
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,
                    'adminId': `${adminId}`,
                    'userLevel': `${userLevel}`,
                },
                timeout: Infinity, // Request will never time out

            };
            const response = await axios.post(
                `${API_BASE_URL}/api/admin/v2/insert-user`,
                formData, config
            );
            console.log(response.data); // Log the response from the server
            // Optionally, you can reset the form data after successful submission
            setFormData({
                // ...formData,
                state_id: "",
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                follower_status: false,
                state: {
                    state_id: "",
                    state_name: "",
                    short_name: ""
                },
                city: {
                    city_id: ""
                },
                taluka: {
                    id: "",
                    name: ""
                },
                user_detail: {
                    education_id: null,
                    crop_details: [{
                        crop: ""
                    },
                    {
                        crop: ""
                    }
                    ],
                    land: "",
                    is_owner: 0,
                    is_smartphone: 0,
                    biography: null,
                    awards: null,
                    plan: "free",
                    whatsapp: "",
                    telegram: "",
                    alternative_contact_number: null,
                    village: "",
                    dob: null,
                    gender: null,
                    purpose: "",
                    user_level: {
                        user_level_id: "",
                        user_level_name: ""
                    }
                }
            });

            if (response.data.error === false && response.data.status_code === 200) {
                setSuccessMessage(response.data.message[0]);
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to update user.');
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage('An error occurred while updating user.');
            setSuccessMessage('');
        }
        setIsLoading(false);
    };




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://khetigaadi.com/webApi/userLevel');
                setUserLevels(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);



    return (

        <div>
            {/* {successMessage && <p> wr{successMessage}</p>}
            {errorMessage && <p> kjh{errorMessage}</p>} */}
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button> */}

            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button> */}

            <div>
                <AddCircleIcon className={classes.tableiconedit} style={{ cursor: "pointer" }} onClick={handleClickOpen} />
            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {/* <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                        Add-Update User
                    </Typography> */}
                    <Typography className={classes.tabletittle} >
                        Add-Update User
                    </Typography>

                    {successMessage && <p className={classes.message}
                        style={{ top: "20px", fontSize: "15px" }}> {successMessage}</p>}

                    {errorMessage && <p className={classes.message}
                        style={{ top: "20px", fontSize: "15px" }}> {errorMessage}</p>}

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {
                    isLoading ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }
                <DialogContent dividers style={{ paddingRight: "40px", paddingLeft: "40px" }} >
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={2} >
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>First Name</label><br />
                                <input
                                    required
                                    placeholder='First Name'
                                    className={classes.inputform}
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Last Name</label><br />
                                <input
                                    required
                                    placeholder='Last Name'
                                    className={classes.inputform}
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange} />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Email</label><br />
                                <input

                                    placeholder='Email'
                                    className={classes.inputform}
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Mobile</label><br />
                                <input
                                    required
                                    placeholder='Mobile'
                                    className={classes.inputform}
                                    type="number"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>WhatsApp Number</label><br />
                                <input
                                    placeholder='WhatsApp Number'
                                    className={classes.inputform}
                                    type="number"
                                    name="user_detail.whatsapp"
                                    value={formData.user_detail.whatsapp}
                                    onChange={handleChange}
                                />
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
                                <label className={classes.labelform}>Same as Mobile Number
                                </label>

                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Telegram Number</label><br />
                                <input
                                    placeholder='Telegram Number'
                                    className={classes.inputform}
                                    type="number"
                                    name="user_detail.telegram"
                                    value={formData.user_detail.telegram}
                                    onChange={handleChange}
                                />
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
                                <label className={classes.labelform}>Same as Whatsapp  No.
                                </label>
                            </Grid>

                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Smart Phone User</label><br />
                                <select form="carform"
                                    name="user_detail.is_smartphone"
                                    value={formData.user_detail.is_smartphone}
                                    onChange={handleChange}
                                    className={classes.selectform} >
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Select Occupation</label><br />
                                <select
                                    name="user_detail.user_level.user_level_id"
                                    value={formData.user_detail.user_level.user_level_id}
                                    onChange={handleChange}
                                    className={classes.selectform} >
                                    <option value="" selected="">
                                        Select Occupation
                                    </option>
                                    {userLevels?.map((userLevel) => (
                                        <option key={userLevel.user_level_id} value={userLevel.user_level_id}>
                                            {userLevel.user_level_name}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Land</label><br />
                                <input
                                    placeholder='Enter Your Land Space'
                                    className={classes.inputform}
                                    type="number"
                                    name="user_detail.land"
                                    value={formData.user_detail.land}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>User Type</label><br />
                                <select id="cars" name="carlist" form="carform"
                                    className={classes.selectform} >
                                    <option value="">Select User Type</option>
                                    <option value="user" selected="">User</option>
                                    <option value="dealer">Dealer</option>
                                    <option value="broker">Broker</option>
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Tractor Owner
                                </label><br />
                                <select id="cars" name="carlist" form="carform"
                                    className={classes.selectform} >
                                    <option value="user" selected="">Yes</option>
                                    <option value="dealer">No</option>
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                            </Grid>

                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select State
                                </label>
                                <br />

                                <select
                                    required
                                    className={classes.selectform}
                                    name="state.state_id"
                                    value={formData?.state.state_id || ""}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleStateChange(e);
                                    }}
                                >
                                    <option value="">Select a state</option>
                                    {states?.map((state) => (
                                        <option key={state.id} value={state.id}>
                                            {state.value}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select District
                                </label>
                                <br />

                                <select
                                    required
                                    className={classes.selectform}
                                    name="city.city_id"
                                    value={formData?.city?.city_id || ""}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleCityChange(e);
                                    }}

                                >
                                    <option value="">Select a city</option>
                                    {cities?.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.value}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select Taluka
                                </label>
                                <br />

                                <select
                                    required
                                    className={classes.selectform}
                                    name="taluka.id"
                                    value={formData?.taluka?.id || ""}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleTalukaChange(e);
                                    }}
                                >
                                    <option value="">Select taluka</option>
                                    {talukas?.map((taluka) => (
                                        <option key={taluka.id} value={taluka.id}>
                                            {taluka.value}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label className={classes.labelform}>Select Village</label><br />
                                <input
                                    className={classes.inputform}
                                    type="text"
                                    name="user_detail.village"
                                    value={formData.user_detail.village}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <br />



                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center',     // Center vertically
                                    height: '100%',
                                }}>
                                    <Button
                                        type='submit'
                                        style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                        className={classes.buttonform}
                                        variant="outlined"
                                        startIcon={<SaveIcon />}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div >
    );
}




// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top Listbox
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },

];




