import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';

const QuotationBookReport = () => {
    const history = useHistory();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const { id, name, email, phone, city } = useParams();
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isEnabledMap, setIsEnabledMap] = useState({});
    const [expanded, setExpanded] = useState({});
    const [filterText, setFilterText] = useState("");
    const [openSidebar, setOpenSidebar] = useState(false);


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate]);

    useEffect(() => {
        applySearchFilter();
    }, [searchText]);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            const initialEnabledMap = {};
            response.data.forEach((item) => {
                initialEnabledMap[item.id] = true; // Initialize all rows as enabled
            });
            setData(response.data);
            setIsEnabledMap(initialEnabledMap);
            setFilteredData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || itemDate >= start) && (!endDate || itemDate <= end);
        });
        setFilteredData(filtered);
    };

    const handleDisable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: false,
        }));
    };

    const handleEnable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: true,
        }));
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Job Title",
        },

        {
            name: "name",
            label: "Experience",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Vanancy",

            options: {
                filter: true,
                filterType: 'multiselect', // Use a multiselect filter

                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        // {
        //     name: "email",
        //     label: "Status",
        // },



        {
            name: 'email',
            label: 'Status',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: 'left',
                }),
                customBodyRender: (value, tableMeta) => {
                    const cellText = value;
                    const isExpanded = expanded[tableMeta.rowIndex];

                    // Define the maximum number of characters to display initially
                    const maxLength = 10;

                    return (
                        <div>
                            {isExpanded ? (
                                // If expanded, show the full content and a "Read Less" link
                                <>
                                    {cellText}{' '}
                                    <span
                                        onClick={() =>
                                            setExpanded({ ...expanded, [tableMeta.rowIndex]: false })
                                        }
                                    >
                                        {/* <SwipeLeftIcon style={{ color: "#dc3545" }} fontSize='small' className={classes.tableiconedit} /> */}
                                        <p style={{ color: "#dc3545" }} fontSize='small' className={classes.tableiconedit} >
                                        </p>

                                        {/* Less */}
                                    </span>
                                </>
                            ) : cellText.length > maxLength ? (
                                // If not expanded, show a portion of the content and a "Read More" link
                                <>
                                    {cellText.slice(0, maxLength)}...
                                    <span
                                        onClick={() =>
                                            setExpanded({ ...expanded, [tableMeta.rowIndex]: true })
                                        }
                                    >
                                        {/* More */}
                                        {/* <SwipeRightIcon style={{ cursor: "pointer", }} fontSize='small' className={classes.tableiconview} /> */}
                                        <p style={{ cursor: "pointer", }} fontSize='small' className={classes.tableiconview} >
                                        </p>

                                    </span>
                                </>
                            ) : (
                                // If the content is short, no need for "Read More"
                                cellText
                            )
                            }
                        </div >
                    );
                },
            },
        },

        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         setCellProps: () => ({
        //             align: "right",
        //         }),
        //         customBodyRender: (value, tableMeta) => {
        //             const rowIndex = tableMeta.rowIndex;
        //             const rowData = data[rowIndex];
        //             return (
        //                 <>

        //                     <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center" }} >
        //                         {/* <Link to={`/app/QuotationBookReportView/${rowData.id}`} > <VisibilityIcon className={classes.tableiconview} /></Link> */}
        //                         <Link to={`/app/update/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableiconedit} /> </Link>
        //                         {/* <Link to={`/app/AddformUpdate/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableicon} /> </Link>
        //                         <Link> <DeleteIcon onClick={() => handleDelete(rowData.id)} className={classes.tableicon} /></Link> */}
        //                     </div>
        //                 </>
        //             );
        //         },
        //     },
        // },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: true, // Enables filtering for this column
                setCellProps: () => ({
                    align: "right", // Sets cell alignment to the right
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const filterValueIndex = 0; // Replace with the correct index of the value you want to filter by
                    const rowData = tableMeta.rowData;

                    // Check if the index is within the bounds of the rowData array
                    if (filterValueIndex >= 0 && filterValueIndex < rowData.length) {
                        const filterValue = rowData[filterValueIndex]; // Get the value to filter by

                        return (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        margin: "auto",
                                        padding: "auto",
                                        textAlign: "center",
                                    }}
                                >
                                    {/* Update the Link to include the filterValue as a query parameter */}
                                    <Link to={`/app/update/${rowData[filterValueIndex]}?filterValue=${filterValue}`}>
                                        <EditIcon fontSize='small' className={classes.tableiconedit} />
                                    </Link>
                                </div>
                            </>
                        );
                    } else {
                        return null; // Handle the case where the index is out of bounds
                    }
                },
            },
        }

    ];

    const options = {
        filter: true,

        // filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 9999, 99999], // Use a large number to simulate "Select All"
        filterType: "custom",
        customFilterDialogFooter: () => (
            <div>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Close
                </Button>
            </div>
        ),
        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} lg={9}>
                        <label for="birthday" className={classes.labelform}>Search Job Title</label><br />
                        <input
                            placeholder='Type To Search Job Title'
                            className={classes.inputform}
                            type="text"
                            label="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Grid>



                    <Grid item xs={6} sm={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                        <br />
                        <Button
                            onClick={applySearchFilter}
                            style={{ backgroundColor: "#34495e", borderColor: "#34495e" }}
                            className={classes.buttonform}
                            // startIcon={<SearchIcon />}
                            variant="outlined"
                        >
                            Apply
                        </Button>
                    </Grid>


                    {/* <Grid item xs={6} sm={6} lg={6}
                        style={{ margin: "auto", padding: "auto", textAlign: "right" }}
                        textAlign="right" className='headbutton' >
                        <br />
                        <Link className="button" to="/">
                            <Button
                                className={classes.buttonred}
                                variant="outlined" startIcon={<PostAddIcon />}>
                                Add New Job Openings
                            </Button>
                        </Link>
                    </Grid> */}
                </Grid>
            </div>
        ),
        filterType: "multiselect", // You can change this based on your filter requirements
        onFilterChange: (changedColumn, filterList) => {
            // Handle filter change if needed
        },


    };

    const currentTheme = customTheme(true);
    return (
        <>

            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Recruitment Details
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}  >
                    <Grid container spacing={1}>
                        <Grid item xs={2} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>Search Job Title</label><br />
                            <input
                                placeholder='Type To Search Job Title'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>


                        <Grid item xs={7} sm={7} lg={7}>
                            <br />
                            <Button
                                onClick={applySearchFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>


                        <Grid item xs={3} sm={3} lg={3}
                            style={{ margin: "auto", padding: "auto", textAlign: "right" }}
                            textAlign="right" className='headbutton' >
                            <br />
                            <Link className="button" to="/">
                                <Button
                                    className={classes.buttonred}
                                    variant="outlined" startIcon={<PostAddIcon />}>
                                    Add New Job Openings
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <br />

            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={
                        <Typography className={classes.tabletittle} >
                            Recruitment Details
                        </Typography>
                    }
                    // data={data}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>

        </>
    );
};

export default QuotationBookReport;



