import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import useStyles from '../../../StyleComponents/StyleFunctionComponent';

const QuotationBookReport = () => {
    const history = useHistory();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const { id, name, email, phone, city } = useParams();
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isEnabledMap, setIsEnabledMap] = useState({});
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        // marginTop: '500px', // Add margin-top

        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            minWidth: 600,
            maxWidth: 800,
            overflowY: 'auto', // Hide vertical overflow
            // overflowY: 'hidden', // Hide vertical overflow
            overflowX: 'hidden',
        },

    }));


    const openModal = (rowData) => {
        setSelectedRowData(rowData);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate]);

    useEffect(() => {
        applySearchFilter();
    }, [searchText]);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            const initialEnabledMap = {};
            response.data.forEach((item) => {
                initialEnabledMap[item.id] = true; // Initialize all rows as enabled
            });
            setData(response.data);
            setIsEnabledMap(initialEnabledMap);
            setFilteredData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || itemDate >= start) && (!endDate || itemDate <= end);
        });
        setFilteredData(filtered);
    };

    const handleDisable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: false,
        }));
    };

    const handleEnable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: true,
        }));
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Tyre Name",
        },

        {
            name: "name",
            label: "Brand",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Model",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "email",
            label: "Size",
        },

        {
            name: "phone",
            label: "Type ",
        },
        {
            name: "name",
            label: "category ",
        },
        {
            name: "name",
            label: "Side",
        },

        {
            name: "actions",
            label: "Status",
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];
                    const id = rowData.id;

                    return (
                        <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center", paddingBottom: "2px" }} >
                            <Button
                                className={classes.buttongreen}
                                onClick={() => handleDisable(id)}
                                disabled={!isEnabledMap[id]}
                                style={{
                                    backgroundColor: isEnabledMap[id] ? 'green' : 'grey',
                                    color: 'white',
                                }}
                            >
                                Disable
                            </Button>
                            <Button
                                className={classes.buttongrey}
                                onClick={() => handleEnable(id)}
                                disabled={isEnabledMap[id]}
                                style={{
                                    backgroundColor: isEnabledMap[id] ? 'grey' : 'green',
                                    color: 'white',
                                }}
                            >
                                Enable
                            </Button>
                        </div>
                    );
                },
            },
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];
                    return (
                        <>

                            <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center" }} >
                                <Link onClick={() => openModal(rowData)}> <VisibilityIcon className={classes.tableiconview} /></Link>
                                <Link to={`/app/Newtyresupdate/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableiconedit} /> </Link>
                                {/* <Link to={`/app/AddformUpdate/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableicon} /> </Link>
                                <Link> <DeleteIcon onClick={() => handleDelete(rowData.id)} className={classes.tableicon} /></Link> */}
                            </div>
                        </>
                    );
                },
            },
        },
    ];
    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 9999, 99999], // Use a large number to simulate "Select All"

    };

    const currentTheme = customTheme(true);
    return (
        <>

            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Tyres
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}  >
                    <Grid container spacing={1}>


                        <Grid item xs={2} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>Search Tyres </label><br />
                            <input
                                placeholder='Type To Search Tyres'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>


                        <Grid item xs={8} sm={8} lg={8}>
                            <br />
                            <Button
                                onClick={applySearchFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>


                        <Grid item xs={2} sm={2} lg={2}
                            style={{ margin: "auto", padding: "auto", textAlign: "right" }}
                            textAlign="right" className='headbutton' >
                            <br />
                            <Link className="button" to="/app/NewtyresForm">
                                <Button
                                    className={classes.buttonred}
                                    variant="outlined" startIcon={<PostAddIcon />}>
                                    Add Tyres
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <br />

            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={
                        <Typography className={classes.tabletittle} >
                            Tyres
                        </Typography>
                    }
                    // data={data}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>

            <BootstrapDialog
                onClose={closeModal}
                aria-labelledby="customized-dialog-title"
                open={isModalOpen}
            >
                <Grid container spacing={3}>
                    <Grid xs={6} lg={6}>
                        <DialogTitle sx={{ m: 0, pl: 4 }} id="customized-dialog-title">
                            <Typography className={classes.tabletittle} >
                                Information
                            </Typography>
                        </DialogTitle>
                    </Grid>
                    <Grid xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                        <IconButton
                            aria-label="close"
                            onClick={closeModal}
                            style={{ marginRight: "10px" }}

                        >
                            <CloseIcon />
                        </IconButton>

                    </Grid>
                </Grid>
                <DialogContent dividers>
                    {selectedRowData && (
                        <div style={{ marginTop: "-15px" }}>
                            <p>Tyre Name: <span className={classes.breadcrumbslinkHightlight}> {selectedRowData.name} </span></p>
                            <p>Brand: <span className={classes.breadcrumbslinkHightlight}> {selectedRowData.username} </span></p>
                            <p>Model: <span className={classes.breadcrumbslinkHightlight}> {selectedRowData.email} </span></p>
                            <p>Size: <span className={classes.breadcrumbslinkHightlight}> {selectedRowData.address.suite} </span></p>
                            <p>Type: <span className={classes.breadcrumbslinkHightlight}> {selectedRowData.address.geo.lat} </span></p>
                            <p>Category: <span className={classes.breadcrumbslinkHightlight}> {selectedRowData.address.geo.lng} </span></p>
                            <p>Image: <span className={classes.breadcrumbslinkHightlight}>
                            </span></p>
                            <img
                                src={imageUrl}
                                alt="Apollo Tyre"
                                style={imageStyle} // Apply the inline CSS styles here
                            />
                            <p>Overview:</p>
                            {/* <p style={{ opacity: "0.9" }}>About Farmtrac 60 Powermaxx 8+2 Tractor
                            </p>

                            <span className={classes.breadcrumbslinkHightlight}>
                                The Farmtrac 60 Powermaxx 8+2 is a remarkable tractor that offers superior performance and efficiency. This powerful machine is equipped with advanced features and cutting-edge technology to meet the demanding needs of modern agriculture.
                                <br />
                                The Farmtrac 60 Powermaxx 8+2 tractor is a large model with an excellent Farmtrac 60 Powermaxx 8+2 price. Additionally, the Farmtrac 60 Powermaxx 8+2 effectively meets all farming requirements. Here we display the features as well as the price of the Farmtrac 60 Powermaxx 8+2, which are listed below.

                                Farmtrac 60 Powermaxx 8+2 tractor price starts at Rs. 8.75 Lac. For more information about the Farmtrac 60 Powermaxx 8+2 tractor, contact the Khetigaadi executive.

                            </span>

                            <p style={{ opacity: "0.8" }}>Farmtrac 60 Powermaxx 8+2 Feature
                            </p>
                            <ul>
                                <li className={classes.breadcrumbslinkHightlight}>The Farmtrac 60 Powermaxx 8+2 Tractor has a 55 HP, 3-cylinder engine.</li>
                                <li className={classes.breadcrumbslinkHightlight}>The best engine size of the Farmtrac 60 Powermaxx 8+2 Tractor, 3514CC, offers good field mileage.</li>
                                <li className={classes.breadcrumbslinkHightlight}>It has 8 forward gears and 2 reverse gearboxes.</li>
                                <li className={classes.breadcrumbslinkHightlight}>It provides a large 60-liter fuel tank capacity for use on farms for a long period of time.</li>
                                <li className={classes.breadcrumbslinkHightlight}>Farmtrac 60 Powermaxx 8+2 Tractor has an 1800-kg lifting capacity.</li>
                                <li className={classes.breadcrumbslinkHightlight}>The Farmtrac 60 Powermaxx 8+2 Tractor has a Dual clutch model.</li>
                            </ul>
                            <p style={{ opacity: "0.8" }}>FARMTRAC 60 POWERMAXX 8+2 FULL SPECIFICATIONS

                            </p>
                            <table style={tableStyle}>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle} >HP Category
                                        </td>
                                        <td className={classes.breadcrumbslinkHightlight} style={tdStyle}>55 HP</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>Engine Capacity</td>
                                        <td className={classes.breadcrumbslinkHightlight} style={tdStyle}>3514 CC</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>Engine Rated RPM</td>
                                        <td style={tdStyle} className={classes.breadcrumbslinkHightlight}>1850 RPM</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>No of Cylinder</td>
                                        <td style={tdStyle} className={classes.breadcrumbslinkHightlight}>3</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>Brake Type</td>
                                        <td style={tdStyle} className={classes.breadcrumbslinkHightlight}>Multiplate Oil immersed Brakes</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>Steering Type</td>
                                        <td style={tdStyle} className={classes.breadcrumbslinkHightlight}>Balanced Power steering</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>PTO Power</td>
                                        <td style={tdStyle} className={classes.breadcrumbslinkHightlight}>49 HP</td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}>PTO RPM</td>
                                        <td style={tdStyle} className={classes.breadcrumbslinkHightlight}>1810</td>
                                    </tr>
                                </tbody>
                            </table> */}


                        </div>
                    )}
                </DialogContent>
            </BootstrapDialog>


        </>
    );
};

export default QuotationBookReport;



const tableStyle = {
    borderCollapse: 'collapse',
    width: '50%',
    margin: '0 auto',
};

const thStyle = {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    textAlign: 'left',
};

const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #ddd',
};

const imageUrl = 'https://khetigaadi.com/images/tyres/apollo%20tyre%20farmking%20front.png';

// Define inline CSS styles for the image (optional)
const imageStyle = {
    width: '150px', // Example width
    height: 'auto', // Automatically adjust height to maintain aspect ratio
    border: '2px solid #ccc', // Example border
    borderRadius: '10px', // Example border radius
};



