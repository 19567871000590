import React, { useState } from 'react';
import axios from 'axios';

const Form = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [responseData, setResponseData] = useState(null);

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.get(`https://jsonplaceholder.typicode.com/${selectedOption}`);
            setResponseData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            <h2>Form with Select Option</h2>
            <form onSubmit={handleSubmit}>
                <select onChange={handleSelectChange} value={selectedOption}>
                    <option value="">Select an option</option>
                    <option value="posts">Posts</option>
                    <option value="comments">Comments</option>
                    <option value="todos">Todos</option>
                </select>
                <button type="submit">Submit</button>
            </form>

            {responseData && (
                <div>
                    <h3>Response Data:</h3>
                    <pre>{JSON.stringify(responseData, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default Form;




