import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { API_BASE_URL, token } from '../../../../constants';
import { useHistory } from 'react-router-dom'; // Import useHistory
import { Grid } from '@material-ui/core';
import AddUpdateUser from "../AddUpdateUser/AddUpdateUser";
import useStyles from "../../../StyleComponents/StyleFunctionComponent";



const AutocompleteSearch = () => {
    const classes = useStyles();

    const history = useHistory();


    const [options, setOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        let cancelRequest = false;

        const searchUser = async () => {
            try {
                setLoading(true);

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity,
                };

                const payload = {
                    "mobile": searchValue,
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/user-search`, payload, config);

                const userData = response.data.data;

                const userList = userData.map(user => ({
                    id: user.user_id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    usermobile: user.mobile,
                }));

                setOptions(userList);
            } catch (error) {
                if (!cancelRequest) {
                    setError(error.message || 'Error fetching users');
                    console.error('Error fetching users:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        if (searchValue.trim() !== '') { // Only trigger search if searchValue is not empty
            searchUser();
        } else {
            setOptions([]); // Clear options if searchValue is empty
        }

        return () => {
            cancelRequest = true; // Cleanup function to handle component unmounting
        };
    }, [searchValue]);

    const handleSelectChange = (event, selectedOption) => {
        setSelectedItem(selectedOption);

        // // Redirect to a new page with the selected user_id
        // if (selectedOption) {
        //     history.push(`/app/AddNewEnquiry/${selectedOption.id}`);
        // }

        // Redirect to a new page with the selected user_id in a new window


        if (selectedOption) {
            window.open(`/app/AddNewEnquiry/${selectedOption.id}`, '_blank');
        }
    };


    return (


        <div>

            <Grid container spacing={1}>
                <Grid item xs={12} lg={12} sm={12}>
                    <Autocomplete

                        classes={{
                            // listbox: classes.listbox,
                            option: classes.autocompleteoption,
                        }}
                        options={options}
                        // getOptionLabel={(option) => option.firstName}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}-${option.usermobile}`}

                        value={selectedItem}
                        onChange={handleSelectChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                onChange={(e) => setSearchValue(e.target.value)}
                                error={!!error}
                                helperText={error}
                                placeholder='Global Search'


                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
                        loading={loading}

                    />
                </Grid>
                {/* <Grid item xs={4} sm={2} lg={1} >
                    <div style={{ paddingTop: "8px" }}>
                        <AddUpdateUser />
                    </div>
                </Grid> */}
            </Grid>

        </div>
    );
};

export default AutocompleteSearch;