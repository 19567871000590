import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Stepper, Step, StepLabel, Button, Container, Paper } from '@mui/material';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step1';
import Step4 from './Steps/Step1';
import Step5 from './Steps/Step1';

const steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5'];

const AddUpdateForm = () => {
    const [formData, setFormData] = useState({ name: '', email: '', /* Add other form fields here */ });
    const { userId } = useParams();
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (userId) {
            // Fetch user data for updating
            fetch(`http://localhost:3003/users/${userId}`)
                .then((response) => response.json())
                .then((user) => {
                    setFormData(user);
                })
                .catch((error) => console.error('Error fetching user:', error));
        }
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (activeStep === steps.length - 1) {
            if (userId) {
                // Handle update logic
                fetch(`http://localhost:3003/users/${userId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then(() => {
                        history.push('/');
                    })
                    .catch((error) => console.error('Error updating user:', error));
            } else {
                // Handle add logic
                fetch('http://localhost:3003/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then(() => {
                        history.push('/');
                    })
                    .catch((error) => console.error('Error adding user:', error));
            }
        } else {
            handleNext();
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Step1 formData={formData} handleInputChange={handleInputChange} />;
            case 1:
                return <Step2 formData={formData} handleInputChange={handleInputChange} />;
            case 2:
                return <Step3 formData={formData} handleInputChange={handleInputChange} />;
            case 3:
                return <Step4 formData={formData} handleInputChange={handleInputChange} />;
            case 4:
                return <Step5 formData={formData} handleInputChange={handleInputChange} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Container maxWidth="sm">
            <a href='http://localhost:3000/app/edit/3'>http://localhost:3000/app/edit/3</a>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <form onSubmit={handleSubmit}>
                    {getStepContent(activeStep)}
                    <div style={{ marginTop: '20px' }}>
                        {activeStep !== 0 && (
                            <Button variant="contained" onClick={handleBack}>
                                Back
                            </Button>
                        )}
                        <Button variant="contained" type="submit" color="primary">
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </div>
                </form>
            </Paper>
        </Container>
    );
};

export default AddUpdateForm;
