// Step1.js
import React from 'react';
import TextField from '@mui/material/TextField';
import { Button, Grid } from '@material-ui/core';

import useStyles from '../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';


const Step1 = ({ formData, handleInputChange }) => {

    const classes = useStyles();

    return (
        <>
            {/* <div>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                />
            </div> */}
            <br />

            <Grid container spacing={2} >
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Select Brand</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Model Name</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tractor Name</label><br />
                    <input
                        placeholder='Tractor Name'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tractor Name In Marathi</label><br />
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tractor Name In Hindi</label><br />
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Drive</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>HP Range</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>

                <Grid item xs={6} sm={6} lg={4} >
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Engine HP</label><br />
                    <input
                        placeholder='Engine HP'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Price From</label><br />
                    <input
                        placeholder='Price From'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Price To</label><br />
                    <input
                        placeholder='Price To'
                        className={classes.inputform}
                        type="text"
                        name="lng"
                        value={formData.address.geo.lng}
                        onChange={handleInputChange}
                        fullWidth

                    />
                </Grid> 

            </Grid >


        </>



    );
};

export default Step1;
