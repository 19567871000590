import { createMuiTheme } from '@material-ui/core';

// Describe material ui theme
export const customTheme = () => {
    return createMuiTheme({

        overrides: {


            MuiTableCell: {
                root: {
                    padding: "1px",
                    fontWeight: "300",
                    fontFamily: "sans-serif",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    // backgroundColor: "red"
                    opacity: 0.9,
                },
            },
            MUIDataTableHeadCell: {
                root: {
                    fontWeight: "700",
                    fontFamily: "sans-serif",
                    padding: "10px",
                    fontSize: "14px",
                    // opacity: 0.8,
                },
                toolButton: {
                    justifyContent: 'center'
                },

            },

            MuiTableHead: {
                root: {
                    // backgroundColor: "red",
                    // opacity: '1 !important',
                    // backgroundColor: "#8593ad !important",
                    // backgroundColor: "red !important",

                },
            },

            MUIDataTableHeadRow: {
                root: {
                    boxShadow: "0px -2px 0px #fff",
                    borderBottom: "2px solid #e0e0e0", // Add your desired border style

                    position: "sticky",
                    top: 0,
                    zIndex: 100,
                    background: "#8593ad !important",
                    // background: "red",
                },

            },

            MuiToolbar: {
                regular: {
                    minHeight: '12px !important',
                },
            },

            MUIDataTable: {
                responsiveBase: {
                    maxHeight: '400px !important',
                    overflow: "auto",
                },
            },



        },
    });
};

// .MUIDataTable-responsiveBase-843 {
//     overflow: auto;
//     height: 300px;
//     max-height: 300px;
// }