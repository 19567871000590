import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import Button from '@mui/material/Button';

const DataTableWithExportButton = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://jsonplaceholder.typicode.com/users')
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const columns = [
        {
            name: 'id',
            label: 'ID',
        },
        {
            name: 'name',
            label: 'Title',
        },
        {
            name: 'email',
            label: 'Body',
        },
        {
            name: 'actions',
            label: 'Actions',
            options: {
                filter: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <>
                            <div style={{ display: "flex" }}>
                                <p>sdfsf</p>

                            </div>
                        </>
                    );
                },
            },
        },
    ];




    const exportToCSV = () => {
        const header = ['ID', 'Name', 'Body'];

        const csvData = data.map((item) => [item.id, item.name, item.body].join(','));
        const csvContent = 'data:text/csv;charset=utf-8,' + header.join(',') + '\n' + csvData.join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'data.csv');
        link.click();
    };


    const options = {
        filter: true,
        selectableRows: 'none',
    };

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={exportToCSV}
            >
                Export CSV
            </Button>
            <MUIDataTable
                title="Posts Data"
                data={data}
                columns={columns}
                options={options}
            />
        </div>
    );
};

export default DataTableWithExportButton;
