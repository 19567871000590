import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';


function FormWithAddRemove() {
    const [options, setOptions] = useState([]);
    const [userState, setUserState] = useState([{ name: '', date: '' }]);

    useEffect(() => {
        axios.get('http://localhost:3003/autocompleteView')
            .then(response => {
                setOptions(response.data);
            })
            .catch(error => {
                console.error('Error fetching autocomplete options:', error);
            });
    }, []);

    const handleAddRow = () => {
        setUserState([...userState, { name: '', date: '' }]);
    };

    const handleRemoveRow = (index) => {
        const updatedRows = [...userState];
        updatedRows.splice(index, 1);
        setUserState(updatedRows);
    };

    const handleNameChange = (index, newValue) => {
        const updatedRows = [...userState];
        updatedRows[index].name = newValue ? newValue.name : '';
        updatedRows[index].date = newValue ? newValue.date : '';
        setUserState(updatedRows);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission here, e.g. sending data to a server
        console.log(userState);
    };
    const optionsd = ['Option 1', 'Option 2', 'Option 3'];


    return (
        <div>

            <br />
            <br />
            <br />

            <ThemeProvider theme={theme} disableGeneration>
                <Autocomplete
                    options={optionsd}
                    renderInput={(params) => (
                        <TextField
                            style={customInputStyle} // Apply the custom style here

                            {...params}

                        />
                    )}
                />
            </ThemeProvider>

            <br />
            <br />
            <br />
            <br />

            <button onClick={handleAddRow}>Add Row</button>
            <form onSubmit={handleSubmit}>
                {userState.map((row, index) => (
                    <div key={index}>
                        <Autocomplete
                            style={{ padding: "1px", margin: "1px", height: "10px", paddingTop: "1px", paddingBottom: "100px" }}
                            options={options}
                            getOptionLabel={(option) => option.name}
                            value={options.find(option => option.name === row.name) || null}
                            onChange={(_, newValue) => handleNameChange(index, newValue)}
                            renderInput={(params) => (
                                <TextField
                                    style={{ padding: "1px", margin: "1px", height: "10px", paddingTop: "1px", paddingBottom: "100px" }}

                                    placeholder='fd' {...params} />
                            )}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <TextField
                            label="Date"
                            variant="outlined"
                            value={row.date}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                        <button type="button" onClick={() => handleRemoveRow(index)}>Remove</button>
                    </div>
                ))}
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default FormWithAddRemove;



// Create a custom theme with overrides
const theme = createTheme({
    overrides: {
        MuiAutocomplete: {
            root: {
                height: '10px !important',
                width: '20px !important',
                padding: '0px',
            },
            inputRoot: {
                borderRadius: 0, // Remove input border radius
            },
            input: {
                padding: '0px', // Adjust padding as needed
            },
        },
        MuiOutlinedInput: {
            root: {
                padding: '0px !important',
            },

        },
        MuiInputBase: {
            root: {
                height: '10px !important',
                width: '20px !important',
                padding: '0px !important',
            },

        }
    },
});





const customInputStyle = {
    font: 'inherit',
    letterSpacing: 'inherit',
    color: 'currentColor',
    // padding: '4px 0 5px',
    border: '0',
    boxSizing: 'content-box',
    background: 'none',
    // height: '10px !important',
    // width: '20px !important',
    // padding: '0px !important',
    height: '5%',
    width: '50%',
    padding: '0%',
    margin: '0',
    WebkitTapHighlightColor: 'transparent',
    display: 'block',
    minWidth: '0',
    WebkitAnimationName: 'mui-auto-fill-cancel',
    animationName: 'mui-auto-fill-cancel',
    WebkitAnimationDuration: '10ms',
    animationDuration: '10ms',
    paddingRight: '0', // You had this property twice in the original CSS rule
};