import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import './Recrument.css';
// import Recrumentimage from '../../../Components/Image/Group 607@2x.png';
import Recrumentimage from '../../../Components/Image/Onboarding.svg';




// import EmployeeeManagementimage from '../../../Components/Image/Attendance & Leaves@2x.png'
import EmployeeeManagementimage from '../../../Components/Image/Attendance & Leaves.svg'



// import PolicyDocumentsimage from '../../../Components/Image/ils_05.png';
import PolicyDocumentsimage from '../../../Components/Image/Salary & Payroll (1).svg';



import Symboll from '../../../Components/Image/Group 611@2x.png';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <>
            <Container>
                <br />
                <br />
                <br />

                {/* <div className="reverse-boxes">
                    <button className='revarse-grid'>Button 1 p</button>
                    <button className='revarse-grid'>Button 2 i</button>
                    <button className='revarse-grid'>Button 3 p</button>
                    <button className='revarse-grid'>Button 4 i</button>
                </div> */}


                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} className="reverse-boxes">
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <img width={300} height="auto" src={Symboll} className="symboll-img" />
                            {/* <img width={200} src={Symboll} className="symboll-imgas" /> */}
                            <img className="" src={Recrumentimage} alt="React Logo" style={{ width: "100%", height: "100%", paddingTop: "40px", objectFit: "contain" }} />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <section>
                                <div>
                                    <h1 className='hiringbold' style={{ textAlign: "left" }}>Employee Onboarding</h1>
                                    <p className='hiringparagraph'>
                                        Experience trouble free and convenient employee onboarding process with HRVERSE so that employees quickly become productive and effective contributors to your organization. Smooth onboarding process with HRVERSE ensures stronger employee experience and higher employee engagement during various stages of employee onboarding.                                    </p>
                                </div></section>
                        </Grid>

                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <section>
                                <div>
                                    <h1 className='hiringbold'>Attendance & Leaves </h1>
                                    <p className='hiringparagraph'>
                                        Tracking Employee time, work duration, physical attendance, remote attendance is made easy with HRVERSE. At the same time HRVERSE offers end-to-end seamless leave management for your employees. Be it Leave applications, leave approvals, short leaves, total leaves, balance leaves, leave encashment with complete data analysis and insights for HR department.
                                    </p>
                                </div>
                            </section>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <img width={300} height="auto" src={Symboll} className="symboll-img-emp" />
                            <img src={EmployeeeManagementimage} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                            <img width={300} height="auto" src={Symboll} className="symboll-img" />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            {/* <img width={300} height="auto" src={Symboll} className="symboll-img" /> */}
                            <img src={PolicyDocumentsimage} alt="React Logo" style={{ objectFit: "contain", width: "100%", height: "100%", }} />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6} className='revarse-grid'>
                            <section>
                                <div>
                                    <h1 className='hiringbold'>Salary & Payroll </h1>
                                    <p className='hiringparagraph'>
                                        One click salary generation with HRVERSE will save your organization’s time, money & energy. Integrated with attendance & leave records, HRVERSE ensures timely & error-free payroll. HRVERSE ensures applicable statutory deductions as per employee earnings and investments along with tax compliance. HRVERSE provides dedicated interface for each employee for instant access to pay slips, leave insights and much more…                                     </p>
                                </div></section>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <br />
            {/* <h1>dsfrdsf</h1> */}










            {/* 
            <Container style={{ paddingTop: "25px" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} className="">
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <section>
                                <div>
                                    <h1 className='hiringbold'>Attendance & Leaves </h1>
                                    <p className='hiringparagraph'>
                                        Tracking Employee time, work duration, physical attendance, remote attendance is made easy with HRVERSE. At the same time HRVERSE offers end-to-end seamless leave management for your employees. Be it Leave applications, leave approvals, short leaves, total leaves, balance leaves, leave encashment with complete data analysis and insights for HR department.
                                    </p>
                                </div>
                            </section>
                        </Grid><Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <img width={300} height="auto" src={Symboll} className="symboll-img-emp" />
                            <img src={EmployeeeManagementimage} alt="React Logo" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                            <img width={300} height="auto" src={Symboll} className="symboll-img" />
                        </Grid>
                    </Grid>
                </Box></Container>
            <Container style={{ paddingTop: "25px" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <img src={PolicyDocumentsimage} alt="React Logo" style={{ objectFit: "contain", width: "100%", height: "100%", }} />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
                            <section>
                                <div>
                                    <h1 className='hiringbold'>Salary & Payroll </h1>
                                    <p className='hiringparagraph'>
                                        One click salary generation with HRVERSE will save your organization’s time, money & energy. Integrated with attendance & leave records, HRVERSE ensures timely & error-free payroll. HRVERSE ensures applicable statutory deductions as per employee earnings and investments along with tax compliance. HRVERSE provides dedicated interface for each employee for instant access to pay slips, leave insights and much more…                                     </p>
                                </div></section>


                        </Grid>
                    </Grid>

                </Box>
            </Container> */}




            <br />
        </>

    );
}






