import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { customTheme } from '../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import useStyles from '../../StyleComponents/StyleFunctionComponent';

const QuotationBookReport = () => {
    const history = useHistory();
    const classes = useStyles();

    const [data, setData] = useState([]);
    const { id, name, email, phone, city } = useParams();
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isEnabledMap, setIsEnabledMap] = useState({});

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate]);

    useEffect(() => {
        applySearchFilter();
    }, [searchText]);

    const fetchData = async () => {
        try {
            const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            const initialEnabledMap = {};
            response.data.forEach((item) => {
                initialEnabledMap[item.id] = true; // Initialize all rows as enabled
            });
            setData(response.data);
            setIsEnabledMap(initialEnabledMap);
            setFilteredData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || itemDate >= start) && (!endDate || itemDate <= end);
        });
        setFilteredData(filtered);
    };

    const handleDisable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: false,
        }));
    };

    const handleEnable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: true,
        }));
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Type",
        },

        {
            name: "name",
            label: "User",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Product",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "email",
            label: "Title",
        },

        {
            name: "name",
            label: "Comment ",
        },
        {
            name: "phone",
            label: "Rating ",
        },
        {
            name: "actions",
            label: "Status",
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];
                    const id = rowData.id;

                    return (
                        <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center", paddingBottom: "2px" }} >
                            <Button
                                className={classes.buttongreen}
                                onClick={() => handleDisable(id)}
                                disabled={!isEnabledMap[id]}
                                style={{
                                    backgroundColor: isEnabledMap[id] ? 'green' : 'grey',
                                    color: 'white',
                                }}
                            >
                                Disable
                            </Button>
                            <Button
                                className={classes.buttongrey}
                                onClick={() => handleEnable(id)}
                                disabled={isEnabledMap[id]}
                                style={{
                                    backgroundColor: isEnabledMap[id] ? 'grey' : 'green',
                                    color: 'white',
                                }}
                            >
                                Enable
                            </Button>
                        </div>
                    );
                },
            },
        },


    ];
    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 9999, 99999], // Use a large number to simulate "Select All"

    };

    const currentTheme = customTheme(true);
    return (
        <>

            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    New Tractor Reviews
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}  >
                    <Grid container spacing={1}>
                        <Grid item xs={2} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>Search review</label><br />

                            <select id="cars" name="carlist" form="carform"
                                className={classes.selectform} >
                                <option value="volvo">select</option>
                                <option value="saab">Order No.</option>
                                <option value="opel">Customer Name</option>
                            </select>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>Search review</label><br />
                            <input
                                placeholder='Type To Search review'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />

                        </Grid>


                        <Grid item xs={6} sm={6} lg={6}>
                            <br />
                            <Button
                                onClick={applySearchFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>


                        {/* <Grid item xs={2} sm={2} lg={2}
                            style={{ margin: "auto", padding: "auto", textAlign: "right" }}
                            textAlign="right" className='headbutton' >
                            <br />
                            <Link className="button" to="/">
                                <Button
                                    className={classes.buttonred}
                                    variant="outlined" startIcon={<PostAddIcon />}>
                                    Add New Videos
                                </Button>
                            </Link>
                        </Grid> */}
                    </Grid>
                </div>
            </div>
            <br />

            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={
                        <Typography className={classes.tabletittle} >
                            New Tractor Reviews
                        </Typography>
                    }
                    // data={data}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>
    );
};

export default QuotationBookReport;



