import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
import Autocomplete from '@material-ui/lab/Autocomplete';
import LightModeIcon from "@mui/icons-material/LightMode";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import AddUpdateUserEditForm from "../../AddUpdateUser/AddUpdateUserEditForm";
import AddUpdateUser from "../../AddUpdateUser/AddUpdateUser";
import AddTractor from "../../AddTractor/AddTractor";
import useStyles from "../../../../StyleComponents/StyleFunctionComponent";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { API_BASE_URL, token, userLevel } from "../../../../../constants";
import LinearProgress from "@mui/material/LinearProgress";
import SearchUser from "../../../CRM/SearchUser/SearchUser";
import Skeleton from "@material-ui/lab/Skeleton";

import axios from "axios";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";


const AutocompleteDemo = () => {

    // const [tractorOptions, setTractorOptions] = useState([]);
    // const [selectedItem, setSelectedItem] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    const [executiveOptions, setExecutiveOptions] = useState([]);
    const [submittedDataArray, setSubmittedDataArray] = useState([]);

    const [stateOptions, setStateOptions] = useState([]);
    const [selectedState, setSelectedState] = useState("");

    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const [comments, setComments] = useState([]); // State for options of the new select field

    const [selectedItem, setSelectedItem] = useState(null);
    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({
        id: "",
        title: "",
        body: "",
    });

    const [tractorOptions, setTractorOptions] = useState([]);
    const [selectedTractor, setSelectedTractor] = useState(null);

    // @@@@@@@@@@@@@@@ state city taluka start

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [talukas, setTalukas] = useState([]);
    // const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    const [selectedTaluka, setSelectedTaluka] = useState("");
    const [message, setMessage] = useState('');
    const [userLevels, setUserLevels] = useState([]);
    const [isMounted, setIsMounted] = useState(true); // Track component's mounted state


    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enquiryData, setEnquiryData] = useState({
        enquiry_from: "",
        tractor_table: {
            tractor_id: "",
            brand_id: "",
        },
        exchanged: "",
        exchanged_tractor_id: "",
        enquiry_source: "",
        date: "",
        is_dublicate: "",
        enquiry_type: "",
        enquiry_name: "",
        enq_details: [
            {
                remark: "",
                down_payment: "",
                loan_type: "",
                reason: "",
                month_year: "",
            },
        ],
        executive: {
            admin_id: "",
        },
        crm_call_status: {
            id: "",
        },
        user: {
            mobile: "",
            first_name: "",
            last_name: " ",
            email: "",
            state: {
                state_id: "",
            },
            city: {
                city_id: "",
            },
            taluka: {
                id: "",
            },
            user_detail: {
                pincode: "",
                user_level: {
                    user_level_id: "",
                },

                village: "",
                whatsapp: "",
                telegram: "",
                land: "",
                tractor_owner: "",
                purpose: "",
                land_unit: "",
                crop_details: [
                    {
                        crop_id: "",
                        to_days: "",
                        crop_icon: "",
                        crop_name: "",
                        from_days: "",
                        scientific_name: " ",
                    },
                ],
            },
        },
    });


    useEffect(() => {
        const fetchTractorData = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.get(
                    `${API_BASE_URL}/webApi/models/7/${enquiryData.tractor_table.brand_id}`,
                    config
                );

                const tractorList = response.data.models.map((model) => ({
                    id: model.id,
                    name: model.name,
                }));

                setTractorOptions(tractorList);
            } catch (error) {
                console.error("Error fetching tractor data:", error);
            }
        };

        if (enquiryData.tractor_table.brand_id) {
            fetchTractorData();
        }
    }, [enquiryData.tractor_table.brand_id, token]);



    // useEffect(() => {
    //     const fetchTractorData = async () => {
    //         try {
    //             const config = {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Accept: "application/json",
    //                     Authorization: `${token}`,
    //                 },
    //                 timeout: Infinity,
    //             };

    //             const requestData = {
    //                 per_page: 1500,
    //                 page: 1,
    //             };

    //             const response = await axios.post(
    //                 `${API_BASE_URL}/admin/api/v1/report/new/tractor`,
    //                 requestData,
    //                 config,
    //             );

    //             const tractorList = response.data.data.new_tractor.map((tractor) => ({
    //                 id: tractor.tractor_id,
    //                 name: tractor.tractor_name,

    //             }));

    //             setTractorOptions(tractorList);
    //         } catch (error) {
    //             console.error("Error fetching tractor data:", error);
    //         }
    //     };

    //     fetchTractorData();
    // }, []);




    useEffect(() => {
        const fetchDatalist = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(
                    `${API_BASE_URL}/api/admin/v2/executive-list`,
                    config,
                );
                const mappedData = response.data.data.map((item) => ({
                    id: item.admin_id,
                    title: item.username,
                }));

                const optionsWithNull = [
                    { id: null, title: "Select Executive" },
                    ...mappedData,
                ];

                setExecutiveOptions(optionsWithNull);
                // setExecutiveOptions(mappedData);
            } catch (error) {
                console.error("Error fetching executives:", error);
            }
        };

        fetchDatalist();
    }, []);


    const MAX_RETRY_ATTEMPTS = 1;
    const INITIAL_DELAY = 2000; // 1 second


    useEffect(() => {
        let retries = 0;

        const fetchData = async () => {
            try {
                setIsLoading(true);

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.post(
                    // `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}/crm`,
                    config,
                );



                if (isMounted) {
                    setEnquiryData(response.data.data);

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("An error occurred while fetching data.");

                // if (error.response) {
                //     const { status } = error.response;
                //     if (status === 500) {
                //         setError("This data already exists.");
                //     } else if (status === 429 && retries < MAX_RETRY_ATTEMPTS) {
                //         retries++;
                //         const delay = INITIAL_DELAY * Math.pow(2, retries);
                //         setTimeout(fetchData, delay);
                //     } else {
                //         setError("An error occurred while fetching datas.");

                //     }
                // } else {
                //     setError("An error occurred while fetching data.");

                // }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            setIsMounted(false);
        };
    }, [id, token, isMounted]);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://khetigaadi.com/webApi/userLevel');
                setUserLevels(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        axios
            .get("https://khetigaadi.com/webApi/states")
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error("Error fetching states:", error);
            });
    }, []);

    // // Fetch cities data based on selected state
    useEffect(() => {
        if (selectedState) {
            axios
                .get(`https://khetigaadi.com/webApi/cities/${selectedState}`)
                .then((response) => {
                    setCities(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching cities:", error);
                });
        }
    }, [selectedState]);

    // Fetch talukas data based on selected city
    useEffect(() => {
        if (selectedCity) {
            axios
                .get(`https://khetigaadi.com/webApi/taluka/${selectedCity}`)
                .then((response) => {
                    setTalukas(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching talukas:", error);
                });
        }
    }, [selectedCity]);

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity("");
        setSelectedTaluka("");
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setSelectedTaluka("");
    };

    const handleTalukaChange = (event) => {
        setSelectedTaluka(event.target.value);
    };



    // @@@@@@@@@@@@@@@ state city taluka start

    // @@@@@@@@@@@@@@@ crop start
    const [crops, setCrops] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState("");

    // Fetch crops data
    useEffect(() => {
        axios
            .get("https://khetigaadi.com/webApi/crop-list")
            .then((response) => {
                setCrops(response.data);
            })
            .catch((error) => {
                console.error("Error fetching crops:", error);
            });
    }, []);

    // const handleCropChange = (event) => {
    // setSelectedCrop(event.target.value);
    // };
    // @@@@@@@@@@@@@@@ crop end


    if (isLoading) {
        return (
            <div>
                <LinearProgress />
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={3}>
                        <Skeleton animation="wave" variant="rounded" height={300} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Skeleton animation="wave" variant="rounded" height={600} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                </Grid>
            </div>
        );
    }

    if (error) {
        return <p style={{ color: "red" }}>{error}</p>;
    }





    const handleInputChangecomman = (e) => {
        const { name, value } = e.target;

        // Split the name into nested property parts
        const propertyNames = name.split(".");

        // Use the property names to traverse the nested structure and update the state
        setEnquiryData((prevData) => {
            let updatedData = { ...prevData };

            // Traverse the nested structure
            let currentLevel = updatedData;
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];

                // Check if it's the last part of the property name
                if (i === propertyNames.length - 1) {
                    // Update the value at the last level
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = value; // Assuming value is directly updated in array
                    } else {
                        currentLevel[propertyName] = value;
                    }
                } else {
                    // Create nested objects if they don't exist
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = currentLevel[index] || {}; // Create empty object if not exists
                    } else {
                        currentLevel[propertyName] = currentLevel[propertyName] || {};
                    }
                    // Move to the next level
                    currentLevel = currentLevel[propertyName];
                }
            }

            return updatedData;
        });
    };

    const getCurrentDate = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1); // Extend current date by one day
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };




    const formatDataForSubmission = (data) => {

        const statusId = enquiryData.crm_call_status.id;

        const date = (statusId === "5" || statusId === "6" || statusId === "7") ? getCurrentDate() : enquiryData?.date;

        return {
            // ...data,

            tractor_table: {
                tractor_id: enquiryData?.tractor_table?.tractor_id || "",
            },
            exchanged: enquiryData?.exchanged || "",
            exchanged_tractor_id: enquiryData?.exchanged_tractor_id || "",
            enquiry_source: enquiryData?.enquiry_from || "",
            date: enquiryData?.date || "",
            // date: date,

            is_dublicate: enquiryData?.is_dublicate || "",
            // enquiry_type: "new",
            enquiry_type: enquiryData?.enquiry_name || "",
            enq_details: {
                remark: enquiryData?.enq_details[0]?.remark || "",
                // remark: "hhdfgdfgdfgdfg",
                // remark: enquiryData?.enq_details?.map(
                //     (rem) => rem.remark,
                // ),
                down_payment: enquiryData?.enq_details[0]?.down_payment || "",
                loan_type: enquiryData?.enq_details[0]?.loan_type || "",
                reason: enquiryData?.enq_details[0]?.reason || "",
                month_year: enquiryData?.enq_details[0]?.month_year || "",
            },
            executive: {
                admin_id: enquiryData?.executive?.admin_id || "",
            },
            crm_call_status: {
                id: enquiryData?.crm_call_status?.id || "",
            },
            user: {
                mobile: enquiryData?.user?.mobile || "",
                first_name: enquiryData?.user?.first_name || "",
                last_name: enquiryData?.user?.last_name || "",
                email: enquiryData.user.email || "",
                state: {
                    // state_id: 28,
                    state_id: enquiryData?.user?.state?.state_id || "",
                },
                city: {
                    // city_id: 999,
                    city_id: enquiryData?.user?.city?.city_id || "",
                },
                taluka: {
                    // id: 4453,
                    id: enquiryData?.user?.taluka?.id || "",
                },


                user_detail: {
                    pincode: enquiryData?.user?.user_detail?.pincode || "",
                    user_level: {
                        // user_level_id: 445
                        user_level_id: enquiryData?.user?.user_detail?.user_level?.user_level_id || "",
                    },
                    village: enquiryData?.user?.user_detail?.village || "",
                    whatsapp: enquiryData?.user?.user_detail?.whatsapp || "",
                    telegram: enquiryData?.user?.user_detail?.telegram || "",
                    land: enquiryData?.user?.user_detail?.land || "",
                    tractor_owner: enquiryData?.user?.user_detail?.tractor_owner || "",
                    purpose: enquiryData?.user?.user_detail?.purpose || "",
                    land_unit: enquiryData?.user?.user_detail?.land_unit || "",
                    crop_details: [
                        {
                            to_days: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.to_days || '',
                            crop_icon: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.crop_icon || '',
                            from_days: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.from_days || '',
                            crop_name: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.crop_name || '',
                            scientific_name: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.scientific_name || '',
                        }
                    ]
                }
            },
        };
    };



    const handleSubmit = async (e) => {
        // window.location.reload();
        e.preventDefault();
        setIsLoading(true);


        const formattedData = formatDataForSubmission(enquiryData);

        try {
            const response = await axios.post(
                // `${API_BASE_URL}/api/admin/v2/update-user-enquiry/enquiry/${enquiryData.id}`,
                // `${API_BASE_URL}/api/admin/v2/update-user-enquiry/bulk-enquiry/${enquiryData.id}`,
                `${API_BASE_URL}/api/admin/v2/update-user-enquiry/enquiry/${enquiryData.id}`,

                // enquiryData,
                formattedData,
            );
            console.log(response.data);
            setMessage(response.data.message);

            // setTimeout(() => {
            //     window.close();
            // }, 5000);


            // console.log("Enquiry data submitted:", enquiryData);
            // setSubmittedDataArray([...submittedDataArray, formattedData]);

        } catch (error) {
            console.error("Error updating data:", error);
            // Handle error
        } finally {
            setIsLoading(false); // Set loading state back to false after the API call completes
        }
    };



    const optionsstatus = [
        { id: "", title: "Select Status" },
        { id: "1", title: "Interested" },
        { id: "2", title: "Call Later" },
        { id: "3", title: "Already Purchased" },
        { id: "4", title: "Not Interested" },
        { id: "5", title: "Ringing No Response" },
        { id: "6", title: "Busy" },
        { id: "7", title: "Switched Off" },
        { id: "8", title: "Invalid Number" },
        { id: "9", title: "Language Problem" },
        { id: "10", title: "Other" },
        { id: "11", title: "No Incoming" },
        { id: "12", title: "Old Tractor Enquiry" },
        { id: "13", title: "Suplied" },
    ];

    return (
        <>
            {message && <p

                className={classes.message}
            >
                <br />
                <br />

                {message}</p>}

            {/* <SearchUser /> */}
            <form onSubmit={handleSubmit}>
                <div style={{ display: "flex" }}>
                    <Link
                        style={{ paddingTop: "10px" }}
                        to="/app/allEnquiries"
                        className={classes.breadcrumbslinkHightlight}
                    >
                        {" "}
                        All Enquiries
                    </Link>
                    <Link
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                        to="/app/TodayPending"
                        className={classes.breadcrumbslinkHightlight}
                    >
                        Today's Pending
                    </Link>
                    <Link
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                        to="/app/Interested"
                        className={classes.breadcrumbslinkHightlight}
                    >
                        Interested
                    </Link>
                    <Link
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                        to="/app/AlreadyPurchased"
                        className={classes.breadcrumbslinkHightlight}
                    >
                        Already Purchased
                    </Link>
                    <Link
                        style={{ paddingLeft: "20px", paddingTop: "10px" }}
                        to="/app/NotConnected"
                        className={classes.breadcrumbslinkHightlight}
                    >
                        Not Connected
                    </Link>

                    <Button
                        style={{
                            backgroundColor: "#dc3545",
                            borderColor: "#dc3545",
                            marginLeft: "20px",
                        }}
                        className={classes.buttonform}
                        variant="outlined"
                        startIcon={<LightModeIcon />}
                    >
                        Update / FollowUp
                    </Button>
                </div>
                <hr />
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={3}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={6} lg={6}>
                                            <Typography className={classes.tabletittle}>
                                                Customer Profile
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            lg={6}
                                            style={{
                                                margin: "auto",
                                                padding: "auto",
                                                textAlign: "right",
                                            }}
                                        >
                                            {/* <AddUpdateUserEditForm /> */}
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Name :{" "}
                                        {enquiryData.user && enquiryData.user.first_name ? (
                                            <span> {enquiryData.user.first_name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        State :{" "}
                                        {enquiryData.user && enquiryData.user.state ? (
                                            <span> {enquiryData.user.state.state_name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        City :{" "}
                                        {enquiryData.user && enquiryData.user.city ? (
                                            <span> {enquiryData.user.city.city_name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Taluka :{" "}
                                        {enquiryData.user && enquiryData.user.taluka ? (
                                            <span> {enquiryData.user.taluka.name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Pincode :{" "}
                                        {enquiryData.user &&
                                            enquiryData.user.user_detail &&
                                            enquiryData.user.user_detail.pincode ? (
                                            <span> {enquiryData.user.user_detail.pincode}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Mobile No:{" "}
                                        {enquiryData.user && enquiryData.user.mobile ? (
                                            <span> {enquiryData.user.mobile}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Email :{" "}
                                        {enquiryData.user && enquiryData.user.email ? (
                                            <span> {enquiryData.user.email}</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Registered on:{" "}
                                        <span>
                                            {new Date(enquiryData.created_at).toLocaleDateString('en-IN', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric',
                                            })}{' '}
                                            {new Date(enquiryData.created_at).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            })}
                                        </span>
                                    </Typography>

                                    {/* <AddTractor /> */}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={4} lg={4}>
                                            <Typography className={classes.tabletittle}>
                                                Update-FollowUp Information
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            lg={8}
                                            style={{
                                                margin: "auto",
                                                padding: "auto",
                                                textAlign: "right",
                                            }}
                                        >
                                            <Button
                                                style={{ height: "20px", fontSize: "12px" }}
                                                className={classes.buttonform}
                                                variant="outlined"
                                            >
                                                <span style={{ fontWeight: "bold" }}>
                                                    {" "}
                                                    Enquiry ID: {enquiryData.id} Date :{" "}
                                                    {enquiryData.created_at ? (
                                                        <span>
                                                            {new Date(
                                                                enquiryData.created_at,
                                                            ).toLocaleDateString("en-IN", {
                                                                year: "numeric",
                                                                month: "long",
                                                                day: "numeric",
                                                            })}
                                                        </span>
                                                    ) : (
                                                        <span> </span>
                                                    )}
                                                </span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <hr />

                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sm={6} lg={6}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Status
                                            </label>
                                            <br />
                                            <select
                                                required
                                                className={classes.selectform}
                                                id="enqStatus"
                                                name="crm_call_status.id"
                                                value={enquiryData?.crm_call_status?.id || ""}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="" selected="selected">
                                                    Select Status
                                                </option>
                                                <option value="1">Interested</option>
                                                <option value="2">Call Later</option>
                                                <option value="3">Already Purchased</option>
                                                <option value="4">Not Interested</option>
                                                <option value="5">Ringing No Response</option>
                                                <option value="6">Busy</option>
                                                <option value="7">Switched Off</option>
                                                <option value="8">Invalid Number</option>
                                                <option value="9">Language Problem</option>
                                                <option value="10">Other</option>
                                                <option value="11">No Incoming</option>
                                                <option value="12">Old Tractor Enquiry</option>
                                                <option value="13">Supplied</option>


                                            </select>
                                            {enquiryData?.crm_call_status?.id === "1" ? <div style={{ display: "flex" }}>
                                                {/* <input
                                                    placeholder="Day"
                                                    className={classes.inputform}
                                                    type="number"
                                                    style={{ marginRight: "50px" }}

                                                /> */}

                                                {/* @@@@@@@@@@ end  */}


                                                <input
                                                    // required={enquiryData?.crm_call_status?.id === "1" || ""}
                                                    placeholder="Day"
                                                    className={classes.inputform}
                                                    type="number"
                                                    style={{ marginRight: "50px" }}
                                                    onChange={(e) => {
                                                        const day = parseInt(e.target.value);
                                                        if (!isNaN(day) && day > 0) {
                                                            const currentDate = new Date();
                                                            currentDate.setDate(currentDate.getDate() + 1);
                                                            const inputDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + day);
                                                            const formattedDate = inputDate.toISOString().split('T')[0];
                                                            handleInputChangecomman({ target: { name: "date", value: formattedDate } });
                                                        }
                                                    }}
                                                />



                                                {/* @@@@@@@@@@ end  */}

                                                <input
                                                    required={enquiryData?.crm_call_status?.id === "1" || ""}
                                                    placeholder="Enter Date"
                                                    className={classes.inputform}
                                                    type="date"
                                                    name="date"
                                                    value={enquiryData?.date || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}

                                            {enquiryData?.crm_call_status?.id === "2" ? <div>

                                                <input
                                                    required={enquiryData?.crm_call_status?.id === "2" || ""}
                                                    placeholder="Enter Date"
                                                    className={classes.inputform}
                                                    type="date"
                                                    name="date"
                                                    // value={enquiryData?.date || ""}
                                                    onChange={handleInputChangecomman}
                                                />

                                            </div> : null}
                                            {enquiryData?.crm_call_status?.id === "3" ? <div>
                                                {/* <select
                                                    id="tractorSelect"
                                                    name="exchanged_tractor_id"
                                                    value={enquiryData.exchanged_tractor_id}
                                                    className={classes.selectform}
                                                    onChange={handleInputChangecomman}
                                                >
                                                    <option >
                                                        Select Tractor
                                                    </option>
                                                    {tractorOptions.map((tractor) => (
                                                        <option key={tractor.id} value={tractor.id}>
                                                            {tractor.name}
                                                        </option>
                                                    ))}
                                                </select> */}

                                                <Autocomplete
                                                    classes={{
                                                        option: classes.autocompleteoption,
                                                    }}
                                                    id="tractorSelect"
                                                    options={tractorOptions}
                                                    value={
                                                        tractorOptions.find(
                                                            (option) =>
                                                                option.id ===
                                                                enquiryData.tractor_table.tractor_id,
                                                        ) || null
                                                    }
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(event, newValue) => {
                                                        handleInputChangecomman({
                                                            target: {
                                                                name: "tractor_table.tractor_id",
                                                                value: newValue ? newValue.id : null,
                                                            },
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            required={enquiryData?.crm_call_status?.id === "3" || ""}
                                                            {...params}
                                                            className={classes.selectform}
                                                        />
                                                    )}
                                                />


                                                <label className={classes.labelform}>
                                                    Select Month Year
                                                </label>
                                                <br />
                                                <input
                                                    required={enquiryData?.crm_call_status?.id === "3" || ""}
                                                    placeholder="-- ----"
                                                    className={classes.inputform}
                                                    type="month"
                                                    name="enq_details.0.month_year"
                                                    value={enquiryData?.enq_details[0]?.month_year || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}
                                            {enquiryData?.crm_call_status?.id === "4" ? <div>
                                                <input
                                                    placeholder="mention the reason"
                                                    className={classes.inputform}
                                                    type="text"
                                                    name="enq_details.0.reason"
                                                    value={enquiryData?.enq_details[0]?.reason || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}
                                            {enquiryData?.crm_call_status?.id === "10" ? <div>
                                                <input
                                                    placeholder="mention the reason"
                                                    className={classes.inputform}
                                                    type="text"
                                                    name="enq_details.0.reason"
                                                    value={enquiryData?.enq_details[0]?.reason || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}

                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6}>
                                            <label for="birthday" className={classes.labelform}>
                                                Executive
                                            </label>
                                            <br />

                                            <select
                                                required
                                                name="executive.admin_id"
                                                value={enquiryData?.executive?.admin_id || ""}
                                                className={classes.selectform}
                                                onChange={handleInputChangecomman}
                                            >
                                                {executiveOptions.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry Source
                                            </label>
                                            <br />
                                            <select
                                                name="enquiry_from"
                                                value={enquiryData?.enquiry_from || ""}
                                                onChange={handleInputChangecomman}
                                                className={classes.selectform}
                                            >
                                                <option value="">Select Source</option>
                                                <option value="fbc">FaceBook Campaign</option>
                                                <option value="app">App</option>
                                                <option value="web">Website</option>
                                                <option value="inbond_call">Inbond Call</option>
                                                <option value="smc">Social Media Comments</option>
                                                <option value="wa">WhatsApp</option>
                                                <option value="tg">Telegram</option>
                                                <option value="ref">Referral</option>
                                            </select>
                                        </Grid>
                                        {/* <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry For
                                            </label>
                                            <br />
                                            <select
                                                id="cars"
                                                name="carlist"
                                                form="carform"
                                                className={classes.selectform}
                                            >
                                                <option value="tractor" selected="">
                                                    tractor
                                                </option>
                                            </select>
                                        </Grid> */}
                                        <Grid item xs={6} sm={8} lg={8}>
                                            <label className={classes.labelform}>
                                                Select Model
                                            </label>
                                            <br />

                                            <Autocomplete
                                                autoHighlight
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                id="tractorSelect"
                                                options={tractorOptions}
                                                value={
                                                    tractorOptions.find(
                                                        (option) =>
                                                            option.id ===
                                                            enquiryData.tractor_table.tractor_id,
                                                    ) || null
                                                }
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {
                                                    handleInputChangecomman({
                                                        target: {
                                                            name: "tractor_table.tractor_id",
                                                            value: newValue ? newValue.id : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        className={classes.selectform}
                                                    />
                                                )}
                                            />


                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry For
                                            </label>
                                            <br />
                                            <select
                                                id="cars"
                                                name="carlist"
                                                form="carform"
                                                className={classes.selectform}
                                            >
                                                <option value="tractor" selected="">
                                                    tractor
                                                </option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Occupation
                                            </label>
                                            <br />
                                            <select
                                                name="user.user_detail.user_level.user_level_id"
                                                value={enquiryData?.user?.user_detail?.user_level?.user_level_id || ""}
                                                onChange={handleInputChangecomman}
                                                className={classes.selectform}
                                            >
                                                <option value="" selected="">
                                                    Select Occupation
                                                </option>
                                                {userLevels?.map((userLevel) => (
                                                    <option key={userLevel.user_level_id} value={userLevel.user_level_id}>
                                                        {userLevel.user_level_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry Type
                                            </label>
                                            <br />

                                            <select
                                                required
                                                className={classes.selectform}
                                                name="enquiry_name"
                                                value={enquiryData?.enquiry_name || ""}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="">Select Enquiry Type</option>
                                                <option value="Price">Price</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Booking">Booking</option>
                                            </select>

                                        </Grid>
                                        <Grid item xs={6} sm={2} lg={2} md={2}>
                                            <label for="birthday" className={classes.labelform}>
                                                Exchange ?
                                            </label>
                                            <br />
                                            <select
                                                required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}
                                                name="exchanged"
                                                className={classes.selectform}
                                                value={enquiryData.exchanged}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value=""> Select</option>
                                                <option value="yes" >Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </Grid>

                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Purpose
                                            </label>
                                            <br />
                                            <select
                                                name="user.user_detail.purpose"
                                                value={enquiryData?.user?.user_detail?.purpose || ""}
                                                onChange={handleInputChangecomman}
                                                required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                className={classes.selectform}
                                            >
                                                <option value="">Select Purpose</option>
                                                <option value="agri - personal">Agri - Personal</option>
                                                <option value="agri - commercial">
                                                    Agri - Commercial
                                                </option>
                                                <option value="commercial">Commercial</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Exchange To
                                            </label>
                                            <br />

                                            {/* <select
                                                id="tractorSelect"
                                                name="exchanged_tractor_id"
                                                value={enquiryData.exchanged_tractor_id}
                                                className={classes.selectform}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option >
                                                    Select Tractor
                                                </option>
                                                {tractorOptions.map((tractor) => (
                                                    <option key={tractor.id} value={tractor.id}>
                                                        {tractor.name}
                                                    </option>
                                                ))}
                                            </select> */}

                                            <Autocomplete
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                id="tractorSelect"
                                                options={tractorOptions}
                                                getOptionLabel={(tractor) => tractor.name}
                                                value={tractorOptions.find(option => option.id === enquiryData.exchanged_tractor_id) || null}
                                                onChange={(event, newValue) => {
                                                    handleInputChangecomman({ target: { name: "exchanged_tractor_id", value: newValue ? newValue.id : "" } });
                                                }}
                                                renderInput={(params) => <TextField
                                                    className={classes.selectform}
                                                    {...params}
                                                    variant="outlined" />}
                                            />



                                        </Grid>

                                        <Grid item xs={6} sm={4} lg={4}></Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label className={classes.labelform}>First Name</label>
                                            <br />
                                            <input
                                                required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                placeholder="Enter First Name"
                                                className={classes.inputform}
                                                type="text"
                                                name="user.first_name"
                                                value={enquiryData?.user?.first_name || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label className={classes.labelform}>Last Name</label>
                                            <br />
                                            <input
                                                placeholder="Enter Last Name"
                                                className={classes.inputform}
                                                type="text"
                                                name="user.last_name"
                                                value={enquiryData?.user?.last_name || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label className={classes.labelform}>Email</label>
                                            <br />
                                            <input
                                                placeholder="Enter Email"
                                                className={classes.inputform}
                                                type="email"
                                                name="user.email"
                                                value={enquiryData?.user?.email || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select State
                                            </label>
                                            <br />

                                            {/* <select
                                                className={classes.selectform}
                                                name="user.state.state_id"
                                                value={enquiryData?.user?.state?.state_id || ""}
                                                onChange={(e) => {
                                                    handleInputChangecomman(e);
                                                    handleStateChange(e);
                                                }}
                                            >
                                                <option value="">Select a state</option>
                                                {states?.map((state) => (
                                                    <option key={state.id} value={state.id}>
                                                        {state.value}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <Autocomplete
                                                required

                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                name="user.state.state_id"
                                                // value={states.find(state => state.id === enquiryData?.user?.state?.state_id) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        const syntheticEvent = {
                                                            target: {
                                                                name: 'user.state.state_id',
                                                                value: newValue.id
                                                            }
                                                        };
                                                        handleInputChangecomman(syntheticEvent);
                                                        handleStateChange(syntheticEvent);
                                                    }
                                                }}
                                                options={states}
                                                getOptionLabel={(state) => state.value}
                                                renderInput={(params) => <TextField
                                                    required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                    placeholder="Select State" {...params} />}
                                            />

                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select District
                                            </label>
                                            <br />

                                            {/* <select
                                                className={classes.selectform}
                                                name="user.city.city_id"
                                                value={enquiryData?.user?.city?.city_id || ""}
                                                onChange={(e) => {
                                                    handleInputChangecomman(e);
                                                    handleCityChange(e);
                                                }}

                                            >
                                                <option value="">Select a city</option>
                                                {cities?.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.value}
                                                    </option>
                                                ))}
                                            </select> */}

                                            <Autocomplete
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                name="user.city.city_id"
                                                // value={cities.find(city => city.id === enquiryData?.user?.city?.city_id) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        const syntheticEvent = {
                                                            target: {
                                                                name: 'user.city.city_id',
                                                                value: newValue.id
                                                            }
                                                        };
                                                        handleInputChangecomman(syntheticEvent);
                                                        handleCityChange(syntheticEvent);
                                                    }
                                                }}
                                                options={cities}
                                                getOptionLabel={(city) => city.value}
                                                renderInput={(params) => <TextField
                                                    required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}
                                                    placeholder="Select City" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Taluka
                                            </label>
                                            <br />

                                            {/* <select
                                                className={classes.selectform}
                                                name="user.taluka.id"
                                                value={enquiryData?.user?.taluka?.id || ""}
                                                onChange={(e) => {
                                                    handleInputChangecomman(e);
                                                    handleTalukaChange(e);
                                                }}
                                            >
                                                <option value="">Select taluka</option>
                                                {talukas?.map((taluka) => (
                                                    <option key={taluka.id} value={taluka.id}>
                                                        {taluka.value}
                                                    </option>
                                                ))}
                                            </select> */}

                                            <Autocomplete
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                name="user.taluka.id"
                                                // value={talukas.find(taluka => taluka.id === enquiryData?.user?.taluka?.id) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        const syntheticEvent = {
                                                            target: {
                                                                name: 'user.taluka.id',
                                                                value: newValue.id
                                                            }
                                                        };
                                                        handleInputChangecomman(syntheticEvent);
                                                        handleTalukaChange(syntheticEvent);
                                                    }
                                                }}
                                                options={talukas}
                                                getOptionLabel={(taluka) => taluka.value}
                                                renderInput={(params) => <TextField
                                                    required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                    placeholder="Select Taluka" {...params} />}
                                            />


                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Village
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Select Village"
                                                className={classes.inputform}
                                                type="text"
                                                name="user.user_detail.village"
                                                // value={enquiryData.user.user_detail.village}
                                                value={enquiryData?.user?.user_detail?.village || ""}
                                                onChange={handleInputChangecomman}
                                            />



                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Pincode
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Select Pincode"
                                                className={classes.inputform}
                                                // value={enquiryData?.user?.user_details?.pincode || ''}
                                                // name="user.user_detail.pincode"
                                                // value={enquiryData?.user?.user_detail?.pincode || ""}
                                                name="user.user_detail.pincode"
                                                value={enquiryData?.user?.user_detail?.pincode || ""}
                                                onChange={handleInputChangecomman}
                                                type="text"
                                                onInput={(e) => {
                                                    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
                                                    if (value.length > 6) {
                                                        value = value.slice(0, 6); // Limit length to 6 digits
                                                    }
                                                    e.target.value = value;
                                                }}
                                                pattern="[1-9]\d{5}"
                                                title="Please enter exactly 6 digits"

                                            />
                                        </Grid>

                                        <Grid item xs={3} sm={5} lg={5} md={5}>
                                            <label for="birthday" className={classes.labelform}>
                                                WhatsApp Number
                                            </label>
                                            <br />
                                            {/* <input
                                                name="user.user_detail.whatsapp"
                                                value={enquiryData?.user?.user_detail?.whatsapp || ""}
                                                onChange={handleInputChangecomman}
                                                placeholder="WhatsApp Number"
                                                className={classes.inputform}
                                                type="number"
                                            /> */}

                                            <input
                                                name="user.user_detail.whatsapp"
                                                value={enquiryData?.user?.user_detail?.whatsapp || ""}
                                                onChange={handleInputChangecomman}
                                                placeholder="WhatsApp Number"
                                                className={classes.inputform}
                                                type="text"
                                                onInput={(e) => {
                                                    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
                                                    if (value.length > 10) {
                                                        value = value.slice(0, 10); // Limit length to 10 digits
                                                    }
                                                    if (value.charAt(0) === '0') {
                                                        value = value.slice(1); // Remove leading zero if present
                                                    }
                                                    e.target.value = value;
                                                }}
                                                pattern="[1-9]\d{9}"
                                                title="Please enter exactly 10 digits"

                                            />

                                            <input
                                                type="checkbox"
                                                id="vehicle1"
                                                name="vehicle1"
                                                value="Bike"
                                            ></input>
                                            <label for="birthday" className={classes.labelform}>
                                                Same as Mobile Number
                                            </label>
                                        </Grid>
                                        <Grid item xs={3} sm={5} lg={5} md={5}>
                                            <label for="birthday" className={classes.labelform}>
                                                Telegram Number
                                            </label>
                                            <br />
                                            <input
                                                name="user.user_detail.telegram"
                                                value={enquiryData?.user?.user_detail?.telegram || ""}
                                                onChange={handleInputChangecomman}
                                                placeholder="Telegram Number"
                                                className={classes.inputform}
                                                type="text"
                                                onInput={(e) => {
                                                    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
                                                    if (value.length > 10) {
                                                        value = value.slice(0, 10); // Limit length to 10 digits
                                                    }
                                                    if (value.charAt(0) === '0') {
                                                        value = value.slice(1); // Remove leading zero if present
                                                    }
                                                    e.target.value = value;
                                                }}
                                                pattern="[1-9]\d{9}"
                                                title="Please enter exactly 10 digits"
                                            />
                                            <input
                                                type="checkbox"
                                                id="vehicle1"
                                                name="vehicle1"
                                                value="Bike"
                                            ></input>
                                            <label for="birthday" className={classes.labelform}>
                                                Same as Whatsapp Number
                                            </label>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Enter Your Farm Land
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Enter Your Farm Land"
                                                className={classes.inputform}
                                                type="text"
                                                name="user.user_detail.land"
                                                value={enquiryData?.user?.user_detail?.land || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Farm Unit
                                            </label>
                                            <br />
                                            <select
                                                id="cars"
                                                name="carlist"
                                                form="carform"
                                                className={classes.selectform}
                                            >
                                                <option value="Bigha">Bigha</option>
                                                <option value="Acre">Acre</option>
                                                <option value="Hectare">Hectare</option>
                                                <option value="Guntha">Guntha</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Tractor Owner
                                            </label>
                                            <br />
                                            <select
                                                id="cars"
                                                name="carlist"
                                                form="carform"
                                                className={classes.selectform}
                                            >
                                                <option value="">Select Tractor Owner</option>
                                                <option value="0" selected="">
                                                    FBT (First Time Buyer)
                                                </option>
                                                <option value="1">Tractor Owner</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={3} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Crops (Multiple Select)
                                            </label>
                                            <br />
                                            <select
                                                className={classes.selectform}
                                                name="user.user_detail.crop_details.0.crop_name"
                                                // // name="user.user_details.crop_details.crop_id"
                                                // value={enquiryData?.user?.user_detail?.crop_details[0]?.crop_name || ''}
                                                value={(enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.crop_name) || ''}

                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="">Select a crop</option>
                                                {crops?.map((crop) => (
                                                    <option key={crop.id} value={crop.value}>
                                                        {crop.value}
                                                    </option>
                                                ))}
                                            </select>
                                        </Grid>

                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Loan
                                            </label>
                                            <br />
                                            <select
                                                required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                className={classes.selectform}
                                                name="enq_details.0.loan_type"
                                                value={enquiryData?.enq_details[0]?.loan_type || ""}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="" selected="">
                                                    Select Loan
                                                </option>
                                                <option value="loan">Loan</option>
                                                <option value="cash">Cash</option>
                                                <option value="not confirm">Not Confirm</option>
                                                <option value="after price">After Price</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Enter Downpayment
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Enter Downpayment"
                                                className={classes.inputform}
                                                type="number"
                                                name="enq_details.0.down_payment"
                                                value={enquiryData?.enq_details[0]?.down_payment || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>




                                        <Grid item xs={12} sm={12} lg={12}>
                                            <label for="birthday" className={classes.labelform}>
                                                Enter Remark
                                            </label>
                                            <br />
                                            <textarea
                                                type="text"
                                                name="enq_details.0.remark"
                                                value={enquiryData?.enq_details[0]?.remark || ""}
                                                onChange={handleInputChangecomman}
                                                placeholder="Enter Your Land Space"
                                                className={classes.inputform}

                                                style={{ height: "50px" }}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <hr />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                            style={{
                                                margin: "auto",
                                                padding: "auto",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                style={{
                                                    backgroundColor: "#dc3545",
                                                    borderColor: "#dc3545",
                                                }}
                                                className={classes.buttonform}
                                                variant="outlined"
                                                startIcon={<LightModeIcon />}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} lg={3}>

                            <Card >
                                <CardContent>
                                    <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={6} lg={6}>
                                            <Typography className={classes.tabletittle} >
                                                FollowUp History
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    {enquiryData.enq_details && enquiryData.enq_details.length > 0 ? (
                                        // data.enq_details.map((enquiry, index) => (
                                        enquiryData.enq_details.slice().reverse().map((enquiry, index) => (

                                            <div key={index} >

                                                {/* $$$$$$$$$$$$$$$ start  */}
                                                <Card variant="outlined" style={{ padding: "10px", marginBottom: "5px" }}>
                                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                                        <DateRangeIcon style={{ marginTop: "-10px", paddingTop: "8px" }} />
                                                        {enquiry.created_at ? (
                                                            <span>
                                                                {new Date(enquiry.created_at).toLocaleDateString('en-IN', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                })}{' '}
                                                                {new Date(enquiry.created_at).toLocaleTimeString('en-US', {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    second: '2-digit',
                                                                    hour12: true,
                                                                })}
                                                            </span>
                                                        ) : (
                                                            <span> </span>
                                                        )}

                                                    </Typography>
                                                    <hr />
                                                    <p>Status:  {enquiry.enquiry_status ? (
                                                        <span>
                                                            {/* {enquiry.enquiry_status} */}
                                                            {optionsstatus.find(option => option.id === enquiry.enquiry_status)?.title || " "}

                                                        </span>
                                                    ) : (
                                                        <span>

                                                        </span>
                                                    )}
                                                    </p>
                                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                                        Date:
                                                        {enquiry.date ? (
                                                            <span>
                                                                {enquiry.date}
                                                            </span>
                                                        ) : (
                                                            <span>

                                                            </span>
                                                        )}

                                                    </Typography>

                                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                                        Executive: {enquiry.admin_id ? (
                                                            <span>
                                                                {/* {enquiry.admin_id} */}
                                                                {/* {executiveOptions.find(option => option.id === data?.enq_details[0]?.admin_id)?.title || "No Executive Selected"} */}
                                                                {executiveOptions.find(option => option.id === enquiry.admin_id)?.title || " "}

                                                            </span>
                                                        ) : (
                                                            <span>

                                                            </span>
                                                        )}
                                                    </Typography>

                                                </Card>

                                            </div>
                                        ))
                                    ) : (
                                        <span> </span>
                                    )}

                                    <br />
                                </CardContent>
                            </Card>
                            {/* ################### end  */}



                        </Grid>
                    </Grid>
                </div>

            </form>
        </>
    );
};

export default AutocompleteDemo;


