import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
import { Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import useStyles from '../../../../StyleComponents/StyleFunctionComponent';
import { AppBar, Toolbar, IconButton, Modal, Box, TextField, Button } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'; // Import Axios

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        minWidth: 600,
        maxWidth: 800,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

const UpdateDialog = ({ selectedRow, setSelectedRow, handleClose }) => {
    const [formData, setFormData] = useState({
        name: selectedRow.name,
        username: selectedRow.username,
        body: selectedRow.address.geo.lat,
        tractorNOC: selectedRow.tractorNOC, // Add this line
        tractorRC: selectedRow.tractorRC,
        tractorMonth: selectedRow.tractorMonth, // Add this line
        tractorHours: selectedRow.tractorHours, // Add this line
    });


    const handleFormChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
            [e.target.username]: e.target.value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            // Send a PUT request to your API endpoint
            const response = await axios.put(`https://jsonplaceholder.typicode.com/users/${selectedRow.id}`, formData);

            if (response.status === 200) {
                // Update was successful
                console.log('Update successful');
            } else {
                // Handle errors
                console.error('Update failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }

        // Close the dialog
        handleClose();
    };

    const classes = useStyles();

    return (
        <BootstrapDialog open={Boolean(selectedRow)} onClose={handleClose}>
            <Grid container spacing={3}>
                <Grid xs={6} lg={6}>
                    <DialogTitle sx={{ m: 0, pl: 4 }} id="customized-dialog-title">
                        <Typography className={classes.tabletittle}>
                            Commercial Vehicle Details
                        </Typography>
                    </DialogTitle>
                </Grid>
                <Grid xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{ marginRight: "10px" }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent dividers>
                <form onSubmit={handleFormSubmit}>
                    {/* <div>
                        <label>Name:</label>
                        <input
                            className={classes.inputform}
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleFormChange}
                        />
                    </div>
                    <div>
                        <label>Body:</label>
                        <input
                            type="text"
                            name="body"
                            value={formData.body}
                            onChange={handleFormChange}
                        />
                    </div> */}



                    <Grid container spacing={2} >
                        <Grid item xs={6} sm={6} lg={6} >
                            <label for="birthday" className={classes.labelform}>Brand</label><br />
                            <input
                                readOnly
                                placeholder='Brand'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                name="name"
                                value={formData.name}
                                onChange={handleFormChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} >
                            <label for="birthday" className={classes.labelform}>Model</label><br />
                            <input
                                placeholder='Model'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                readOnly
                                name="username"
                                value={formData.username}
                                onChange={handleFormChange}

                            />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>Purchase Year</label><br />
                            <input
                                placeholder='Purchase Year'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                            />
                        </Grid>

                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>RC Status</label><br />
                            <select id="cars" name="carlist" form="carform"
                                className={classes.selectform} >
                                <option value="yes">Yes</option>
                                <option value="one" selected="">No</option>
                            </select>
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>Permit Level</label><br />
                            <select id="cars" name="carlist" form="carform"
                                className={classes.selectform} >
                                <option value="yes">National Permit </option>
                                <option value="one" selected="">State Permit </option>
                            </select>
                        </Grid>




                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>Fitness Validity</label><br />
                            <input
                                placeholder='Exact HP'
                                className={classes.inputform}
                                type="date"
                                label="Search"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>Insurance Validity</label><br />
                            <input
                                placeholder='Exact HP'
                                className={classes.inputform}
                                type="date"
                                label="Search"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>tax Validity</label><br />
                            <input
                                placeholder='Exact HP'
                                className={classes.inputform}
                                type="date"
                                label="Search"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>Expected Price</label><br />
                            <input
                                placeholder='Exact HP'
                                className={classes.inputform}
                                type="number"
                                label="Search"
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} lg={3} >
                            <label for="birthday" className={classes.labelform}>Driven Kilometer</label><br />
                            <input
                                placeholder='Exact HP'
                                className={classes.inputform}
                                type="number"
                                label="Search"
                            />
                        </Grid>


                        <Grid item xs={12} sm={12} lg={12} >
                            <label for="birthday" className={classes.labelform}>About Us</label><br />
                            <textarea
                                style={{ height: "50px" }}
                                placeholder='About Us'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} >
                            <label for="birthday" className={classes.labelform}>Live Status</label><br />
                            <select id="cars" name="carlist" form="carform"
                                className={classes.selectform} >
                                <option value="1">Active</option>
                                <option value="0" selected="">Inactive</option>
                            </select>
                        </Grid>
                        <Grid item xs={6} sm={6} lg={6} >
                            <label for="birthday" className={classes.labelform}>Sold Status</label><br />
                            <select id="cars" name="carlist" form="carform"
                                className={classes.selectform} >
                                <option value="1">Sold</option>
                                <option value="0" selected="">Unsold</option>

                            </select>
                        </Grid>
                    </Grid>
                    <Grid style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                        <br />
                        <Button
                            type="submit"
                            className={classes.buttonform}
                            color="primary">
                            Submit
                        </Button>
                        <Button
                            style={{ backgroundColor: "#dc3545", borderColor: '#dc3545', marginLeft: "10px" }}

                            onClick={handleClose}
                            color="primary"
                            // style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                            className={classes.buttonform}
                        >
                            Close
                        </Button>
                    </Grid>
                </form>
            </DialogContent>
        </BootstrapDialog>
    );
};

export default UpdateDialog;


