import React from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
// components
import Layout from "./Layout/Layout";
// import Web_header from "../components/Website/Web_header";
import Web_header from "../Website/src/Pages/Home/Home";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
// import EmpDashboard from "../EmployeeDashboard/Layout/LayoutEmp";
import Infragaadi from "../Infragaadi/components/Layout/Layout";
import khetiguru from "../Khetiguru/components/Layout/Layout";


// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global

  var { isAuthenticated } = useUserState();

  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} /> */}
        {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}

        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />

        {/* ssssss @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}

        {/* <Route exact path="/" render={() => <Redirect to="/emps/EmpDashboard" />} /> */}
        <Route
          exact
          path="/infra"
          render={() => <Redirect to="/infra/EmpDashboard" />}
        />

        <PrivateRoute path="/infra" component={Infragaadi} />

        <Route
          exact
          path="/khetiguru"
          render={() => <Redirect to="/khetiguru/Dashboard" />}
        />

        <PrivateRoute path="/khetiguru" component={khetiguru} />

        {/* <Route exact path="/app/Carddemo" component={cardlist} /> */}

        {/*eeeee ############################################### */}
        {/* <PrivateRoute path="/EmpDashboard" component={EmpDashboard} /> */}
        <PublicRoute path="/login" component={Login} />
        {/* <PublicRoute path="/" component={Web_header} /> */}
        <PublicRoute path="/" component={Login} />
        {/* <Route component={Error} /> */}
        {/* employee */}
      </Switch>
    </BrowserRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              exact
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/app",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}









