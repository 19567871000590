import React, { useEffect, useState } from 'react';
import { API_BASE_URL, IMPLEMENT_REPORT_ENDPOINT, TOKEN } from './constants';

const ImplementTable = () => {
    const [implementData, setImplementData] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page

    const fetchData = async (page) => {
        try {
            const response = await fetch(`${API_BASE_URL}${IMPLEMENT_REPORT_ENDPOINT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`,
                },
                body: new URLSearchParams({
                    page: page,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            const data = await response.json();
            const newData = data.data.new_implement.map(item => ({
                implement_id: item.implement_id,
                implement_name: item.implement_name,
                brand_name: item.brand.brand_name,
            }));

            setImplementData(newData);
        } catch (error) {
            setError('An error occurred while fetching data. Please try again later.');
        }
    };

    useEffect(() => {
        fetchData(currentPage); // Fetch data on component mount and when currentPage changes
    }, [currentPage]);

    const goToNextPage = () => {
        setCurrentPage(currentPage + 1); // Increase current page on Next button click
    };

    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1); // Decrease current page on Prev button click
        }
    };

    return (
        <div>
            <h1>Implement Data Table</h1>
            {error ? (
                <p>Error: {error}</p>
            ) : (
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Implement ID</th>
                                <th>Implement Name</th>
                                <th>Brand Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {implementData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.implement_id}</td>
                                    <td>{item.implement_name}</td>
                                    <td>{item.brand_name}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div>
                        <button onClick={goToPrevPage} disabled={currentPage === 1}>Previous</button>
                        <button onClick={goToNextPage}>Next</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImplementTable;





