import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FeaturesLink from "./FeaturesLink";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Outlet, Link } from "react-router-dom";
import { Navbar } from 'react-bootstrap';
// import { Home } from '@mui/icons-material';

import NoPage from '../../Demopages/NoPage/NoPage';

import "./AppBar.css";
// import { Link } from '@mui/material';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFFFFF',
        color: '#707070',
        // maxWidth: 220,
        maxWidth: 420,

        fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
    },
}));

export default function CustomizedTooltips() {
    return (<>

        <Navbar >
            <div style={{ color: "#707070", textDecoration: "none", }} >
                {/* <LightTooltip >
                <Button>Light</Button>
            </LightTooltip> */}
                {/* <LightTooltip style={{ textAlign: "center" }}>
                    <button style={{ textAlign: "center" }}>
                        <Link className='linkappbar' to="/" > <span className='hoverfont tooltip'>Home <span class="tooltiptext"> </span></span></Link>
                    </button>
                </LightTooltip>
                <HtmlTooltip
                    title={
                        <React.Fragment >
                            <FeaturesLink />
                        </React.Fragment>
                    }
                >
                    <button className='linkappbar'  > <span className='hoverfont tooltip'>Features  <span class="tooltiptext"> </span></span><ArrowDropDownIcon /></button>
                </HtmlTooltip> */}

                {/* <LightTooltip >
                    <button style={{ padding: "4px", color: "#707070" }}>
                        <Link to="/Pricing" className='linkappbar'><span className='hoverfont tooltip'>Pricing  <span class="tooltiptext"> </span></span></Link>
                    </button>
                </LightTooltip>
                <LightTooltip >
                    <button style={{ padding: "4px", color: "#707070" }}>
                        <Link to="/Startup" className='linkappbar'><span className='hoverfont tooltip'>Startup  <span class="tooltiptext"> </span></span></Link>
                    </button>
                </LightTooltip>
                <LightTooltip >
                    <button style={{ padding: "4px", color: "#707070" }}>

                        <Link to="/Blogs" className='linkappbar'>
                            <span className='hoverfont tooltip'>Blog  <span class="tooltiptext"> </span></span> </Link>
                    </button>
                </LightTooltip> */}
                <BootstrapTooltip  >
                    <Button style={{ padding: "4px", color: "#707070", }} className="responsivenavbar">
                        {/* <a href="http://app.hrvers.com/" className='linkappbar'> <span className='hoverfont '>  <LockOpenIcon style={{ fontSize: "15px" }} />Login</span></a> */}
                        <Link to="/Login" className='linkappbar' style={{ textTransform: "capitalize" }}> <span className='hoverfont '>  <LockOpenIcon style={{ fontSize: "15px", marginTop: "4px", }} />Login</span></Link>
                    </Button>
                </BootstrapTooltip>
                <BootstrapTooltip   >
                    <Link to="/" >
                        <Button className='bookbuttonlink responsivenavbar' style={{ borderRadius: "10px", padding: "4px", color: "#FFFFFF", backgroundColor: " #1C6BFE", textAlign: "center", textTransform: "capitalize" }}>
                            {/* <NoPage /> */}
                            {/* <Link to="/" > */}

                            Book Live Demo
                            {/* </Link> */}
                        </Button>
                    </Link>
                </BootstrapTooltip>
            </div>
        </Navbar>
        {/* <Outlet /> */}


    </>
    );
}
