import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    textField: {
        width: '300px',
        marginBottom: '20px',
        border: '2px',
    },
}));

const AutocompleteDemo = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();

    const [selectedItem, setSelectedItem] = useState(null);
    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        body: '',
    });

    useEffect(() => {
        // Fetch data from the JSONPlaceholder fake API
        axios
            .get('https://jsonplaceholder.typicode.com/posts')
            .then((response) => {
                const data = response.data.map((item) => ({
                    title: item.title,
                    id: item.id,
                }));
                setOptions(data);

                // If an "id" parameter is present in the URL, preselect the corresponding item
                if (id) {
                    const selected = data.find((item) => item.id === Number(id));
                    if (selected) {
                        setSelectedItem(selected);
                    }
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [id]);

    useEffect(() => {
        // Fetch and store the full data when an option is selected
        if (selectedItem) {
            axios
                .get(`https://jsonplaceholder.typicode.com/posts/${selectedItem.id}`)
                .then((response) => {
                    setFormData({
                        id: response.data.id,
                        title: response.data.title,
                        body: response.data.body,
                    });

                    // Update the URL when an option is selected
                    // history.push(`/app/searchUser/${selectedItem.id}`);
                    // history.push(`/app/BulkUpload/${selectedItem.id}`);
                })
                .catch((error) => {
                    console.error('Error fetching selected data:', error);
                });
        }
    }, [selectedItem, history]);

    const handleTitleChange = (event) => {
        setFormData({ ...formData, title: event.target.value });
    };

    const handleBodyChange = (event) => {
        setFormData({ ...formData, body: event.target.value });
    };

    return (
        <div className={classes.container}>
            {/* <Link to={`/app/searchUser/${selectedItem.id}`}> searchUser</Link> */}
            <Autocomplete
                className={classes.textField}
                options={options}
                getOptionLabel={(option) => option.title}
                value={selectedItem}
                onChange={(event, newValue) => {
                    setSelectedItem(newValue);
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
            {formData.id && (
                <>
                    <h1> {formData.title} </h1>
                    <Link to={`/app/searchUser/${selectedItem.id}`}> searchUser</Link>

                    <form>
                        <TextField label="ID" variant="outlined" value={formData.id} disabled />
                        <TextField
                            label="Title"
                            variant="outlined"
                            value={formData.title}
                            onChange={handleTitleChange}
                        />
                        <input
                            label="Body"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formData.body}
                            onChange={handleBodyChange}
                        />
                    </form>
                </>
            )}
        </div>
    );
};

export default AutocompleteDemo;



