// Step1.js
import React from 'react';
import TextField from '@mui/material/TextField';
import { Button, Grid } from '@material-ui/core';

import useStyles from '../../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';


const Step1 = ({ formData, handleInputChange }) => {

    const classes = useStyles();

    return (
        <>
            {/* <div>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                />
            </div> */}
            <br />

            <Grid container spacing={2} >

                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Working Depth'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        // value={formData.name}
                        // onChange={handleInputChange}
                        fullWidth
                        readOnly
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth

                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Working width '
                        className={classes.inputform}
                        type="text"
                        name="name"
                        // value={formData.name}
                        // onChange={handleInputChange}
                        fullWidth
                        readOnly
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Engine'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        fullWidth
                        readOnly
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Displacement'
                        className={classes.inputform}
                        type="text"
                        name="name"

                        fullWidth
                        readOnly
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Net Power'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        readOnly
                        fullWidth

                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                         placeholder='Weight'
                         className={classes.inputform}
                         type="text"
                         name="name"
                         readOnly
                         fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Fuel tank capacity'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        readOnly
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Type of soil'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        readOnly
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tool rotation speed'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        readOnly
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Overview'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        readOnly
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={6} >
                    <input
                        placeholder='Tractor Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>

            </Grid >
        </>

    );
};

export default Step1;
