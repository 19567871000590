import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';

const YourComponent = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://jsonplaceholder.typicode.com/users');
                const modifiedData = response.data.map((user) => ({
                    id: user.id,
                    name: user.name,
                    address: `${user.address.street}, ${user.address.suite}, ${user.address.city} - ${user.address.zipcode}`,
                    geo: `Lat: ${user.address.geo.lat}, Lng: ${user.address.geo.lng}`,
                    phone: user.phone,
                    website: user.website,
                    company: user.company.name,
                    catchPhrase: user.company.catchPhrase,
                    bs: user.company.bs,
                }));
                setData(modifiedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const columns = [
        {
            name: 'id',
            label: 'ID',
        },
        {
            name: 'name',
            label: 'Name',
        },
        {
            name: 'address',
            label: 'Address',
        },
        {
            name: 'geo',
            label: 'Geo',
        },
        {
            name: 'phone',
            label: 'Phone',
        },
        {
            name: 'website',
            label: 'Website',
        },
        {
            name: 'company',
            label: 'Company',
        },
        {
            name: 'catchPhrase',
            label: 'Catch Phrase',
        },
        {
            name: 'bs',
            label: 'BS',
        },
    ];

    const options = {
        filter: true,
        selectableRows: 'none',
    };

    return (
        <MUIDataTable
            title="Users Data"
            data={data}
            columns={columns}
            options={options}
        />
    );
};

export default YourComponent;
