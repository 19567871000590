import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import { Button, Grid, Typography } from '@material-ui/core';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@mui/icons-material/Add';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { API_BASE_URL, token, userLevel } from "../../../../constants";
import axios from "axios";





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        minWidth: 300,
        maxWidth: 900,
    },

}));


export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    // const [selectedTractor, setSelectedTractor] = useState('');


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const { id } = useParams(); // Get the ID from the route params


    const classes = useStyles();


    const [selectedTractor, setSelectedTractor] = useState('');
    const [tractorOptions, setTractorOptions] = useState([]);

    useEffect(() => {
        const fetchTractorData = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const requestData = {
                    per_page: 1500,
                    page: 1, // Assuming you want the first page
                };

                const response = await axios.post(
                    `${API_BASE_URL}/admin/api/v1/report/new/tractor`,
                    requestData,
                    config
                );

                const tractorList = response.data.data.new_tractor.map((tractor) => ({
                    id: tractor.tractor_id,
                    name: tractor.tractor_name,
                }));

                setTractorOptions(tractorList);
            } catch (error) {
                console.error('Error fetching tractor data:', error);
            }
        };

        fetchTractorData();
    }, []);


    const handleInputChangecomman = (e) => {
        const { name, value } = e.target;

        // Split the name into nested property parts
        const propertyNames = name.split(".");

        // Use the property names to traverse the nested structure and update the state
        setEnquiryData((prevData) => {
            let updatedData = { ...prevData };

            // Traverse the nested structure
            let currentLevel = updatedData;
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];

                // Check if it's the last part of the property name
                if (i === propertyNames.length - 1) {
                    // Update the value at the last level
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = value; // Assuming value is directly updated in array
                    } else {
                        currentLevel[propertyName] = value;
                    }
                } else {
                    // Create nested objects if they don't exist
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = currentLevel[index] || {}; // Create empty object if not exists
                    } else {
                        currentLevel[propertyName] = currentLevel[propertyName] || {};
                    }
                    // Move to the next level
                    currentLevel = currentLevel[propertyName];
                }
            }

            return updatedData;
        });
    };

    // @@@@@@@@@@@@@@@@@@@fa-border
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enquiryData, setEnquiryData] = useState({
        user_id: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.post(
                    `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    config,
                );

                setEnquiryData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("An error occurred while fetching data.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, token]);



    const formatDataForSubmission = (data) => {
        return {
            // ...data,
            tractor_id: selectedTractor,

            user_id: enquiryData.user_id,
            tractor_month: selectedMonth,
            tractor_year: selectedYear
        };
    };

    const handleSubmit = async (e) => {
        // window.location.reload();
        e.preventDefault();

        const formattedData = formatDataForSubmission(enquiryData);

        try {
            const response = await axios.post(
                // `${API_BASE_URL}/api/admin/v2/update-user-enquiry/enquiry/${enquiryData.id}`,
                `${API_BASE_URL}/api/admin/v2/insert-owner-tractor`,
                // enquiryData,
                formattedData,
            );
            console.log(response.data);

            // console.log("Enquiry data submitted:", formattedData);
            // console.log("Enquiry data submitted:", enquiryData);
            // setSubmittedDataArray([...submittedDataArray, formattedData]);
        } catch (error) {
            console.error("Error updating data:", error);
            // Handle error
        }
    };

    const startYear = 2020;
    const endYear = 2030;
    const years = [];

    // Generate an array of years from startYear to endYear
    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }


    return (

        <div>

            <Button
                onClick={handleClickOpen}
                className={classes.buttonform}
                variant="outlined" startIcon={<AddIcon />}>
                Add Tractors
            </Button>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">

                    <Typography className={classes.tabletittle} >
                        Add Tractors
                    </Typography>

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers >
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} lg={12} >
                                <label for="birthday" className={classes.labelform}>Select Tractor</label><br />
                                <select
                                    className={classes.selectform}
                                    value={selectedTractor}
                                    onChange={(e) => setSelectedTractor(e.target.value)}
                                >
                                    <option value="">Select tractor</option>
                                    {tractorOptions.map((tractor) => (
                                        <option key={tractor.id} value={tractor.id}>
                                            {tractor.name}
                                        </option>
                                    ))}
                                </select>

                                {/* <Autocomplete
                                    value={selectedTractor}
                                    onChange={(event, newValue) => {
                                        setSelectedTractor(newValue ? newValue.id : '');
                                    }}
                                    options={tractorOptions}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} placeholder="Select tractor" variant="outlined" />}
                                /> */}

                                {/* <Autocomplete
                                    id="tractorSelect"
                                    options={tractorOptions}
                                    value={selectedTractor}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e) => setSelectedTractor(e.target.value)}

                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={classes.selectform}
                                        />
                                    )}
                                /> */}




                            </Grid>
                            <Grid item xs={12} sm={6} lg={6} >
                                <label for="birthday" className={classes.labelform}>Select Month</label><br />
                                <select
                                    className={classes.selectform}
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                >
                                    <option value="">Select Month</option>
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                            </Grid>

                            <Grid item xs={12} sm={6} lg={6} >
                                <label for="birthday" className={classes.labelform}>Select Year</label><br />
                                {/* <select
                                    className={classes.selectform}

                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                >
                                    <option value="">Select Year</option>
                                    <option value="2024">2024</option>
                                    <option value="2023">2023</option>
                                    <option value="2022">2022</option>
                                    <option value="2021">2021</option>
                                </select> */}
                                <select className={classes.selectform}

                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}>
                                    <option value="">Select Year</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>

                            </Grid>
                        </Grid>

                        <br />
                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center',     // Center vertically
                                    height: '100%',
                                }}>
                                    <Button
                                        // style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                        type="submit"
                                        className={classes.buttonform}
                                        variant="outlined"
                                        startIcon={<SaveIcon />}
                                    >
                                        Submit
                                    </Button>

                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>

            </BootstrapDialog>
        </div>
    );
}






