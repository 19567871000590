import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MUIDataTable from "mui-datatables";

const MyDataTable = () => {
    const [brandData, setBrandData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://khetigaadi.com/mobile/api/v1/new-tractor/brand/model/236');
            const tractorModels = response.data.data.tractor_models;
            const formattedData = tractorModels.map(model => ({
                tractor_model_id: model.tractor_model_id,
                brand_id: model.brand_id,
                tractor_model_name: model.tractor_model_name,
            }));
            setBrandData(formattedData);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const columns = [
        {
            name: 'tractor_model_id',
            label: 'Tractor Model ID',
        },
        {
            name: 'brand_id',
            label: 'Brand ID',
        },
        {
            name: 'tractor_model_name',
            label: 'Tractor Model Name',
        },
    ];

    const options = {
        filter: true,
        filterType: 'dropdown',
        responsive: 'standard',
        rowsPerPage: 50, // Set the number of rows per page to 50
        rowsPerPageOptions: [10, 50, 999, 9999, { value: data.length, label: 'All' }], // Include 'All' option
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading data...</p>
            ) : (
                <MUIDataTable
                    title="Tractor Models"
                    data={brandData}
                    columns={columns}
                    options={options}
                />
            )}
        </div>
    );
};

export default MyDataTable;
