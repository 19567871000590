import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar_h from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBars from "./AppBar/AppBar";
import Avatar from '@mui/material/Avatar';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import "./Sidebar.css";
// import { Outlet, Link } from "react-router-dom";
import Logo from "./Image/HRVERSE_logo.svg";
import TopAppbar from "./AppBar/TopAppbar";
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Treedrop from './AppBar/Treedrop';

const drawerWidth = 240;
const navItems = [
    'Home',
    'Features', 'Services', "Pricing", 'Blog', 'Login', "book Live"
];

function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        // @@@@@@@@@@@@@@@@@@@@@fa-border

        // #########################
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }} style={{ color: "#707070" }}>


                {/* HRVers */}

                <img style={{ textAlign: "center", margin: "auto", padding: " auto" }} className='mobileviewlogo' width={40} src={Logo} />

            </Typography>
            <Divider />
            <List>
                {/* {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))} */}
                <ListItem>
                    <ListItemButton>
                        <ListItemText>
                            {/* <AppBars /> */}

                            <Treedrop />
                        </ListItemText>
                    </ListItemButton>
                </ListItem>

            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>

            <Box sx={{ display: 'flex' }} >
                <CssBaseline />
                <AppBar_h position='fixed' style={{ background: "#FFFFFF", height: "100px" }}>

                    <Card style={{ backgroundColor: "#D1F0FF", padding: "7px", }}>

                        <div className='top-header-responsive' style={{ textAlign: "right", paddingRight: "10%" }}>
                            <a href="tel:+4733378901" style={{ color: "#707070", fontWeight: "normal" }} > <span style={{ padding: "10px" }}>   <CallIcon style={{ fontSize: "15px", }} />   &nbsp; +1234567890</span> </a>
                            <a href="mailto:sales@hrverse.com" style={{ color: "#707070", fontWeight: "normal" }} >            <span> <EmailIcon style={{ fontSize: "15px" }} />  &nbsp;sales@hrverse.com</span></a>
                        </div>




                        {/* <div className='toptripbar mobiletopstripbar '> */}


                        {/* 
                        <div className='top-header-responsive' style={{ textAlign: "right", paddingRight: "10%" }}>
                            <a href="tel:+4733378901" > <span style={{ padding: "10px" }}>   <CallIcon style={{ fontSize: "15px", }} />   &nbsp; +1234567890</span> </a>
                            <a href="mailto:sales@hrverse.com" >            <span> <EmailIcon style={{ fontSize: "15px" }} />  &nbsp;sales@hrverse.com</span></a>
                        </div> */}



                        {/* <Box
                                component="div"
                                sx={{
                                    display: 'inline',
                                    p: 1,
                                    m: 1,
                                    fontSize: '15px',
                                    fontWeight: " normal",
                                    color: "#707070",
                                }}
                            >
                                <a href="tel:+4733378901" className='heading-contect-font'>
                                    <CallIcon style={{ fontSize: "15px", }} />   &nbsp; +1234567890</a>
                            </Box>
                            <Box
                                component="div"
                                sx={{
                                    display: 'inline',
                                    p: 1,
                                    m: 1,
                                    fontSize: '15px',
                                    fontWeight: " normal",
                                    color: "#707070",
                                }}
                            >
                                <a href="mailto:sales@hrverse.com" className='heading-contect-font'>
                                    <EmailIcon style={{ fontSize: "15px" }} />  &nbsp;      sales@hrverse.com
                                </a>
                            </Box> */}




                        {/* </div> */}



                    </Card>



                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon style={{ backgroundColor: "#D1F0FF", color: "#707070", borderRadius: "5px" }} />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            {/* MUI */}
                            <Link to="/">
                                <img className='logoappbar' src={Logo} />
                            </Link>
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }} style={{ paddingRight: "8%" }}>
                            {/* {navItems.map((item) => (
                            <Button key={item} sx={{ color: '#707070' }}>
                                {item}
                            </Button>
                        ))} */}
                            {/* <AppBar /> */}
                            <AppBars />
                        </Box>

                    </Toolbar>
                </AppBar_h>
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none', },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>
                {/* <Box component="main" sx={{ p: 3 }}>
                    <Toolbar />
                    <Typography>
                        Hrverse
                    </Typography>
                </Box> */}
            </Box>
            {/* <Outlet /> */}
        </>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;


