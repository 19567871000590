// Step1.js
import React from 'react';
import TextField from '@mui/material/TextField';
import { Button, Grid } from '@material-ui/core';

import useStyles from '../../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';


const Step1 = ({ formData, handleInputChange }) => {

    const classes = useStyles();

    return (
        <>
            {/* <div>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                />
            </div> */}
            <br />

            <Grid container spacing={2} >
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Seo Title</label><br />
                    <input
                        placeholder='Seo Title'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Seo Title In Marathi</label><br />
                    <input
                        placeholder='Seo Title In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Seo Title In Hindi</label><br />
                    <input
                        placeholder='Seo Title In Hindi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Seo Desc</label><br />
                    <textarea
                        placeholder='Tractor Name'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        style={{ height:"50px"}}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Seo Desc In Marathi</label><br />
                    <textarea
                        placeholder='Seo Desc In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        style={{ height:"50px"}}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Seo Desc In Hindi</label><br />
                    <textarea
                        placeholder='Seo Desc In Hindi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        style={{ height:"50px"}}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Seo Keyword</label><br />
                    <textarea
                        placeholder='Seo Keyword'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        style={{ height:"50px"}}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Seo Keyword In Marathi</label><br />
                    <textarea
                        placeholder='Seo Keyword In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        style={{ height:"50px"}}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Seo Keyword In Hindi</label><br />
                    <textarea
                        placeholder='Seo Keyword In Hindi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        style={{ height:"50px"}}
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>


            </Grid >
        </>

    );
};

export default Step1;
