import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { API_BASE_URL, token } from '../../../constants';
import { Grid, CircularProgress, Snackbar } from '@material-ui/core';

const AutocompleteSearch = () => {
    const [tractorOptions, setTractorOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        let cancelTokenSource;

        const fetchTractorData = async () => {
            setLoading(true);

            // Cancel previous request if it exists
            if (cancelTokenSource) {
                cancelTokenSource.cancel('Request cancelled due to new request');
            }

            cancelTokenSource = axios.CancelToken.source();

            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity,
                    cancelToken: cancelTokenSource.token,
                };

                const requestData = {
                    per_page: 1500,
                    page: 1,

                };

                const response = await axios.post(
                    `${API_BASE_URL}/admin/api/v1/report/new/tractor`,
                    requestData,
                    config,
                );

                const tractorList = response.data.data.new_tractor.map((tractor) => ({
                    id: tractor.tractor_id,
                    name: tractor.tractor_name,
                }));

                setTractorOptions(tractorList);
            } catch (error) {
                if (!axios.isCancel(error)) {
                    setError(error.message || 'Error fetching tractors');
                    setSnackbarMessage('Error fetching tractors');
                    setSnackbarOpen(true);
                    console.error('Error fetching tractors:', error);
                }
            } finally {
                setLoading(false);
            }
        };

        const debounceSearch = setTimeout(() => {
            if (searchValue.trim() !== '') {
                fetchTractorData();
            } else {
                setTractorOptions([]);
            }
        }, 1000); // 1-second debounce delay

        return () => {
            clearTimeout(debounceSearch);
            if (cancelTokenSource) {
                cancelTokenSource.cancel('Request cancelled due to component unmount');
            }
        };
    }, [searchValue]);

    const handleSelectChange = (event, selectedOption) => {
        setSelectedItem(selectedOption);
        // Do something with the selected tractor
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3} sm={3}>
                    <Autocomplete
                        options={tractorOptions}
                        getOptionLabel={(option) => option.name}
                        value={selectedItem}
                        onChange={handleSelectChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                onChange={(e) => setSearchValue(e.target.value)}
                                error={!!error}
                                helperText={error}
                                placeholder='Search Tractor'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </div>
    );
};

export default AutocompleteSearch;
