import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { Button, Grid, Typography } from '@material-ui/core';
import axios from "axios";

import { useAutocomplete } from '@mui/base/useAutocomplete';
import { API_BASE_URL, token, userLevel } from "../../../../constants";




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        minWidth: 300,
        maxWidth: 900,
    },

}));

export default function CustomizedDialogs() {
    const [open, setOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({}); // Store form data
    const { id } = useParams(); // Retrieve the ID from the URL

    // const handleClickOpen = () => {
    //     setOpen(true);
    //     // Fetch data from the JSONPlaceholder API using the ID
    //     fetch(`https://jsonplaceholder.typicode.com/posts/${id}`)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             setFormData(data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching data:', error);
    //         });
    // };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSaveChanges = () => {
        // Perform your update logic here
        // formData contains the updated data
        console.log('Updated data:', formData);
        setOpen(false);
    };




    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-demo',
        options: top100Films,
        getOptionLabel: (option) => option.title,
    });

    const classes = useStyles();


    // @@@@@@@@@@@@@@@ state city taluka start
    const [selectedState, setSelectedState] = useState("");

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [talukas, setTalukas] = useState([]);
    // const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    const [selectedTaluka, setSelectedTaluka] = useState("");



    const [userLevels, setUserLevels] = useState([]);
    const [selectedUserLevel, setSelectedUserLevel] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://khetigaadi.com/webApi/userLevel');
                setUserLevels(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);


    // Fetch states data
    useEffect(() => {
        axios
            .get("https://khetigaadi.com/webApi/states")
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error("Error fetching states:", error);
            });
    }, []);

    // // Fetch cities data based on selected state
    useEffect(() => {
        if (selectedState) {
            axios
                .get(`https://khetigaadi.com/webApi/cities/${selectedState}`)
                .then((response) => {
                    setCities(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching cities:", error);
                });
        }
    }, [selectedState]);

    // Fetch talukas data based on selected city
    useEffect(() => {
        if (selectedCity) {
            axios
                .get(`https://khetigaadi.com/webApi/taluka/${selectedCity}`)
                .then((response) => {
                    setTalukas(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching talukas:", error);
                });
        }
    }, [selectedCity]);

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity("");
        setSelectedTaluka("");
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setSelectedTaluka("");
    };

    const handleTalukaChange = (event) => {
        setSelectedTaluka(event.target.value);
    };



    // @@@@@@@@@@@@@@@ state city taluka start


    const handleInputChangecomman = (e) => {
        const { name, value } = e.target;

        // Split the name into nested property parts
        const propertyNames = name.split(".");

        // Use the property names to traverse the nested structure and update the state
        setEnquiryData((prevData) => {
            let updatedData = { ...prevData };

            // Traverse the nested structure
            let currentLevel = updatedData;
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];

                // Check if it's the last part of the property name
                if (i === propertyNames.length - 1) {
                    // Update the value at the last level
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = value; // Assuming value is directly updated in array
                    } else {
                        currentLevel[propertyName] = value;
                    }
                } else {
                    // Create nested objects if they don't exist
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = currentLevel[index] || {}; // Create empty object if not exists
                    } else {
                        currentLevel[propertyName] = currentLevel[propertyName] || {};
                    }
                    // Move to the next level
                    currentLevel = currentLevel[propertyName];
                }
            }

            return updatedData;
        });
    };

    // @@@@@@@@@@@@@@@@@@@fa-border
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enquiryData, setEnquiryData] = useState({
        enquiry_from: "",
        tractor_table: {
            tractor_id: "",
        },
        exchanged: "",
        exchanged_tractor_id: "",
        enquiry_source: "",
        date: "",
        is_dublicate: "",
        enquiry_type: "",
        enquiry_name: "",
        enq_details: [
            {
                remark: "",
                down_payment: "",
                loan_type: "",
                reason: "",
                month_year: "",
            },
        ],
        executive: {
            admin_id: "",
        },
        crm_call_status: {
            id: "",
        },
        user: {
            mobile: "",
            first_name: "",
            last_name: " ",
            email: "",
            state: {
                state_id: "",
            },
            city: {
                city_id: "",
            },
            taluka: {
                id: "",
            },
            user_detail: {
                pincode: "",
                user_level: {
                    user_level_id: "",
                },

                village: "",
                whatsapp: "",
                telegram: "",
                land: "",
                tractor_owner: "",
                purpose: "",
                land_unit: "",
                crop_details: [
                    {
                        crop_id: "",
                        // crop_id: "",
                        to_days: "",
                        crop_icon: "",
                        crop_name: "",
                        from_days: "",
                        scientific_name: " ",
                    },
                ],
            },
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.post(
                    `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    config,
                );

                setEnquiryData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("An error occurred while fetching data.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, token]);



    const formatDataForSubmission = (data) => {
        return {
            // ...data,

            tractor_table: {
                tractor_id: enquiryData?.tractor_table?.tractor_id || "",
            },
            exchanged: enquiryData?.exchanged || "",
            exchanged_tractor_id: enquiryData?.exchanged_tractor_id || "",
            enquiry_source: enquiryData?.enquiry_from || "",
            date: enquiryData?.date || "",
            is_dublicate: enquiryData?.is_dublicate || "",
            // enquiry_type: "new",
            enquiry_type: enquiryData?.enquiry_name || "",
            enq_details: {
                remark: enquiryData?.enq_details[0]?.remark || "",
                // remark: "hhdfgdfgdfgdfg",
                // remark: enquiryData?.enq_details?.map(
                //     (rem) => rem.remark,
                // ),
                down_payment: enquiryData?.enq_details[0]?.down_payment || "",
                loan_type: enquiryData?.enq_details[0]?.loan_type || "",
                reason: enquiryData?.enq_details[0]?.reason || "",
                month_year: enquiryData?.enq_details[0]?.month_year || "",
            },
            executive: {
                admin_id: enquiryData?.executive?.admin_id || "",
            },
            crm_call_status: {
                id: enquiryData?.crm_call_status?.id || "",
            },
            user: {
                mobile: enquiryData?.user?.mobile || "",
                first_name: enquiryData?.user?.first_name || "",
                last_name: enquiryData?.user?.last_name || "",
                email: enquiryData.user.email || "",
                state: {
                    // state_id: 28,
                    state_id: enquiryData?.user?.state?.state_id || "",
                },
                city: {
                    // city_id: 999,
                    city_id: enquiryData?.user?.city?.city_id || "",
                },
                taluka: {
                    // id: 4453,
                    id: enquiryData?.user?.taluka?.id || "",
                },
                // user_detail: {
                //   
                //     land_unit: enquiryData.user.user_detail.land_unit,
                //     crop_details: [
                //         {
                //             // crop_id: 2,
                //             // crop_id: enquiryData?.user?.user_detail?.crop_details[0]?.crop_id,
                //             // crop_id:
                //             // enquiryData?.user?.user_detail?.crop_details.map(
                //             // (crop) => crop.crop_id,
                //             // ) || [],

                //             // crop_id:
                //             // enquiryData?.user?.user_detail?.crop_details?.map(
                //             // (crop) => crop.crop_id,
                //             // ) || [],
                //             // crop_id: enquiryData?.user?.user_detail?.crop_details?.map(
                //             //     (crop) => crop.crop_id,
                //             // ),

                //             // @@@@@@@@@@@
                //             // to_days: enquiryData?.user?.user_detail?.crop_details[0]?.to_days || "",
                //             // crop_icon: enquiryData?.user?.user_detail?.crop_details[0]?.crop_icon || "",
                //             // crop_name: enquiryData?.user?.user_detail?.crop_details[0]?.crop_name || "",
                //             // from_days: enquiryData?.user?.user_detail?.crop_details[0]?.from_days || "",
                //             // scientific_name: enquiryData?.user?.user_detail?.crop_details[0]?.scientific_name || "",
                //         },
                //     ],
                // },

                user_detail: {
                    pincode: enquiryData?.user?.user_detail?.pincode || "",
                    user_level: {
                        // user_level_id: 445
                        user_level_id: enquiryData?.user?.user_detail?.user_level?.user_level_id || "",
                    },
                    village: enquiryData?.user?.user_detail?.village || "",
                    whatsapp: enquiryData?.user?.user_detail?.whatsapp || "",
                    telegram: enquiryData?.user?.user_detail?.telegram || "",
                    land: enquiryData?.user?.user_detail?.land || "",
                    tractor_owner: enquiryData?.user?.user_detail?.tractor_owner || "",
                    purpose: enquiryData?.user?.user_detail?.purpose || "",
                    land_unit: enquiryData?.user?.user_detail?.land_unit || "",
                    crop_details: [
                        {
                            // to_days: enquiryData?.user?.user_detail?.crop_details[0]?.to_days || "",
                            // crop_icon: enquiryData?.user?.user_detail?.crop_details[0]?.crop_icon || "",
                            // from_days: enquiryData?.user?.user_detail?.crop_details[0]?.from_days || "",
                            // scientific_name: enquiryData?.user?.user_detail?.crop_details[0]?.scientific_name || "",

                            // to_days: 390,
                            // crop_icon: "Sugarcane_icon.png",
                            // from_days: 380,
                            // scientific_name: "Saccharum officinarum"

                            to_days: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.to_days || '',
                            crop_icon: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.crop_icon || '',
                            from_days: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.from_days || '',
                            crop_name: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.crop_name || '',
                            scientific_name: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.scientific_name || '',
                        }
                    ]
                }
            },
        };
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading state to true before making the API call


        const formattedData = formatDataForSubmission(enquiryData);

        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/admin/v2/update-user-enquiry/enquiry/${enquiryData.id}`,
                // enquiryData,
                formattedData,
            );
            console.log(response.data);

            // console.log("Enquiry data submitted:", formattedData);
            // console.log("Enquiry data submitted:", enquiryData);
            // setSubmittedDataArray([...submittedDataArray, formattedData]);

        } catch (error) {
            console.error("Error updating data:", error);
            // Handle error
        } finally {
            setIsLoading(false); // Set loading state back to false after the API call completes
        }
    };


    return (


        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open dialog
            </Button> */}
            <div>
                <EditIcon className={classes.tableiconedit} style={{ cursor: "pointer" }} onClick={handleClickOpen} />
            </div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <Typography className={classes.tabletittle} >
                        Add-Update User
                    </Typography>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers >
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={2} >
                            <Grid item xs={3} sm={3} lg={3} >

                                <label for="birthday" className={classes.labelform}>First Name</label><br />
                                <input
                                    placeholder="Enter First Name"
                                    className={classes.inputform}
                                    type="text"
                                    name="user.first_name"
                                    value={enquiryData?.user?.first_name || ""}
                                    onChange={handleInputChangecomman}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Last Name</label><br />
                                <input
                                    placeholder="Enter Last Name"
                                    className={classes.inputform}
                                    type="text"
                                    name="user.last_name"
                                    value={enquiryData?.user?.last_name || ""}
                                    onChange={handleInputChangecomman}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Email</label><br />
                                <input
                                    placeholder="Enter Email"
                                    className={classes.inputform}
                                    type="email"
                                    name="user.email"
                                    value={enquiryData?.user?.email || ""}
                                    onChange={handleInputChangecomman}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Mobile</label><br />
                                <input
                                    placeholder="Enter Email"
                                    className={classes.inputform}
                                    type="number"
                                    name="user.mobile"
                                    value={enquiryData?.user?.mobile || ""}
                                    onChange={handleInputChangecomman}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>WhatsApp Number</label><br />
                                <input
                                    name="user.user_detail.whatsapp"
                                    value={enquiryData?.user?.user_detail?.whatsapp || ""}
                                    onChange={handleInputChangecomman}
                                    placeholder="WhatsApp Number"
                                    className={classes.inputform}
                                    type="number"
                                />
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
                                <label for="birthday" className={classes.labelform}>Same as Mobile Number
                                </label>

                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Telegram Number</label><br />
                                <input
                                    name="user.user_detail.telegram"
                                    value={enquiryData?.user?.user_detail?.telegram || ""}
                                    onChange={handleInputChangecomman}
                                    placeholder="Telegram Number"
                                    className={classes.inputform}
                                    type="number"
                                />
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"></input>
                                <label for="birthday" className={classes.labelform}>Same as Whatsapp  Number
                                </label>
                            </Grid>

                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Smart Phone User</label><br />
                                <select id="cars" name="carlist" form="carform"
                                    className={classes.selectform} >
                                    <option value="volvo">Yes</option>
                                    <option value="saab">No</option>
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Select Occupation</label><br />
                                <select
                                    name="user.user_detail.user_level.user_level_id"
                                    value={enquiryData?.user?.user_detail?.user_level?.user_level_id || ""}
                                    onChange={handleInputChangecomman}
                                    className={classes.selectform}
                                >
                                    <option value="" selected="">
                                        Select Occupation
                                    </option>
                                    {userLevels?.map((userLevel) => (
                                        <option key={userLevel.user_level_id} value={userLevel.user_level_id}>
                                            {userLevel.user_level_name}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Land</label><br />
                                <input
                                    placeholder="Enter Your Farm Land"
                                    className={classes.inputform}
                                    type="text"
                                    name="user.user_detail.land"
                                    value={enquiryData?.user?.user_detail?.land || ""}
                                    onChange={handleInputChangecomman}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>User Type</label><br />
                                <select id="cars" name="carlist" form="carform"
                                    className={classes.selectform} >
                                    <option value="">Select User Type</option>
                                    <option value="user" selected="">User</option>
                                    <option value="dealer">Dealer</option>
                                    <option value="broker">Broker</option>
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>Tractor Owner
                                </label><br />
                                <select id="cars" name="carlist" form="carform"
                                    className={classes.selectform} >
                                    <option value="user" selected="">Yes</option>
                                    <option value="dealer">No</option>
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                            </Grid>
                            {/* <Grid item xs={3} sm={3} lg={3} >
                            <div>
                                <div {...getRootProps()}>
                                    <label for="birthday" className={classes.labelform}>Select State</label><br />
                                    <input className={classes.inputform}
                                        {...getInputProps()} />
                                </div>
                                {groupedOptions.length > 0 ? (
                                    <ul className={classes.listbox}  {...getListboxProps()}>
                                        {groupedOptions.map((option, index) => (
                                            <li  {...getOptionProps({ option, index })}>{option.title}</li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>
                        </Grid> */}

                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select State
                                </label>
                                <br />

                                <select
                                    className={classes.selectform}
                                    name="user.state.state_id"
                                    value={enquiryData?.user?.state?.state_id || ""}
                                    onChange={(e) => {
                                        handleInputChangecomman(e);
                                        handleStateChange(e);
                                    }}
                                >
                                    <option value="">Select a state</option>
                                    {states.map((state) => (
                                        <option key={state.id} value={state.id}>
                                            {state.value}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select District
                                </label>
                                <br />

                                <select
                                    className={classes.selectform}
                                    name="user.city.city_id"
                                    value={enquiryData?.user?.city?.city_id || ""}
                                    onChange={(e) => {
                                        handleInputChangecomman(e);
                                        handleCityChange(e);
                                    }}
                                // onChange={handleInputChangecomman}
                                >
                                    <option value="">Select a city</option>
                                    {cities.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.value}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select Taluka
                                </label>
                                <br />

                                <select
                                    className={classes.selectform}
                                    name="user.taluka.id"
                                    value={enquiryData?.user?.taluka?.id || ""}
                                    onChange={(e) => {
                                        handleInputChangecomman(e);
                                        handleTalukaChange(e);
                                    }}
                                >
                                    <option value="">Select taluka</option>
                                    {talukas.map((taluka) => (
                                        <option key={taluka.id} value={taluka.id}>
                                            {taluka.value}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={3} sm={3} lg={3} >
                                <label for="birthday" className={classes.labelform}>
                                    Select Village
                                </label>
                                <br />
                                <input
                                    placeholder="Select Village"
                                    className={classes.inputform}
                                    type="text"
                                    name="user.user_detail.village"
                                    // value={enquiryData?.user?.user_detail?.village || ''}
                                    value={enquiryData?.user?.user_detail?.village || ""}
                                    onChange={handleInputChangecomman}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <br />

                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center',     // Center vertically
                                    height: '100%',
                                }}>
                                    <Button
                                        type="submit"
                                        style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                        className={classes.buttonform}
                                        variant="outlined"
                                        startIcon={<SaveIcon />}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button autoFocus onClick={handleSaveChanges}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>
        </div>
    );
}




// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top Listbox
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },

];
