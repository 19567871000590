import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import axios from 'axios'; // Import Axios

const DataTableWithAutocomplete = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [users, setUsers] = useState([]); // Store API data

  const columns = ["Name", "Email"];

  const options = {
    filterType: 'checkbox',
    responsive: 'vertical',
  };

  useEffect(() => {
    // Fetch data from JSONPlaceholder API
    axios.get('https://jsonplaceholder.typicode.com/users')
      .then((response) => {
        const userData = response.data.map((user) => ({
          name: user.name,
          email: user.email,
        }));
        setUsers(userData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array to fetch data once on component mount

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedOptions([...users]);
    } else {
      setSelectedOptions([]);
    }
    setSelectAll(!selectAll);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedOptions(newValue);

    if (newValue.length === 0) {
      setFilteredData(users);
    } else {
      const selectedNames = newValue.map((item) => item.name);
      const filtered = users.filter((user) => selectedNames.includes(user.name));
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    setFilteredData(users); // Initialize with all data
  }, [users]);

  return (
    <div>
      <Autocomplete
        multiple
        id="checkboxes-autocomplete"
        options={users}
        disableCloseOnSelect
        getOptionLabel={(option) => option.name}
        value={selectedOptions}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Checkboxes"
            InputLabelProps={{ style: { fontSize: '12px', color: '##64728c' } }}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <>
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              {option.name}
            </li>
          </>
        )}
        limitTags={2}
      />
      <MUIDataTable
        title={'User List'}
        data={filteredData.map((user) => [user.name, user.email])}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default DataTableWithAutocomplete;


