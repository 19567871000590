import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from "../../../../Khetigaadi/StyleComponents/StyleFunctionComponent";
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, TableFooter, TableRow, TableCell } from "@material-ui/core";





const CommentTable = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `https://jsonplaceholder.typicode.com/comments?postId=${id}`
            );
            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const columns = [

        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },


        {
            name: "name",
            label: "Brand Model",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "id",
            label: "On Road Price",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "id",
            label: "Demo",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "id",
            label: "Book Now",
        },

        {
            name: "id", // The name of the column
            label: "Total", // The label to display in the table header
            // label: (
            //     <span style={{ color: "#dc3545" }} >Total</span> // Apply the custom CSS class here
            // ),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Customize the rendering of the cell content here
                    return (
                        <span className={classes.fontred}>
                            {value}
                        </span>
                    );
                },
            },
        },


        // {
        //     name: 'id',
        //     label: 'ID',
        // },
        // {
        //     name: 'name',
        //     label: 'Name',
        // },
        // {
        //     name: 'email',
        //     label: 'Email',
        // },
        // {
        //     name: 'body',
        //     label: 'Comment',
        //     options: {
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             return (
        //                 <span className={classes.fontred}>
        //                     {value}
        //                 </span>
        //             );
        //         },
        //     },
        // },
    ];




    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 9999, 99999],
        customTableBodyFooterRender: () => {
            return (
                <>
                    <TableFooter className={classes.footerCell}>
                        <TableRow>
                            <TableCell colspan="2">
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                8666.3
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                5433.00
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                30.0044
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                550.00
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </>
            );
        }
    };

    const theme = createTheme();
    const classes = useStyles();


    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Harvester Brands Stat Reports
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <br />

            <MuiThemeProvider theme={customTheme}>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <MUIDataTable
                        // title={`Comments for Post ID ${id}`}
                        title={
                            <Typography className={classes.tabletittle} >
                                Harvester Models Stat Reports
                                {/* {` ${id}`} */}
                            </Typography>
                        }
                        data={data}
                        columns={columns}
                        options={options}
                    />
                )}
            </MuiThemeProvider>
        </div>
    );
};

export default CommentTable;
