import React, { Component, useState, Fragment, useEffect, useRef, useMemo } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import LightModeIcon from '@mui/icons-material/LightMode';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, Drawer, Menu, Paper, Select, MenuItem, Tooltip } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { customTheme, getColorfont, getColor } from '../../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import ApprovalIcon from '@mui/icons-material/Approval';
import FilterListIcon from '@mui/icons-material/FilterAlt';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddUpdateUser from "../../AddUpdateUser/AddUpdateUser";
import SearchUserform from "../../SearchUser/SearchUserform";
import useStyles from '../../../../StyleComponents/StyleFunctionComponent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SettingsIcon from '@mui/icons-material/Settings';


import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';

import { API_BASE_URL, token, userLevel, adminId } from '../../../../../constants';


const QuotationBookReport = () => {
    const history = useHistory();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadinguser, setIsLoadinguser] = useState(false);
    const [error, setError] = useState(null);
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    // const currentDate = new Date();
    const currentDate = new Date();
    // Set the current date to 10 days later
    currentDate.setDate(currentDate.getDate() + 60);

    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 100);

    const [fromDate, setFromDate] = useState(sevenDaysAgo.toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(currentDate.toISOString().split('T')[0]);
    const [productBrand, setProductBrand] = useState([]);
    const [states, setStates] = useState([]);
    const [enquirySource, setEnquirySource] = useState([]);

    const [selectedExecutive, setSelectedExecutive] = useState(null);
    const [executiveOptions, setExecutiveOptions] = useState([]);
    const [selectedState, setSelectedState] = useState();
    const [stateOptions, setStateOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState();
    const [brandOptions, setBrandOptions] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [excutiveassign, setExcutiveAssign] = useState('');
    const [doubleClickedRow, setDoubleClickedRow] = useState(null);
    const [message, setMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [sources, setSources] = useState([]); // Changed state variable name to sources
    const [selectedSource, setSelectedSource] = useState(); // Renamed selectedOption to selectedSource

    const { id } = useParams();

    const [enquiryData, setEnquiryData] = useState({

        tractor_table: {
            tractor_id: "",
        },
        crm_call_status: {
            id: ""
        },

        date: "",
        is_dublicate: "",
        exchanged: "",
        enquiry_source: "",
        exchanged_tractor_id: "",
        enq_details: {
            remark: "",
            down_payment: "",
            loan_type: "",
            reason: "",
            month_year: ""
        },
        executive: {
            admin_id: "",
        },

        // @@@@@@@@@@ to add extra feild 
        user_id: "",
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        state_id: "",
        city_id: "",
        taluka_id: "",
        user_type: "",
        status: "",
        user_image: "",
        otp: "",
        otp_guru: "",
        blocked_at: "",
        is_onboard: "",
        created_at: "",
        updated_at: "",
        follower_status: "",
        user_detail: {
            user_level: {
                user_level_id: ""
            },
            education_id: "",
            crop_details: [
                {
                    crop_id: "",
                    to_days: "",
                    crop_icon: "",
                    crop_name: "",
                    from_days: "",
                    scientific_name: ""
                },

            ],
            land: "",
            tractor_owner: "",
            is_owner: "",
            is_smartphone: "",
            biography: "",
            awards: "",
            plan: "",
            whatsapp: "",
            telegram: "",
            alternative_contact_number: "",
            village: "",
            pincode: "",
            dob: "",
            gender: "",
            purpose: ""
        },
        state: {
            state_id: "",
            state_name: "",
            short_name: ""
        },
        city: {
            city_id: "",
            city_name: " "
        },
        taluka: ""
    });


    // const fetchData = async (from, to, perPage, pageNumber, searchQuery,) => {
    const fetchData = async (from, to, pageNumber, searchQuery,) => {
        setIsLoadinguser(true);
        setError(null); // Reset error state

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,
                    'adminId': `${adminId}`,
                    'userLevel': `${userLevel}`,

                }
            };

            const requestData = {
                start_date: fromDate,
                end_date: toDate,
                pagination: perPage,
                page: page + 1,
                search: search,
                product_brand: selectedBrand,
                states: selectedState,
                executive: selectedExecutive,
                user_level: userLevel,

                enquiry_source: selectedSource ? [{ from: selectedSource }] : null, // Filter based on selected enquiry source
                // user_id: 1513629,
                user_id: id,
                // start_date: "2020-11-29",
                // end_date: "2024-03-08",



            };

            // Conditionally set executive based on user_level selectedSource
            if (userLevel === 'executive') {
                requestData.executive = adminId; // Replace selectedExecutive with admin_id
            } else {
                requestData.executive = selectedExecutive;
            }


            const response = await axios.post(`${API_BASE_URL}/api/admin/v2/pendings/all-pending/user`, requestData, config);

            const mappedData = response.data.data.data.map(item => {

                const formattedDate = new Date(item.created_at).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour12: true,
                });
                // const date = new Date(item.date).toLocaleString('en-IN', {
                //     year: 'numeric',
                //     month: 'short',
                //     day: '2-digit',
                //     hour12: true,
                // });

                const date = item.date ? new Date(item.date).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour12: true,
                }) : "";
                const fullName = `${item.user.first_name} ${item.user.last_name}`;
                const tractorname = item.tractor_table.tractor_model ? item.tractor_table.tractor_model.tractor_model_name : '';
                const brandname = item.tractor_table.brand ? item.tractor_table.brand.brand_name : '';
                const stateName = item.user.state ? item.user.state.state_name : '';
                const cityName = item.user.city ? item.user.city.city_name : '';
                const taluka = item.user.taluka ? item.user.taluka.name : '';
                const executive = item.executive ? item.executive.username : '';
                const admin_id = item.executive ? item.executive.admin_id : '';
                const call_status = item.crm_call_status ? item.crm_call_status.call_status : '';
                return {
                    id: item.id,
                    enquiry_name: item.enquiry_name,
                    fullName: fullName,
                    mobile: item.user.mobile,
                    email: item.user.email,
                    created_at: formattedDate,
                    tractor_model_name: tractorname,
                    brand_name: brandname,
                    state_name: stateName,
                    city_name: cityName,
                    taluka: taluka,
                    executive: executive,
                    admin_id: admin_id,
                    enquiry_from: item.enquiry_from,
                    is_dublicate: item.is_dublicate,
                    call_status: call_status,
                    date: date,

                };
            });

            const sortedData = mappedData.sort((a, b) => a.id - b.id);

            setData(sortedData);
            setTotalCount(response.data.data.total);
            setIsLoadinguser(false);

            // Set the message for Snackbar
            setSnackbarMessage(response.data.message);
            // Open the Snackbar
            setSnackbarOpen(true);


        } catch (error) {
            console.error("Error fetching data:", error);

            if (axios.isCancel(error)) {
                console.error("Request canceled:", error.message);
                // Handle canceled request (if needed)
            } else {
                if (error.code === 'ECONNABORTED') {
                    setError('The request timed out. Please try again.');
                } else if (error.response) {
                    console.error("Server Error Details:", error.response.data);
                    setError(`Error: ${error.response.status} - ${error.response.statusText}`);
                } else {
                    setError('An error occurred while fetching data.');
                }
            }

        } finally {
            setIsLoadinguser(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                        userLevel: `${userLevel}`,
                        adminId: `${adminId}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.get(
                    // `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    // `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    `${API_BASE_URL}/api/admin/v2/show-user/${id}`,
                    config,
                );

                setEnquiryData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("An error occurred while fetching data.");
                // history.push('/app/bulkUpload'); 
                // history.push(`/AddNewEnquiry/${id}/bulk`);

            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, token]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://khetigaadi.com/webApi/sources');
                const data = response.data.data;
                setSources(data.map(item => item.enquiry_from)); // Updated setOptions to setSources
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSelectChange = (event) => {
        setSelectedSource(event.target.value); // Changed selectedOption to selectedSource
    };


    useEffect(() => {
        const fetchDatabrand = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/brand-list`, config);

                const statelist = response.data.data.map(item => ({
                    id: item.brand_id,
                    title: item.brand_name,
                }));

                setBrandOptions(statelist);

            } catch (error) {
                console.error('Error fetching state:', error);
            }
        };

        fetchDatabrand();
    }, []);




    useEffect(() => {
        const fetchDatastate = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/state-list`, config);
                const statelist = response.data.data.map(item => ({
                    id: item.state_id,
                    title: item.state_name,
                }));

                setStateOptions(statelist);

            } catch (error) {
                console.error('Error fetching state:', error);
            }
        };

        fetchDatastate();
    }, []);

    useEffect(() => {
        const fetchDatalist = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/executive-list`, config);
                const mappedData = response.data.data.map(item => ({
                    id: item.admin_id,
                    title: item.username,
                }));
                // Include an option for null or empty value
                const optionsWithNull = [
                    // { id: null, title: 'Select Executive' },
                    ...mappedData,
                ];

                setExecutiveOptions(optionsWithNull);
                // setExecutiveOptions(mappedData);

            } catch (error) {
                console.error('Error fetching executives:', error);
            }
        };

        fetchDatalist();
    }, []);

    // const handleExecutiveChange = (event, newValue) => {
    //     setSelectedExecutive(newValue);
    // };


    const handleExecutiveChange = (event) => {
        const selectedId = event.target.value;
        setSelectedExecutive(selectedId);
    };



    const handleStateChange = (event, newValue) => {
        setSelectedState(newValue);
    };

    const handleBrandChange = (event, newValue) => {
        setSelectedBrand(newValue);
    };

    const source = axios.CancelToken.source();

    // useEffect(() => {
    //     fetchData(perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource);
    //     // Cleanup function
    //     return () => {
    //         source.cancel('Request canceled by cleanup');
    //     };
    // }, [perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource]);



    const sourceRef = useRef(null);

    useEffect(() => {
        // Create a new cancel token source
        sourceRef.current = axios.CancelToken.source();

        fetchData(
            perPage,
            page,
            search,
            totalCount,
            selectedExecutive,
            selectedBrand,
            selectedState,
            enquirySource,
            sourceRef.current
        );

        // Cleanup function
        return () => {
            // Cancel the request when the component unmounts
            sourceRef.current.cancel('Request canceled by cleanup');
        };
    }, [perPage, page,], 2000); // Empty dependency array


    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
    };

    const handlePrevious = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setPage(0);
    //     fetchData(fromDate, toDate,);

    // };


    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        // fetchData(fromDate, toDate,);
        fetchData(perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource);

    };


    const clearForm = () => {
        window.location.reload();
        setSearch(''); // Reset search state to empty
    };



    const handleSelectAllCheck = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(Array.from({ length: data.length }, (_, i) => i));
        }
    };

    const handleRowSelect = (rowIndex) => {
        if (selectedRows.includes(rowIndex)) {
            setSelectedRows(selectedRows.filter((row) => row !== rowIndex));
        } else {
            setSelectedRows([...selectedRows, rowIndex]);
        }
    };

    const handleRowsSelect = (currentRowsSelected, allRowsSelected) => {
        setSelectedRows(allRowsSelected.map((row) => row.index));
    };

    const handleRowsDelete = (rowsDeleted) => {
        console.log('Rows to be deleted:', rowsDeleted);
        // Implement your logic to delete rows
    };


    const handleSubmits = () => {
        // window.location.reload();

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${token}`,
                'adminId': `${adminId}`,
                'userLevel': `${userLevel}`,


            },
            timeout: Infinity, // Request will never time out

        };

        const formData = {

            enquiry_id: selectedRows.map((rowIndex) => ({
                enquiry_id: data[rowIndex].id,
            })),


            executive: {
                admin_id: excutiveassign,
            }
        };

        console.log('Form Data:', formData);

        // Make an API request to submit the form data
        // axios.post('/api/submit', formData)
        axios.post(`${API_BASE_URL}/api/admin/v2/enquiry-assign-executive`, formData, config)
            .then(response => {
                // Handle the API response as needed
                console.log('API Response:', response.data);
                setMessage(response.data.message);

            })
            .catch(error => {
                // Handle API error
                console.error('API Error:', error);
            });

        // Implement your logic to submit form data
    };

    const handleExecutiveChanges = (e) => {
        setExcutiveAssign(e.target.value);
    };


    const columns = [

        {
            name: 'id',
            label: (
                <input
                    type="checkbox"
                    checked={selectedRows.length === data.length && data.length !== 0}
                    onChange={handleSelectAllCheck}
                />
            ),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => (
                    <input
                        type="checkbox"
                        checked={selectedRows.includes(tableMeta.rowIndex)}
                        onChange={() => handleRowSelect(tableMeta.rowIndex)}
                    />
                ),
            },
        },

        {
            name: 'id',
            label: 'S/N',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                }),

            },
        },
        {
            name: 'created_at', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Date",
        },
        {
            // name: 'admin_id', options: {
            name: 'date', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Folloup date",
        },

        {
            name: "enquiry_name",
            label: "Type",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.typestrip}
                        style={{
                            // backgroundColor: getColor(tableMeta.rowData[0]),
                            backgroundColor: getColor(value),
                            width: "100% !important",

                        }}
                    >
                        {value}
                    </button>
                ),
            },
        },
        {
            name: "brand_name",
            label: "Brand",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.brandstrip}

                        style={{
                            backgroundColor: getColor(value),
                            color: getColorfont(value),
                            width: "100% !important",

                        }}
                    >
                        {value}
                    </button>
                ),
            },
        },
        {
            name: "tractor_model_name",
            label: "Model ",
        },

        {
            name: "fullName",
            label: "User ",
        },
        {
            name: "mobile",
            label: "Mobile  ",
        },

        {
            name: "state_name",
            label: "State  ",
        },
        {
            name: "city_name",
            label: "City  ",
        },
        {
            name: "taluka",
            label: "Taluka  ",
        },
        {
            name: "enquiry_from",
            label: "From  ",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.typestrip}
                        style={{
                            backgroundColor: getColor(value),
                            width: "100%",
                        }}
                    >
                        {value}
                    </button>
                ),
            },
        },
        {
            name: "name",
            label: "Status",
        },
        {
            name: "executive",
            label: "Executive",
        },
        {
            name: "call_status",
            label: "Followup  ",
        },
        {
            name: "",
            label: "Followp Date",
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];
                    return (
                        <>
                            <Link to={`/app/UpdateFollowUpEnquiry/${rowData.id}/crm`} target="_blank">
                                <Button
                                    style={{ width: "20px", fontSize: "10px" }}
                                    className={classes.buttonform}
                                    variant="outlined" startIcon={<EditIcon style={{ fontSize: "12px" }} />}>
                                    Update
                                </Button>
                            </Link>

                        </>
                    );
                },
            },
        },
    ];

    const CustomToolbarAction = ({ toggleFilter }) => (
        // <IconButton aria-label="Filter list" onClick={toggleFilter}>
        //     <FilterListIcon onClick={toggleSidebar} />
        // </IconButton>

        <Tooltip title="Open (Shift + F)">
            <IconButton aria-label="Filter list" onClick={toggleSidebar}>
                <FilterListIcon />
            </IconButton>
        </Tooltip>
    );


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.shiftKey && event.key === 'F') {
                // Shift + D is pressed, open the sidebar
                setOpenSidebar(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };



    const options = {
        selectableRows: 'multiple',
        onRowsSelect: handleRowsSelect,
        onRowsDelete: handleRowsDelete,
        // Add more options as needed 
        rowsPerPage: perPage,
        selectableRows: 'none',
        page: page,
        serverSide: true,
        count: totalCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    setPage(0);
                    break;
                default:
                    break;
            }
        },

        customToolbar: () => <CustomToolbarAction />,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 1000, 10000, { value: totalCount, label: 'All' }],
        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <TextField
                    label="Filter Data"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    fullWidth
                />
                <Button onClick={applyNewFilter} variant="contained" color="primary">
                    Apply Filter
                </Button>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Cancel
                </Button>
            </div>
        ),
        setRowProps: (row, dataIndex, rowIndex) => ({
            style: {
                backgroundColor: doubleClickedRow === rowIndex ? '#D2DE32' : selectedRows.includes(dataIndex) ? '#D2DE32' : 'inherit',
            },
            onDoubleClick: () => handleRowDoubleClick(row, rowIndex),
        }),


    };


    const handleRowDoubleClick = (rowIndex) => {
        setDoubleClickedRow(rowIndex);
    };
    const currentTheme = customTheme(true);
    const classes = useStyles();
    const memoizedData = useMemo(() => data, [data]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <div>
                {/* Your other components */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <MuiAlert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
            {message && <p
                className={classes.message}
            >
                {message}
            </p>}

            <div style={{ display: "flex" }}>
                <Grid item xs={10} sm={6} md={10} lg={10} >
                    <Link className={classNames(classes.breadcrumbslinkHightlight, classes.bridheader)} to={`/app/UserEnquiries/${id}`} > Users Enquiries</Link>

                    <Button
                        style={{ marginLeft: "20px" }}
                        className={classes.buttonred}
                        variant="outlined" startIcon={<LightModeIcon />}>
                        Pending
                    </Button>

                    {/* <Link className={classNames(classes.breadcrumbslinkHightlight, classes.bridheader)} to={`/app/UserPending/${id}`} >Pending</Link> */}
                    <Link className={classNames(classes.breadcrumbslinkHightlight, classes.bridheader)} to={`/app/UserInterested/${id}`} >Interested</Link>
                    <Link className={classNames(classes.breadcrumbslinkHightlight, classes.bridheader)} to={`/app/AddNewEnquiry/${id}`} >Add New Enquiry</Link>
                    <Button
                        style={{ backgroundColor: "#28A745", borderColor: '#28A745', marginLeft: "20px", }}
                        className={classes.buttonform}
                        // startIcon={<PersonIcon />}
                        variant="outlined"
                    >
                        <span> {enquiryData.first_name}</span> &nbsp; <span> {enquiryData.last_name}</span>
                    </Button>
                    <Button
                        style={{ backgroundColor: "#28A745", borderColor: '#28A745', marginLeft: "20px", }}
                        className={classes.buttonform}
                        // startIcon={<PhoneIcon />}
                        variant="outlined"
                    >
                        <span> {enquiryData.mobile}</span>
                    </Button>
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={2} style={{ textAlign: "right", margin: "auto", padding: "auto", }}>
                    <Grid container>
                        <Grid item xs={10} sm={10} lg={10}>
                            {/* <SearchUserform /> */}
                        </Grid>
                        <Grid item xs={2} sm={2} lg={2} style={{ paddingTop: "8px" }}>
                            {/* <AddUpdateUser /> */}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <hr />
            <MuiThemeProvider theme={currentTheme}>
                {
                    isLoadinguser ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }

                <MUIDataTable
                    title={
                        isLoadinguser ? (
                            <Skeleton animation="wave" />

                        ) : (
                            <Typography className={classes.tabletittle} >
                                <div style={{ display: 'flex' }}>
                                    <select

                                        style={{ width: "200px", marginRight: "10px" }}
                                        className={classes.selectform} value={excutiveassign}
                                        onChange={handleExecutiveChanges}
                                    >
                                        <option value="">Select Executive</option>

                                        {executiveOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.title}
                                            </option>
                                        ))}
                                    </select>


                                    <Button
                                        onClick={handleSubmits}
                                        style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                        className={classes.buttonform}
                                        variant="outlined"
                                        startIcon={<AssignmentIndIcon />}
                                    >
                                        Assign
                                    </Button>
                                </div>
                            </Typography>
                        )
                    }
                    data={memoizedData}
                    columns={columns}
                    options={options}
                />

            </MuiThemeProvider>
            <Drawer anchor="right" open={openSidebar}

                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: 300,
                        padding: "10px"
                    }
                }}

                onClose={() => setOpenSidebar(false)}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>

                            <ClearIcon style={{ color: '#c0392b' }} onClick={() => setOpenSidebar(false)} />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={12} >
                            <br />
                            <Typography className={classes.tabletittle} >
                                Filter
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.root} style={{ paddingRight: "22px", paddingLeft: "22px" }}>
                        <div className={classes.root}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} lg={12} >
                                    {/* <Autocomplete
                                        options={executiveOptions}
                                        // getOptionLabel={(option) => option.id}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Excutive' variant="outlined" />
                                        )}
                                        value={selectedExecutive}
                                        onChange={handleExecutiveChange}
                                    /> */}
                                    <select
                                        className={classes.inputform}
                                        id="executiveSelect"
                                        value={selectedExecutive}
                                        onChange={handleExecutiveChange}
                                    >
                                        <option value="">Select Executive</option>
                                        {executiveOptions.map(option => (
                                            <option key={option.id} value={option.id}>
                                                {option.title}
                                            </option>
                                        ))}
                                    </select>



                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} >
                                    <select
                                        className={classes.inputform}
                                        id="sourceSelect"
                                        value={selectedSource} // Changed selectedOption to selectedSource
                                        onChange={handleSelectChange}
                                    >
                                        <option value="">Select Source</option>
                                        {sources.map((source, index) => ( // Changed options to sources
                                            <option key={index} value={source}>{source}</option> // Changed options to sources
                                        ))}
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} >
                                    <Autocomplete
                                        // multiple  
                                        classes={{
                                            option: classes.autocompleteoption,
                                        }}
                                        options={stateOptions}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select State' variant="outlined" />
                                        )}
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        disableCloseOnSelect  // Keep the dropdown open after selecting an option
                                        isOptionEqualToValue={(option, value) => option.id === value.id} // Customize option equality check
                                        getOptionSelected={(option, value) => option.id === value.id} // Customize how options are considered selected
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                {/* <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                /> */}
                                                {option.title}
                                            </li>
                                        )}
                                    />


                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} >
                                    <Autocomplete
                                        classes={{
                                            option: classes.autocompleteoption,
                                        }}
                                        // multiple 
                                        options={brandOptions}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select brand' variant="outlined" />
                                        )}
                                        value={selectedBrand}
                                        onChange={handleBrandChange}
                                        disableCloseOnSelect
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                {/* <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                /> */}
                                                {option.title}
                                            </li>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2} lg={12} >
                                    <input className={classes.inputform} type="date" name="fromDate" value={fromDate} onChange={handleDateChange} />

                                </Grid>

                                <Grid item xs={4} sm={2} lg={12}>
                                    <input className={classes.inputform} type="date" name="toDate" value={toDate} onChange={handleDateChange} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <br />

                    <Grid >
                        <Grid item xs={4} sm={2} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                            {/* <button className={classes.buttonform} type="submit">Fetch Data</button> */}
                            <Button
                                type='submit'
                                style={{ marginRight: "10px" }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ApprovalIcon />}>
                                Apply
                            </Button>
                            <Button
                                type="button" onClick={clearForm}
                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>

                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Drawer >

        </>
    );
};

export default QuotationBookReport;








