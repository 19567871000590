// constants.js

export const API_BASE_URL = 'https://khetigaadi.com';
export const IMPLEMENT_REPORT_ENDPOINT = '/admin/api/v1/report/new/implement';
// export const TOKEN = 'TOKEN';
// export const TOKEN = ''
export const TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiMmFhMDZiMzFjNTUxZmVkYThjMDM4MzA0ZTIwNmQ5MTUxOTVmOTE3MWVlN2QyZmFiY2NjMDJlNzdhMGRkZTljMmFkYjA1MmFmYmZiMWQ3ODQiLCJpYXQiOjE2OTg3MzMyMzMsIm5iZiI6MTY5ODczMzIzMywiZXhwIjoxNzMwMzU1NjMzLCJzdWIiOiI5Iiwic2NvcGVzIjpbXX0.t4wPWYo2VPZgqyixNTs-weR_bwEj80qLn1YBAbru7caSUFIyEiBsaubxnsrTVrp0zx9AyElewJmIcVXlNjY2e79OvKCMrtMShZ4gp6rc8PlA8qhfTlA86bniFTkZ9ho9s7g9mikcqAb0iZ-niEU5zPq58acXN-bHbDHTzIMH1LAt20CPb5VUbI3FSNp7KO05ePVxeRD5eKSB5_rRxb5PsUXW0YqeRdkJj6YqPTs8Iqv1DNlbG9ga2mshjEwYu0EJ9qXxwG_CU1CqVywOKD9AbSfEv4Oz3-hMs5EUx6-EMyOBvsapT_MispUdnIpt--Z7V1AEYYrqVN5DndT3Y2kbpzcEpdO4N0f2zBWIiY27TUT29Sc0utsdW9TgsgE-RUXrrSBkfcJfQW_cx_uSnwJ_rJDMIjwgWLfqAXNr0vYworYy6muSOGDh6jWdH7hvVkmCSZEwUwhZXcP8f64daFbF2DxV6aOreYHtEsE5T1fwoYkGY6nOyRZoZX4o0QCKHjabCDp-4K2RKFeZc84kmlrZDQHbvmfVhKlnSQhM43Cb6DaNo8l0QLQkW2LlOhBAm45YTJKo2exvadho9IZEAPhTqEQnqT12yjK5h4Mo5iR4E-TfGM2QU7F3UQbjRiYSxwLQ5Og3uVNDAP-W67phiEVQClEom-dxw3D3fczwEFd_Jbs'
// export const TOKEN = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiMmFhMDZiMzFjNTUxZmVkYThjMDM4MzA0ZTIwNmQ5MTUxOTVmOTE3MWVlN2QyZmFiY2NjMDJlNzdhMGRkZTljMmFkYjA1MmFmYmZiMWQ3ODQiLCJpYXQiOjE2OTg3MzMyMzMsIm5iZiI6MTY5ODczMzIzMywiZXhwIjoxNzMwMzU1NjMzLCJzdWIiOiI5Iiwic2NvcGVzIjpbXX0.t4wPWYo2VPZgqyixNTs-weR_bwEj80qLn1YBAbru7caSUFIyEiBsaubxnsrTVrp0zx9AyElewJmIcVXlNjY2e79OvKCMrtMShZ4gp6rc8PlA8qhfTlA86bniFTkZ9ho9s7g9mikcqAb0iZ-niEU5zPq58acXN-bHbDHTzIMH1LAt20CPb5VUbI3FSNp7KO05ePVxeRD5eKSB5_rRxb5PsUXW0YqeRdkJj6YqPTs8Iqv1DNlbG9ga2mshjEwYu0EJ9qXxwG_CU1CqVywOKD9AbSfEv4Oz3-hMs5EUx6-EMyOBvsapT_MispUdnIpt--Z7V1AEYYrqVN5DndT3Y2kbpzcEpdO4N0f2zBWIiY27TUT29Sc0utsdW9TgsgE-RUXrrSBkfcJfQW_cx_uSnwJ_rJDMIjwgWLfqAXNr0vYworYy6muSOGDh6jWdH7hvVkmCSZEwUwhZXcP8f64daFbF2DxV6aOreYHtEsE5T1fwoYkGY6nOyRZoZX4o0QCKHjabCDp-4K2RKFeZc84kmlrZDQHbvmfVhKlnSQhM43Cb6DaNo8l0QLQkW2LlOhBAm45YTJKo2exvadho9IZEAPhTqEQnqT12yjK5h4Mo5iR4E-TfGM2QU7F3UQbjRiYSxwLQ5Og3uVNDAP-W67phiEVQClEom-dxw3D3fczwEFd_Jbs'
// ...other constants

// export const IMPLEMENT_REPORT_ENDPOINT = '/admin/api/v1/login';





