import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LightModeIcon from "@mui/icons-material/LightMode";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import AddUpdateUserEditForm from "../../AddUpdateUser/AddUpdateUserEditForm";
import AddUpdateUser from "../../AddUpdateUser/AddUpdateUser";
import AddTractor from "../../AddTractor/AddTractor";
import useStyles from "../../../../StyleComponents/StyleFunctionComponent";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { API_BASE_URL, token, userLevel, adminId } from "../../../../../constants";
import LinearProgress from "@mui/material/LinearProgress";
import SearchUser from "../../../CRM/SearchUser/SearchUser";
import Skeleton from "@material-ui/lab/Skeleton";

import axios from "axios";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";

const AutocompleteDemo = () => {
    const BASE = "http://127.0.0.1:8000";

    const [executiveOptions, setExecutiveOptions] = useState([]);
    const [submittedDataArray, setSubmittedDataArray] = useState([]);

    const [stateOptions, setStateOptions] = useState([]);
    const [selectedState, setSelectedState] = useState("");

    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const [comments, setComments] = useState([]); // State for options of the new select field

    const [selectedItem, setSelectedItem] = useState(null);
    const [options, setOptions] = useState([]);
    const [formData, setFormData] = useState({
        id: "",
        title: "",
        body: "",
    });

    const [tractorOptions, setTractorOptions] = useState([]);
    const [selectedTractor, setSelectedTractor] = useState(null);

    // @@@@@@@@@@@@@@@ state city taluka start

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [talukas, setTalukas] = useState([]);
    // const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    const [selectedTaluka, setSelectedTaluka] = useState("");
    const [message, setMessage] = useState('');




    const [userLevels, setUserLevels] = useState([]);

    const [selectedUserLevel, setSelectedUserLevel] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://khetigaadi.com/webApi/userLevel');
                setUserLevels(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error
            }
        };

        fetchData();
    }, []);

    // Fetch states data
    useEffect(() => {
        axios
            .get("https://khetigaadi.com/webApi/states")
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                console.error("Error fetching states:", error);
            });
    }, []);

    // // Fetch cities data based on selected state
    useEffect(() => {
        if (selectedState) {
            axios
                .get(`https://khetigaadi.com/webApi/cities/${selectedState}`)
                .then((response) => {
                    setCities(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching cities:", error);
                });
        }
    }, [selectedState]);

    // Fetch talukas data based on selected city
    useEffect(() => {
        if (selectedCity) {
            axios
                .get(`https://khetigaadi.com/webApi/taluka/${selectedCity}`)
                .then((response) => {
                    setTalukas(response.data);
                })
                .catch((error) => {
                    console.error("Error fetching talukas:", error);
                });
        }
    }, [selectedCity]);

    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        setSelectedCity("");
        setSelectedTaluka("");
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        setSelectedTaluka("");
    };

    const handleTalukaChange = (event) => {
        setSelectedTaluka(event.target.value);
    };



    // @@@@@@@@@@@@@@@ state city taluka start

    // @@@@@@@@@@@@@@@ crop start
    const [crops, setCrops] = useState([]);
    const [selectedCrop, setSelectedCrop] = useState("");

    // Fetch crops data
    useEffect(() => {
        axios
            .get("https://khetigaadi.com/webApi/crop-list")
            .then((response) => {
                setCrops(response.data);
            })
            .catch((error) => {
                console.error("Error fetching crops:", error);
            });
    }, []);

    // const handleCropChange = (event) => {
    // setSelectedCrop(event.target.value);
    // };
    // @@@@@@@@@@@@@@@ crop end

    useEffect(() => {
        const fetchTractorData = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const requestData = {
                    per_page: 1500,
                    page: 1, // Assuming you want the first page
                };

                const response = await axios.post(
                    `${API_BASE_URL}/admin/api/v1/report/new/tractor`,
                    requestData,
                    config,
                );

                const tractorList = response.data.data.new_tractor.map((tractor) => ({
                    id: tractor.tractor_id,
                    name: tractor.tractor_name,
                    // name: tractor.tractor_id.brand.brand_name
                    // name: tractor.brand.brand_name

                    // name: tractor.tractor_model.tractor_model_name,
                }));

                setTractorOptions(tractorList);
            } catch (error) {
                console.error("Error fetching tractor data:", error);
            }
        };

        fetchTractorData();
    }, []); // Empty dependency array ensures this effect runs only once

    // useEffect(() => {
    // const fetchDatastate = async () => {
    // try {
    // const config = {
    // headers: {
    // 'Content-Type': 'application/json',
    // 'Accept': 'application/json',
    // 'Authorization': `${token}`,
    // },
    // timeout: Infinity, // Request will never time out
    // };

    // // const response = await axios.post(`${API_BASE_URL}/api/admin/v2/state-list`, config);
    // const response = await axios.get(`https://khetigaadi.com/webApi/states`, config);
    // const statelist = response.data.map(item => ({
    // id: item.id,
    // title: item.value,
    // }));

    // setStateOptions(statelist);

    // } catch (error) {
    // console.error('Error fetching state:', error);
    // }
    // };

    // fetchDatastate();
    // }, []);

    // @@@@@@@@@@@@@ excutive

    useEffect(() => {
        const fetchDatalist = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(
                    `${API_BASE_URL}/api/admin/v2/executive-list`,
                    config,
                );
                const mappedData = response.data.data.map((item) => ({
                    id: item.admin_id,
                    title: item.username,
                }));
                // Include an option for null or empty value
                const optionsWithNull = [
                    // { id: null, title: "Select Executive" },
                    ...mappedData,
                ];

                setExecutiveOptions(optionsWithNull);
                // setExecutiveOptions(mappedData);
            } catch (error) {
                console.error("Error fetching executives:", error);
            }
        };

        fetchDatalist();
    }, []);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                // Replace with the actual API endpoint for comments
                const response = await axios.get(
                    "https://jsonplaceholder.typicode.com/comments",
                );
                setComments(response.data);
            } catch (error) {
                console.error("Error fetching comments:", error);
            }
        };
        fetchComments();
    }, []);

    useEffect(() => {
        // Fetch data from the JSONPlaceholder fake API
        axios
            .get("https://jsonplaceholder.typicode.com/posts")
            .then((response) => {
                const data = response.data.map((item) => ({
                    title: item.title,
                    id: item.id,
                }));
                setOptions(data);

                // If an "id" parameter is present in the URL, preselect the corresponding item
                if (id) {
                    const selected = data.find((item) => item.id === Number(id));
                    if (selected) {
                        setSelectedItem(selected);
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [id]);

    useEffect(() => {
        // Fetch and store the full data when an option is selected
        if (selectedItem) {
            axios
                .get(`https://jsonplaceholder.typicode.com/posts/${selectedItem.id}`)
                .then((response) => {
                    setFormData({
                        id: response.data.id,
                        title: response.data.title,
                        body: response.data.body,
                    });
                })
                .catch((error) => {
                    console.error("Error fetching selected data:", error);
                });
        }
    }, [selectedItem, history]);

    const handleTitleChange = (event) => {
        setFormData({ ...formData, title: event.target.value });
    };

    const handleBodyChange = (event) => {
        setFormData({ ...formData, body: event.target.value });
    };

    // @@@@@@@@@@@@@@@@@@@fa-border
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [enquiryData, setEnquiryData] = useState({

        tractor_table: {
            tractor_id: "",
        },
        crm_call_status: {
            id: ""
        },

        date: "",
        is_dublicate: "",
        enquiry_type: "",
        exchanged: "",
        enquiry_source: "",
        exchanged_tractor_id: "",
        enq_details: {
            remark: "",
            down_payment: "",
            loan_type: "",
            reason: "",
            month_year: ""
        },
        executive: {
            admin_id: "",
        },

        // @@@@@@@@@@ to add extra feild 
        user_id: "",
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        state_id: "",
        city_id: "",
        taluka_id: "",
        user_type: "",
        status: "",
        user_image: "",
        otp: "",
        otp_guru: "",
        blocked_at: "",
        is_onboard: "",
        created_at: "",
        updated_at: "",
        follower_status: "",
        user_detail: {
            user_level: {
                user_level_id: ""
            },
            education_id: "",
            crop_details: [
                {
                    crop_id: "",
                    to_days: "",
                    crop_icon: "",
                    crop_name: "",
                    from_days: "",
                    scientific_name: ""
                },

            ],
            land: "",
            tractor_owner: "",
            is_owner: "",
            is_smartphone: "",
            biography: "",
            awards: "",
            plan: "",
            whatsapp: "",
            telegram: "",
            alternative_contact_number: "",
            village: "",
            pincode: "",
            dob: "",
            gender: "",
            purpose: ""
        },
        state: {
            state_id: "",
            state_name: "",
            short_name: ""
        },
        city: {
            city_id: "",
            city_name: " "
        },
        taluka: ""
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                        userLevel: `${userLevel}`,
                        adminId: `${adminId}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.get(
                    // `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    // `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`,
                    `${API_BASE_URL}/api/admin/v2/show-user/${id}`,
                    config,
                );

                setEnquiryData(response.data.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setError("An error occurred while fetching data.");
                // history.push('/app/bulkUpload'); 
                // history.push(`/AddNewEnquiry/${id}/bulk`);

            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, token]);


    // const sourceRef = useRef(null);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             setIsLoading(true);

    //             const config = {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Accept: "application/json",
    //                     Authorization: `${token}`,
    //                 },
    //                 timeout: Infinity,
    //                 cancelToken: new axios.CancelToken((cancel) => {
    //                     sourceRef.current = cancel;
    //                 }),
    //             };

    //             const response = await axios.post(
    //                 `${API_BASE_URL}/api/admin/v2/view-followup-history/${id}/bulk`,
    //                 config,
    //             );

    //             setEnquiryData(response.data.data);
    //             setError(null); // Clear error if successful
    //         } catch (error) {
    //             if (axios.isCancel(error)) {
    //                 console.log('Request canceled:', error.message);
    //             } else {
    //                 console.error("Error fetching data:", error);
    //                 setError("An error occurred while fetching data.");
    //             }
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     fetchData();

    //     return () => {
    //         // Cancel the axios request if the component unmounts or the dependencies change
    //         if (sourceRef.current) {
    //             sourceRef.current("Request canceled by cleanup.");
    //         }
    //     };
    // }, [id, token]);


    if (isLoading) {
        return (
            <div>
                <LinearProgress />
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={3}>
                        <Skeleton animation="wave" variant="rounded" height={300} />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <Skeleton animation="wave" variant="rounded" height={600} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                </Grid>
            </div>
        );
    }

    if (error) {
        return <p style={{ color: "red" }}>{error}</p>;
    }

    const handleInputChangenam = (e) => {
        const { name, value } = e.target;

        // Handle nested state
        setEnquiryData((prevData) => ({
            ...prevData,
            user: {
                ...prevData.user,
                [name]: value,
            },
        }));
    };

    // const handleInputChangecomman = (e) => {
    // const { name, value } = e.target;

    // // Split the name into nested property parts
    // const propertyNames = name.split(".");

    // // Use the property names to traverse the nested structure and update the state
    // setEnquiryData((prevData) => {
    // let updatedData = { ...prevData };

    // // Traverse the nested structure
    // let currentLevel = updatedData;
    // for (let i = 0; i < propertyNames.length; i++) {
    // const propertyName = propertyNames[i];

    // // Check if it's the last part of the property name
    // if (i === propertyNames.length - 1) {
    // // Update the value at the last level
    // currentLevel[propertyName] = value;
    // } else {
    // // Create nested objects if they don't exist
    // currentLevel[propertyName] = currentLevel[propertyName] || {};
    // // Move to the next level
    // currentLevel = currentLevel[propertyName];
    // }
    // }

    // return updatedData;
    // });
    // };

    const handleInputChangecomman = (e) => {
        const { name, value } = e.target;

        // Split the name into nested property parts
        const propertyNames = name.split(".");

        // Use the property names to traverse the nested structure and update the state
        setEnquiryData((prevData) => {
            let updatedData = { ...prevData };

            // Traverse the nested structure
            let currentLevel = updatedData;
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];

                // Check if it's the last part of the property name
                if (i === propertyNames.length - 1) {
                    // Update the value at the last level
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = value; // Assuming value is directly updated in array
                    } else {
                        currentLevel[propertyName] = value;
                    }
                } else {
                    // Create nested objects if they don't exist
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = currentLevel[index] || {}; // Create empty object if not exists
                    } else {
                        currentLevel[propertyName] = currentLevel[propertyName] || {};
                    }
                    // Move to the next level
                    currentLevel = currentLevel[propertyName];
                }
            }

            return updatedData;
        });
    };

    // const handleInputChangecommansfsdf = (e) => {
    //     const { name, value } = e.target;

    //     // Update the remark value inside the enq_details array
    //     setEnquiryData((prevData) => {
    //         let updatedData = { ...prevData };

    //         // Extract the index from the name attribute
    //         const index = parseInt(name.split(".")[1]);

    //         // Update the remark value at the specified index
    //         updatedData.enq_details[index].remark = value;

    //         return updatedData;
    //     });
    // };




    const formatDataForSubmission = (data) => {
        return {
            // enq_details: {
            //     remark: enquiryData?.enq_details?.[0]?.remark || "",


            //     down_payment: enquiryData?.enq_details?.[0]?.down_payment || "",
            //     loan_type: enquiryData?.enq_details?.[0]?.loan_type || "",
            //     reason: enquiryData?.enq_details?.[0]?.reason || "",
            //     month_year: enquiryData?.enq_details?.[0]?.month_year || "",
            // },

            // tractor_table: {
            //     tractor_id: enquiryData?.tractor_table?.tractor_id || "",
            // },
            // exchanged: enquiryData?.exchanged || "",
            // exchanged_tractor_id: enquiryData?.exchanged_tractor_id || "",
            // enquiry_source: enquiryData?.enquiry_from || "",
            // date: enquiryData?.date || "",

            // is_dublicate: enquiryData?.is_dublicate || "",
            // enquiry_type: enquiryData?.enquiry_name || "",


            // executive: {
            //     admin_id: enquiryData?.executive?.admin_id || "",
            // },
            // crm_call_status: {
            //     id: enquiryData?.crm_call_status?.id || "",
            // },
            // user: {
            //     mobile: enquiryData?.user?.mobile || "",
            //     user_id: enquiryData?.user?.user_id || "",
            //     first_name: enquiryData?.user?.first_name || "",
            //     last_name: enquiryData?.user?.last_name || "",
            //     email: enquiryData.user.email || "",
            //     state: {
            //         state_id: enquiryData?.user?.state?.state_id || "",
            //     },
            //     city: {
            //         city_id: enquiryData?.user?.city?.city_id || "",
            //     },
            //     taluka: {
            //         id: enquiryData?.user?.taluka?.id || "",
            //     },


            //     user_detail: {
            //         pincode: enquiryData?.user?.user_detail?.pincode || "",
            //         user_level: {
            //             user_level_id: enquiryData?.user?.user_detail?.user_level?.user_level_id || "",
            //         },
            //         village: enquiryData?.user?.user_detail?.village || "",
            //         whatsapp: enquiryData?.user?.user_detail?.whatsapp || "",

            //         telegram: enquiryData?.user?.user_detail?.telegram || "",
            //         land: enquiryData?.user?.user_detail?.land || "",

            //         tractor_owner: enquiryData?.user?.user_detail?.tractor_owner || "",
            //         purpose: enquiryData?.user?.user_detail?.purpose || "",

            //         crop_details: [
            //             {
            //                 crop: enquiryData?.user?.user_detail?.crop_details && enquiryData.user.user_detail.crop_details[0]?.crop_id || '',
            //             },

            //         ],
            //     }
            // },

            tractor_table: {
                // tractor_id: 1146
                tractor_id: enquiryData?.tractor_table?.tractor_id || "",
            },
            exchanged: enquiryData?.exchanged || "",
            exchanged_tractor_id: enquiryData?.exchanged_tractor_id || "",
            enquiry_source: enquiryData?.enquiry_source || "",
            // date: "2023-10-21",
            date: enquiryData?.date || "",

            is_dublicate: enquiryData?.is_dublicate || "",

            enquiry_type: enquiryData?.enquiry_type || "",
            // enq_details: {
            //     remark: "",
            //     down_payment: "80000",
            //     loan_type: "loan",
            //     reason: "jdsfjhd",
            //     month_year: "01-2023"
            // },
            enq_details: {
                remark: enquiryData?.enq_details?.remark || "",
                down_payment: enquiryData?.enq_details?.down_payment || "",
                loan_type: enquiryData?.enq_details?.loan_type || "",
                reason: enquiryData?.enq_details?.reason || "",
                month_year: enquiryData?.enq_details?.month_year || "",
            },
            executive: {
                admin_id: enquiryData?.executive?.admin_id || "",

            },
            crm_call_status: {
                id: enquiryData?.crm_call_status?.id || "",
            },
            user: {
                mobile: enquiryData?.mobile || "",
                user_id: enquiryData?.user_id || "",
                first_name: enquiryData?.first_name || "",
                last_name: enquiryData?.last_name || "",
                email: enquiryData?.email || "",
                state: {
                    state_id: enquiryData?.state?.state_id || "",

                },
                city: {
                    city_id: enquiryData?.city?.city_id || "",

                },
                taluka: {
                    id: enquiryData?.taluka?.id || "",

                },
                user_detail: {
                    pincode: enquiryData?.user_detail?.pincode || "",
                    // village: "gaon",
                    village: enquiryData?.user_detail?.village || "",

                    whatsapp: enquiryData?.user_detail?.whatsapp || "",

                    telegram: enquiryData?.user_detail?.telegram || "",
                    land: enquiryData?.user_detail?.land || "",
                    tractor_owner: enquiryData?.user_detail?.tractor_owner || "",
                    purpose: enquiryData?.user_detail?.purpose || "",
                    land_unit: "4",
                    user_level: {
                        user_level_id: enquiryData?.user_detail?.user_level?.user_level_id || "",

                    },
                    crop_details: [
                        {
                            crop: enquiryData?.user_detail?.crop_details && enquiryData.user_detail.crop_details[0]?.crop_id || '',

                        },

                    ]
                }
            }

        };
    };



    const handleSubmit = async (e) => {
        // window.location.reload();
        e.preventDefault();
        setIsLoading(true); // Set loading state to true before making the API call


        const formattedData = formatDataForSubmission(enquiryData);

        try {
            const response = await axios.post(
                // `${API_BASE_URL}/api/admin/v2/update-user-enquiry/enquiry/${enquiryData.id}`,
                `${API_BASE_URL}/api/admin/v2/update-user-enquiry/new-enquiry${enquiryData.id}`,
                // enquiryData,
                formattedData,
            );
            console.log(response.data);
            setMessage(response.data.message);


            // console.log("Enquiry data submitted:", formattedData);
            // console.log("Enquiry data submitted:", enquiryData);
            // setSubmittedDataArray([...submittedDataArray, formattedData]);

        } catch (error) {
            console.error("Error updating data:", error);
            // Handle error
        } finally {
            setIsLoading(false); // Set loading state back to false after the API call completes
        }
    };

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEnquiryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setEnquiryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    // @@@@@@@@

    // const handleStateChange = (e) => {
    // setSelectedState(e.target.value);
    // };

    const handleInputChangejj = (e) => {
        const { name, value } = e.target;
        setEnquiryData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setEnquiryData((prevData) => ({
            ...prevData,
            user: {
                ...prevData.user,
                [name]: value,
            },
        }));
    };

    const handleSelectChangess = (e) => {
        const { name, value } = e.target;
        // Extract the selected executive's username from executiveOptions
        const selectedExecutive = executiveOptions.find(
            (option) => option.id === value,
        );

        setEnquiryData((prevData) => ({
            ...prevData,
            executive: {
                ...prevData.executive,
                admin_id: value,
                username: selectedExecutive ? selectedExecutive.title : "", // Set the username
            },
        }));
    };

    const handleCropChange = (index, e) => {
        const { value } = e.target;
        setEnquiryData((prevData) => ({
            ...prevData,
            user: {
                ...prevData.user,
                crop: prevData.user.crop.map((crop, i) =>
                    i === index ? { ...crop, crop: value } : crop,
                ),
            },
        }));
    };

    return (

        <>

            {message && <p
                className={classes.message}
            >
                <br />
                <br />
                <br />
                <br />
                {message}</p>}

            <SearchUser />

            <div style={{ display: "flex" }}>
                <Link to={`/app/UserEnquiries/${id}`} style={{ paddingTop: "10px" }} className={classes.breadcrumbslinkHightlight}>Users Enquiries  </Link>
                <Link to={`/app/UserPending/${id}`} style={{ paddingLeft: "20px", paddingTop: "10px" }} className={classes.breadcrumbslinkHightlight}>Pending</Link>
                <Link to={`/app/UserInterested/${id}`} style={{ paddingLeft: "20px", paddingTop: "10px" }} className={classes.breadcrumbslinkHightlight}>Interested</Link>
                <Button
                    style={{ backgroundColor: "#dc3545", borderColor: '#dc3545', marginLeft: "20px", }}
                    className={classes.buttonform}
                    variant="outlined" startIcon={<LightModeIcon />}>
                    Add New Enquiry
                </Button>
                <Button
                    style={{ backgroundColor: "#28A745", borderColor: '#28A745', marginLeft: "20px", }}
                    className={classes.buttonform}
                    variant="outlined" startIcon={<PersonIcon />}>
                    <span> {enquiryData.first_name}</span> &nbsp; <span> {enquiryData.last_name}</span>
                </Button>
                <Button
                    style={{ backgroundColor: "#28A745", borderColor: '#28A745', marginLeft: "20px", }}
                    className={classes.buttonform}
                    variant="outlined" startIcon={<PhoneIcon />}>
                    <span> {enquiryData.mobile}</span>
                </Button>
            </div>

            <form onSubmit={handleSubmit}>

                <hr />
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={3}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={6} lg={6}>
                                            <Typography className={classes.tabletittle}>
                                                Customer Profile
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            lg={6}
                                            style={{
                                                margin: "auto",
                                                padding: "auto",
                                                textAlign: "right",
                                            }}
                                        >
                                            {/* <AddUpdateUserEditForm /> */}
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Name :
                                        <span> {enquiryData.first_name}</span> &nbsp; <span> {enquiryData.last_name}</span>


                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        State :{" "}
                                        {enquiryData && enquiryData.state ? (
                                            <span> {enquiryData.state.state_name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        City :{" "}
                                        {enquiryData && enquiryData.city ? (
                                            <span> {enquiryData.city.city_name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Taluka :{" "}
                                        {enquiryData && enquiryData.taluka ? (
                                            <span> {enquiryData.taluka.name}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Pincode :{" "}
                                        {enquiryData.user &&
                                            enquiryData.user.user_detail &&
                                            enquiryData.user.user_detail.pincode ? (
                                            <span> {enquiryData.user.user_detail.pincode}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Mobile No:  <span> {enquiryData.mobile}</span>

                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Email :{" "}
                                        <span> {enquiryData.email}</span>
                                    </Typography>
                                    <Typography className={classes.breadcrumbslinkHightlight}>
                                        Registered on:{" "}
                                        {enquiryData.created_at ? (
                                            <span>
                                                {new Date(enquiryData.created_at).toLocaleDateString('en-IN', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                })}{' '}
                                                {new Date(enquiryData.created_at).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true,
                                                })}
                                            </span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>

                                    {/* <AddTractor /> */}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={8} lg={8}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={4} lg={4}>
                                            <Typography className={classes.tabletittle}>
                                                Adding New Enquiry
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <hr />

                                    <Grid container spacing={1}>
                                        <Grid item xs={6} sm={6} lg={6}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Status
                                            </label>
                                            <br />
                                            <select
                                                required
                                                name="crm_call_status.id"
                                                className={classes.selectform}
                                                id="enqStatus"
                                                value={enquiryData?.crm_call_status?.id || ""}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="" selected="selected">
                                                    Select Status
                                                </option>
                                                <option value="1">Interested</option>
                                                <option value="2">Call Later</option>
                                                <option value="3">Already Purchased</option>
                                                <option value="4">Not Interested</option>
                                                <option value="5">Ringing No Response</option>
                                                <option value="6">Busy</option>
                                                <option value="7">Switched Off</option>
                                                <option value="8">Invalid Number</option>
                                                <option value="9">Language Problem</option>
                                                <option value="10">Other</option>
                                                <option value="11">No Incoming</option>
                                                <option value="12">Old Tractor Enquiry</option>
                                                <option value="13">Supplied</option>

                                            </select>
                                            {enquiryData?.crm_call_status?.id === "1" ? <div style={{ display: "flex" }}>
                                                {/* <input
                                                    placeholder="Day"
                                                    className={classes.inputform}
                                                    type="number"
                                                    style={{ marginRight: "50px" }}

                                                /> */}
                                                <input
                                                    placeholder="Day"
                                                    className={classes.inputform}
                                                    type="number"
                                                    style={{ marginRight: "50px" }}
                                                    onChange={(e) => {
                                                        const day = parseInt(e.target.value);
                                                        if (!isNaN(day) && day > 0) {
                                                            const currentDate = new Date();
                                                            currentDate.setDate(currentDate.getDate() + 1);
                                                            const inputDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + day);
                                                            const formattedDate = inputDate.toISOString().split('T')[0];
                                                            handleInputChangecomman({ target: { name: "date", value: formattedDate } });
                                                        }
                                                    }}
                                                />


                                                <input
                                                    required={enquiryData?.crm_call_status?.id === "1" || ""}

                                                    placeholder="Enter Date"
                                                    className={classes.inputform}
                                                    type="date"
                                                    name="date"
                                                    value={enquiryData?.date || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}

                                            {enquiryData?.crm_call_status?.id === "2" ? <div>
                                                <input
                                                    required={enquiryData?.crm_call_status?.id === "2" || ""}

                                                    placeholder="Enter Date"
                                                    className={classes.inputform}
                                                    type="date"
                                                    name="date"
                                                    value={enquiryData?.date || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}
                                            {enquiryData?.crm_call_status?.id === "3" ? <div>
                                                <select
                                                    required={enquiryData?.crm_call_status?.id === "3" || ""}

                                                    id="tractorSelect"
                                                    name="exchanged_tractor_id"
                                                    value={enquiryData.exchanged_tractor_id}
                                                    className={classes.selectform}
                                                    onChange={handleInputChangecomman}
                                                >
                                                    <option >
                                                        Select Tractor
                                                    </option>
                                                    {tractorOptions.map((tractor) => (
                                                        <option key={tractor.id} value={tractor.id}>
                                                            {tractor.name}
                                                        </option>
                                                    ))}
                                                </select>

                                                <label for="birthday" className={classes.labelform}>
                                                    Select Month Year
                                                </label>
                                                <br />
                                                <input
                                                    required={enquiryData?.crm_call_status?.id === "3" || ""}

                                                    placeholder="-- ----"
                                                    className={classes.inputform}
                                                    type="month"
                                                    name="enq_details.month_year"
                                                    value={enquiryData?.enq_details?.month_year || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}
                                            {enquiryData?.crm_call_status?.id === "4" ? <div>
                                                <input
                                                    placeholder="mention the reason"
                                                    className={classes.inputform}
                                                    type="text"
                                                    name="enq_details.reason"
                                                    value={enquiryData?.enq_details?.reason || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}
                                            {enquiryData?.crm_call_status?.id === "10" ? <div>
                                                <input
                                                    placeholder="mention the reason"
                                                    className={classes.inputform}
                                                    type="text"
                                                    name="enq_details.reason"
                                                    value={enquiryData?.enq_details?.reason || ""}
                                                    onChange={handleInputChangecomman}
                                                />
                                            </div> : null}
                                        </Grid>
                                        <Grid item xs={6} sm={6} lg={6}>
                                            <label for="birthday" className={classes.labelform}>
                                                Executive
                                            </label>
                                            <br />

                                            <select
                                                name="executive.admin_id"
                                                value={enquiryData?.executive?.admin_id || ""}
                                                className={classes.selectform}
                                                onChange={handleInputChangecomman}
                                                required
                                            >
                                                <option value="">Select Executive</option>
                                                {executiveOptions.map((option) => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.title}
                                                    </option>
                                                ))}
                                            </select>

                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry Source
                                            </label>
                                            <br />
                                            <select
                                                required
                                                name="enquiry_source"
                                                value={enquiryData.enquiry_source}
                                                onChange={handleInputChangecomman}
                                                className={classes.selectform}
                                            >
                                                <option value="">Select Source </option>
                                                <option value="fbc">FaceBook Campaign</option>
                                                <option value="app">App</option>
                                                <option value="web">Website</option>
                                                <option value="inbond_call">Inbond Call</option>
                                                <option value="smc">Social Media Comments</option>
                                                <option value="wa">WhatsApp</option>
                                                <option value="tg">Telegram</option>
                                                <option value="ref">Referral</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry For
                                            </label>
                                            <br />
                                            <select
                                                id="cars"
                                                name="carlist"
                                                form="carform"
                                                className={classes.selectform}
                                            >
                                                <option value="tractor" selected="">
                                                    tractor
                                                </option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Model
                                            </label>
                                            <br />

                                            <Autocomplete

                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}

                                                id="tractorSelect"
                                                options={tractorOptions}
                                                value={
                                                    tractorOptions.find(
                                                        (option) =>
                                                            option.id ===
                                                            enquiryData?.tractor_table?.tractor_id || "",
                                                    ) || null
                                                }

                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {
                                                    handleInputChangecomman({
                                                        target: {
                                                            name: "tractor_table.tractor_id",
                                                            value: newValue ? newValue.id : null,
                                                        },
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        required
                                                        {...params}
                                                        className={classes.selectform}

                                                    />
                                                )}
                                            />


                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Occupation
                                            </label>
                                            <br />
                                            <select
                                                name="user_detail.user_level.user_level_id"
                                                value={enquiryData?.user_detail?.user_level?.user_level_id || ""}
                                                onChange={handleInputChangecomman}
                                                className={classes.selectform}
                                            >
                                                <option value="" selected="">
                                                    Select Occupation
                                                </option>
                                                {userLevels?.map((userLevel) => (
                                                    <option key={userLevel.user_level_id} value={userLevel.user_level_id}>
                                                        {userLevel.user_level_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Enquiry Type
                                            </label>
                                            <br />

                                            <select

                                                className={classes.selectform}
                                                required
                                                name="enquiry_type"
                                                value={enquiryData.enquiry_type}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="">Select Enquiry Type</option>
                                                <option value="Price">Price</option>
                                                <option value="Demo">Demo</option>
                                                <option value="Booking">Booking</option>
                                            </select>

                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}></Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label className={classes.labelform}>First Name</label>
                                            <br />
                                            <input
                                                placeholder="Enter First Name"
                                                className={classes.inputform}
                                                type="text"
                                                name="first_name"
                                                value={enquiryData?.first_name || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label className={classes.labelform}>Last Name</label>
                                            <br />
                                            <input
                                                placeholder="Enter Last Name"
                                                className={classes.inputform}
                                                type="text"
                                                name="last_name"
                                                value={enquiryData?.last_name || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label className={classes.labelform}>Email</label>
                                            <br />
                                            <input
                                                placeholder="Enter Email"
                                                className={classes.inputform}
                                                type="email"
                                                name="email"
                                                value={enquiryData?.email || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select State
                                            </label>
                                            <br />

                                            {/* <select
                                                required
                                                className={classes.selectform}
                                                name="state.state_id"
                                                value={enquiryData?.state?.state_id || ""}
                                                onChange={(e) => {
                                                    handleInputChangecomman(e);
                                                    handleStateChange(e);
                                                }}
                                            >
                                                <option value="">Select a state</option>
                                                {states.map((state) => (
                                                    <option key={state.id} value={state.id}>
                                                        {state.value}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <Autocomplete
                                                required
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                name="state.state_id"
                                                // value={states.find(state => state.id === enquiryData?.state?.state_id) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        const syntheticEvent = {
                                                            target: {
                                                                name: 'state.state_id',
                                                                value: newValue.id
                                                            }
                                                        };
                                                        handleInputChangecomman(syntheticEvent);
                                                        handleStateChange(syntheticEvent);
                                                    }
                                                }}
                                                options={states}
                                                getOptionLabel={(state) => state.value}
                                                renderInput={(params) => <TextField
                                                    required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                    placeholder="Select State" {...params} />}
                                            />




                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select District
                                            </label>
                                            <br />

                                            {/* <select

                                                className={classes.selectform}
                                                name="city.city_id"
                                                value={enquiryData?.city?.city_id || ""}
                                                onChange={(e) => {
                                                    handleInputChangecomman(e);
                                                    handleCityChange(e);
                                                }}
                                            // onChange={handleInputChangecomman}
                                            >
                                                <option value="">Select a city</option>
                                                {cities.map((city) => (
                                                    <option key={city.id} value={city.id}>
                                                        {city.value}
                                                    </option>
                                                ))}
                                            </select> */}

                                            <Autocomplete
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                name="city.city_id"
                                                // value={cities.find(city => city.id === enquiryData?.user?.city?.city_id) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        const syntheticEvent = {
                                                            target: {
                                                                name: 'city.city_id',
                                                                value: newValue.id
                                                            }
                                                        };
                                                        handleInputChangecomman(syntheticEvent);
                                                        handleCityChange(syntheticEvent);
                                                    }
                                                }}
                                                options={cities}
                                                getOptionLabel={(city) => city.value}
                                                renderInput={(params) => <TextField
                                                    required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}


                                                    placeholder="Select City" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Taluka
                                            </label>
                                            <br />

                                            {/* <select

                                                className={classes.selectform}
                                                name="taluka.id"
                                                value={enquiryData?.taluka?.id || ""}
                                                onChange={(e) => {
                                                    handleInputChangecomman(e);
                                                    handleTalukaChange(e);
                                                }}
                                            >
                                                <option value="">Select taluka</option>
                                                {talukas.map((taluka) => (
                                                    <option key={taluka.id} value={taluka.id}>
                                                        {taluka.value}
                                                    </option>
                                                ))}
                                            </select> */}
                                            <Autocomplete
                                                classes={{
                                                    option: classes.autocompleteoption,
                                                }}
                                                name="taluka.id"
                                                // value={talukas.find(taluka => taluka.id === enquiryData?.user?.taluka?.id) || null}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        const syntheticEvent = {
                                                            target: {
                                                                name: 'taluka.id',
                                                                value: newValue.id
                                                            }
                                                        };
                                                        handleInputChangecomman(syntheticEvent);
                                                        handleTalukaChange(syntheticEvent);
                                                    }
                                                }}
                                                options={talukas}
                                                getOptionLabel={(taluka) => taluka.value}
                                                renderInput={(params) => <TextField
                                                    required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}
                                                    placeholder="Select Taluka" {...params} />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Village
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Select Village"
                                                className={classes.inputform}
                                                type="text"
                                                name="user_detail.village"
                                                // value={enquiryData?.user?.user_detail?.village || ''}
                                                value={enquiryData?.user_detail?.village || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Pincode
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Select Pincode"
                                                className={classes.inputform}
                                                type="number"
                                                name="user_detail.pincode"
                                                value={enquiryData?.user_detail?.pincode || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={4}></Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Enter Your Farm Land
                                            </label>
                                            <br />
                                            <input

                                                placeholder="Enter Your Farm Land"
                                                className={classes.inputform}
                                                type="text"
                                                name="user_detail.land"
                                                value={enquiryData?.user_detail?.land || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Farm Unit
                                            </label>
                                            <br />
                                            <select
                                                id="cars"
                                                name="carlist"
                                                form="carform"
                                                className={classes.selectform}
                                            >
                                                <option value="Bigha">Bigha</option>
                                                <option value="Acre">Acre</option>
                                                <option value="Hectare">Hectare</option>
                                                <option value="Guntha">Guntha</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label className={classes.labelform}>
                                                Select Tractor Owner
                                            </label>
                                            <br />
                                            <select
                                                name="user_detail.is_owner"
                                                value={enquiryData?.user_detail?.is_owner || ""}
                                                className={classes.selectform}
                                                onChange={handleInputChangecomman}

                                            >
                                                <option value="">Select Tractor Owner</option>
                                                <option value="0" selected="">
                                                    FBT (First Time Buyer)
                                                </option>
                                                <option value="1">Tractor Owner</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={3} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Crops (Multiple Select)
                                            </label>
                                            <br />
                                            <select
                                                className={classes.selectform}
                                                name="user_detail.crop_details.0.crop_name"
                                                // // name="user.user_details.crop_details.crop_id"
                                                // value={enquiryData?.user?.user_detail?.crop_details[0]?.crop_name || ''}
                                                value={(enquiryData?.user_detail?.crop_details && enquiryData.user_detail.crop_details[0]?.crop_name) || ''}

                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="">Select a crop</option>
                                                {crops?.map((crop) => (
                                                    <option key={crop.id} value={crop.value}>
                                                        {crop.value}
                                                    </option>
                                                ))}
                                            </select>
                                            {/* <Autocomplete
                                                multiple
                                                id="crops-autocomplete"
                                                options={crops}
                                                getOptionLabel={(crop) => crop.value}
                                                value={selectedCrops}
                                                value={selectedCrops}
                                                onChange={handleInputChangecomman}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Select crops"
                                                        placeholder="Select one or more crops"
                                                    />
                                                )}
                                            /> */}


                                        </Grid>

                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Loan
                                            </label>
                                            <br />
                                            <select
                                                required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}
                                                className={classes.selectform}
                                                name="enq_details.loan_type"
                                                value={enquiryData?.enq_details?.loan_type || ""}
                                                // value={(enquiryData?.enq_details && enquiryData.enq_details[0]?.loan_type) || ""}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option value="" selected="">
                                                    Select Loan
                                                </option>
                                                <option value="loan">Loan</option>
                                                <option value="cash">Cash</option>
                                                <option value="not confirm">Not Confirm</option>
                                                <option value="after price">After Price</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Enter Downpayment
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Enter Downpayment"
                                                className={classes.inputform}
                                                type="number"
                                                name="enq_details.down_payment"
                                                value={enquiryData?.enq_details?.down_payment || ""}
                                                onChange={handleInputChangecomman}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2} lg={2} md={2}>
                                            <label for="birthday" className={classes.labelform}>
                                                Exchange ?
                                            </label>
                                            <br />
                                            <select
                                                required={enquiryData?.crm_call_status?.id === "1" || enquiryData?.crm_call_status?.id === "3" || ""}

                                                name="exchanged"
                                                className={classes.selectform}
                                                value={enquiryData.exchanged}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option>Select</option>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </Grid>

                                        <Grid item xs={3} sm={5} lg={5} md={5}>
                                            <label for="birthday" className={classes.labelform}>
                                                WhatsApp Number
                                            </label>
                                            <br />
                                            <input
                                                name="user_detail.whatsapp"
                                                value={enquiryData?.user_detail?.whatsapp || ""}
                                                onChange={handleInputChangecomman}
                                                placeholder="WhatsApp Number"
                                                className={classes.inputform}
                                                type="number"
                                            />
                                            <input
                                                type="checkbox"
                                                id="vehicle1"
                                                name="vehicle1"
                                                value="Bike"
                                            ></input>
                                            <label for="birthday" className={classes.labelform}>
                                                Same as Mobile Number
                                            </label>
                                        </Grid>
                                        <Grid item xs={3} sm={5} lg={5} md={5}>
                                            <label for="birthday" className={classes.labelform}>
                                                Telegram Number
                                            </label>
                                            <br />
                                            <input
                                                placeholder="Telegram Number"
                                                name="user_detail.telegram"
                                                value={enquiryData?.user_detail?.telegram || ""}
                                                onChange={handleInputChangecomman}
                                                className={classes.inputform}
                                                type="number"
                                            />
                                            <input
                                                type="checkbox"
                                                id="vehicle1"
                                                name="vehicle1"
                                                value="Bike"
                                            ></input>
                                            <label for="birthday" className={classes.labelform}>
                                                Same as Whatsapp Number
                                            </label>
                                        </Grid>

                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Select Purpose
                                            </label>
                                            <br />
                                            <select
                                                name="user_detail.purpose"
                                                value={enquiryData?.user_detail?.purpose || ""}
                                                onChange={handleInputChangecomman}

                                                className={classes.selectform}
                                            >
                                                <option value="">Select Purpose</option>
                                                <option value="agri - personal">Agri - Personal</option>
                                                <option value="agri - commercial">
                                                    Agri - Commercial
                                                </option>
                                                <option value="commercial">Commercial</option>
                                            </select>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={4}>
                                            <label for="birthday" className={classes.labelform}>
                                                Exchange To
                                            </label>
                                            <br />

                                            <select
                                                id="tractorSelect"
                                                name="exchanged_tractor_id"
                                                value={enquiryData.exchanged_tractor_id}
                                                className={classes.selectform}
                                                onChange={handleInputChangecomman}
                                            >
                                                <option >
                                                    Select Tractor
                                                </option>
                                                {tractorOptions.map((tractor) => (
                                                    <option key={tractor.id} value={tractor.id}>
                                                        {tractor.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <label for="birthday" className={classes.labelform}>
                                                Enter Remark
                                            </label>
                                            <br />
                                            <textarea
                                                type="text"
                                                name="enq_details.remark"
                                                value={enquiryData?.enq_details?.remark || ""}
                                                onChange={handleInputChangecomman}
                                                placeholder="Enter Your Land Space"
                                                className={classes.inputform}
                                                style={{ height: "50px" }}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={12} lg={12}>
                                            <hr />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            lg={12}
                                            style={{
                                                margin: "auto",
                                                padding: "auto",
                                                textAlign: "center",
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                style={{
                                                    backgroundColor: "#dc3545",
                                                    borderColor: "#dc3545",
                                                }}
                                                className={classes.buttonform}
                                                variant="outlined"
                                                startIcon={<LightModeIcon />}
                                            >
                                                Submit
                                            </Button>
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }} >
                                            <Button
                                                type="submit"
                                                // style={{ backgroundColor: "#28A745", borderColor: '#28A745',}}
                                                className={classes.buttonform}
                                                variant="outlined" startIcon={<PersonIcon />}>
                                                Submit
                                            </Button>
                                            <Button
                                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545', marginLeft: "20px", }}
                                                className={classes.buttonform}
                                                variant="outlined" startIcon={<LightModeIcon />}>
                                                Discard
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* <Grid item xs={12} lg={3}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={6} lg={6}>
                                            <Typography className={classes.tabletittle}>
                                                FollowUp History
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <hr />

                                    <Card variant="outlined" style={{ padding: "10px" }}>
                                        <Typography className={classes.breadcrumbslinkHightlight}>
                                            <DateRangeIcon
                                                style={{ marginTop: "-10px", paddingTop: "8px" }}
                                            />{" "}
                                            {enquiryData.created_at ? (
                                                <span>
                                                    {new Date(enquiryData.created_at).toLocaleDateString(
                                                        "en-IN",
                                                        {
                                                            day: "numeric",
                                                            month: "short",
                                                            year: "numeric",
                                                        },
                                                    )}{" "}
                                                    {new Date(enquiryData.created_at).toLocaleTimeString(
                                                        "en-US",
                                                        {
                                                            hour: "2-digit",
                                                            minute: "2-digit",
                                                            second: "2-digit",
                                                            hour12: true,
                                                        },
                                                    )}
                                                </span>
                                            ) : (
                                                <span> </span>
                                            )}
                                        </Typography>
                                        <hr />
                                        <p>
                                            Status:{" "}
                                            {enquiryData.crm_call_status &&
                                                enquiryData.crm_call_status &&
                                                enquiryData.crm_call_status.call_status ? (
                                                <span> {enquiryData.crm_call_status.call_status} </span>
                                            ) : (
                                                <span> </span>
                                            )}
                                        </p>
                                        <Typography className={classes.breadcrumbslinkHightlight}>
                                            Date:{" "}
                                            {enquiryData.date ? (
                                                <span>
                                                    {new Date(enquiryData.date).toLocaleDateString(
                                                        "en-IN",
                                                        {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        },
                                                    )}
                                                </span>
                                            ) : (
                                                <span> </span>
                                            )}
                                        </Typography>
                                        <Typography className={classes.breadcrumbslinkHightlight}>
                                            Executive:{" "}
                                            {enquiryData.executive &&
                                                enquiryData.executive.username ? (
                                                <span> {enquiryData.executive.username} </span>
                                            ) : (
                                                <span> </span>
                                            )}
                                        </Typography>
                                    </Card>

                                    <br />
                                </CardContent>
                            </Card>
                        </Grid> */}
                    </Grid>
                </div>



            </form>
        </>
    );
};

export default AutocompleteDemo;



