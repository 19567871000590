import { createMuiTheme } from '@material-ui/core';

// Describe material ui theme
export const customTheme = () => {
    return createMuiTheme({

        overrides: {

            MuiTableCell: {
                root: {
                    padding: "1px",
                    fontWeight: "300",
                    fontFamily: "sans-serif",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    opacity: 0.9,
                    borderRight: '1px solid #e0e0e0',
                    position: 'relative',
                    // backgroundColor: "red"
                    whiteSpace: 'nowrap', // Add this line to prevent text from breaking
                    overflow: 'hidden', // Add this line to hide content that overflows
                    textOverflow: 'clip', // Note: 'clip' may not be a valid value for textOverflow, use 'ellipsis' instead

                },
                head: {
                    // backgroundColor: "#2980b9 !important",

                    // borderRight: '1px solid #2980b9 !important',
                    // padding:"1px !important"

                },
                // body: {
                //     backgroundColor: "#2980b9 !important",
                // },
            },



            MUIDataTableHeadCell: {
                root: {
                    fontWeight: "700",
                    fontFamily: "sans-serif",
                    padding: "10px",
                    fontSize: "14px",

                    // color: "#ecf0f1"
                    // opacity: 0.8,

                },
                toolButton: {
                    justifyContent: 'center',

                },

            },

            MuiTableHead: {
                root: {
                    // backgroundColor: "red",
                    // opacity: '1 !important',
                    // backgroundColor: "#8593ad !important",
                    // backgroundColor: "red !important",

                },
            },
            MuiTableRow: {
                root: {
                    '&:nth-child(even)': {
                        // backgroundColor: '#f2f2f2',
                        backgroundColor: 'rgba(0, 0, 0, .05)',
                    },
                },
                // root: {
                //     '&:hover': {
                //         backgroundColor: '#faf0e6 !important',
                //         opacity: "0.9"
                //     },
                // },
            },




            MUIDataTableHeadRow: {
                root: {
                    boxShadow: "0px -2px 0px #fff",
                    borderBottom: "2px solid #e0e0e0", // Add your desired border style

                    position: "sticky",
                    top: 0,
                    zIndex: 100,
                    background: "#8593ad !important",
                    // background: "red",
                },

            },

            MuiToolbar: {
                regular: {
                    minHeight: '12px !important',
                },
            },

            MUIDataTable: {
                responsiveBase: {
                    maxHeight: '550px !important',
                    overflow: "auto",
                },
            },

            // MUIDataTable: {
            //     responsiveBase: {
            //       maxHeight: '550px !important',
            //       overflow: 'auto',
            //     },
            //     '@media (min-width: 1280px)': {
            //       responsiveBase: {
            //         maxHeight: '750px !important', // Adjust the maxHeight for lg screens
            //       },
            //     },
            //     '@media (min-width: 1920px)': {
            //       responsiveBase: {
            //         maxHeight: '950px !important', // Adjust the maxHeight for xl screens
            //       },
            //     },
            //   },






            // @@@@@@@@@@@@@@@@ filter form style start 

            // MuiMenuItem: {
            //     root: {
            //         width: 'auto',
            //         overflow: 'hidden',
            //         fontSize: '12px',
            //         boxSizing: 'border-box',
            //         minHeight: '48px',
            //         fontFamily: "sans-serif",
            //         fontWeight: 300,
            //         lineHeight: 1.5,
            //         paddingTop: '3px',
            //         whiteSpace: 'nowrap',
            //         letterSpacing: '0.00938em',
            //         paddingBottom: '3px',
            //         opacity: 0.9,

            //     },
            // },
            // ############

            // MuiInput: {
            //     underline: {
            //         '&:before': {
            //             borderBottom: 'none', // Remove the bottom line
            //         },
            //         '&:after': {
            //             borderBottom: 'none', // Remove the bottom line
            //         },
            //     },
            // },

            MuiInput: {
                input: {
                    height: 'auto',
                    overflow: 'hidden',
                    // backgroundColor: 'red',
                    minHeight: '1.1876em',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    width: '100%',
                    border: '1px solid #e8eaef',
                    borderRadius: '4px',
                    padding: '10px',
                    height: '9px',
                    marginTop: '1px',
                    fontFamily: 'sans-serif',
                    color: '#64728c',
                    fontSize: '12px',
                },
                underline: {
                    '&:before': {
                        borderBottom: 'none', // Remove the bottom line
                    },
                    '&:after': {
                        borderBottom: 'none', // Remove the bottom line
                    },
                    '&:hover': {
                        '&:before': {
                            borderBottom: 'none', // Remove the bottom line on hover
                        },
                        '&:after': {
                            borderBottom: 'none', // Remove the bottom line on hover
                        },
                    },
                },
            },
            // ###########
            // MuiSelect: {
            //     selectMenu: {
            //         height: 'auto',
            //         overflow: 'hidden',
            //         backgroundColor: 'red',
            //         minHeight: '1.1876em',
            //         whiteSpace: 'nowrap',
            //         textOverflow: 'ellipsis',
            //         width: '100%',
            //         border: '1px solid #e8eaef',
            //         borderRadius: '4px',
            //         padding: '10px',
            //         height: '9px',
            //         marginTop: '10px',
            //         fontFamily: 'sans-serif',
            //         color: '#64728c',
            //         fontSize: '12px',

            //     },
            // },

            MuiInputLabel: {
                formControl: {
                    padding: '10px',
                    fontFamily: 'sans-serif',
                    color: '#64728c',
                    fontSize: '12px',
                    fontWeight: 600,
                },
            },

            MUIDataTableFilter: {
                gridListTile: {
                    marginTop: '-25px',
                },
            },


            // @@@@@@@@@@@@@@@@ filter form style End

            MuiPaper: {
                elevation8: {
                    // backgroundColor: "red",
                    // boxShadow: '0px 5px 5px -3px rgba(189, 195, 199,1.0), 0px 2px 10px 1px rgba(189, 195, 199,1.0)',
                    boxShadow: 'none',
                    // boxShadow: "0px 3px 3px 0px rgba(44, 62, 80,1.0)",
                    // boxShadow: "0px 3px 3px 0px rgba(206, 214, 224,1.0)",

                },
            },

        },
    });
};




export const getColorfont = (value) => {
    // Implement your logic here to determine the color based on the value
    // For example:
    if (value === 'Price') {
        return '#090529';
    }
    else if (value === 'Demo') {
        return '#272d33';
    }

    else if (value === 'Booking') {
        return '#300605';
    }

    else if (value === 'fbc') {
        return '#031336';
    }

    else if (value === 'app') {
        return '#3d0803';
    }

    else if (value === 'App') {
        return '#3d0803';
    }
    // @@@@@@@@@ 
    else if (value === 'Sonalika') {
        return '#201c30';
    }
    else if (value === 'Kubota') {
        return '#4d2d12';
    }
    else if (value === 'Force Motors') {
        return '#233642';
    }
    else if (value === "Captain") {
        return '#4d312e';
    }

    else if (value === 'John Deere') {
        return '#2d3d32';
    }

    else if (value === 'Indo Farm') {
        return '#22331f';
    }

    else if (value === 'New Holland') {
        return '#373e52';
    }

    else if (value === 'Preet') {
        return '#3d422c';
    }

    else if (value === 'Same Deutz Fahr') {
        return '#2d3b34';
    }

    else if (value === 'Tafe') {
        return '#3b2827';
    }

    else if (value === 'VST Shakti') {
        return '#422e2e';
    }


    else if (value === 'Mahindra') {
        return '#3d2b2c';
    }

    else if (value === 'Swaraj') {
        return '#2a3842';
    }

    else if (value === 'Eicher Tractors') {
        return '#302124';
    }

    else if (value === 'Massey Ferguson') {
        return '#3b2d30';
    }


    else if (value === 'Escorts') {
        return '#282f3b';
    }

    else if (value === 'ACE') {
        return '#2d3a3b';
    }

    else if (value === 'Trakstar') {
        return '#212329';
    }

    else if (value === 'Solis') {
        return '#3b2b2b';
    }

    else if (value === 'Digitrac') {
        return '#1d2129';
    }

    else if (value === 'Standard Tractors') {
        return '#191921';
    }

    else if (value === 'Polaris') {
        return '#04121f';
    }

    else if (value === 'Powertrac') {
        return '#444f59';
    }

    else if (value === 'Farmtrac') {
        return '#32303d';
    }

    else if (value === 'JCB') {
        return '#4d4007';
    }

    else if (value === 'web') {
        return '#070229';
    }


    else {
        return '#03111f'; // Default color if value doesn't match any condition
    }
};



export const getColor = (value) => {
    // Implement your logic here to determine the color based on the value
    // For example:
    if (value === 'Price') {
        return '#a69ee8';
    } else if (value === 'Demo') {
        return '#b9c0c7';
    }
    else if (value === 'Booking') {
        return '#f0bcbb';
    }
    else if (value === 'fbc') {
        return '#cbd8f5';
    }
    else if (value === 'app') {
        return '#f7c6c1';
    }
    else if (value === 'App') {
        return '#f7c6c1';
    }
    else if (value === 'web') {
        return '#9e99bf';
    }
    // @@@@@@@@@@@ brand start

    else if (value === 'Kubota') {
        return '#f0d0b4';
    }
    else if (value === 'Sonalika') {
        // return '#341f97';
        return '#8d82bd';
    }
    else if (value === 'Force Motors') {
        return '#9fcfed';
    }
    else if (value === "Captain") {
        return '#e6aba5';
    }
    else if (value === 'John Deere') {
        return '#b3e6c4';
    }
    else if (value === 'Indo Farm') {
        return '#bef2b6';
    }
    else if (value === 'New Holland') {
        return '#b5c3e8';
    }
    else if (value === 'Preet') {
        return '#dce8b5';
    }
    else if (value === 'Same Deutz Fahr') {
        return '#b1e3ca';
    }
    else if (value === 'Tafe') {
        return '#e3adaa';
    }
    else if (value === 'VST Shakti') {
        return '#e6b8b8';
    }
    else if (value === 'Mahindra') {
        return '#f2ced0';
    }
    else if (value === 'Swaraj') {
        return '#b5d3e8';
    }
    else if (value === 'Eicher Tractors') {
        return '#f08196';
    }
    else if (value === 'Massey Ferguson') {
        return '#f2c7d0';
    }
    else if (value === 'Escorts') {
        return '#b3c6e6';
    }
    else if (value === 'ACE') {
        return '#b6ecf0';
    }
    else if (value === 'Trakstar') {
        return '#a8baf0';
    }
    else if (value === 'Solis') {
        return '#f79c9c';
    }
    else if (value === 'Digitrac') {
        return '#cedaf2';
    }
    else if (value === 'Standard Tractors') {
        return '#d3d3f0';
    }
    else if (value === 'Polaris') {
        return '#1e90ff';
    }
    else if (value === 'Powertrac') {
        return '#c4dcf2';
    }
    else if (value === 'Farmtrac') {
        return '#ccc5f0';
    }
    else if (value === 'JCB') {
        return '#f7e8ab';
    }
    // @@@@@@@@@@@ brand end


    else {
        return '#dce7f2'; // Default color if value doesn't match any condition
    }
};


// const jsonData = [
//     {
//         "brand": "Price",
//         "color": "#FF5733"
//     },
//     {
//         "brand": "Sonalika",
//         "color": "#33FF57"
//     },
//     {
//         "brand": "Kubota",
//         "color": "#337AFF"
//     },
//     {
//         "brand": "JCB",
//         "color": "#F933FF"
//     }
// ];

// const getColor = (brandName) => {
//     const foundBrand = jsonData.find(item => item.brand === brandName);
//     return foundBrand ? foundBrand.color : ''; // Return the color if brand is found, otherwise empty string
// };