import React, { Component, useState, Fragment, useEffect, useMemo, useRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import LightModeIcon from '@mui/icons-material/LightMode';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, InputLabel, Drawer, Menu, Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { customTheme, getColorfont, getColor } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import ApprovalIcon from '@mui/icons-material/Approval';
import FilterListIcon from '@mui/icons-material/FilterAlt';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddUpdateUser from "../AddUpdateUser/AddUpdateUser";
import BulkUpload from "../BulkUpload/BulkUpload";
import AccordionSummary from '@mui/material/AccordionSummary';
import SettingsIcon from '@mui/icons-material/Settings';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Popover from '@mui/material/Popover';

import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';

import AddBulkUploadform from "./AddBulkUploadform";

import { API_BASE_URL, token, userLevel, adminId } from '../../../../constants';



const QuotationBookReport = () => {
    const history = useHistory();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() - 1);
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);

    const [fromDate, setFromDate] = useState(sevenDaysAgo.toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(currentDate.toISOString().split('T')[0]);

    const [productBrand, setProductBrand] = useState([]);
    const [enquirySource, setEnquirySource] = useState([]);

    // const [selectedExecutive, setSelectedExecutive] = useState('');

    const [selectedExecutive, setSelectedExecutive] = useState(null);

    const [executiveOptions, setExecutiveOptions] = useState([]);

    const [selectedState, setSelectedState] = useState();
    const [stateOptions, setStateOptions] = useState([]);


    const [selectedBrand, setSelectedBrand] = useState();
    const [brandOptions, setBrandOptions] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [excutiveassign, setExcutiveAssign] = useState('');

    const [selectedUser, setSelectedUser] = useState(null);
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [updatedData, setUpdatedData] = useState({
        status: '',
        // email: '',
        // Add other fields you want to update
    });

    // @@@@@@@@@@@@@@@@@@@@@@@ start 
    const [brands, setBrands] = useState([]);
    const [selectedBrandId, setSelectedBrandId] = useState('');
    const [selectedModelId, setSelectedModelId] = useState('');
    const [tractorModels, setTractorModels] = useState([]);
    const [doubleClickedRow, setDoubleClickedRow] = useState(null);
    const [message, setMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    useEffect(() => {
        // Fetch brand names from the API
        fetch('https://khetigaadi.com/mobile/api/v1/new-tractor/brand/model')
            .then(response => response.json())
            .then(data => {
                setBrands(data.data);
            })
            .catch(error => {
                console.error('Error fetching brands:', error);
            });
    }, []);

    const handleBrandChangeBrandselect = (event) => {
        const brandId = event.target.value;
        setSelectedBrandId(brandId);

        // Fetch tractor models based on the selected brand ID
        const selectedBrand = brands.find(brand => brand.brand_id === parseInt(brandId));
        if (selectedBrand) {
            setTractorModels(selectedBrand.tractor_models);
            setSelectedModelId(''); // Reset selected model when brand changes
        }
    };

    const handleModelChange = (event) => {
        const modelId = event.target.value;
        setSelectedModelId(modelId);
    };

    // const handleBrandChangeBrandselect = (event) => {
    // @@@@@@@@@@@@@@@@@@@@@@@ end


    useEffect(() => {
        const fetchDatabrand = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/brand-list`, config);

                const statelist = response.data.data.map(item => ({
                    id: item.brand_id,
                    title: item.brand_name,
                }));

                setBrandOptions(statelist);

            } catch (error) {
                console.error('Error fetching state:', error);
            }
        };

        fetchDatabrand();
    }, []);




    useEffect(() => {
        const fetchDatastate = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/state-list`, config);
                const statelist = response.data.data.map(item => ({
                    // id: item.state_id,
                    id: item.state_name,
                    title: item.state_name,
                }));

                setStateOptions(statelist);

            } catch (error) {
                console.error('Error fetching state:', error);
            }
        };

        fetchDatastate();
    }, []);

    useEffect(() => {
        const fetchDatalist = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/executive-list`, config);

                const filteredData = response.data.data.filter(item => item.status === 1); // Filter data based on status code

                const mappedData = filteredData.map(item => ({
                    id: item.admin_id,
                    title: item.username,
                }));
                // Include an option for null or empty value
                const optionsWithNull = [
                    // { id: null, title: 'Select Executive' },
                    ...mappedData,
                ];

                setExecutiveOptions(optionsWithNull);
                // setExecutiveOptions(mappedData);

            } catch (error) {
                console.error('Error fetching executives:', error);
            }
        };

        fetchDatalist();
    }, []);




    // const handleExecutiveChange = (event, newValue) => {
    //     setSelectedExecutive(newValue);
    // };


    // const handleExecutiveChange = (event, newValue) => {
    //     if (newValue) {
    //         // If a value is selected
    //         setSelectedExecutive({
    //             id: newValue.id,
    //             title: newValue.title !== null ? newValue.title : null
    //         });
    //     } else {
    //         // If no value is selected (e.g., cleared the selection)
    //         setSelectedExecutive(null);
    //     }
    // };



    const handleExecutiveChange = (event) => {
        const selectedId = event.target.value;
        setSelectedExecutive(selectedId);
    };
    const handleStateChange = (event, newValue) => {
        setSelectedState(newValue);
    };

    const handleBrandChange = (event, newValue) => {
        setSelectedBrand(newValue);
    };

    const fetchData = async (from, to, pageNumber, searchQuery,) => {
        setError(null); // Reset error state
        setIsLoading(true);


        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,
                    'adminId': `${adminId}`,
                    'userLevel': `${userLevel}`,


                },
                timeout: Infinity, // Request will never time out

            };

            const requestData = {
                start_date: fromDate,
                end_date: toDate,
                pagination: perPage,
                page: page + 1,
                search: search,
                product_brand: selectedBrand,
                enquiry_source: enquirySource,
                // states: state,
                states: selectedState,
                // states: [
                //     "Maharashtra"
                // ],
                executive: selectedExecutive,
                user_level: userLevel,
                // admin_id: adminId,

                // executive: "23",
                // user_level: "executive"
                brand_id: selectedBrandId,
                product_model: selectedModelId,
            };
            // Conditionally set executive based on user_level
            if (userLevel === 'executive') {
                requestData.executive = adminId; // Replace selectedExecutive with admin_id
            } else {
                requestData.executive = selectedExecutive;
            }

            const response = await axios.post(`${API_BASE_URL}/api/admin/v2/bulk-enquiry-list`, requestData, config);

            const mappedData = response.data.data.data.map(item => {
                const formattedDate = new Date(item.created_at).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true,

                });

                // const tractorname = item.tractor_table.tractor_model ? item.tractor_table.tractor_model.tractor_model_name : '';

                const brandname = item.brand_obj ? item.brand_obj.brand_name : '';
                const executive = item.executive ? item.executive.username : '';
                const admin_id = item.executive ? item.executive.admin_id : '';
                const tractor_model_name = item.model_obj ? item.model_obj.tractor_model_name : '';



                return {
                    id: item.id,
                    full_name: item.full_name,
                    mobile: item.mobile,
                    state: item.state,
                    city: item.city,
                    pincode: item.pincode,
                    source: item.source,
                    executive: executive,
                    created_at: formattedDate,
                    admin_id: admin_id,


                    // tractorname: tractorname,
                    brandname: brandname,
                    tractor_model_name: tractor_model_name,

                };
            });

            // const sortedData = mappedData.sort((a, b) => b.id - a.id);
            // setData(sortedData);

            setData(mappedData);
            setTotalCount(response.data.data.total);
            // alert(response.data.message); 
            setIsLoading(false);
            // Set the message for Snackbar
            setSnackbarMessage(response.data.message);
            // Open the Snackbar
            setSnackbarOpen(true);

        } catch (error) {
            console.error("Error fetching data:", error);

            if (axios.isCancel(error)) {
                console.error("Request canceled:", error.message);
                // Handle canceled request (if needed)
            } else {
                if (error.code === 'ECONNABORTED') {
                    setError('The request timed out. Please try again.');
                } else if (error.response) {
                    console.error("Server Error Details:", error.response.data);
                    setError(`Error: ${error.response.status} - ${error.response.statusText}`);
                } else {
                    setError('An error occurred while fetching data.');
                }
            }

        } finally {
            setIsLoading(false);
        }
    };
    const source = axios.CancelToken.source();


    // useEffect(() => {
    //     fetchData(fromDate, toDate, perPage, page, search, totalCount, selectedExecutive, productBrand, states, enquirySource);
    //     return () => {
    //         source.cancel('Request canceled by cleanup');
    //     };
    // }, [fromDate, toDate, perPage, page, search, totalCount, selectedExecutive, productBrand, states, enquirySource]);


    // useEffect(() => {
    //     fetchData(perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource);
    //     // Cleanup function
    //     return () => {
    //         source.cancel('Request canceled by cleanup');
    //     };
    // }, [perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource]);



    // useEffect(() => {
    //     fetchData();
    // }, [fromDate, toDate, perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource]);


    const sourceRef = useRef(null);

    useEffect(() => {
        // Create a new cancel token source
        sourceRef.current = axios.CancelToken.source();

        fetchData(
            perPage,
            page,
            search,
            totalCount,
            selectedExecutive,
            selectedBrand,
            selectedState,
            enquirySource,
            sourceRef.current
        );

        // Cleanup function
        return () => {
            // Cancel the request when the component unmounts
            sourceRef.current.cancel('Request canceled by cleanup');
        };
    }, [perPage, page,], 2000); // Empty dependency array




    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
    };

    const handlePrevious = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        // fetchData(fromDate, toDate);
        setSelectedRows([]);


        fetchData(perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource);


    };

    const clearForm = () => {
        window.location.reload();

        setSearch(''); // Reset search state to empty

    };


    const handleSelectAllCheck = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(Array.from({ length: data.length }, (_, i) => i));
        }
    };

    const handleRowSelect = (rowIndex) => {
        if (selectedRows.includes(rowIndex)) {
            setSelectedRows(selectedRows.filter((row) => row !== rowIndex));
        } else {
            setSelectedRows([...selectedRows, rowIndex]);
        }
    };

    const handleRowsSelect = (currentRowsSelected, allRowsSelected) => {
        setSelectedRows(allRowsSelected.map((row) => row.index));
    };

    const handleRowsDelete = (rowsDeleted) => {
        console.log('Rows to be deleted:', rowsDeleted);
        // Implement your logic to delete rows
    };

    const handleSubmits = () => {
        // Construct the form data structure
        // window.location.reload();
        setIsLoading(true); // Set loading state to true

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `${token}`,
                'adminId': `${adminId}`,
                'userLevel': `${userLevel}`,


            },
            timeout: Infinity, // Request will never time out

        };

        const formData = {

            enquiry_id: selectedRows.map((rowIndex) => ({
                enquiry_id: data[rowIndex].id,
            })),


            executive: {
                admin_id: excutiveassign,
            }
        };

        console.log('Form Data:', formData);

        // Make an API request to submit the form data
        // axios.post('/api/submit', formData)
        // axios.post(`${API_BASE_URL}/api/admin/v2/enquiry-assign-executive`, formData)
        axios.post(`${API_BASE_URL}/api/admin/v2/bulk-enquiry-assign-executive`, formData, config)
            .then(response => {
                // Handle the API response as needed
                console.log('API Response:', response.data);
                // history.push('/app/bulkUpload');
                // setMessage('Enquiry Assigned To Executive Successfully');
                // setMessage(response.data.message);
                // setMessage(response.data.data);
                setMessage(response.data.data + " " + response.data.message);

            })
            .catch(error => {
                // Handle API error
                console.error('API Error:', error);

            })
            .finally(() => {
                setIsLoading(false); // Set loading state to false after the request completes
            });


    };

    const handleExecutiveChanges = (e) => {
        setExcutiveAssign(e.target.value);
    };


    const columns = [

        {
            name: 'id',
            label: (
                <input
                    type="checkbox"
                    checked={selectedRows.length === data.length && data.length !== 0}
                    onChange={handleSelectAllCheck}
                />
            ),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => (
                    <input
                        type="checkbox"
                        checked={selectedRows.includes(tableMeta.rowIndex)}
                        onChange={() => handleRowSelect(tableMeta.rowIndex)}
                    />
                ),
            },
        },
        {
            name: 'id', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "S/N",
        },
        {
            name: 'full_name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Full Name",
        },

        {
            name: "mobile",
            label: "Mobile",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "state",
            label: "State",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "city",
            label: "City ",
        },

        {
            name: "pincode",
            label: "Pincode",
        },
        {
            name: "source",
            label: "Source",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.brandstrip}

                        style={{
                            backgroundColor: getColor(value),
                            color: getColorfont(value),
                            width: "100%"
                        }}
                    >
                        {value}
                    </button>
                ),
            },
        },
        {
            name: "brandname",
            label: "Brand",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.brandstrip}

                        style={{
                            backgroundColor: getColor(value),
                            color: getColorfont(value),
                        }}
                    >
                        {value}
                    </button>
                ),
            },

        },
        {
            name: "tractor_model_name",
            label: "Model",
        },
        {
            name: "executive",
            label: "Executive",
        },
        {
            name: "created_at",
            label: "Date",
        },
        // {
        //     name: 'actions',
        //     label: 'Actions',
        //     options: {
        //         filter: false,
        //         customBodyRender: (value, tableMeta) => {
        //             const rowIndex = tableMeta.rowIndex;
        //             const rowData = data[rowIndex];
        //             return (
        //                 <>
        //                     <button onClick={() => handleToggleDropdown(rowData)}>Toggle Dropdown</button>
        //                     {openDropdown && openDropdown === rowData && (
        //                         <div>
        //                             <p>Name: {rowData.name}</p>
        //                             <p>Email: {rowData.email}</p>
        //                             <p>Phone: {rowData.phone}</p>
        //                         </div>
        //                     )}
        //                 </>
        //             );
        //         },
        //     },
        // },

        {
            name: "Update",
            label: "Actions",
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];
                    return (
                        <>

                            {/* <Link style={{ color: "red" }} to={`/app/update/${rowData.id}`}>
                                Update
                            </Link>

                            <Link style={{ color: "red", padding: "5px" }} to={`/app/view/${rowData.id}`}>
                                view
                            </Link>
                            <button onClick={() => handleDelete(rowData.id)}>
                                Delete
                            </button> */}

                            {/* <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center" }} > */}
                            <div style={{ display: "flex" }}>
                                {/* <Link to={`/app/AddNewEnquiry/${rowData.id}`} > */}
                                {/* <Link to={`/app/AddNewEnquiry/${rowData.id}/bulk`} target="_blank"> */}
                                <Link to={`/app/bulkupdate/${rowData.id}/bulk`} target="_blank">
                                    <Button
                                        style={{ width: "20px", fontSize: "10px", marginRight: "5px" }}
                                        className={classes.buttonform}
                                        variant="outlined" startIcon={<EditIcon style={{ fontSize: "12px" }} />}>
                                        Update
                                    </Button>
                                </Link>
                                <Button
                                    style={{ width: "20px", fontSize: "10px", marginRight: "5px" }}
                                    className={classes.buttonform} onClick={(e) => handleUpdateClick(e, tableMeta.rowIndex)}>
                                    Status
                                </Button>

                            </div>


                        </>
                    );
                },
            },
        },


    ]
    const CustomToolbarAction = ({ toggleFilter }) => (
        <IconButton aria-label="Filter list" onClick={toggleFilter}>
            <FilterListIcon onClick={toggleSidebar} />
        </IconButton>
    );


    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    const handleRowClick = (rowIndex) => {
        // Toggle selection of the clicked row
        const isSelected = selectedRows.includes(rowIndex);
        if (isSelected) {
            setSelectedRows(selectedRows.filter((row) => row !== rowIndex));
        } else {
            setSelectedRows([...selectedRows, rowIndex]);
        }
    };

    const options = {
        onRowClick: (rowData, rowMeta) => handleRowClick(rowMeta.dataIndex),
        selectableRows: 'multiple',
        onRowsSelect: handleRowsSelect,
        onRowsDelete: handleRowsDelete,
        // Add more options as needed 
        rowsPerPage: perPage,
        selectableRows: 'none',
        page: page,
        serverSide: true,
        count: totalCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    setPage(0);
                    break;
                default:
                    break;
            }
        },

        customToolbar: () => <CustomToolbarAction />,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 500, 1000, 10000, { value: totalCount, label: 'All' }],
        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <TextField
                    label="Filter Data"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    fullWidth
                />
                <Button onClick={applyNewFilter} variant="contained" color="primary">
                    Apply Filter
                </Button>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Cancel
                </Button>
            </div>
        ),
        setRowProps: (row, dataIndex, rowIndex) => ({
            style: {
                backgroundColor: doubleClickedRow === rowIndex ? '#D2DE32' : selectedRows.includes(dataIndex) ? '#D2DE32' : 'inherit',
            },
            onDoubleClick: () => handleRowDoubleClick(row, rowIndex),
        }),

    };
    const currentTheme = customTheme(true);
    const classes = useStyles();


    // Use useMemo to memoize the mapped data
    const memoizedData = useMemo(() => data, [data]);



    // const handleRowDoubleClick = (rowIndex) => {
    //     setDoubleClickedRow(rowIndex);
    // };


    const handleRowDoubleClick = (row, rowIndex) => {
        console.log("Row double-clicked:", row);
        console.log("Row index:", rowIndex);
        // Add your logic to select the row here
        const updatedSelectedRows = [...selectedRows];
        const rowIndexInSelectedRows = updatedSelectedRows.indexOf(rowIndex);
        if (rowIndexInSelectedRows === -1) {
            updatedSelectedRows.push(rowIndex);
        } else {
            updatedSelectedRows.splice(rowIndexInSelectedRows, 1);
        }
        setSelectedRows(updatedSelectedRows);
    };

    const handleUpdateClick = (event, rowIndex) => {
        setSelectedUser(data[rowIndex]);
        setUpdatedData({
            status: data[rowIndex].status,
            email: data[rowIndex].email,
            // Add other fields you want to update
        });
        setPopoverAnchor(event.currentTarget);
    };

    const handleClosePopover = () => {
        setPopoverAnchor(null);
    };

    // const handleInputChange = (e) => {
    //     setUpdatedData({
    //         ...updatedData,
    //         [e.target.name]: e.target.value,
    //     });
    // };

    const handleInputChange = (e) => {
        setUpdatedData({
            ...updatedData,
            [e.target.name]: e.target.value,
        });

        // Automatically submit the form when the 'status' field changes
        if (e.target.name === 'status') {
            handleFormSubmit();
        }
    };



    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Perform the update logic here (e.g., make an API request)
        try {
            const updatedUserData = { ...selectedUser, ...updatedData };
            // const updatedUserData = { ...data, ...updatedData }; {{BASE_URL}}/api/admin/v2/bulk-status-change/59459
            // Replace the following URL with your actual API endpoint for updating user data
            // await axios.put(`https://your-api-endpoint/${selectedUser.id}`, updatedUserData);
            // await axios.post(`https://khetigaadi.com/api/admin/v2/bulk-status-change/${selectedUser.id}`, updatedUserData);

            // await axios.post(`${API_BASE_URL}/api/admin/v2/bulk-status-change/${selectedUser.id}`, updatedUserData);
            const response = await axios.post(`${API_BASE_URL}/api/admin/v2/bulk-status-change/${selectedUser.id}`, updatedUserData);


            console.log('User data updated:', updatedUserData);
            setMessage(response.data.message);


            handleClosePopover();
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };



    const statusOptions = ['Status', 'Busy', 'Not Connected', 'Switched Off', 'Wrong Number'];

    const handleSelectedRowsCount = () => {
        return selectedRows.length;
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    return (
        <>
            {message && <p
                className={classes.message}
            >{message}</p>}
            <div>
                {/* Your other components */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <MuiAlert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>

            <Box sx={{ width: '100%' }}>
                {/* <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Add Bulk Enquiries
                </Typography> */}
                <Grid container>
                    <Grid item xs={6} style={{ marginTop: "8px" }}>
                        <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                            Add Bulk Enquiries
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ margin: "auto", padding: "auto", textAlign: "right", marginBottom: "5px" }}>

                        <AddBulkUploadform />
                    </Grid>
                </Grid>
                <hr className={classes.horizontalline} />

            </Box>
            <MuiThemeProvider theme={customTheme()}>
                {
                    isLoading ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }
                <MUIDataTable
                    title={
                        isLoading ? (
                            <Skeleton animation="wave" />

                        ) : (
                            <Typography className={classes.tabletittle} >
                                <div style={{ display: 'flex' }}>
                                    <select
                                        style={{ width: "200px", marginRight: "10px" }}
                                        className={classes.selectform} value={excutiveassign}
                                        onChange={handleExecutiveChanges}
                                    >
                                        <option value="">Select excutive</option>

                                        {executiveOptions.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.title}
                                            </option>
                                        ))}
                                    </select>


                                    <Button
                                        onClick={handleSubmits}
                                        style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                        className={classes.buttonform}
                                        variant="outlined"
                                        startIcon={<AssignmentIndIcon />}
                                    > <span
                                    > {handleSelectedRowsCount()}</span>-Assign
                                    </Button>
                                </div>
                            </Typography>
                        )
                    } data={memoizedData}
                    columns={columns}
                    options={options}
                />

            </MuiThemeProvider>
            <Drawer anchor="right" open={openSidebar}

                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: 300,
                        padding: "10px"
                    }
                }}

                onClose={() => setOpenSidebar(false)}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>

                            <ClearIcon style={{ color: '#c0392b' }} onClick={() => setOpenSidebar(false)} />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={12} >
                            <br />
                            <Typography className={classes.tabletittle} >
                                Filter
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.root} style={{ paddingRight: "22px", paddingLeft: "22px" }}>
                        <div className={classes.root}>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} lg={12} >
                                    {/* <Autocomplete
                                        options={executiveOptions}
                                        // getOptionLabel={(option) => option.id}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select Excutive' variant="outlined" />
                                        )}
                                        value={selectedExecutive}
                                        onChange={handleExecutiveChange}
                                    /> */}

                                    <select
                                        className={classes.inputform}
                                        id="executiveSelect"
                                        value={selectedExecutive}
                                        onChange={handleExecutiveChange}
                                    >
                                        <option value="">Select Executive</option>
                                        {executiveOptions.map(option => (
                                            <option key={option.id} value={option.id}>
                                                {option.title}
                                            </option>
                                        ))}
                                    </select>



                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} >
                                    <select
                                        className={classes.inputform}

                                        id="brandSelect" value={selectedBrandId} onChange={handleBrandChangeBrandselect}>
                                        <option value="">Select Brand</option>
                                        {brands.map(brand => (
                                            <option key={brand.brand_id} value={brand.brand_id}>{brand.brand_name}</option>
                                        ))}
                                    </select>

                                    <select
                                        className={classes.inputform}

                                        id="modelSelect" value={selectedModelId} onChange={handleModelChange}>
                                        <option value="">Select Model</option>
                                        {tractorModels.map(model => (
                                            <option key={model.tractor_model_id} value={model.tractor_model_id}>{model.tractor_model_name}</option>
                                        ))}
                                    </select>

                                    {/* <button onClick={handleSubmit}>Submit</button> */}


                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} >
                                    <Autocomplete
                                        // multiple  
                                        classes={{
                                            option: classes.autocompleteoption,
                                        }}
                                        options={stateOptions}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select State' variant="outlined" />
                                        )}
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        disableCloseOnSelect  // Keep the dropdown open after selecting an option
                                        isOptionEqualToValue={(option, value) => option.id === value.id} // Customize option equality check
                                        getOptionSelected={(option, value) => option.id === value.id} // Customize how options are considered selected
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>

                                                {/* <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                /> */}

                                                {option.title}
                                            </li>
                                        )}
                                    />


                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} >
                                    <Autocomplete
                                        classes={{
                                            option: classes.autocompleteoption,
                                        }}
                                        // multiple 
                                        options={brandOptions}
                                        getOptionLabel={(option) => option.title}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select brand' variant="outlined" />
                                        )}
                                        value={selectedBrand}
                                        onChange={handleBrandChange}
                                        disableCloseOnSelect
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>

                                                {/* <Checkbox
                                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                /> */}

                                                {option.title}
                                            </li>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2} lg={12} >
                                    <input className={classes.inputform} type="date" name="fromDate" value={fromDate} onChange={handleDateChange} />

                                </Grid>

                                <Grid item xs={4} sm={2} lg={12}>
                                    <input className={classes.inputform} type="date" name="toDate" value={toDate} onChange={handleDateChange} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <br />
                    <Grid >
                        <Grid item xs={4} sm={2} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                            {/* <button className={classes.buttonform} type="submit">Fetch Data</button> */}
                            <Button
                                type='submit'
                                style={{ marginRight: "10px" }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ApprovalIcon />}>
                                Apply
                            </Button>
                            <Button
                                type="button" onClick={clearForm}
                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>

                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Drawer >

            <Popover
                open={Boolean(popoverAnchor)}
                anchorEl={popoverAnchor}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {selectedUser && (
                    <div>
                        <form onSubmit={handleFormSubmit}>
                            {/* <label>
                                Name:
                                <input
                                    type="text"
                                    name="name"
                                    value={updatedData.name}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <br />
                            <label>
                                Email:
                                <input
                                    type="text"
                                    name="email"
                                    value={updatedData.email}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <br /> */}
                            <div style={{ display: "flex", padding: "10px" }}>
                                <label>
                                    <select
                                        style={{ width: "100px", marginRight: "10px" }}
                                        className={classes.selectform}
                                        name="status"
                                        value={updatedData.status}
                                        onChange={handleInputChange}
                                    >
                                        {statusOptions.map((status) => (
                                            <option key={status} value={status}>
                                                {status}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <Button
                                    style={{ marginRight: "10px" }}
                                    className={classes.buttonform}
                                    type="submit">Submit</Button>
                            </div>
                            {/* Add other form fields for updating */}



                            {/* <Button
                                onClick={handleClosePopover}
                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                className={classes.buttonform}
                            >Close</Button> */}

                        </form>
                    </div>
                )}
            </Popover>

        </>
    );
};

export default QuotationBookReport;


