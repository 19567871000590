// constants.js

export const API_BASE_URL = 'https://khetigaadi.com';
export const token = localStorage.getItem('token');

// export const IMPLEMENT_REPORT_ENDPOINT = '/admin/api/v1/report/new/implement';
// export const TOKEN = 'TOKEN';
// export const TOKEN = ''
// ...other constants

// export const IMPLEMENT_REPORT_ENDPOINT = '/admin/api/v1/login';






// Retrieve data from localStorage
// const tokenLocal = localStorage.getItem('token');
const userLevelLocal = localStorage.getItem('user_level');
const adminIdLocal = localStorage.getItem('admin_id');
const adminEmailLocal = localStorage.getItem('admin_email');

// Retrieve data from sessionStorage
// const tokenSession = sessionStorage.getItem('token');
const userLevelSession = sessionStorage.getItem('user_level');
const adminIdSession = sessionStorage.getItem('admin_id');
const adminEmailSession = sessionStorage.getItem('admin_email');

// Use the retrieved data as needed
// const token = tokenLocal || tokenSession;
export const userLevel = userLevelLocal || userLevelSession;
export const adminId = adminIdLocal || adminIdSession;
export const adminEmail = adminEmailLocal || adminEmailSession;




// export const getColorfont = (value) => {
//     // Implement your logic here to determine the color based on the value
//     // For example:
//     if (value === 'Price') {
//         return '#11009E';
//     }
//     else if (value === 'Demo') {
//         return '#000000';
//     }

//     else if (value === 'JCB') {
//         return '#000000';
//     }
//     else {
//         return 'white'; // Default color if value doesn't match any condition
//     }
// };
