import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ViewDialog = ({ selectedRow, handleClose }) => {
    return (
        <Dialog onClose={handleClose} open={true}>
            <DialogTitle>View Post</DialogTitle>
            <DialogContent>
                <div>
                    <label>Title:</label>
                    <p>{selectedRow.name}</p>
                </div>
                <div>
                    <label>Body:</label>
                    <p>{selectedRow.id}</p>
                </div>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default ViewDialog;
