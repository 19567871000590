import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { List } from '@mui/material';
import { Outlet, Link } from "react-router-dom";
import "./AppBar.css";
import ListItem from '@mui/material/ListItem';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {



    // fucntion to help scroll to top smoothly
    const goToTop_OnBoarding = () => {
        window.scrollTo({
            top: 1500,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Document = () => {
        window.scrollTo({
            top: 2200,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };

    const goToTop_Attendance = () => {
        window.scrollTo({
            top: 10,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Leave = () => {
        window.scrollTo({
            top: 1000,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Payroll = () => {
        window.scrollTo({
            top: 500,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Exit = () => {
        window.scrollTo({
            top: 3000,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Expenses = () => {
        window.scrollTo({
            top: 1500,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Assets = () => {
        window.scrollTo({
            top: 1500,
            // behavior: 'smooth',
            behavior: "auto"

        });
    };
    const goToTop_Self = () => {
        window.scrollTo({
            top: 2600,
            // behavior: 'smooth',
            behavior: "auto"
        });
    };

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ function scrolling end
    return (

        <>


            <Card style={{ padding: "20px", fontSize: "12px", textDecoration: "none", color: '#707070', }}>
                <Box sx={{ flexGrow: 1 }}>


                    <Grid container spacing={2}  >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <button onClick={goToTop_OnBoarding}>
                                <Link to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }}  ><span className='hoverfontdropdown'>Employee OnBoarding </span>   </Link>
                            </button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Document} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }}  ><span className='hoverfontdropdown'>Document Management  </span>   </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Attendance} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} ><span className='hoverfontdropdown'>Attendance Management</span>  </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Leave} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} ><span className='hoverfontdropdown'>Leave Management</span>   </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Payroll} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} ><span className='hoverfontdropdown'>Payroll </span>  </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Exit} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} ><span className='hoverfontdropdown'> Exit Process </span></Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Expenses} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} > <span className='hoverfontdropdown'>Expenses Management </span>    </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Link onClick={goToTop_Assets} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} > <span className='hoverfontdropdown'>Assets Management</span>   </Link>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                            <Link onClick={goToTop_Self} to="/Recruitment" style={{ textDecoration: "none", color: '#707070' }} > <span className='hoverfontdropdown'>Employee Self Services </span>    </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            {/* <Outlet /> */}
        </>

    );
}
