// Step1.js
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';

import useStyles from '../../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';


const Step1 = ({ formData, handleInputChange, contactName, handleUsernameChange, users, }) => {

    const classes = useStyles();


    // Add a new state to store the selected user's website
    const [selectedUserWebsite, setSelectedUserWebsite] = useState('');

    useEffect(() => {
        // Find the selected user object based on the username
        const selectedUser = users.find((user) => user.username === formData.username);

        // If a user is found, set their website in the form data
        if (selectedUser) {
            setSelectedUserWebsite(selectedUser.website);
            // You can also set this data in the form data if needed:
            // setFormData({ ...formData, website: selectedUser.website });
        }
    }, [formData.username, users]);



    return (
        <>
            {/* <div>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                />
            </div> */}

            {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="contactName">Contact Name</InputLabel>
                <Select
                    label="Contact Name"
                    name="contacts[0].name"
                    value={contactName}
                    onChange={handleInputChange}
                    inputProps={{
                        name: 'contacts[0].name',
                        id: 'contactName',
                    }}
                >
                    {formData.contacts.map((contact) => (
                        <MenuItem key={contact.id} value={contact.name}>
                            {contact.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}
            {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="username">Select a username</InputLabel>
                <select
                    label="Select a username"
                    name="username"
                    value={formData.username}
                    onChange={handleUsernameChange}
                    inputProps={{
                        name: 'username',
                        id: 'username',
                    }}
                >
                    <option value="">Select a username</option>
                    {users.map((user) => (
                        <option key={user.id} value={user.username}>
                            {user.username}
                        </option>
                    ))}
                </select>
            </FormControl> */}

            {/* <TextField
                label="Website"
                name="website"
                value={selectedUserWebsite}
                onChange={handleInputChange}
                fullWidth
                required
            /> */}


            <br />

            <Grid container spacing={2} >
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Select Brand</label><br />
                    <select name="contacts[0].name"
                        value={contactName}
                        onChange={handleInputChange}
                        inputProps={{
                            name: 'contacts[0].name',
                            id: 'contactName',
                        }}
                        className={classes.selectform} >
                        {formData.contacts.map((contact) => (
                            <option key={contact.id} value={contact.name}>
                                {contact.name}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Model Name</label><br />
                    <select
                        className={classes.selectform}
                        label="Select a username"
                        name="username"
                        value={formData.username}
                        onChange={handleUsernameChange}
                        inputProps={{
                            name: 'username',
                            id: 'username',
                        }}
                    >
                        <option value="">Select Model Name</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.username}>
                                {user.username}
                            </option>
                        ))}
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Size</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Name</label><br />
                    <input
                        placeholder='Tyre Name'
                        className={classes.inputform}
                        name="website"
                        value={selectedUserWebsite}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Name In Hindi</label><br />
                    <input
                        placeholder='Tyre Name In Hindi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Name In Marathi</label><br />
                    <input
                        placeholder='Tyre Name In Marathi'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Type</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Category</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Tyre Side</label><br />
                    <select id="cars" name="carlist" form="carform"
                        className={classes.selectform} >
                        <option value="1">Active</option>
                        <option value="0" selected="">Inactive</option>
                    </select>
                </Grid>
                {/* <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Engine HP</label><br />
                    <input
                        placeholder='Engine HP'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid> */}


            </Grid >
        </>

    );
};

export default Step1;



