import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import classNames from "classnames";
import { Breadcrumbs, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, CardMedia } from '@material-ui/core';
import { style } from '@mui/system';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ClearIcon from '@material-ui/icons/Clear';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { BorderLeft } from '@material-ui/icons';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import DateRangeIcon from '@material-ui/icons/DateRange';
import useStyles from '../../../../../StyleComponents/StyleFunctionComponent';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import { toWords } from 'number-to-words-en';
import { useHistory } from "react-router-dom";
import { customTheme, getColorfont, getColor } from '../../../../../StyleComponents/Styledatatable';


import { API_BASE_URL, token, userLevel } from '../../../../../../constants';
import { Chip, Stack } from "@mui/material";

const YourComponent = ({ token }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const classes = useStyles();
    const { id } = useParams(); // Extract 'id' from the URL
    const [executiveOptions, setExecutiveOptions] = useState([]);

    const [data, setData] = useState({
        id: "",
        tractor_id: "",
        user_id: "",
        enquiry_name: "",
        created_at: "",
        date: "",
        enquiry_from: "",
        call_status: "",
        admin_id: "",
        exchanged_tractor_id: "",
        exchanged: "",
        is_dublicate: "",
        tractor_table: {
            tractor_id: "",
            brand_id: "",
            tractor_model_id: "",
            tractor_name: "",
            is_bookmark: "",
            brand: {
                brand_id: "",
                brand_name: ""
            },
            tractor_model: {
                tractor_model_id: "",
                tractor_model_name: ""
            }
        },
        enq_details: [
            {
                id: "",
                enquiry_id: "",
                enquiry_status: "",
                date: "",
                month_year: "",
                tractor_id: "",
                reason: "",
                loan_type: "",
                down_payment: "",
                remark: "",
                admin_id: "",
                admin_id: "",
                created_at: "",
                updated_at: ""
            }
        ],
        user: {
            user_id: "",
            state_id: "",
            city_id: "",
            taluka_id: "",
            first_name: "",
            last_name: "",
            email: "",
            mobile: "",
            follower_status: "",
            state: {
                state_id: "",
                state_name: "",
                short_name: ""
            },
            city: {
                city_id: "",
                city_name: ""
            },
            taluka: {
                id: "",
                name: ""
            },
            user_detail: {
                is_owner: "",
                is_smartphone: "",
                biography: "",
                awards: "",
                land: "",
                land_unit: "",
                crop_details: [
                    {
                        crop_id: "",
                        to_days: "",
                        crop_icon: "",
                        crop_name: "",
                        from_days: "",
                        scientific_name: ""
                    }
                ],
                pincode: "",
                whatsapp: "",
                telegram: "",
                alternative_contact_number: "",
                village: "",
                dob: "",
                gender: "",
                user_level: {
                    user_level_id: "",
                    user_level_name: ""
                }
            }
        },
        crm_call_status: {
            id: "",
            call_status: "",
            created_at: "",
            updated_at: ""
        },
        executive: {
            admin_id: "",
            username: "",
            admin_email: "",
            user_level: ""
        }
    }
    );

    const optionsstatus = [
        { id: "", title: "Select Status" },
        { id: "1", title: "Interested" },
        { id: "2", title: "Call Later" },
        { id: "3", title: "Already Purchased" },
        { id: "4", title: "Not Interested" },
        { id: "5", title: "Ringing No Response" },
        { id: "6", title: "Busy" },
        { id: "7", title: "Switched Off" },
        { id: "8", title: "Invalid Number" },
        { id: "9", title: "Language Problem" },
        { id: "10", title: "Other" },
        { id: "11", title: "No Incoming" },
        { id: "12", title: "Old Tractor Enquiry" },
    ];

    useEffect(() => {
        const fetchDatalist = async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        Authorization: `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(
                    `${API_BASE_URL}/api/admin/v2/executive-list`,
                    config,
                );
                const mappedData = response.data.data.map((item) => ({
                    id: item.admin_id,
                    title: item.username,
                }));
                // Include an option for null or empty value
                const optionsWithNull = [
                    { id: null, title: "Select Executive" },
                    ...mappedData,
                ];

                setExecutiveOptions(optionsWithNull);
                // setExecutiveOptions(mappedData);
            } catch (error) {
                console.error("Error fetching executives:", error);
            }
        };

        fetchDatalist();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity,
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/view-followup-history/${id}`, config);

                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, token]);

    if (isLoading) {
        return <div>
            <LinearProgress />
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                    <Card>
                        <Skeleton animation="wave" variant="rounded" height={300} />
                    </Card>

                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <Card>

                        <Skeleton animation="wave" variant="rounded" height={600} />
                    </Card>

                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Card>
                </Grid>

            </Grid>


        </div>;

    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }


    const handleInputChangecomman = (e) => {
        const { name, value } = e.target;

        // Split the name into nested property parts
        const propertyNames = name.split(".");

        // Use the property names to traverse the nested structure and update the state
        setData((prevData) => {
            let updatedData = { ...prevData };

            // Traverse the nested structure
            let currentLevel = updatedData;
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];

                // Check if it's the last part of the property name
                if (i === propertyNames.length - 1) {
                    // Update the value at the last level
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = value; // Assuming value is directly updated in array
                    } else {
                        currentLevel[propertyName] = value;
                    }
                } else {
                    // Create nested objects if they don't exist
                    if (Array.isArray(currentLevel)) {
                        const index = parseInt(propertyName); // Convert index to integer
                        currentLevel[index] = currentLevel[index] || {}; // Create empty object if not exists
                    } else {
                        currentLevel[propertyName] = currentLevel[propertyName] || {};
                    }
                    // Move to the next level
                    currentLevel = currentLevel[propertyName];
                }
            }

            return updatedData;
        });
    };

    return (

        <>

            {/* <button
                className={classes.typestrip}
                style={{
                    // backgroundColor: getColor(tableMeta.rowData[0]),
                    backgroundColor: getColor(data.enquiry_from),
                }}
            >
                {data.enquiry_from}
            </button> */}

            {/* **************** start   */}
            {/* <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Card className="mb-4" style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                        <Grid container spacing={1} style={{ marginTop: "10px" }}>
                            <Grid item xs={6} lg={6}>
                                <Typography className={classes.tabletittle} >
                                    Customer Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                            </Grid>
                        </Grid>
                        <hr />
                        <CardMedia
                            component="img"
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                            // src="https://img.freepik.com/free-vector/farmer-using-technology-digital-agriculture_53876-113813.jpg"
                            alt="avatar"
                            className="rounded-circle"
                            fluid
                            style={{ margin: "auto", padding: "auto", textAlign: "center", width: '120px' }}
                        />
                        <CardContent className="text-center">
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                {data.user.first_name} {data.user.last_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>

                            </Typography>

                        </CardContent>


                        <CardContent style={{ marginTop: "-20px" }}>
                            <Grid container spacing={1}>
                                <Grid item sm={4}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight} >  Full Name</Typography>
                                </Grid>
                                <Grid item sm={8}>
                                    <Typography className={classes.breadcrumbslinkHightlight} variant="body2" > {data.user.first_name} {data.user.last_name}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>State</Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}>    {data.user && data.user.state && data.user.state.state_name ? (
                                        <span> {data.user.state.state_name}</span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>City </Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}>     {data.user && data.user.city && data.user.city.city_name ? (
                                        <span> {data.user.city.city_name}</span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Taluka</Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}> {data.user && data.user.taluka && data.user.taluka.name ? (
                                        <span> {data.user.taluka.name}</span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Village </Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}>  {data.user && data.user.user_detail && data.user.user_detail.village ? (
                                        <span> {data.user.user_detail.village}</span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Pincode </Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}>  {data.user && data.user.user_detail && data.user.user_detail.pincode ? (
                                        <span> {data.user.user_detail.pincode} </span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Mobile</Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}>   {data.user && data.user.mobile ? (
                                        <span> {data.user.mobile} </span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Email </Typography>
                                </Grid>
                                <Grid item sm={9}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}> {data.user && data.user.email ? (
                                        <span> {data.user.email} </span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Registered on</Typography>
                                </Grid>
                                <Grid item sm={7}>
                                    <Typography variant="body2" className={classes.breadcrumbslinkHightlight}> {data.created_at ? (
                                        <span>
                                            {new Date(data.created_at).toLocaleDateString('en-IN', {
                                                day: 'numeric',
                                                month: 'short',
                                                year: 'numeric',
                                            })}{' '}
                                            {new Date(data.created_at).toLocaleTimeString('en-US', {
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                                hour12: true,
                                            })}
                                        </span>
                                    ) : (
                                        <span> </span>
                                    )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid> */}


            {/* **************** end  */}
            <Grid container spacing={1}>

                {/* <Grid item xs={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={6} lg={6}>
                                    <Typography className={classes.tabletittle} >
                                        Customer Profile
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                                </Grid>
                            </Grid>
                            <hr />
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Name : {data.user.first_name} {data.user.last_name}
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight} style={{ display: "flex" }}>
                                State :

                                {data.user && data.user.state && data.user.state.state_name ? (
                                    <span> {data.user.state.state_name}</span>
                                ) : (
                                    <span> </span>
                                )}
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                City :
                                {data.user && data.user.city && data.user.city.city_name ? (
                                    <span> {data.user.city.city_name}</span>
                                ) : (
                                    <span> </span>
                                )}

                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Taluka:
                                {data.user && data.user.taluka && data.user.taluka.name ? (
                                    <span> {data.user.taluka.name}</span>
                                ) : (
                                    <span> </span>
                                )}

                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Village :
                                {data.user && data.user.user_detail && data.user.user_detail.village ? (
                                    <span> {data.user.user_detail.village}</span>
                                ) : (
                                    <span> </span>
                                )}

                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Pincode :
                                {data.user && data.user.user_detail && data.user.user_detail.pincode ? (
                                    <span> {data.user.user_detail.pincode} </span>
                                ) : (
                                    <span> </span>
                                )}
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Mobile No:
                                {data.user && data.user.mobile ? (
                                    <span> {data.user.mobile} </span>
                                ) : (
                                    <span> </span>
                                )}

                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Email :
                                {data.user && data.user.email ? (
                                    <span> {data.user.email} </span>
                                ) : (
                                    <span> </span>
                                )}

                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Registered on: {data.created_at ? (
                                    <span>
                                        {new Date(data.created_at).toLocaleDateString('en-IN', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })}{' '}
                                        {new Date(data.created_at).toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true,
                                        })}
                                    </span>
                                ) : (
                                    <span> </span>
                                )}

                            </Typography>
                        </CardContent>
                    </Card>
                </Grid> */}
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>

                    <Card className="mb-4" style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                        {/* <Card className="mb-4" style={{ margin: "auto", padding: "auto", textAlign: "center", backgroundImage: "linear-gradient(to right, #EEF5FF, #B80000)", }}> */}


                        <Grid container spacing={1} style={{ marginTop: "8px" }}>
                            <Grid item xs={6} lg={6}>
                                <Typography className={classes.tabletittle} >
                                    Customer Profile
                                </Typography>
                            </Grid>
                            <Grid item xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                            </Grid>
                        </Grid>
                        <hr />
                        <CardMedia
                            component="img"
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                            // src="https://img.freepik.com/free-vector/farmer-using-technology-digital-agriculture_53876-113813.jpg"
                            alt="avatar"
                            className="rounded-circle"
                            fluid
                            style={{ margin: "auto", padding: "auto", textAlign: "center", width: '120px' }}
                        />
                        <CardContent className="text-center">
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                {data.user.first_name} {data.user.last_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>

                            </Typography>

                        </CardContent>


                        <CardContent style={{ marginTop: "-20px", textAlign: "left" }}>
                            <Grid container spacing={1}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight} >  Full Name</Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography style={{ fontWeight: "bold", color: "#4A4A4A" }} className={classes.breadcrumbslinkHightlight} variant="body2" > {data.user.first_name} {data.user.last_name}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>State</Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>    {data.user && data.user.state && data.user.state.state_name ? (
                                        <span> {data.user.state.state_name}</span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>City </Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>     {data.user && data.user.city && data.user.city.city_name ? (
                                        <span> {data.user.city.city_name}</span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Taluka</Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}> {data.user && data.user.taluka && data.user.taluka.name ? (
                                        <span> {data.user.taluka.name}</span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Village </Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>  {data.user && data.user.user_detail && data.user.user_detail.village ? (
                                        <span> {data.user.user_detail.village}</span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Pincode </Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>  {data.user && data.user.user_detail && data.user.user_detail.pincode ? (
                                        <span> {data.user.user_detail.pincode} </span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Mobile</Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>   {data.user && data.user.mobile ? (
                                        <span> {data.user.mobile} </span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            {/* <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>WhatsApp no. :</Typography>
                                </Grid>
                                <Grid item sm={7} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>   {data.user && data.user.mobile ? (
                                        <span> {data.user.mobile} </span>
                                    ) : (
                                        <span> </span>
                                    )} </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Telegram no:</Typography>
                                </Grid>
                                <Grid item sm={7} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}> <span className={classes.tabletittle}>  {data.user && data.user.user_detail && data.user.user_detail.telegram ? (
                                        <span> {data.user.user_detail.telegram}</span>
                                    ) : (
                                        <span> </span>
                                    )}   </span></Typography>
                                </Grid>
                            </Grid>
                            <hr /> */}
                            <Grid container spacing={2}>
                                <Grid item sm={3}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}>Email </Typography>
                                </Grid>
                                <Grid item sm={9} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}> {data.user && data.user.email ? (
                                        <span> {data.user.email} </span>
                                    ) : (
                                        <span> </span>
                                    )}</Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item sm={4}>
                                    <Typography variant="body1" className={classes.breadcrumbslinkHightlight}> Registered on </Typography>
                                </Grid>
                                <Grid item sm={8} style={{ textAlign: "right" }}>
                                    <Typography variant="body2" className={classes.tabletittle}>
                                        {data.created_at ? (
                                            <span>
                                                {new Date(data.created_at).toLocaleDateString('en-IN', {
                                                    day: 'numeric',
                                                    month: 'short',
                                                    year: 'numeric',
                                                })}{' '}
                                                {new Date(data.created_at).toLocaleTimeString('en-US', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true,
                                                })}
                                            </span>
                                        ) : (
                                            <span> </span>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                    <Card>
                        <CardContent>
                            {/* <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={4} lg={4}>
                                    <Typography className={classes.tabletittle} >
                                        Enquiry Details
                                    </Typography>
                                </Grid>
                            </Grid> */}
                            <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={4} lg={4}>

                                </Grid>
                                <Grid item xs={4} lg={4}>
                                    <Typography className={classes.tabletittle} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                        Enquiry Details
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    lg={4}
                                    style={{
                                        margin: "auto",
                                        padding: "auto",
                                        textAlign: "right",
                                    }}
                                >
                                    <Button
                                        style={{ height: "20px", fontSize: "12px" }}
                                        className={classes.buttonform}
                                        variant="outlined"
                                    >
                                        <span style={{ fontWeight: "bold" }}>
                                            Enquiry ID: {data.id}

                                        </span>
                                    </Button>
                                </Grid>
                            </Grid>
                            <hr />

                            <Grid container rowSpacing={0} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                    <p > <span className={classes.breadcrumbslinkHightlight} > Status: </span> <span className={classes.tabletittle}>

                                        {data.crm_call_status && data.crm_call_status && data.crm_call_status.call_status ? (
                                            <span> {data.crm_call_status.call_status} </span>
                                        ) : (
                                            <span> </span>
                                        )}

                                    </span></p>


                                </Grid>
                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}> Executive:</span><span className={classes.tabletittle}>

                                        {data.executive && data.executive.username ? (
                                            <span> {data.executive.username} </span>
                                        ) : (
                                            <span> </span>
                                        )}

                                    </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}> Enquiry Source:</span>
                                        <span className={classes.tabletittle}>
                                            {data.enquiry_from ? (
                                                <span> {data.enquiry_from} </span>
                                            ) : (
                                                <span> </span>
                                            )}
                                        </span>

                                    </p>

                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}>  Enquiry For:</span>
                                        <span className={classes.tabletittle}> {data.tractor_table && data.tractor_table.brand && data.tractor_table.brand ? (
                                            <span> {data.tractor_table.brand.brand_name} </span>
                                        ) : (
                                            <span> </span>
                                        )}

                                        </span>
                                    </p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>

                                    <p> <span className={classes.breadcrumbslinkHightlight}>Model: </span>  <span className={classes.tabletittle}>
                                        {data.tractor_table && data.tractor_table.tractor_name ? (
                                            <span> {data.tractor_table.tractor_name} </span>
                                        ) : (
                                            <span> </span>
                                        )}

                                    </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}>Exchange ?: </span>  <span className={classes.tabletittle}>{data.exchanged} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight} > Exchange To: </span> <span className={classes.tabletittle}> </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}>Enquiry Type: </span> <span className={classes.tabletittle}> {data.enquiry_name}  </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight}>WhatsApp Number:  </span> <span className={classes.tabletittle}>    {data.user && data.user.user_detail && data.user.user_detail.whatsapp ? (
                                        <span> {data.user.user_detail.whatsapp}</span>
                                    ) : (
                                        <span> </span>
                                    )}   </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={8} sm={8} lg={8} md={8}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}> Telegram Number:</span> <span className={classes.tabletittle}>  {data.user && data.user.user_detail && data.user.user_detail.telegram ? (
                                        <span> {data.user.user_detail.telegram}</span>
                                    ) : (
                                        <span> </span>
                                    )}   </span></p>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} md={12} style={{ margin: "Auto", padding: "auto", textAlign: "center" }}>
                                    Farmer Information

                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <hr />
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight}> Your Farm Land: </span>  <span className={classes.tabletittle}>
                                        {data.user && data.user.user_detail && data.user.user_detail.land ? (
                                            <span> {data.user.user_detail.land}</span>
                                        ) : (
                                            <span> </span>
                                        )}
                                        {data.user && data.user.user_detail && data.user.user_detail.land_unit ? (
                                            <span> {data.user.user_detail.land_unit}</span>
                                        ) : (
                                            <span> </span>
                                        )}   </span></p>
                                </Grid>
                                <Grid item xs={8} sm={8} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight}> Farm Unit:</span> </p>
                                </Grid>
                                {/* <Grid item xs={8} sm={8} lg={4} md={4}> */}
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}>Occupation: </span>  <span className={classes.tabletittle}>
                                        {data.enquiry_name}
                                    </span></p>
                                </Grid>
                                {/* </Grid> */}
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight}> Tractor Owner: </span>  <span className={classes.tabletittle}>   </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight}> Crops: </span> <span className={classes.tabletittle}>   {data.user && data.user.user_detail && data.user.user_detail.crop_details ? (
                                        data.user.user_detail.crop_details.map(crop => (
                                            <span key={crop.crop_id}>{crop.crop_name}</span>
                                        ))
                                    ) : (
                                        <span> </span>
                                    )} </span></p>



                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}>Loan: </span> <span className={classes.tabletittle}>
                                        {/* {data.enq_details && data.enq_details.length > 0 ? (
                                            data.enq_details.map(enquiry => (
                                                <span key={enquiry.id}>{enquiry.loan_type}</span>
                                            ))
                                        ) : (
                                            <span> </span>
                                        )} */}
                                    </span>
                                    </p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight}> Downpayment:</span>  <span className={classes.tabletittle}>   </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={8} sm={8} lg={8} md={8}>
                                    <p><span className={classes.breadcrumbslinkHightlight}>  Purpose:</span>  <span className={classes.tabletittle}>    {data.user && data.user.user_detail && data.user.user_detail.purpose ? (
                                        <span> {data.user.user_detail.purpose}</span>
                                    ) : (
                                        <span> </span>
                                    )}   </span></p>
                                </Grid>

                                {/* <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> <span className={classes.breadcrumbslinkHightlight}>Exchange ?: </span>  <span className={classes.tabletittle}>{data.exchanged} </span></p>
                                </Grid> */}
                                {/* <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p><span className={classes.breadcrumbslinkHightlight} > Exchange To: </span> <span className={classes.tabletittle}> </span></p>
                                </Grid> */}
                                <Grid style={mystyle} item xs={12} sm={12} lg={12} md={12}>
                                    <p>  <span className={classes.breadcrumbslinkHightlight}>Enter Remark: </span>
                                        {/* <span className={classes.breadcrumbslinkHightlight}>
                                            {data.enq_details && data.enq_details.length > 0 ? (
                                                data.enq_details.map(enquiry => (
                                                    <span key={enquiry.id}>{enquiry.remark}</span>
                                                ))
                                            ) : (
                                                <span> </span>
                                            )}  </span> */}

                                    </p>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} >
                                    <hr />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>


                    <Card >
                        <CardContent>
                            <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={6} lg={6}>
                                    <Typography className={classes.tabletittle} >
                                        FollowUp History
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr />
                            {data.enq_details && data.enq_details.length > 0 ? (
                                // data.enq_details.map((enquiry, index) => (
                                data.enq_details.slice().reverse().map((enquiry, index) => (

                                    <div key={index} >

                                        {/* $$$$$$$$$$$$$$$ start  */}
                                        <Card variant="outlined" style={{ padding: "10px", marginBottom: "5px" }}>
                                            <Typography className={classes.breadcrumbslinkHightlight} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                                <DateRangeIcon style={{ marginTop: "-10px", paddingTop: "8px" }} />
                                                {enquiry.created_at ? (
                                                    <span>
                                                        {new Date(enquiry.created_at).toLocaleDateString('en-IN', {
                                                            day: 'numeric',
                                                            month: 'short',
                                                            year: 'numeric',
                                                        })}{' '}
                                                        {new Date(enquiry.created_at).toLocaleTimeString('en-US', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                            hour12: true,
                                                        })}
                                                    </span>
                                                ) : (
                                                    <span> </span>
                                                )}

                                            </Typography>
                                            <hr />
                                            <p>Status:  {enquiry.enquiry_status ? (
                                                <span>
                                                    {optionsstatus.find(option => option.id === enquiry.enquiry_status)?.title || " "}

                                                </span>
                                            ) : (
                                                <span>

                                                </span>
                                            )}
                                            </p>
                                            <Typography className={classes.breadcrumbslinkHightlight}>
                                                Date:

                                                {enquiry.date ? (
                                                    <span>
                                                        {new Date(enquiry.date).toLocaleDateString('en-IN', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        })}
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                            </Typography>

                                            {/* <Typography className={classes.breadcrumbslinkHightlight}>
                                                Downpayment:
                                                {enquiry.down_payment ? (
                                                    <span>
                                                        {enquiry.down_payment}
                                                    </span>
                                                ) : (
                                                    <span>

                                                    </span>
                                                )}

                                            </Typography> */}
                                            <Typography className={classes.breadcrumbslinkHightlight}>
                                                Month year:

                                                {enquiry.month_year ? (
                                                    <span>
                                                        {new Date(enquiry.month_year).toLocaleDateString('en-IN', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        })}
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}


                                            </Typography>
                                            <Typography className={classes.breadcrumbslinkHightlight}>
                                                Reason:
                                                {enquiry.reason ? (
                                                    <span>
                                                        {enquiry.reason}
                                                    </span>
                                                ) : (
                                                    <span>

                                                    </span>
                                                )}

                                            </Typography>
                                            <Typography className={classes.breadcrumbslinkHightlight}>
                                                Loan:
                                                {enquiry.loan_type ? (
                                                    <span>
                                                        {enquiry.loan_type}
                                                    </span>
                                                ) : (
                                                    <span>

                                                    </span>
                                                )}

                                            </Typography>
                                            <Typography className={classes.breadcrumbslinkHightlight}>
                                                Executive: {enquiry.admin_id ? (
                                                    <span>

                                                        {executiveOptions.find(option => option.id === enquiry.admin_id)?.title || " "}

                                                    </span>
                                                ) : (
                                                    <span>

                                                    </span>
                                                )}
                                            </Typography>

                                        </Card>

                                    </div>
                                ))
                            ) : (
                                <span> </span>
                            )}


                            <br />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </>



    );
};


export default YourComponent;




const mystyle = {
    marginTop: "-10px"
};

