import React, { useState } from 'react';
import axios from 'axios';

function CombinedForm() {
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        address: {
            geo: {
                lat: '',
                lng: '',
            },
        },
        contacts: [{ id: '', name: '', age: '' }],
    });

    const handleAddContact = () => {
        setFormData(prevData => ({
            ...prevData,
            contacts: [...prevData.contacts, { id: '', name: '', age: '' }],
        }));
    };

    const handleRemoveContact = (index) => {
        setFormData(prevData => {
            const updatedContacts = [...prevData.contacts];
            updatedContacts.splice(index, 1);
            return {
                ...prevData,
                contacts: updatedContacts,
            };
        });
    };

    const handleContactChange = (index, field, value) => {
        setFormData(prevData => {
            const updatedContacts = [...prevData.contacts];
            updatedContacts[index][field] = value;
            return {
                ...prevData,
                contacts: updatedContacts,
            };
        });
    };

    const handleGeoChange = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            address: {
                ...prevData.address,
                geo: {
                    ...prevData.address.geo,
                    [field]: value,
                },
            },
        }));
    };


    const handleReset = () => {
        setFormData({
            name: '',
            date: '',
            address: {
                geo: {
                    lat: '',
                    lng: '',
                },
            },
            contacts: [{ id: '', name: '', age: '' }],
        })
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('http://localhost:3003/users', formData)
            .then(response => {
                console.log('New combined data added:', response.data);
                // Optionally, you can update your data list here
            })
            .catch(error => {
                console.error('Error adding combined data:', error);
            });
    };

    return (
        <div>
            <h2>Add New Combined Data</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input type="text" value={formData.name} onChange={e => setFormData({ ...formData, name: e.target.value })} />
                </div>
                <div>
                    <label>Date:</label>
                    <input type="date" value={formData.date} onChange={e => setFormData({ ...formData, date: e.target.value })} />
                </div>
                <div>
                    <label>Geo Latitude:</label>
                    <input type="text" value={formData.address.geo.lat} onChange={e => handleGeoChange('lat', e.target.value)} />
                </div>
                <div>
                    <label>Geo Longitude:</label>
                    <input type="text" value={formData.address.geo.lng} onChange={e => handleGeoChange('lng', e.target.value)} />
                </div>
                <div>
                    <h3>Contacts:</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Age</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {formData.contacts.map((contact, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="text"
                                            value={contact.id}
                                            onChange={e => handleContactChange(index, 'id', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={contact.name}
                                            onChange={e => handleContactChange(index, 'name', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            value={contact.age}
                                            onChange={e => handleContactChange(index, 'age', e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        {index > 0 && (
                                            <button type="button" onClick={() => handleRemoveContact(index)}>Remove</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button type="button" onClick={handleAddContact}>Add Contact</button>
                </div>
                <button type="submit">Add Combined Data</button>

                <button type="button" onClick={handleReset}>Reset</button>

            </form>
        </div>
    );
}

export default CombinedForm;
