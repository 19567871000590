import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import MobileUserProfile from './MobileUserProfile/MobileUserProfile';
import './Sidebar.css';

import {
    Wallpaper as WallpaperIcon,
    Home as HomeIcon,
    ArrowBack as ArrowBackIcon,
    Settings as MasterIcon,
    EqualizerOutlined as EqualizerOutlinedIcon,
    Receipt as ReceiptIcon,
    AddShoppingCart as AddShoppingCartIcon,
    Dashboard as DashboardIcon,
    Report as ReportIcon,
    Functions as FunctionsIcon,
    Timeline as TimelineIcon,
    Apps as AppsIcon,
    AccountBalance as AccountBalanceIcon,
    Description as DescriptionIcon,
    Work as WorkIcon,
    Subject as SubjectIcon,
    Keyboard as KeyboardIcon,
    NotificationsActive as NotificationsActiveIcon,
    Dns as DnsIcon,
    Person as PersonIcon,
    PersonPin as PersonPinIcon,
    LiveTv as LiveTvIcon,
    MobileScreenShare as MobileScreenShareIcon,
    DesktopMac as DesktopMacIcon,
    RateReview as RateReviewIcon,
    Business as BusinessIcon,
    YouTube as YouTubeIcon,
    EmojiObjects as EmojiObjectsIcon,
    EmojiTransportation as EmojiTransportationIcon,
    Commute as CommuteIcon,
    DriveEta as DriveEtaIcon,
    Wc as WcIcon,
    Accessibility as AccessibilityIcon,

} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import useStyles from "./styles";
import AgricultureIcon from '@mui/icons-material/Agriculture';
import SidebarLink from "./components/SidebarLink/SidebarLinkmain";
import Dot from "./components/Dot";
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
    {
        id: 0,
        label: "Dashboard",
        icon: <HomeIcon />,
        link: "/app/dashboard"
    },


    {
        id: 1,

        label: "CRM",
        label_sub: "label_sub",
        icon: <TimelineIcon />,
        children: [
            { label: "All Enquiries", link: "/app/allEnquiries" },
            { label: "Interested Enquiries", link: "/app/Interested" },
            // { label: "Interested Enquiries", link: "/app/interestedEnquiries" },
            { label: "All Pending Enquiries", link: "/app/pendingEnquiriesAll" },
            { label: "Search User", link: "/app/searchUser" },
            { label: "Bulk Upload", link: "/app/bulkUpload" },
        ],
        children_sub: [
            { label: " children_sub Enquiries", link: "" },

        ],
    },
    {
        id: '2',
        icon: <AppsIcon />,
        label: "Enquiry Management",
        children: [
            {
                id: '10',
                label: 'New',
                link: '/',
                icon: <AgricultureIcon />,
                children: [
                    {
                        id: '8',
                        label: 'Tractor',
                        link: '/app/NewTractorEnquiry',
                        icon: <FunctionsIcon />,
                    },
                    {
                        id: '8',
                        label: 'Tractor Not Interested Enq.',
                        link: '/app/NewTractorNotInterestedEnquiries',
                        icon: <FunctionsIcon />,
                    },
                    {
                        id: '8',
                        label: 'Implement',
                        link: '/app/NewImplementEnquiries',
                        icon: <FunctionsIcon />,
                    },
                    {
                        id: '8',
                        label: 'Harvester',
                        link: '/app/NewHarvesterEnquiries',
                        icon: <FunctionsIcon />,
                    },
                    {
                        id: '8',
                        label: 'Earth Mover',
                        link: '/app/NewEarthMover',
                        icon: <FunctionsIcon />,
                    },
                ],
            },

            {
                id: '6',
                label: 'Shop ',
                icon: <AddShoppingCartIcon />,
                children: [
                    {
                        id: '8',
                        label: 'Order Booking',
                        link: '/app/ShopProductEnquiry',
                    },
                    {
                        id: '8',
                        label: 'Expert Advice',
                        link: '/app/ShopExpertAdviceEnquiry',
                        icon: <FunctionsIcon />,
                    },
                    {
                        id: '8',
                        label: 'Expert Crop Advice',
                        link: '/app/ShopExpertAdviceCropEnquiry',
                        icon: <FunctionsIcon />,
                    },
                ],
            },
            {
                id: '6',
                label: 'Old ',
                icon: <CommuteIcon />,
                children: [
                    {
                        id: '8',
                        label: 'Tractor Contact Seller',
                        link: '/app/OldContactSeller',
                    },

                ],
            },
            {
                id: '6',
                label: 'Rent ',
                icon: <EmojiTransportationIcon />,
                children: [
                    {
                        id: '8',
                        label: 'Rent Tractor Enquires',
                        link: '/app/RentTractorEnquiry',
                    },

                ],
            },
            {
                id: '6',
                label: 'Dealers ',
                icon: <WcIcon />,
                children: [
                    {
                        id: '8',
                        label: 'Dealers',
                        link: '/app/Dealers',
                    },
                    {
                        id: '8',
                        label: 'Contact Dealers',
                        link: '/app/ContactDealerEnquiry',
                        icon: <FunctionsIcon />,
                    },
                    {
                        id: '8',
                        label: 'Dealership Enquiry',
                        link: '/app/DealershipEnquiries',
                        icon: <FunctionsIcon />,
                    },

                ],
            },
            {
                id: '11',
                label: 'Tyre',
                link: '/app/TyreEnquiries',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'Contact Us',
                link: '/app/ContactUsEnquiries',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'Loan',
                link: '/app/Loan',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'Allied Clients',
                link: '/app/AlliedClientsEnquiry',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'Escorts Responses',
                link: '/app/EscortAPIResponse',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'CNH Responses',
                link: '/app/CNHAPIResponse',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'JCB Responses',
                link: '/app/JCBAPIResponse',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'Carnot Responses',
                link: '/app/CarnotResponses',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'Authorized Dealer Listing Enq.',
                link: '/app/AuthorizedDealer',
                icon: <FunctionsIcon />,
            },
            {
                id: '11',
                label: 'JCB Enquiries',
                link: '/app/JCBEnquiries',
                icon: <FunctionsIcon />,
            },

        ],
    },
];

function Sidebar({ location }) {
    var classes = useStyles();
    var theme = useTheme();
    var { isSidebarOpened } = useLayoutState();
    var layoutDispatch = useLayoutDispatch();
    var [isPermanent, setPermanent] = useState(true);
    useEffect(function () {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    return (

        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>

                    <ArrowBackIcon
                        style={{ color: "#ffffff" }}
                        classes={{
                            root: classNames(classes.headerIcon, classes.headerIconCollapse),
                        }}
                    />
                </IconButton>
            </div>
            <List >
                <div className="sidebar_profile" style={{ marginTop: "-35px" }}>
                    <MobileUserProfile />
                    <List className={classes.sidebarList} >
                        {master.map(link => (
                            <SidebarLink
                                key={link.id}
                                location={location}
                                isSidebarOpened={isSidebarOpened}
                                {...link}
                            />
                        ))}
                    </List>

                    <hr />

                </div>

                <List className={classes.sidebarList}
                    style={{
                        paddingLeft: "5px",
                        paddingTop: "1px",
                        marginTop: "-15px",
                        marginLeft: "-25px",
                        // ####

                    }} >
                    {structure.map(link => (
                        <SidebarLink
                            key={link.id}
                            location={location}
                            isSidebarOpened={!isSidebarOpened}
                            {...link}
                        />
                    ))}
                </List>
            </List>
        </Drawer>
    );

    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }

}

export default withRouter(Sidebar);


const master = [
    {
        id: 1,
        label: "Master Department",
        icon: <MasterIcon />,
        children: [
            { label: " Company Master ", link: "/app/companyMaster", icon: <WallpaperIcon />, },
            { label: " Department Master ", link: "/app/departmentmaster" },
            { label: " Designation Master ", link: "/app/designationMaster" },
            { label: " Employee Master  ", link: "/app/EmployeeMaster" },
            { label: " Cupboard Master ", link: "/app/CupboardMaster" },
            { label: " Expense Master ", link: "/app/expenseMaster" },
            { label: " System Master ", link: "/app/SystemSetting" },
            { label: " Rule Management ", link: "/app/RuleManagement" },
            { label: " Earnings Deductions ", link: "/app/CompanyEarnings" },
        ],
    },
];







