import *  as React from 'react';

import { useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Card from '@mui/material/Card';
import FeedBack from "./MyProfileUser/FeedBack";
import SyncAltIcon from '@material-ui/icons/SyncAlt';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import UserSidebarcompany from './UserSidebarcompany';
import { useUserDispatch, signOut } from "../../../../context/UserContext";
// import {useUserDispatch , signOut}
//  from '../../../context/UserContext'
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import './UserSidebar.css';
import {
    IconButton,
} from "@material-ui/core";
import { color } from '@mui/system';
import { Container } from 'react-bootstrap';

export default function TemporaryDrawer(props) {


    var userDispatch = useUserDispatch();

    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <>
            <Box
                sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
                role="presentation"
                onClick={toggleDrawer(anchor, true)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <List style={{ backgroundColor: "#065dff", marginTop: "40px" }}>
                    {/* {['Inboxhhh', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} /> 
                        </ListItemButton>
                    </ListItem>
                ))} */}


                    <div style={{ margin: "auto ", padding: "auto", textAlign: "center", paddingTop: "5%" }} >
                        <div>
                            <br />
                            <Avatar
                                style={{ margin: "auto ", padding: "auto", textAlign: "center" }}
                                alt="Remy Sharp"
                                // src="/static/images/avatar/1.jpg"
                                src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg"
                                sx={{ width: 60, height: 60 }}
                            />
                            <h1 className='profile-name' style={{ fontSize: "18px", fontFamily: "sans-serif", fontWeight: "200" }} >Developer Only</h1>
                            <p className='profile-email' style={{ fontSize: "15px" }}> Email Id - shop@khetigaadi.com</p>
                            <p className='profile-email' style={{ fontSize: "16px" }}> SAR</p>
                            {/* <UserSidebarcompany /> */}
                            <br />
                        </div>
                    </div>
                </List>

                <Divider />
                <Stack direction="row" spacing={2} style={{ paddingTop: "3px" }}>
                    <Button
                        to="/app/MyProfileUser"
                        component={Link}
                        style={{ textTransform: "capitalize", margin: "auto", padding: "auto", textAlign: "center", display: "flex", fontWeight: "100", fontFamily: "sans-serif", fontSize: "12px" }}
                        variant="outlined" startIcon={<PersonIcon />}>
                        My Account
                    </Button>
                    <Button
                        onClick={() => signOut(userDispatch, props.history)}

                        style={{ fontSize: "12px", margin: "auto", padding: "auto", textAlign: "center", display: "flex", fontWeight: "100", fontFamily: "sans-serif", backgroundColor: "#065DFF", textTransform: "capitalize" }} variant="contained" endIcon={<LogoutIcon />}>
                        Sign Out
                    </Button>
                </Stack>
                <hr />

                <Stack direction="row" spacing={2} style={{ paddingTop: "3px", display: "block", }}>
                    <Button
                        to="/emps/dashboard"
                        component={Link}
                        style={{
                            textTransform: "capitalize", margin: "auto", padding: "auto", textAlign: "center",
                            display: "flex", fontWeight: "100", fontFamily: "sans-serif",
                            fontSize: "12px", width: "220px", marginBottom: "5px"
                        }}
                        variant="outlined" startIcon={<SyncAltIcon />}>
                        Switch To KhetiGuru Account
                    </Button>
                    <Button
                        to="/app/dashboard"
                        component={Link}
                        style={{ textTransform: "capitalize", margin: "auto", padding: "auto", textAlign: "center", display: "flex", fontWeight: "100", fontFamily: "sans-serif", fontSize: "12px", width: "220px" }}
                        variant="outlined" startIcon={<SyncAltIcon />}>
                        Switch To Khetigaadi
                    </Button>
                </Stack>

                <hr style={{ marginRight: '10px', marginLeft: '10px' }} />

                <div style={{ padding: "10px", marginTop: "-45px" }}>
                    <h6 style={{ fontSize: "15px", color: "#555555", fontFamily: "sans-serif", fontWeight: "100" }}>
                        NEED HELP?
                    </h6>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <div style={{ display: "block", }}>
                                <ul style={{ listStyleType: "none", marginLeft: "-40px", marginTop: "-30px", }}>
                                    <li style={{ color: "#1976d2", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span>Chat with us </span></li>
                                    <li style={{ color: "#1976d2", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span>Community </span></li>
                                    <li style={{ color: "#1976d2", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span>Take a tour
                                    </span></li>
                                </ul>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{ display: "block", }}>
                                <ul style={{ listStyleType: "none", marginLeft: "-40px", marginTop: "-30px", }}>
                                    <li style={{ color: "#1976d2", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span>Write to us </span></li>
                                    <li style={{ color: "#1976d2", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span>Write to us </span></li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <hr style={{ marginRight: '10px', marginLeft: '10px' }} />
                <Grid container style={{ marginTop: '-29px' }}>
                    <Grid item xs={9} >
                        <h6 style={{ paddingLeft: "10px", fontSize: "12px", color: "#555555", fontFamily: "sans-serif", fontWeight: "100" }}>MY ORGANIZATIONS</h6>
                    </Grid>
                    <Grid item xs={1}>
                        <h6 style={{ color: "#555555", fontSize: "10px", marginLeft: "10px" }} >
                            <SettingsIcon style={{ color: "#555555", fontSize: "15px", marginTop: "5px" }} />
                        </h6>
                    </Grid>
                    <Grid item xs={2}>
                        <h6 style={{ fontSize: "12px", color: "#555555", fontFamily: "sans-serif", fontWeight: "100" }}>Manage </h6>
                    </Grid>
                </Grid>

                <div style={{ display: "block", marginTop: "-60px", paddingLeft: "10px", }}>
                    <h6 style={{ fontSize: "15px", color: "#555555", fontFamily: "sans-serif", fontWeight: "100" }}>
                        SUBSCRIPTION
                    </h6>
                    <ul style={{ listStyleType: "none", marginLeft: "-40px", marginTop: "-30px", }}>
                        <li style={{ color: "#555555", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span> Enterprise trial expires in 14 day(s)</span></li>
                        <li style={{ color: "#555555", fontFamily: "sans-serif", fontWeight: "100", fontSize: "12px", paddingTop: "4px" }}><span> Try Other Editions
                        </span></li>
                    </ul>
                </div>
            </Box>

        </>
    );

    return (

        <div  >
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {/* <Button onClick={toggleDrawer(anchor, true)}> {anchor} ddd </Button> */}
                    <IconButton onClick={toggleDrawer(anchor, true)}> <AccountCircleIcon /> </IconButton>

                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>


    );
}




// #####################







