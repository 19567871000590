import React, { useState } from "react";
import NewComponent from './NewComponent';
import SettingsIcon from '@material-ui/icons/Settings';
// import DepartmentMaster from '../../Master/DepartmentMaster/CompanyMaster/DepartmentMaster';
// import CompanyMaster from  '../../Master/CompanyMaster/CompanyMaster'
// import DesignationMaster from '../../Master/DesignationMaster/DesignationMaster'
// import EmployeeMaster from '../../Master/EmployeeMaster/EmployeeMaster'
// import ExpenseMaster from '../../Master/ExpenseMaster/EmployeeMaster/ExpenseMaster'
// import RuleManagement from '../../Master/RuleManagement/EmployeeMaster/RuleManagement'
import { Link } from "react-router-dom";
// import UserSidebar from "../../Dashboard/Star/components/UserSidebar";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import UserSidebar from './Usersidebar/UserSidebar';
// import smallogo from './Headerimage/icon-36x36.png';
import smallogo from './Headerimage/icon-36x36 (2).png';
import logo from './Headerimage/MicrosoftTeams-image (38).png';
import SearchBar from '../Header/SearchBar/SearchBar';
import CallIcon from '@mui/icons-material/Call';
import HeaderMaster from "./HeaderMaster/HeaderMaster";
import HeaderNotification from "./HeaderNotification/HeaderNotification";
import './Header.css';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  // Link
} from "@material-ui/core";

import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  // NotificationsNone as NotificationsIcon,
  Add as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../../context/UserContext";
import HeaderView from "./HeaderView";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
    // Link: "https://v5.reactrouter.com/web/example/url-params"
    // Link: "/app/dashboard"


  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  {
    id: 0,
    color: "primary",
    message: "Tractor Model",
    type: "company",
    link: "/",
  },

  {
    id: 1,
    color: "primary",
    type: "department",
    message: "Tractor Series",
    link: "/",
  },
  {
    id: 2,
    color: "primary",
    type: "degignation",
    message: "Implement Model",
    link: "/",

  },

  {
    id: 3,
    color: "primary",
    type: "EmployeeMaster",
    message: "Harvester Model",
    link: "/",
  },


  {
    id: 4,
    color: "primary",
    type: "EmployeeMaster",
    message: "Implement Type",
    link: "/",
  },
  {
    id: 5,
    color: "primary",
    type: "cupboard",
    message: "Implement Harvester Specification",
    link: "/",
  },
  {
    id: 6,
    color: "primary",
    type: "expenses",
    message: "Implement Harvester Type Specification",
    link: "/",
  },
  {
    id: 7,
    color: "primary",
    type: "expenses",
    message: "Page SEO",
    link: "/",
  },
  {
    id: 8,
    color: "primary",
    type: "expenses",
    message: "Tyre Brands",
    link: "/",
  },
  {
    id: 9,
    color: "primary",
    type: "expenses",
    message: "Tyre Models",
    link: "/",
  },
  {
    id: 10,
    color: "primary",
    type: "expenses",
    message: "Commercial Models",
    link: "/",
  },

];

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  return (

    <>

      <AppBar position="fixed" className={classes.appBar} classes="backgroundcolor" >

        <Toolbar className={classes.toolbar} style={{ minHeight: "20px" }}>
          {/* <Toolbar > */}
          <IconButton
            color="inherit"
            onClick={() => toggleSidebar(layoutDispatch)}
            className={classNames(
              classes.headerMenuButtonSandwich,
              classes.headerMenuButtonCollapse,
            )}
          >
            {layoutState.isSidebarOpened ? (

              <Link to='/infra/dashboard'>

                <img

                  src={logo} style={{ width: "104PX", height: "auto", }} />
              </Link>


            ) : (


              <Link to='/infra/dashboard'>
                <img classes={{
                  root: classNames(
                    classes.headerIcon,
                    classes.headerIconCollapse,
                  ),
                }} src={smallogo} style={{ height: "36px", width: "auto" }} /></Link>

            )}
          </IconButton>
          <Typography variant="h1" weight="medium" className={classes.logotype}>

            <MenuOpenIcon style={{ color: "#0056b3", marginBottom: "0px", width: "40px", height: "30px" }} onClick={() => toggleSidebar(layoutDispatch)}
            />


          </Typography>

          {/* <div style={{ paddingLeft: "25%" }}>
            <SearchBar />
          </div> */}

          <div className={classes.grow} />

          <div
            className={classNames(classes.search, {
              [classes.searchFocused]: isSearchOpen,
            })}
          >
          </div>
          {/* <Badge
            color="warning"
          >
            <div className="mobile_display">
              <Link to="/app/Calendar">
                <CalendarMonthIcon style={{ cursor: "pointer", marginTop: "8px" }} classes={{ root: classes.headerIcon }} /></Link>
            </div>
          </Badge> */}


          <IconButton
            color="inherit"
            aria-haspopup="true"
            aria-controls="mail-menu"
            onClick={e => {
              setNotificationsMenu(e.currentTarget);
              setIsNotificationsUnread(false);
            }}
            // onMouseEnter={() => {
            //   setNotificationsMenu(true);
            //   setIsNotificationsUnread(false);
            // }}
            // onMouseLeave={() => {
            //   setNotificationsMenu(false);
            // }}
            className={classes.headerMenuButton}
          >
            <div className="mobile_display">

              <Badge

                badgeContent={isNotificationsUnread ? notifications.length : null}
                color="warning"
              >
                <SettingsIcon classes={{ root: classes.headerIcon }} />

              </Badge>
            </div>
          </IconButton>
          {/* <div className="" style={{ paddingLeft: "10px" }}>
            <HeaderMaster classes={{ root: classes.headerIcon }} />
          </div> */}
          {/* <Badge
            color="warning"

          >
            <div className="mobile_display" style={{ marginLeft: "10px" }}>
              <Link to="/">
                <CallIcon style={{ cursor: "pointer", marginTop: "8px" }} classes={{ root: classes.headerIcon }} /></Link>
            </div>
          </Badge> */}

          {/* <div className="" style={{ paddingLeft: "10px" }}>
            <HeaderNotification classes={{ root: classes.headerIcon }} />
          </div> */}

          <div className="mobile_display">
            <UserSidebar classes={{ root: classes.headerIcon }} />
          </div>


          <Menu
            id="mail-menu"
            open={Boolean(mailMenu)}
            anchorEl={mailMenu}
            onClose={() => setMailMenu(null)}
            MenuListProps={{ className: classes.headerMenuList }}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <div className={classes.profileMenuUser}>
              <Typography variant="h4" weight="medium">
                New Messages
              </Typography>
              <Typography
                className={classes.profileMenuLink}
                component="a"
                color="secondary"
              >
                {messages.length} New Messages
              </Typography>
            </div>
            {/* @@@@@@@ */}
            {messages.map(message => (
              <MenuItem key={message.id} className={classes.messageNotification}>
                <div className={classes.messageNotificationSide}>
                  <UserAvatar color={message.variant} name={message.name} />
                  <Typography size="sm" color="text" colorBrightness="secondary">
                    {message.Link}
                    <Link to={message.Link} href="#"></Link>

                  </Typography>

                </div>
                <div
                  className={classNames(
                    classes.messageNotificationSide,
                    classes.messageNotificationBodySide,
                  )}
                >
                  <Typography weight="medium" gutterBottom>
                    {message.name}
                  </Typography>
                  <Typography color="text" colorBrightness="secondary">
                    {message.message}
                  </Typography>
                </div>
              </MenuItem>
            ))}


            <Fab
              variant="extended"
              color="primary"
              aria-label="Add"
              className={classes.sendMessageButton}
            >
              Send New Message
              <SendIcon className={classes.sendButtonIcon} />
            </Fab>
          </Menu>
          <Menu
            id="notifications-menu"
            open={Boolean(notificationsMenu)}
            anchorEl={notificationsMenu}
            onClose={() => setNotificationsMenu(null)}
            className={classes.headerMenu}
            disableAutoFocusItem
          >
            {notifications.map(notification => (

              <MenuItem

                to={notification.Link}
                key={notification.id}

                className={classes.headerMenuItem}

              >
                <Notification {...notification} typographyVariant="inherit" />
              </MenuItem>
            ))}
          </Menu>

        </Toolbar>
      </AppBar >
    </>
  );
}




