import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import useStyles from '../../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';

const MultipleImageUpload = ({ formData, handleInputChange }) => {

    const classes = useStyles();

    // function MultipleImageUpload() {
    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (e) => {
        const files = e.target.files;
        const selectedImageArray = Array.from(files);

        setSelectedImages((prevImages) => [...prevImages, ...selectedImageArray]);
    };

    const handleRemoveImage = (index) => {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);
        setSelectedImages(updatedImages);
    };

    return (
        // <div>
        //     <h2>Multiple Image Upload</h2>
        //     <input
        //         type="file"
        //         accept="image/*"
        //         multiple
        //         onChange={handleImageChange}
        //     />
        //     <div className="selected-images">
        //         {selectedImages.map((image, index) => (
        //             <div key={index} className="image-container">
        //                 <img
        //                     style={{ width: "20px", height: "20px" }}
        //                     src={URL.createObjectURL(image)}
        //                     alt={`Image ${index + 1}`}
        //                     className="preview-image"
        //                 />
        //                 <button onClick={() => handleRemoveImage(index)}>
        //                     Remove
        //                 </button>
        //             </div>
        //         ))}
        //     </div>
        // </div>

        <>
            <br />
            <Grid container spacing={2} >
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Select Brand</label><br />
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageChange}
                    />
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Implement Type</label><br />
                    {selectedImages.map((image, index) => (
                        <div key={index} className="image-container">
                            <img
                                style={{ width: "20px", height: "20px" }}
                                src={URL.createObjectURL(image)}
                                alt={`Image ${index + 1}`}
                                className="preview-image"
                            />
                            <button onClick={() => handleRemoveImage(index)}>
                                Remove
                            </button>
                        </div>
                    ))}
                </Grid>
                <Grid item xs={6} sm={6} lg={4} >
                    <label for="birthday" className={classes.labelform}>Model Name</label><br />
                    <input
                        placeholder='Tractor Name'
                        className={classes.inputform}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid >

            <table style={{ border: '1px solid #000', borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #000', padding: '8px' }}>id</th>
                        <th style={{ border: '1px solid #000', padding: '8px' }}>Image url</th>
                        <th style={{ border: '1px solid #000', padding: '8px' }}>Image</th>
                        <th style={{ border: '1px solid #000', padding: '8px' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedImages.map((image, index) => (
                        <tr key={index}>
                            <td style={{ border: '1px solid #000', padding: '8px' }}>
                                {(index + 1).toString().padStart(2, '0')}
                            </td>
                            <td style={{ border: '1px solid #000', padding: '8px' }}>
                                <p>{formData.name}</p>
                            </td>
                            <td style={{ border: '1px solid #000', padding: '8px' }}>
                                <img
                                    style={{ width: "80px", height: "80px", border: '1px solid #000' }}
                                    src={URL.createObjectURL(image)}
                                    alt={`Image ${index + 1}`}
                                    className="preview-image"
                                />
                            </td>
                            <td style={{ border: '1px solid #000', padding: '8px' }}>
                                <button onClick={() => handleRemoveImage(index)}>
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


        </>
    );
}

export default MultipleImageUpload;














