import React, { useState, useEffect } from 'react';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarDensitySelector,
    GridToolbarColumnsButton,
    GridEditCellPropsParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { IconButton } from '@material-ui/core';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";




function App() {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [editRowsModel, setEditRowsModel] = useState({});

    useEffect(() => {
        axios.get('https://jsonplaceholder.typicode.com/todos').then((response) => {
            setData(response.data);
        });
    }, []);

    const handleEditCellChange = (params: GridEditCellPropsParams) => {
        const updatedEditRowsModel = { ...editRowsModel };
        updatedEditRowsModel[params.id] = params.model;
        setEditRowsModel(updatedEditRowsModel);
    };

    const handleEditButtonClick = (id) => {
        const updatedEditRowsModel = { ...editRowsModel };
        updatedEditRowsModel[id] = {};
        setEditRowsModel(updatedEditRowsModel);
    };

    const handleSaveButtonClick = (id) => {
        // Implement your save logic here
        const updatedEditRowsModel = { ...editRowsModel };
        delete updatedEditRowsModel[id];
        setEditRowsModel(updatedEditRowsModel);
    };

    const handleCancelEdit = (id) => {
        const updatedEditRowsModel = { ...editRowsModel };
        delete updatedEditRowsModel[id];
        setEditRowsModel(updatedEditRowsModel);
    };

    return (
        <div style={{ height: 400, width: '100%' }}>
            <MuiThemeProvider theme={customTheme()}>
                <DataGrid
                    rows={data}
                    columns={[
                        { field: 'id', headerName: 'ID', flex: 1 },
                        {
                            field: 'title',
                            headerName: 'Name',
                            flex: 1,
                            editable: true,
                            renderCell: (params) =>
                                editRowsModel[params.id] ? (
                                    <input
                                        className={classes.inputform}

                                        type="text"
                                        value={params.value}
                                        onChange={(e) =>
                                            handleEditCellChange({ id: params.id, field: params.field, model: e.target.value })
                                        }
                                    />
                                ) : (
                                    params.value
                                ),
                        },
                        {
                            field: 'userId',
                            headerName: 'Email',
                            flex: 1,
                            editable: true,
                            renderCell: (params) =>
                                editRowsModel[params.id] ? (
                                    <input
                                        type="text"
                                        value={params.value}
                                        className={classes.inputform}

                                        onChange={(e) =>
                                            handleEditCellChange({ id: params.id, field: params.field, model: e.target.value })
                                        }
                                    />
                                ) : (
                                    params.value
                                ),
                        },
                        {
                            field: 'username',
                            headerName: 'Username',
                            flex: 1,
                            editable: true,
                            renderCell: (params) =>
                                editRowsModel[params.id] ? (
                                    <input
                                        type="text"
                                        value={params.value}
                                        className={classes.inputform}
                                        onChange={(e) =>
                                            handleEditCellChange({ id: params.id, field: params.field, model: e.target.value })
                                        }
                                    />
                                ) : (
                                    params.value
                                ),
                        },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            flex: 1,
                            sortable: false,
                            renderCell: (params) =>
                                editRowsModel[params.id] ? (
                                    <>
                                        <IconButton onClick={() => handleSaveButtonClick(params.id)}>
                                            <SaveIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleCancelEdit(params.id)}>
                                            <CancelIcon />
                                        </IconButton>
                                    </>
                                ) : (
                                    <IconButton onClick={() => handleEditButtonClick(params.id)}>
                                        <EditIcon />
                                    </IconButton>
                                ),
                        },
                    ]}
                    editMode="row"
                    components={{
                        Toolbar: (props) => (
                            <GridToolbarContainer>
                                <GridToolbarColumnsButton />
                                <GridToolbarDensitySelector />
                                <GridToolbarExport />
                            </GridToolbarContainer>
                        ),
                    }}
                />
            </MuiThemeProvider>
        </div>
    );
}

export default App;






