import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from '@material-ui/core/CircularProgress'; // Import CircularProgress component

import classNames from 'classnames';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { jsPDF } from 'jspdf';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';


import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft';

const QuotationBookReport = () => {
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(true); // State to track loading status

    const [data, setData] = useState([]);
    const { id, name, email, phone, city } = useParams();
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isEnabledMap, setIsEnabledMap] = useState({});
    const [expanded, setExpanded] = useState({});

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         fetchData();
    //     }, 1000); // Update every 5 seconds

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    // Example of caching data in localStorage

    useEffect(() => {
        const cachedData = localStorage.getItem('myDataTableData');
        if (cachedData) {
            setData(JSON.parse(cachedData));
            setIsLoading(true);
        } else {
            fetchData();
        }
    }, []);



    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate]);

    useEffect(() => {
        applySearchFilter();
    }, [searchText]);

    const fetchData = async () => {
        try {
            // const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            const response = await axios.get("https://hub.dummyapis.com/employee?noofRecords=1000&idStarts=1000000");
            const initialEnabledMap = {};
            response.data.forEach((item) => {
                initialEnabledMap[item.id] = true; // Initialize all rows as enabled
            });
            const sortedData = response.data.sort((a, b) => b.id - a.id); // Sort data by 'id' in descending order
            setData(sortedData);
            setData(response.data);
            setIsEnabledMap(initialEnabledMap);
            setFilteredData(response.data);
            setIsLoading(false); // Set loading status to false when data is loaded

        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false); // Set loading status to false even on error

        }
    };

    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (!startDate || itemDate >= start) && (!endDate || itemDate <= end);
        });
        setFilteredData(filtered);
    };

    const handleDisable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: false,
        }));
    };

    const handleEnable = (id) => {
        setIsEnabledMap((prevMap) => ({
            ...prevMap,
            [id]: true,
        }));
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };



    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'firstName', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Name",
        },

        {
            name: "dob",
            label: "Apply For",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "contactNumber",
            label: "Mobile",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        // {
        //     name: "email",
        //     label: "Email",
        // },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: 'left',
                }),
                customBodyRender: (value, tableMeta) => {
                    const cellText = value;
                    const isExpanded = expanded[tableMeta.rowIndex];

                    // Define the maximum number of characters to display initially
                    const maxLength = 10;

                    return (
                        <div>
                            {isExpanded ? (
                                // If expanded, show the full content and a "Read Less" link
                                <>
                                    {cellText}{' '}
                                    <span
                                        onClick={() =>
                                            setExpanded({ ...expanded, [tableMeta.rowIndex]: false })
                                        }
                                    >
                                        <SwipeLeftIcon style={{ cursor: "pointer", color: "#dc3545" }} fontSize='small' className={classes.tableiconedit} />
                                        {/* Less */}
                                    </span>
                                </>
                            ) : cellText.length > maxLength ? (
                                // If not expanded, show a portion of the content and a "Read More" link
                                <>
                                    {cellText.slice(0, maxLength)}...
                                    <span
                                        onClick={() =>
                                            setExpanded({ ...expanded, [tableMeta.rowIndex]: true })
                                        }
                                    >
                                        {/* More */}
                                        <SwipeRightIcon style={{ cursor: "pointer", }} fontSize='small' className={classes.tableiconview} />

                                    </span>
                                </>
                            ) : (
                                // If the content is short, no need for "Read More"
                                cellText
                            )}
                        </div>
                    );
                },
            },
        },
        {
            name: "address",
            label: "State",
        },


        {
            name: 'imageUrl',
            label: 'City',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const downloadAsPDF = () => {
                        const doc = new jsPDF();
                        const img = new Image();
                        img.src = value;

                        // You can adjust the position and size of the image in the PDF here
                        doc.addImage(img, 'JPEG', 10, 10, 90, 60);

                        // Save the PDF with a custom name (e.g., 'image.pdf')
                        doc.save('image.pdf');
                    };

                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <img src={value} alt="Employee" style={{ width: '10px', marginRight: '5px' }} /> */}
                            <span>
                                {/* <button onClick={downloadAsPDF}>
                                    <img
                                        src="download-icon.png" // Replace with your download icon image
                                        alt="Download as PDF"
                                        style={{ width: '10px', cursor: 'pointer' }}
                                    />
                                </button> */}

                                <GetAppIcon onClick={downloadAsPDF} />
                            </span>
                        </div>
                    );
                },
            },
        },


        // {
        //     name: "address",
        //     label: "City",
        // },

        {
            name: 'imageUrl',
            label: 'City',
            options: {
                customBodyRender: (value) => (
                    <img src={value} alt="Employee" style={{ width: '10px' }} />
                ),
            },
        },

        {
            name: "address",
            label: "Taluka",
        },
        {
            name: "email",
            label: "Resume",
            options: {
                filter: true, // Enables filtering for this column
                setCellProps: () => ({
                    align: "right", // Sets cell alignment to the right
                }),
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];

                    // Define the filter criteria (e.g., filter by email)
                    const filterValue = rowData.email; // You can replace this with your actual filter value

                    return (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    margin: "auto",
                                    padding: "auto",
                                    textAlign: "center",
                                }}
                            >
                                {/* Update the Link to include the filterValue as a query parameter */}
                                {/* <Link to={`/app/update/${rowData.id}?filterValue=${filterValue}`}>
                                    <GetAppIcon fontSize='small' className={classes.tableiconedit} />
                                </Link> */}
                                <Link to={`/app/update/${tableMeta.rowData[0]}?filterValue=${filterValue}`}>
                                    <GetAppIcon fontSize='small' className={classes.tableiconedit} />
                                </Link>
                            </div>
                        </>
                    );
                },
            },
        },

        {
            name: "email",
            label: "Applied On",
            options: {
                filter: true, // Enable filtering for this column
                filterType: 'textField', // Set the filter type to "textField" (Autocomplete)

            },
        },
        {
            name: 'id',
            label: 'S/N',
            options: {
                filter: true, // Enable filtering
                customBodyRender: (value, tableMeta) => {
                    const userId = value;

                    // Create a filter function to update the filteredData state
                    const handleFilter = (value) => {
                        // Apply a filter to the original data based on the selected ID
                        const filtered = data.filter((user) => user.id === value);
                        setFilteredData(filtered);
                    };

                    // Generate a link based on the user's ID
                    const linkToUser = `/app/user/${userId}`;

                    return (
                        <>
                            <div style={{ textAlign: 'center', display: 'flex' }}>
                                <p>{userId}</p>
                                <Link to={linkToUser}>View</Link>
                            </div>
                        </>
                    );
                },
            },
        },



    ];

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPage: 50, // Set the number of rows per page to 50
        rowsPerPageOptions: [10, 50, 999, 9999, { value: data.length, label: 'All' }], // Include 'All' option


    };

    const currentTheme = customTheme(true);
    return (
        <>

            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Recruitment Details
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}  >
                    <Grid container spacing={1}>
                        <Grid item xs={2} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>Search Job Title</label><br />
                            <input
                                placeholder='Type To Search Job Title'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>


                        <Grid item xs={7} sm={7} lg={7}>
                            <br />
                            <Button
                                onClick={applySearchFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>


                        <Grid item xs={3} sm={3} lg={3}
                            style={{ margin: "auto", padding: "auto", textAlign: "right" }}
                            textAlign="right" className='headbutton' >
                            <br />
                            <Link className="button" to="/">
                                <Button
                                    className={classes.buttonred}
                                    variant="outlined" startIcon={<PostAddIcon />}>
                                    Add New Job Openings
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <br />

            <MuiThemeProvider theme={customTheme()}>
                {isLoading ? ( // Render the loading spinner while data is loading
                    <div className={classes.loadingSpinner} style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20vh', // Adjust this to control the spinner's position on the screen
                    }}>
                        <CircularProgress />
                    </div>
                ) : (

                    <MUIDataTable
                        title={
                            <Typography className={classes.tabletittle} >
                                Recruitment Details
                            </Typography>
                        }
                        // data={data}
                        data={filteredData}
                        columns={columns}
                        options={options}
                    />
                )}

            </MuiThemeProvider>
        </>
    );
};

export default QuotationBookReport;



