import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import LightModeIcon from '@mui/icons-material/LightMode';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, InputLabel } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
// import styles from "./Quotation/Style";
// import styles from "../../../Style/StyleClassComponenet";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import AddUpdateUser from "../AddUpdateUser/AddUpdateUser";

// import BulkUpload from "../BulkUpload/BulkUpload";

import useStyles from '../../../StyleComponents/StyleFunctionComponent';


// ###########################


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const QuotationBookReport = () => {
    const history = useHistory();

    const [users, setUsers] = useState([]); // Define the "users" state variable
    const [selectedOption, setSelectedOption] = useState(null);
    const [data, setData] = useState([]);
    const { id, name, email, phone, city, } = useParams();
    // const { id,  } = useParams();
    const [searchText, setSearchText] = useState('');
    const [selectAll, setSelectAll] = React.useState(false);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);



    useEffect(() => {
        applySearchFilters();
    }, [selectedOptions]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        applyDateFilter();
    }, [startDate, endDate,]);


    useEffect(() => {
        applySearchFilter();
    }, [searchText]);


    const fetchData = async () => {
        try {
            const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            // const response = await axios.get("https://jsonplaceholder.typicode.com/users");
            setData(response.data);
            setFilteredData(response.data);
            setUsers(response.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const applySearchFilters = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return selectedOptions.length === 0 || selectedOptions.includes(item.name);
        });
        setFilteredData(filtered);
    };





    const applySearchFilter = () => {
        const filtered = data.filter((item) => {
            const itemString = JSON.stringify(item).toLowerCase();
            return itemString.includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const applyDateFilter = () => {
        const filtered = data.filter((item) => {
            if (!startDate && !endDate) return true;
            const itemDate = new Date(item.date);
            const start = new Date(startDate);
            const end = new Date(endDate);
            return (
                (!startDate || itemDate >= start) &&
                (!endDate || itemDate <= end)
            );
        });
        setFilteredData(filtered);
    };



    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://jsonplaceholder.typicode.com/users/${id}`);
            fetchData();
            window.location.reload();
            // history.push("/app/QuotationBookReport");
            // history.push(history.location.pathname); // Push the current route path
            // const currentPath = history.location.pathname;
            // const newURL = `${}/app/QuotationBookReport`; // Modify the route path as needed
            // history.push(newURL);
        } catch (error) {
            console.error("Error deleting data:", error);
        }

    };

    const handleSelectChange = (event) => {
        const selectedName = event.target.value;
        setSelectedOption(selectedName);

        if (selectedName === null) {
            setFilteredData(users);
        } else {
            const filtered = users.filter((user) => user.name === selectedName);
            setFilteredData(filtered);
        }
    };


    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "S/N",
        },
        {
            name: 'name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Date",
        },

        {
            name: "phone",
            label: "Type",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Brand",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "name",
            label: "Model",
        },

        {
            name: "name",
            label: "User",
        },
        {
            name: "phone",
            label: "Mobile",
        },
        {
            name: "name",
            label: "State",
        },
        {
            name: "name",
            label: "City",
        },
        {
            name: "name",
            label: "Taluka",
        },
        {
            name: "name",
            label: "From",
        },

        // {
        //     name: "name",
        //     label: "Status",
        // },
        // {
        //     name: "name",
        //     label: "Executive",
        // },
        // {
        //     name: "name",
        //     label: "Followup",
        // },
        // {
        //     name: "name",
        //     label: "Followp Date",
        // },


        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         setCellProps: () => ({
        //             align: "right",
        //         }),
        //         customBodyRender: (value, tableMeta) => {
        //             const rowIndex = tableMeta.rowIndex;
        //             const rowData = data[rowIndex];
        //             return (
        //                 <>
        //                     <Link to={`/app/NotInterestedAttemptwiseReport/${rowData.id}`} >
        //                         <Button
        //                             style={{ width: "20px", fontSize: "10px" }}
        //                             onClick={applySearchFilter}
        //                             className={classes.buttonform}
        //                             variant="outlined" startIcon={<EditIcon style={{ fontSize: "12px" }} />}>
        //                             Actions
        //                         </Button>
        //                         <button onClick={() => handleDelete(rowData.id)}>
        //                             Delete
        //                         </button>
        //                     </Link>
        //                 </>
        //             );
        //         },
        //     },
        // },
    ];



    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [50, 100, 9999, 99999], // Use a large number to simulate "Select All"

    };
    const handleSelectAll = () => {
        if (!selectAll) {
            setSelectedOptions([...users.slice(1)]);
        } else {
            setSelectedOptions([]);
        }
        setSelectAll(!selectAll);
    };

    const currentTheme = customTheme(true);
    const classes = useStyles();
    const checkboxStyle = {
        marginRight: '8px',
    };

    const labelStyle = {
        fontSize: '12px',
        color: '##64728c',

    };

    const tagStyle = {
        margin: '0px',
        maxWidth: 'calc(100% - 6px)',
        marginTop: '-20px',
        paddingRight: "5px"

    };
    return (
        <>

            {/* <Grid container spacing={1}>
                <Grid item xs={4} sm={2} lg={2} >
                    <input
                        placeholder='Enter The Search'
                        className={classes.inputform}
                        type="text"
                        label="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4} sm={2} lg={1} >
                    <Button
                        style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                        onClick={applySearchFilter}
                        className={classes.buttonform}
                        variant="outlined" startIcon={<AssignmentIndIcon />}>
                        Assign
                    </Button>
                </Grid>
            </Grid> */}

            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Not Contact Enquiries Report
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        {/* <Grid item xs={4} sm={2} lg={2} >
                            <select id="cars" name="carlist" form="carform"
                                value={selectedOption}
                                onChange={handleSelectChange}
                                className={classes.selectform} >
                                <option value=''>1 st Attempt </option>
                                {users.map((user) => (<>
                                    <option key={user.name} value={user.name}>
                                        {user.name}
                                    </option>
                                </>
                                ))}
                            </select>
                        </Grid> */}
                        <Grid item xs={4} sm={2} lg={2} >
                            <Autocomplete
                                multiple
                                limitTags={1}
                                id="search-autocomplete"
                                options={users.map((user) => user.name)}
                                value={selectedOptions}
                                onChange={(event, newValue) => setSelectedOptions(newValue)}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} style={labelStyle}>
                                        <Checkbox
                                            style={checkboxStyle}
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            // style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        // label="Search"
                                        placeholder="Please Select"
                                        InputLabelProps={{
                                            style: labelStyle,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={4} sm={2} lg={2} >
                            <select id="cars" name="carlist" form="carform"
                                value={selectedOption}
                                onChange={handleSelectChange}
                                className={classes.selectform} >
                                <option value=''>Executive</option>
                                {users.map((user) => (<>
                                    <option key={user.name} value={user.name}>
                                        {user.name}
                                    </option>
                                </>
                                ))}
                            </select>
                        </Grid> */}
                        <Grid item xs={1} sm={2} lg={2} >
                            <input
                                placeholder='date'
                                type="date"
                                className={classes.inputform}
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={2} sm={2} lg={2}>
                            {/* <label for="birthday" className={classes.labelform}>End Date</label><br /> */}
                            <input
                                className={classes.inputform}
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={4} sm={4} lg={4} style={{ display: "flex" }}>
                            <Button
                                style={{ marginRight: "10px" }}
                                onClick={applyDateFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                            <Button
                                // onClick={applyDateFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>
                        </Grid>
                        <Grid item xs={4} sm={2} lg={2} >
                            <input
                                placeholder='Enter The Search'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>



                    </Grid>
                </div>
            </div>
            <br />
            <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={
                        <Typography className={classes.tabletittle} >
                            Not Contact Enquiries Report
                        </Typography>
                    }
                    // data={data}
                    data={filteredData}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>
    );
};

export default QuotationBookReport;








