import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from '@mui/material/Button';
import axios from 'axios';

const YourComponent = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get('http://localhost:3003/display');
            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const handleEnable = async (id) => {
        try {
            // Make an API request to update the isEnabled status to true
            await axios.patch(`http://localhost:3003/display/${id}`, { isEnabled: true });
            // Update the local data to reflect the updated status
            setData((prevData) =>
                prevData.map((item) =>
                    item.id === id ? { ...item, isEnabled: true } : item
                )
            );
        } catch (error) {
            console.error('Error enabling:', error);
        }
    };

    const handleDisable = async (id) => {
        try {
            // Make an API request to update the isEnabled status to false
            await axios.patch(`http://localhost:3003/display/${id}`, { isEnabled: false });
            // Update the local data to reflect the updated status
            setData((prevData) =>
                prevData.map((item) =>
                    item.id === id ? { ...item, isEnabled: false } : item
                )
            );
        } catch (error) {
            console.error('Error disabling:', error);
        }
    };

    const columns = [
        {
            name: 'id',
            label: 'ID',
        },
        {
            name: 'name',
            label: 'Name',
        },
        {
            name: 'isEnabled',
            label: 'Status',
            options: {
                filter: true,
                customBodyRender: (value, tableMeta) => {
                    const id = data[tableMeta.rowIndex].id;
                    return (
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleEnable(id)}
                                disabled={value} // Disable if already enabled
                            >
                                Enable
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleDisable(id)}
                                disabled={!value} // Disable if already disabled
                            >
                                Disable
                            </Button>
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        filter: true,
        selectableRows: 'none',
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <MUIDataTable
                    title={'User Data'}
                    data={data}
                    columns={columns}
                    options={options}
                />
            )}
        </div>
    );
};

export default YourComponent;
