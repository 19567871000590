import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const Step1 = ({ formData, handleInputChange }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    useEffect(() => {
        // Fetch data from JSONPlaceholder
        fetch('https://jsonplaceholder.typicode.com/posts')
            .then((response) => response.json())
            .then((data) => {
                // Extract the titles from the fetched data
                const titles = data.map((item) => item.title);
                // Set the options state with the titles
                setOptions(titles);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handlePostRequest = () => {
        // You can now use the selectedOption to include in your POST request.
        // Here, we are just logging it for demonstration purposes.
        console.log('Selected Option:', selectedOption);

        // Add your POST request logic here.
        // Example: Send a POST request with the selectedOption
        // fetch('your-api-endpoint', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ selectedOption }),
        // })
        //     .then((response) => response.json())
        //     .then((data) => {
        //         // Handle the response as needed
        //         console.log('POST Response:', data);
        //     })
        //     .catch((error) => console.error('Error making POST request:', error));
    };

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>Select an Option</InputLabel>
                <Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handlePostRequest}>
                Fire POST Request
            </Button>
        </div>
    );
};

export default Step1;
