import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

// import UserSidebarcompany from './UserSidebarcompany';
// import { useUserDispatch, signOut } from "../../../context/UserContext";
import { useUserDispatch, signOut } from '../../../context/UserContext';
import UserSidebarcompany from '../../Header/Usersidebar/UserSidebarcompany'


export default function BasicButtons(props) {
    var userDispatch = useUserDispatch();

    return (
        <div style={{ margin: "auto ", padding: "auto", textAlign: "center", }} >
            <div>
                <Avatar
                    style={{ margin: "auto ", padding: "auto", textAlign: "center" }}
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: 60, height: 60 }}
                />
                <h1 className='profile-name' style={{ fontSize: "15px" }}>Pravin Shinde</h1>
                <p className='profile-email' style={{ fontSize: "12px" }}> Email Id - pravin.shinde@khetigaadi.com</p>
                <UserSidebarcompany />
                <br />



                <table >
                    <tr className='profile-table'>

                        <th className='profile-table'>
                            {/* My Account */}
                            {/* <Button variant="contained" style={{ color: "#fff", backgroundColor: "#008acc" }}>My Account</Button> */}
                            <span variant="contained" style={{ color: "#fff", fontSize: "12px", fontWeight: "normal" }}>My Account </span>
                        </th>
                        <th
                            style={{ cursor: "pointer" }}
                            className='profile-table' onClick={() => signOut(userDispatch, props.history)}>
                            {/* Sign Out */}
                            <span variant="contained" style={{ color: "#fff", fontSize: "12px", fontWeight: "normal" }}>Sign Out</span>

                        </th>
                    </tr>
                </table>

            </div>

        </div>
    );
}






