import React, { Component, useState, Fragment, useEffect, useMemo, useRef } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, InputLabel, Drawer, Menu, Paper } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import StyleClassComponenet from '../../../Style/StyleClassComponenet';
import StyleClassComponenet from '../../../StyleComponents/StyleClassComponenet';
import { customTheme, getColor } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';

import FilterListIcon from '@mui/icons-material/FilterAlt';
import ApprovalIcon from '@mui/icons-material/Approval';
// import styles from "../../../Style/StyleClassComponenet";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { API_BASE_URL, token } from '../../../../constants';

import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import useStyles from "../../../../Khetigaadi/StyleComponents/StyleFunctionComponent";

// ###########################

const QuotationBookReport = () => {
    const history = useHistory();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);


    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 30);

    const [fromDate, setFromDate] = useState(sevenDaysAgo.toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(currentDate.toISOString().split('T')[0]);

    const fetchData = async (from, to, perPageValue, pageNumber, searchQuery) => {
        setIsLoading(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,

                }
            };

            const requestData = {
                from_date: from,
                to_date: to,
                per_page: perPageValue,
                page: pageNumber + 1, // Increment by 1 as MUI DataTable uses 1-based indexing
                search: searchQuery
            };

            // const response = await axios.post(`${API_BASE_URL}/admin/api/v1/enquiry/finance/loan`, requestData, config);


            const response1 = await axios.post(`${API_BASE_URL}/admin/api/v1/enquiry/finance/loan`, requestData, config);
            const response2 = await axios.post(`${API_BASE_URL}/admin/api/v1/enquiry/finance/insurance`, requestData, config);
            // const response3 = await axios.post(`${API_BASE_URL}/admin/api/v1/new/harvester/booknow`, requestData, config);

            // Merge the data from all three APIs
            const mergedData = response1.data.data.enquiry_report.concat(response2.data.data.enquiry_report);

            // Sort the data by tractor_id in descending order
            mergedData.sort((a, b) => b.id - a.id);
            const totalSum = response1.data.data.total + response2.data.data.total;
            // Map the data to the desired format

            // const mappedData = response.data.data.enquiry_report.map(item => {
            const mappedData = mergedData.map(item => {


                const formattedDate = new Date(item.created_at).toLocaleString('en-IN', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',

                    hour12: true,
                });
                const fullName = `${item.user.first_name} ${item.user.last_name}`;
                const stateName = item.user.state ? item.user.state.state_name : '';
                const cityName = item.user.city ? item.user.city.city_name : '';
                const talukaname = item.user.city ? item.user.taluka.name : '';
                const response = item.tvs ? item.tvs.response : '';
                return {
                    id: item.id,
                    fullName: fullName,
                    created_at: formattedDate,
                    type: item.type,
                    enquiry_type: item.enquiry_type,
                    product_code: item.product_code,
                    mobile: item.user.mobile,
                    email: item.user.email,
                    state_name: stateName,
                    city_name: cityName,
                    taluka_name: talukaname,
                    response: response,
                    enquiry_from: item.enquiry_from,
                };
            });
            setData(mappedData);
            // setData(response.data.data.enquiry_report);
            // setTotalCount(response.data.data.total);
            setTotalCount(response1.data.data.total + response2.data.data.total);
            setTotalCount(totalSum);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);

            if (error.response) {
                setError(`Error: ${error.response.status} - ${error.response.statusText}`);
            } else {
                setError('An error occurred while fetching data.');
            }
        }
    };

    // useEffect(() => {
    //     fetchData(fromDate, toDate, perPage, page, search, totalCount);
    // }, [fromDate, toDate, perPage, page, search, totalCount]);


    const sourceRef = useRef(null);

    useEffect(() => {
        // Create a new cancel token source
        sourceRef.current = axios.CancelToken.source();

        fetchData(
            fromDate, toDate, perPage, page, search, totalCount,
            sourceRef.current
        );

        // Cleanup function
        return () => {
            // Cancel the request when the component unmounts
            sourceRef.current.cancel('Request canceled by cleanup');
        };
    }, [perPage, page,], 2000); // Empty dependency array

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
    };

    const handlePrevious = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        fetchData(fromDate, toDate,);
        // fetchData(perPage, page, search, totalCount, fromDate, toDate,);

    };

    const clearForm = () => {
        setSearch(''); // Reset search state to empty
    };

    const columns = [
        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'created_at', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Date",

        },

        {
            name: "enquiry_type",
            label: "Enquiry Type",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "type",
            label: "Finance Type",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "product_code",
            label: "Product Code",
        },
        {
            name: "response",
            label: "Response",
        },
        {
            name: "fullName",
            label: "User Name",
        },

        {
            name: "mobile", // The name of the column
            label: "Mobile", // The label to display in the table header

        },
        {
            name: "email",
            label: "Email",

        },
        {
            name: "state_name",
            label: "State",

        },
        {
            name: "city_name",
            label: "City",

        },
        {
            name: "taluka_name",
            label: "Taluka",

        },
        {
            name: "enquiry_from",
            label: "From",

            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.typestrip}
                        style={{
                            backgroundColor: getColor(value),
                            width: "100%",
                        }}
                    >
                        {value}
                    </button>
                ),
            },

        },
    ];

    const CustomToolbarAction = ({ toggleFilter }) => (
        <IconButton aria-label="Filter list" onClick={toggleFilter}>
            <FilterListIcon onClick={toggleSidebar} />
        </IconButton>
    );


    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };



    const options = {
        rowsPerPage: perPage,
        selectableRows: 'none',
        page: page,
        serverSide: true,
        count: totalCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    setPage(0);
                    break;
                default:
                    break;
            }
        },

        customToolbar: () => <CustomToolbarAction />,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 1000, 10000, { value: totalCount, label: 'All' }],
        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <TextField
                    label="Filter Data"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    fullWidth
                />
                <Button onClick={applyNewFilter} variant="contained" color="primary">
                    Apply Filter
                </Button>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Cancel
                </Button>
            </div>
        ),


    };
    const currentTheme = customTheme(true);
    const classes = useStyles();
    const memoizedData = useMemo(() => data, [data]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    All Finance Enquiries

                </Typography>
                <hr className={classes.horizontalline} />
            </Box>

            <div>
                <MuiThemeProvider theme={currentTheme}>

                    {
                        isLoading ? (
                            <LinearProgress />

                        ) : (
                            ""
                        )
                    }

                    <MUIDataTable
                        title={
                            isLoading ? (
                                <Skeleton animation="wave" />

                            ) : (
                                <Typography className={classes.tabletittle} >
                                    All Finance Enquiries
                                </Typography>
                            )
                        }
                        data={memoizedData}
                        columns={columns}
                        options={options}
                    />

                </MuiThemeProvider>
            </div>

            <Drawer anchor="right" open={openSidebar}

                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: 300,
                        padding: "10px"
                    }
                }}

                onClose={() => setOpenSidebar(false)}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>


                        <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>

                            <ClearIcon style={{ color: '#c0392b' }} onClick={() => setOpenSidebar(false)} />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={12} >
                            <br />
                            <Typography className={classes.tabletittle} >
                                Filter
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.root} style={{ paddingRight: "22px", paddingLeft: "22px" }}>
                        <div className={classes.root}>
                            <Grid container spacing={1}>
                                <Grid item xs={4} sm={2} lg={12} >
                                    <input
                                        className={classes.inputform}
                                        placeholder='Type To Search'
                                        type="text" value={search}
                                        onChange={handleSearchChange} />

                                </Grid>
                                <Grid item xs={4} sm={2} lg={12} >
                                    <input className={classes.inputform} type="date" name="fromDate" value={fromDate} onChange={handleDateChange} />

                                </Grid>

                                <Grid item xs={4} sm={2} lg={12}>
                                    <input className={classes.inputform} type="date" name="toDate" value={toDate} onChange={handleDateChange} />

                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <br />
                    <Grid >
                        <Grid item xs={4} sm={2} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                            {/* <button className={classes.buttonform} type="submit">Fetch Data</button> */}
                            <Button
                                type='submit'
                                style={{ marginRight: "10px" }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ApprovalIcon />}>
                                Apply
                            </Button>
                            <Button
                                type="button" onClick={clearForm}
                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>

                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Drawer >
        </>
    );
};

export default QuotationBookReport;








