import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
    Stepper,
    Step,
    StepLabel,
    Container,
    Paper,
    Box,

} from '@mui/material';
import Step1 from './Steps/General';
import Step2 from './Steps/Specification';
import Step3 from './Steps/SEO';
import Step4 from './Steps/Content';
import Step5 from './Steps/Image';
import useStyles from '../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';
import { Button, Typography } from '@material-ui/core';

const steps = ['General', 'Specification', 'SEO', 'Content', 'Image'];

const AddUpdateForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        contacts: [
            {
                id: '',
                name: '',
                age: '',
            },
        ],
        address: {
            street: '',
            suite: '',
            city: '',
            zipcode: '',
            geo: {
                lat: '',
                lng: '',
            },
        },
        phone: '',
        website: '',
        company: {
            name: '',
            catchPhrase: '',
            bs: '',
        },
        username: '',
        email: '',
    });

    const { userId } = useParams();
    const history = useHistory();
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (userId) {
            // Fetch user data for updating
            fetch(`http://localhost:3003/users/${userId}`)
                .then((response) => response.json())
                .then((user) => {
                    setFormData(user);
                })
                .catch((error) => console.error('Error fetching user:', error));
        }
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (activeStep === steps.length - 1) {
            if (userId) {
                // Handle update logic
                fetch(`http://localhost:3003/users/${userId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then(() => {
                        history.push('/app/NewHarvesters');
                    })
                    .catch((error) => console.error('Error updating user:', error));
            } else {
                // Handle add logic
                fetch('http://localhost:3003/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                })
                    .then((response) => response.json())
                    .then(() => {
                        history.push('/app/NewHarvesters');
                    })
                    .catch((error) => console.error('Error adding user:', error));
            }
        } else {
            handleNext();
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Step1 formData={formData} handleInputChange={handleInputChange} />;
            case 1:
                return <Step2 formData={formData} handleInputChange={handleInputChange} />;
            case 2:
                return <Step3 formData={formData} handleInputChange={handleInputChange} />;
            case 3:
                return <Step4 formData={formData} handleInputChange={handleInputChange} />;
            case 4:
                return <Step5 formData={formData} handleInputChange={handleInputChange} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    {userId ? 'Updating Harvester' : 'Adding Harvester'}
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <form onSubmit={handleSubmit}>
                    {getStepContent(activeStep)}
                    <div style={{ margin: 'auto', padding: 'auto', textAlign: 'center' }}>
                        <br />

                        {activeStep !== 0 && (
                            <Button className={classes.buttonform} variant="contained" onClick={handleBack}>
                                Back
                            </Button>
                        )}
                        <Button
                            className={classes.buttonform}
                            style={{ backgroundColor: '#dc3545', borderColor: '#dc3545', marginLeft: '10px' }}
                            variant="contained"
                            type="submit"
                            color="primary"
                        >
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </div>
                </form>
            </Paper>
        </>
    );
};

export default AddUpdateForm;




