import React, { Component, useState, Fragment, useEffect, useRef, useMemo } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import LightModeIcon from '@mui/icons-material/LightMode';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, Drawer, Menu, Paper, Select, MenuItem } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import ApprovalIcon from '@mui/icons-material/Approval';
import FilterListIcon from '@mui/icons-material/FilterAlt';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import AddUpdateUser from "../AddUpdateUser/AddUpdateUser";
// import SearchUserform from "../SearchUser/SearchUserform";
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccordionSummary from '@mui/material/AccordionSummary';
import SettingsIcon from '@mui/icons-material/Settings';

import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { API_BASE_URL, token, userLevel, adminId } from '../../../../constants';


const YourComponent = ({ token }) => {
    const [executiveOptions, setExecutiveOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0); // Track the current page
    const [rowsPerPage, setRowsPerPage] = useState(50); // Set default rows per page to 50
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');


    useEffect(() => {
        const fetchDatalist = async () => {
            setIsLoading(true);

            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/executive-list`, config);
                const mappedData = response.data.data.map(item => ({
                    id: item.admin_id,
                    username: item.username,
                    admin_email: item.admin_email,
                    user_level: item.user_level,
                    status: item.status,
                }));


                setExecutiveOptions(mappedData);
                // setExecutiveOptions(mappedData);

            } catch (error) {
                console.error('Error fetching executives:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchDatalist();
    }, [token]);

    const columns = [
        {
            name: 'id',
            label: 'Admin ID',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },

        },
        {
            name: 'username',
            label: 'User name',

        },
        {
            name: 'admin_email',
            label: 'Admin email',
        },
        {
            name: 'user_level',
            label: 'User level',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
        },
        // {
        //     name: 'status',
        //     label: 'status',
        // },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                }),
                customBodyRender: (value, tableMeta, updateValue) => (
                    <Button
                        className={classes.buttonform}
                        style={{ backgroundColor: value === 1 ? '#009432' : '#E72929' }}

                        variant="contained"
                        // color={value === 1 ? '#1A4D2E' : '#E72929'}
                        onClick={() => handleStatusChange(tableMeta.rowData[0], value)}
                    >
                        {value === 1 ? 'Enabled' : 'Disabled'}
                    </Button>
                )
            }
        },
    ];
    const options = {
        selectableRows: 'none',
        rowsPerPageOptions: [20, 50, 100, 1000, 10000],
        serverSide: true, // Enable server-side pagination
        count: executiveOptions.length,
        page: page,
        rowsPerPage: rowsPerPage,
        onChangePage: (currentPage) => setPage(currentPage),
        onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
            setPage(0); // Reset to the first page when changing rows per page
        }
    };
    const currentTheme = customTheme(true);
    const classes = useStyles();

    const handleStatusChange = async (adminId, currentStatus) => {
        setIsLoading(true); // Set loading state to true before making the API call

        try {
            const response = await axios.post(`${API_BASE_URL}/api/admin/v2/admin-disable`, {
                admin_id: adminId,
                status: currentStatus === 1 ? 0 : 1, // Toggle the status
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,
                    'userLevel': 'super',
                    'adminId': '1'
                }
            });

            // Update the status locally
            const updatedData = executiveOptions.map(item => {
                if (item.id === adminId) {
                    return {
                        ...item,
                        status: currentStatus === 1 ? 0 : 1,
                    };
                }
                return item;
            });
            setExecutiveOptions(updatedData);
            // alert(response.data.message);

            // Set the message for Snackbar
            setSnackbarMessage(response.data.message);
            // Open the Snackbar
            setSnackbarOpen(true);

        } catch (error) {
            console.error('Error toggling status:', error);
        } finally {
            setIsLoading(false); // Reset loading state after the API call is completed or failed
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <>
            <div>
                {/* Your other components */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <MuiAlert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </div>
            <MuiThemeProvider theme={currentTheme}>
                {
                    isLoading ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }

                <MUIDataTable
                    title={
                        isLoading ? (
                            <Skeleton animation="wave" />

                        ) : (
                            <Typography className={classes.tabletittle} >
                                User Login
                            </Typography>
                        )
                    }
                    data={executiveOptions}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>
    );
};

export default YourComponent;