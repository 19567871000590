// Step1.js
import React from 'react';
import TextField from '@mui/material/TextField';

const Step1 = ({ formData, handleInputChange }) => {
    return (
        <div>
            <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                fullWidth
                required
            />
        </div>


    );
};

export default Step1;
