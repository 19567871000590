import React, { useState } from "react";
import {
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@material-ui/core";
import {
    Inbox as InboxIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import classnames from "classnames";
import useStyles from "./styles";
import Dot from "../Dot";

export default function SidebarLink({
    link,
    icon,
    label,
    children,
    location,
    isSidebarOpened,
    nested,
    type,
}) {
    var classes = useStyles();
    var [isOpen, setIsOpen] = useState(false);
    var isLinkActive =
        link &&
        (location.pathname === link || location.pathname.indexOf(link) !== -1);

    // Define the toggleCollapse function inside the component


    function toggleCollapse(e) {
        if (isSidebarOpened) {
            e.preventDefault();
            setIsOpen(!isOpen);
        }
    }

    function handleMouseEnter() {
        if (isSidebarOpened) {
            setIsOpen(true);
            // setIsOpen(false);
        }
    }

    function handleMouseLeave() {
        if (isSidebarOpened) {
            setIsOpen(false);
            // setIsOpen(true);
        }
    }


    if (type === "title")
        return (
            <Typography
                className={classnames(classes.linkText, classes.sectionTitle, {
                    [classes.linkTextHidden]: !isSidebarOpened,
                })}
            >
                {label}
            </Typography>
        );

    if (type === "divider") return <Divider className={classes.divider} />;

    if (link && link.includes('http')) {
        return (
            <ListItem
                button
                className={classes.link}
                classes={{
                    root: classnames(classes.linkRoot, {
                        [classes.linkActive]: isLinkActive && !nested,
                        [classes.linkNested]: nested,
                    }),
                }}
                disableRipple
            >
                <a className={classes.externalLink} href={link}>
                    <ListItemIcon
                        className={classnames(classes.linkIcon, {
                            [classes.linkIconActive]: isLinkActive,
                        })}
                    >
                        {nested ? <Dot color={isLinkActive && "white"} /> : icon}
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: classnames(classes.linkText, {
                                [classes.linkTextActive]: isLinkActive,
                                [classes.linkTextHidden]: !isSidebarOpened,
                            }),
                        }}
                        primary={label}
                    />
                </a>
            </ListItem>
        )
    }

    if (!children)
        return (
            <ListItem
                button
                component={link && Link}
                to={link}
                className={classes.link}
                classes={{
                    root: classnames(classes.linkRoot, {
                        [classes.linkActive]: isLinkActive && !nested,
                        [classes.linkNested]: nested,
                    }),
                }}
                disableRipple
            >
                <ListItemIcon
                    className={classnames(classes.linkIcon, {
                        [classes.linkIconActive]: isLinkActive,
                    })}
                >
                    {nested ? <Dot color={isLinkActive && "white"} /> : icon}
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classnames(classes.linkText, {
                            [classes.linkTextActive]: isLinkActive,
                            [classes.linkTextHidden]: !isSidebarOpened,
                        }),
                    }}
                    primary={label}
                />
            </ListItem>
        );

    return (
        <>
            <ListItem
                button
                component={link && Link}
                // onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={classes.link}
                to={link}
                disableRipple
                onClick={toggleCollapse}
            >
                <ListItemIcon
                    className={classnames(classes.linkIcon, {
                        [classes.linkIconActive]: isLinkActive,
                    })}
                >
                    {icon ? icon : <InboxIcon />}
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classnames(classes.linkText, {
                            [classes.linkTextActive]: isLinkActive,
                            [classes.linkTextHidden]: !isSidebarOpened,
                        }),
                    }}
                    primary={label}
                />
                <KeyboardArrowDownIcon
                    style={{
                        color: "#FFFFFF",
                        transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                        transition: "transform 0.3s ease-in-out",
                    }}
                />
            </ListItem>
            {children && (
                <Collapse
                    in={isOpen && isSidebarOpened}
                    timeout="auto"
                    unmountOnExit
                    className={classes.nestedList}
                    style={{ paddingLeft: "14px" }}
                >
                    <List component="div" disablePadding
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        // onClick={toggleCollapse}

                        style={{ opacity: '0.9' }}
                    >
                        {children.map(childrenLink => (
                            <SidebarLink
                                key={childrenLink && childrenLink.link}
                                location={location}
                                isSidebarOpened={isSidebarOpened}
                                classes={classes}
                                nested
                                {...childrenLink}
                            />
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
}
