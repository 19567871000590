import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import BackupIcon from '@material-ui/icons/Backup';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, Typography } from '@material-ui/core';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import SaveIcon from '@material-ui/icons/Save';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

import classNames from 'classnames';
import { API_BASE_URL, token, userLevel, adminId } from '../../../../constants';
import axios from 'axios';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        minWidth: 300,
        maxWidth: 900,
    },

}));


const MyAutocomplete = () => {
    const [message, setMessage] = useState('');

    const [uploadedFile, setUploadedFile] = useState(null);
    const [source, setSource] = useState('');
    const [brandId, setBrandId] = useState('');
    const [campaignName, setCampaignName] = useState('');

    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = async (event) => {
        setIsLoading(true);

        event.preventDefault();

        const apiUrl = `${API_BASE_URL}/api/admin/v2/bulk`;

        const formData = new FormData();
        formData.append('uploaded_file', uploadedFile);
        formData.append('source', source);
        formData.append('brand_id', brandId);
        formData.append('campaign_name', campaignName); // Add campaign_name to form data


        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'adminId': adminId,
                    'userLevel': userLevel,
                },
                body: formData
            });

            // if (response.ok) {
            //     console.log('Form submitted successfully');
            //     setMessage(response.data.message);
            if (response.ok) {
                const responseData = await response.json(); // Parse JSON response
                console.log('Form submitted successfully');
                // setMessage(responseData.message); 
                setMessage(responseData.data);

            } else {
                console.error('Error submitting form:', response.data.message);
                // setMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            // setMessage(response.data.message);

        } finally {
            setIsLoading(false); // Set loading state to false regardless of success or failure
        }
    };



    const [brandOptions, setBrandOptions] = useState([]);

    useEffect(() => {
        const fetchBrandData = async () => {
            try {


                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': token,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/brand-list`, config);

                const brandList = response.data.data.map(item => ({
                    id: item.brand_id,
                    title: item.brand_name,
                }));

                setBrandOptions(brandList);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrandData();
    }, []);


    // @@@@@@@@@@@@@@@@@@@@@
    const classes = useStyles();

    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [options3, setOptions3] = useState([]);
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedOption2, setSelectedOption2] = useState(null);
    const [selectedOption3, setSelectedOption3] = useState(null);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        // Fetch data for the first Autocomplete field
        fetch('https://jsonplaceholder.typicode.com/users')
            .then((response) => response.json())
            .then((data) => setOptions1(data));
    }, []);

    useEffect(() => {
        // Fetch data for the second Autocomplete field based on the selection in the first field
        if (selectedOption1) {
            // Filter data for the second field based on the selected name from the first field
            const filteredData = options1.find((user) => user.name === selectedOption1);
            setOptions2(filteredData ? [filteredData.address.city] : []);
            setSelectedOption2(null);
        } else {
            setOptions2([]);
            setSelectedOption2(null);
        }
    }, [selectedOption1, options1]);

    useEffect(() => {
        // Fetch data for the third Autocomplete field based on the selections in the first two fields
        if (selectedOption1 && selectedOption2) {
            // Filter data for the third field based on the selected city and name
            const filteredData = options1.find(
                (user) => user.name === selectedOption1 && user.address.city === selectedOption2
            );
            setOptions3(filteredData ? [filteredData.company.name] : []);
            setSelectedOption3(null);
        } else {
            setOptions3([]);
            setSelectedOption3(null);
        }
    }, [selectedOption1, selectedOption2, options1]);

    return (

        <>


            <div>
                <Button
                    onClick={handleClickOpen}
                    style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                    className={classes.buttonform}
                    startIcon={<BackupIcon />}
                    variant="outlined" >
                    Upload
                </Button>

            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {/* <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                        Add-Update User
                    </Typography> */}
                    <Typography className={classes.tabletittle} >
                        Add Bulk Enquiries
                    </Typography>
                    {message && <p
                        className={classes.message}
                        style={{ top: "20px", fontSize: "15px" }}
                    >{message}</p>}

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {
                    isLoading ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }
                <DialogContent dividers >


                    <form onSubmit={handleSubmit}>


                        <Grid container spacing={1}>
                            <Grid item xs={4} sm={4} lg={4} >
                                <select
                                    required
                                    className={classes.selectform}
                                    type="text"
                                    id="source"
                                    value={source}
                                    onChange={(e) => setSource(e.target.value)}
                                >
                                    <option value="">Select Source</option>
                                    <option value="fbc">FaceBook Campaign</option>
                                    <option value="smc">FaceBook Comments</option>
                                    <option value="wa">Whatsapp</option>
                                    <option value="tg">Telegram</option>
                                    <option value="oc">Offline Campaign</option>
                                    <option value="dc">Dealer Campaign</option>
                                    <option value="fbc-krishie">Facebook Campaign - Krishi-e</option>
                                </select>

                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} >

                                {/* <input
                                    placeholder='Enter Campaign Name'
                                    className={classes.inputform}
                                    type="text"
                                    label="Search"

                                /> */}

                                <input
                                    placeholder="Enter Campaign Name"
                                    className={classes.inputform}
                                    type="text"
                                    value={campaignName}
                                    onChange={(e) => setCampaignName(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={4} sm={4} lg={4} >

                                {/* <Autocomplete
                                options={options1.map((user) => user.name)}
                                value={selectedOption1}
                                onChange={(event, newValue) => setSelectedOption1(newValue)}
                                renderInput={(params) => <TextField {...params} placeholder='Tractor' />}
                            /> */}
                                <select
                                    required
                                    className={classes.selectform}

                                    id="brandId"
                                    value={brandId}
                                    onChange={(e) => setBrandId(e.target.value)}
                                >
                                    <option value="">Select Brand</option>
                                    {brandOptions.map(brand => (
                                        <option key={brand.id} value={brand.id}>{brand.title}</option>
                                    ))}
                                </select>

                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} >

                                <Autocomplete
                                    options={options2}
                                    value={selectedOption2}
                                    onChange={(event, newValue) => setSelectedOption2(newValue)}
                                    renderInput={(params) => <TextField {...params} placeholder='Select Brand' />}
                                    disabled={!selectedOption1}
                                />


                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} >

                                <Autocomplete
                                    options={options3}
                                    value={selectedOption3}
                                    onChange={(event, newValue) => setSelectedOption3(newValue)}
                                    renderInput={(params) => <TextField {...params} placeholder='Select Model' />}
                                    disabled={!selectedOption1 || !selectedOption2}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2} lg={2} >
                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} >

                                <input
                                    required
                                    placeholder='Enter The Search'
                                    className={classes.inputform}
                                    type="file"
                                    id="uploadedFile"
                                    accept=".csv"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setUploadedFile(file);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                                <Button
                                    type='submit'
                                    style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                    // onClick={applySearchFilter}
                                    className={classes.buttonform}
                                    variant="outlined" startIcon={<BackupIcon />}>
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>

                        {/* @@@@@@@@@@@@@@@@ */}
                        {/* <form onSubmit={handleSubmit}> */}

                        {/* <div>
                            <label htmlFor="uploadedFile">Uploaded File:</label>
                            <input
                                type="file"
                                id="uploadedFile"
                                accept=".csv"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (file) {
                                        setUploadedFile(file);
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <label htmlFor="source">Source:</label>
                            <input
                                type="text"
                                id="source"
                                value={source}
                                onChange={(e) => setSource(e.target.value)}
                            />
                            <select
                                type="text"
                                id="source"
                                value={source}
                                onChange={(e) => setSource(e.target.value)}
                            >
                                <option value="">Select Source</option>
                                <option value="fbc">FaceBook Campaign</option>
                                <option value="smc">FaceBook Comments</option>
                                <option value="wa">Whatsapp</option>
                                <option value="tg">Telegram</option>
                                <option value="oc">Offline Campaign</option>
                                <option value="dc">Dealer Campaign</option>
                                <option value="fbc-krishie">Facebook Campaign - Krishi-e</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="brandId">Brand ID:</label>
                            <input
                                type="text"
                                id="brandId"
                                value={brandId}
                                onChange={(e) => setBrandId(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="brandId">Brand ID:</label>
                            <select
                                id="brandId"
                                value={brandId}
                                onChange={(e) => setBrandId(e.target.value)}
                            >
                                <option value="">Select Brand</option>
                                {brandOptions.map(brand => (
                                    <option key={brand.id} value={brand.id}>{brand.title}</option>
                                ))}
                            </select>
                        </div>

                        <button type="submit">Submit</button> */}



                    </form>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </BootstrapDialog>

        </>
    );
};

export default MyAutocomplete;
