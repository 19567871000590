import React, { Component, useState, Fragment, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, InputLabel, Drawer, Menu, Paper, Popover } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import StyleClassComponenet from '../../../Style/StyleClassComponenet';
import StyleClassComponenet from '../../../StyleComponents/StyleClassComponenet';
import { customTheme, getColorfont, getColor } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
// import styles from "./Quotation/Style";
// import styles from "../../../Style/StyleClassComponenet";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import useStyles from "../../../../Khetigaadi/StyleComponents/StyleFunctionComponent";
import { API_BASE_URL, token } from '../../../../constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import FilterListIcon from '@mui/icons-material/FilterAlt';
import ApprovalIcon from '@mui/icons-material/Approval';
import PostAddIcon from '@material-ui/icons/PostAdd';
// ###########################
import { BootstrapDialog, BootstrapDialogTitle, BootstrapDialogContent } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';


const QuotationBookReport = () => {
    const history = useHistory();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    const [selectedRow, setSelectedRow] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [popoverState, setPopoverState] = useState({
        isOpen: false,
        rowData: null,
    });
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 30);

    const [fromDate, setFromDate] = useState(sevenDaysAgo.toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(currentDate.toISOString().split('T')[0]);

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        // marginTop: '500px', // Add margin-top

        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            minWidth: 600,
            maxWidth: 800,
            overflowY: 'auto', // Hide vertical overflow
            // overflowY: 'hidden', // Hide vertical overflow
            overflowX: 'hidden',
        },

    }));


    const fetchData = async (perPageValue, pageNumber, searchQuery) => {
        setIsLoading(true);
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,
                }
            };

            const requestData = {
                per_page: perPageValue,
                page: pageNumber + 1, // Increment by 1 as MUI DataTable uses 1-based indexing
                search: searchQuery,

            };

            const response = await axios.post(`${API_BASE_URL}/admin/api/v1/report/new/tractor`, requestData, config);

            const mappedData = response.data.data.new_tractor.map(item => {
                const brand_image = item.brand ? item.brand.brand_image : '';
                const brand_name = item.brand ? item.brand.brand_name : '';
                const tractor_model_name = item.tractor_model ? item.tractor_model.tractor_model_name : '';
                const drive_name = item.drive ? item.drive.drive_name : '';
                const hp_range = item.hp ? item.hp.hp_range : '';

                return {
                    tractor_id: item.tractor_id,
                    brand_image: brand_image,
                    brand_name: brand_name,
                    tractor_model_name: tractor_model_name,
                    tractor_name: item.tractor_name,
                    hp_range: hp_range,
                    drive_name: drive_name,
                    price_to: item.price_to,
                    price_from: item.price_from,

                };
            });

            mappedData.sort((a, b) => b.tractor_id - a.tractor_id);
            setData(mappedData);
            // setData(response.data.data.enquiry_report);
            setTotalCount(response.data.data.total);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching data:", error);

            if (error.response) {
                setError(`Error: ${error.response.status} - ${error.response.statusText}`);
            } else {
                setError('An error occurred while fetching data.');
            }
        }
    };

    useEffect(() => {
        fetchData(perPage, page, search, totalCount);
    }, [perPage, page, search, totalCount]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
    };

    const handlePrevious = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        // fetchData(search);

    };



    const clearForm = () => {
        setSearch(''); // Reset search state to empty
    };


    const columns = [
        {
            name: "tractor_id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },
        {
            name: 'brand_name',
            label: "Brand",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <button
                        className={classes.brandstrip}

                        style={{
                            backgroundColor: getColor(value),
                            color: getColorfont(value),
                            width: "100% !important",

                        }}
                    >
                        {value}
                    </button>
                ),
            },


        },
        {
            name: 'tractor_model_name',
            label: "Model",
        },
        {
            name: 'tractor_name',
            label: "Tractor Name",
        },
        {
            name: 'hp_range',
            label: "Exact HP",
        },
        {
            name: 'drive_name',
            label: "Drive",
        },
        {
            name: 'price_from',
            label: "Price From",
        },
        {
            name: 'price_to',
            label: "Price To",
        },
        // {
        //     name: 'view',
        //     label: 'View',
        //     options: {
        //         filter: false,
        //         sort: false,
        //         customBodyRenderLite: (dataIndex) => (
        //             <Button style={{ color: "red" }} variant="outlined" color="primary" onClick={() => handleViewClick(dataIndex)}>
        //                 View
        //             </Button>
        //         ),
        //     },
        // },
        // {
        // name: 'taluka_id',
        // label: "Status",
        // },
        // {
        // name: 'upload_from',
        // label: "Action",
        // },

        // Sr. No. Brand Model Tractor Name Exact HP Drive Price From Price To Status Action
        {
            name: 'isEnabled',
            label: 'Status',
            options: {
                filter: true,
                customBodyRender: (value, tableMeta) => {
                    const id = data[tableMeta.rowIndex].id;
                    return (
                        <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center", paddingBottom: "2px" }} >

                            <Button
                                className={classes.buttongreen}
                                variant="contained"
                                color="primary"
                            // onClick={() => handleEnable(id)}
                            // disabled={value}
                            >
                                Enable
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.buttongrey}
                                color="secondary"
                            // onClick={() => handleDisable(id)}
                            // disabled={!value}
                            >
                                Disable
                            </Button>


                        </div>
                    );
                },
            },
        },
        // {
        // name: "actions",
        // label: "Status",
        // options: {
        // filter: false,
        // setCellProps: () => ({
        // align: "right",
        // }),
        // customBodyRender: (value, tableMeta) => {
        // const rowIndex = tableMeta.rowIndex;
        // const rowData = data[rowIndex];
        // const id = rowData.id;

        // return (
        // <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center", paddingBottom: "2px" }} >
        // <Button
        // className={classes.buttongreen}
        // onClick={() => handleDisable(id)}
        // disabled={!isEnabledMap[id]}
        // style={{
        // backgroundColor: isEnabledMap[id] ? 'green' : 'grey',
        // color: 'white',
        // }}
        // >
        // Disable
        // </Button>
        // <Button
        // className={classes.buttongrey}
        // onClick={() => handleEnable(id)}
        // disabled={isEnabledMap[id]}
        // style={{
        // backgroundColor: isEnabledMap[id] ? 'grey' : 'green',
        // color: 'white',
        // }}
        // >
        // Enable
        // </Button>
        // </div>
        // );
        // },
        // },
        // },

        // {
        // name: 'View',
        // options: {
        // customBodyRender: (value, tableMeta) => {
        // const rowData = data[tableMeta.rowIndex];
        // return (
        // <button onClick={() => openModal(rowData)}>View</button>
        // );
        // },
        // },
        // },
        // {
        // name: 'view',
        // options: {
        // filter: false,
        // customBodyRender: (value, tableMeta) => (
        // <IconButton onClick={() => handleViewClick(tableMeta.rowData)}>
        // <VisibilityIcon />
        // </IconButton>
        // ),
        // },
        // },


        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right",
                }),
                customBodyRender: (value, tableMeta,) => {
                    const rowData = data[tableMeta.rowIndex];


                    return (
                        <>

                            <div style={{ display: "flex", margin: "auto", padding: "auto", textAlign: "center" }} >

                                {/* <Button
                                    style={{ color: 'red' }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleViewClick(tableMeta.rowIndex)}
                                >
                                    View
                                </Button> */}
                                <Link
                                    // onClick={() => handleViewClick(tableMeta.rowData)}
                                    // onClick={() => openModal(rowData)}
                                    onClick={() => handleViewClick(tableMeta.rowIndex)}

                                > <VisibilityIcon className={classes.tableiconview} /></Link>

                                {/* <Link to={`/app/edit/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableiconedit} /> </Link> */}
                                <Link to={`/app/edit/${tableMeta.rowData[0]}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableiconedit} /> </Link>
                                {/* <Link to={`/app/AddformUpdate/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableicon} /> </Link>
<Link> <DeleteIcon onClick={() => handleDelete(rowData.id)} className={classes.tableicon} /></Link> */}
                            </div>
                        </>
                    );
                },
            },
        },



    ];
    const CustomToolbarAction = ({ toggleFilter }) => (
        <IconButton aria-label="Filter list" onClick={toggleFilter}>
            <FilterListIcon onClick={toggleSidebar} />
        </IconButton>
    );


    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    // const handleViewClick = (rowData) => {
    //     setPopoverState({
    //         isOpen: true,
    //         rowData,
    //     });
    // };



    const handleClosePopover = () => {
        setPopoverState({
            isOpen: false,
            rowData: null,
        });
    };



    const closeModal = () => {
        setPopoverState(false);
    };

    // const handleViewClick = (dataIndex) => {
    //     setSelectedRow(data[dataIndex]);
    //     setIsDialogOpen(true);
    // };

    const handleViewClick = (rowIndex) => {
        const rowData = data[rowIndex];
        setSelectedRow(rowData);
        setIsDialogOpen(true);
    };


    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const options = {
        rowsPerPage: perPage,
        selectableRows: 'none',
        page: page,
        serverSide: true,
        count: totalCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    setPage(0);
                    break;
                default:
                    break;
            }
        },

        customToolbar: () => <CustomToolbarAction />,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 1000, 10000, { value: totalCount, label: 'All' }],
        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <TextField
                    label="Filter Data"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    fullWidth
                />
                <Button onClick={applyNewFilter} variant="contained" color="primary">
                    Apply Filter
                </Button>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Cancel
                </Button>
            </div>
        ),

    };
    const currentTheme = customTheme(true);
    const classes = useStyles();
    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    New Tractors
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>

            <MuiThemeProvider theme={customTheme()}>
                {
                    isLoading ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }

                <MUIDataTable
                    title={
                        isLoading ? (
                            <Skeleton animation="wave" />

                        ) : (
                            <Typography className={classes.tabletittle} >
                                New Tractors
                            </Typography>
                        )
                    }
                    data={data}
                    columns={columns}
                    options={options}
                />

            </MuiThemeProvider>
            <Drawer anchor="right" open={openSidebar}
                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: 300,
                        padding: "10px"
                    }
                }}

                onClose={() => setOpenSidebar(false)}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>

                            <ClearIcon style={{ color: '#c0392b' }} onClick={() => setOpenSidebar(false)} />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>
                            <Link className="button" to="/app/AddNewTractors">
                                <Button
                                    className={classes.buttonred}
                                    variant="outlined" startIcon={<PostAddIcon />}>
                                    Add New Tractor
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={4} sm={2} lg={12} >
                            <br />
                            <Typography className={classes.tabletittle} >
                                Filter
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.root} style={{ paddingRight: "22px", paddingLeft: "22px" }}>
                        <div className={classes.root}>
                            <Grid container spacing={1}>
                                <Grid item xs={4} sm={2} lg={12} >
                                    <input
                                        className={classes.inputform}
                                        placeholder='Type To Search'
                                        type="text" value={search}
                                        onChange={handleSearchChange} />
                                </Grid>
                                {/* <Grid item xs={4} sm={2} lg={12} >
<input className={classes.inputform} type="date" name="fromDate" value={fromDate} onChange={handleDateChange} />
</Grid>
<Grid item xs={4} sm={2} lg={12}>
<input className={classes.inputform} type="date" name="toDate" value={toDate} onChange={handleDateChange} />
</Grid> */}
                            </Grid>
                        </div>
                    </div>
                    <br />
                    <Grid >
                        <Grid item xs={4} sm={2} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                            {/* <button className={classes.buttonform} type="submit">Fetch Data</button> */}
                            <Button
                                type='submit'
                                style={{ marginRight: "10px" }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ApprovalIcon />}>
                                Apply
                            </Button>
                            <Button
                                type="button" onClick={clearForm}
                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>

                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Drawer >
            {/* ##################### */}

            {/* <Popover
open={popoverState.isOpen}
onClose={handleClosePopover}
anchorEl={popoverState.anchorEl}
anchorOrigin={{
vertical: 'center',
horizontal: 'center',
}}
transformOrigin={{
vertical: 'center',
horizontal: 'center',
}}
>
{popoverState.rowData && (
<div>
<p>Tractor ID: {popoverState.rowData[0]}</p>
</div>
)}
</Popover> */}

            {selectedRow && (
                <BootstrapDialog
                    open={isDialogOpen} onClose={handleCloseDialog}
                    aria-labelledby="customized-dialog-title"
                >
                    <Grid container spacing={3}>
                        <Grid xs={6} lg={6}>
                            <DialogTitle sx={{ m: 0, pl: 4 }} id="customized-dialog-title">
                                <Typography className={classes.tabletittle} >
                                    {selectedRow.brand_name}
                                </Typography>
                            </DialogTitle>
                        </Grid>
                        <Grid xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                            <IconButton
                                aria-label="close"
                                // onClick={closeModal}
                                onClick={handleCloseDialog}
                                style={{ marginRight: "10px" }}

                            >
                                <CloseIcon />
                            </IconButton>

                        </Grid>
                    </Grid>

                    {/* <DialogContent dividers>
                    {selectedRow && (
                        <div>
                            <Typography>ID: {selectedRow.tractor_id}</Typography>
                            <Typography>Name: {selectedRow.tractor_name}</Typography>
                            <Typography>Username: {selectedRow.username}</Typography>
                            <Typography>Email: {selectedRow.email}</Typography>
                            <Typography>Phone: {selectedRow.phone}</Typography>
                        </div>
                    )}
                </DialogContent> */}
                    <DialogContent dividers>
                        {/* {selectedRow && ( */}
                        <div>
                            {/* Render your popover content using popoverState.rowData */}
                            {/* <p>Tractor ID: {popoverState.rowData[0]}</p> */}

                            {/* Add more details as needed */}
                            <div style={{ marginTop: "-15px" }}>
                                {/* <Typography>Name: {selectedRow.tractor_name}</Typography> */}
                                <p>Tractor Name: <span className={classes.popoverdetail}> {selectedRow.tractor_name} </span></p>
                                <p>Model: <span className={classes.popoverdetail}> {selectedRow.tractor_model_name} </span></p>
                                <p>Drive: <span className={classes.popoverdetail}> {selectedRow.drive_name} </span></p>
                                <p>Price From: <span className={classes.popoverdetail}> {selectedRow.price_from} </span></p>
                                <p>Price To: <span className={classes.popoverdetail}> {selectedRow.price_to} </span></p>
                                <p>Image: <span className={classes.popoverdetail}> </span></p>
                                <img
                                    // src={imageUrl} brand_image
                                    // src={selectedRow.brand_image}
                                    src={`${API_BASE_URL}/images/brands/${selectedRow.brand_image}`} // Adjust the path as needed
                                    alt={selectedRow.brand_name}
                                    style={imageStyle} // Apply the inline CSS styles here
                                />
                                <p>Overview:</p>
                                <p style={{ opacity: "0.9" }}>About Farmtrac 60 Powermaxx 8+2 Tractor
                                </p>

                                <span className={classes.popoverdetail}>
                                    The Farmtrac 60 Powermaxx 8+2 is a remarkable tractor that offers superior performance and efficiency. This powerful machine is equipped with advanced features and cutting-edge technology to meet the demanding needs of modern agriculture.
                                    <br />
                                    The Farmtrac 60 Powermaxx 8+2 tractor is a large model with an excellent Farmtrac 60 Powermaxx 8+2 price. Additionally, the Farmtrac 60 Powermaxx 8+2 effectively meets all farming requirements. Here we display the features as well as the price of the Farmtrac 60 Powermaxx 8+2, which are listed below.

                                    Farmtrac 60 Powermaxx 8+2 tractor price starts at Rs. 8.75 Lac. For more information about the Farmtrac 60 Powermaxx 8+2 tractor, contact the Khetigaadi executive.

                                </span>

                                <p style={{ opacity: "0.8" }}>Farmtrac 60 Powermaxx 8+2 Feature
                                </p>
                                <ul>
                                    <li className={classes.popoverdetail}>The Farmtrac 60 Powermaxx 8+2 Tractor has a 55 HP, 3-cylinder engine.</li>
                                    <li className={classes.popoverdetail}>The best engine size of the Farmtrac 60 Powermaxx 8+2 Tractor, 3514CC, offers good field mileage.</li>
                                    <li className={classes.popoverdetail}>It has 8 forward gears and 2 reverse gearboxes.</li>
                                    <li className={classes.popoverdetail}>It provides a large 60-liter fuel tank capacity for use on farms for a long period of time.</li>
                                    <li className={classes.popoverdetail}>Farmtrac 60 Powermaxx 8+2 Tractor has an 1800-kg lifting capacity.</li>
                                    <li className={classes.popoverdetail}>The Farmtrac 60 Powermaxx 8+2 Tractor has a Dual clutch model.</li>
                                </ul>
                                <p style={{ opacity: "0.8" }}>FARMTRAC 60 POWERMAXX 8+2 FULL SPECIFICATIONS
                                </p>
                                <table style={tableStyle}>
                                    <tbody>
                                        <tr>
                                            <td style={tdStyle} >HP Category
                                            </td>
                                            <td className={classes.popoverdetail} style={tdStyle}>55 HP</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>Engine Capacity</td>
                                            <td className={classes.popoverdetail} style={tdStyle}>3514 CC</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>Engine Rated RPM</td>
                                            <td style={tdStyle} className={classes.popoverdetail}>1850 RPM</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>No of Cylinder</td>
                                            <td style={tdStyle} className={classes.popoverdetail}>3</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>Brake Type</td>
                                            <td style={tdStyle} className={classes.popoverdetail}>Multiplate Oil immersed Brakes</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>Steering Type</td>
                                            <td style={tdStyle} className={classes.popoverdetail}>Balanced Power steering</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>PTO Power</td>
                                            <td style={tdStyle} className={classes.popoverdetail}>49 HP</td>
                                        </tr>
                                        <tr>
                                            <td style={tdStyle}>PTO RPM</td>
                                            <td style={tdStyle} className={classes.popoverdetail}>1810</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* )} */}
                    </DialogContent>
                </BootstrapDialog>
            )}



        </>
    );
};

export default QuotationBookReport;




const tableStyle = {
    borderCollapse: 'collapse',
    width: '50%',
    margin: '0 auto',
};

const thStyle = {
    backgroundColor: '#f2f2f2',
    padding: '8px',
    textAlign: 'left',
};

const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #ddd',
};


const imageUrl = 'https://khetigaadi.com/images/new-tractor/Indo-Farm-3090-DI1681120072.png';

// Define inline CSS styles for the image (optional)
const imageStyle = {
    width: '300px', // Example width
    height: 'auto', // Automatically adjust height to maintain aspect ratio
    border: '2px solid #ccc', // Example border
    borderRadius: '10px', // Example border radius
};



