import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TypingEffect from './TypingEffect';
import { padding } from '@mui/system';
import Hoverjumpdemo from '../../Demopages/hoverjumpdemo/Hoverjumpdemo';
import "./TypingEffect.css";
import BookLiveForm from './BookLiveForm/BookLiveForm';
import Logo from '../../Components/Image/HRVERSE_logo.svg';
import Symboll from '../../Components/Image/Group 632@2x.png';
import StartupSection from './StartupSection/StartupSection';
import NutShell from './NutShell/NutShell';
import Recrument from './Recrument/Recrument';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FooterMobile from '../FooterMobile/FooterMobile';
import Industry from "./Industry/Industry";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Startforfree from './Startforfree/Startforfree';
import Customer from "./Customer/Customer";
import Counter from './Counter/Counter';
import Footer from '../Footer/Footer';
// import Container from '@mui/material/Container';
import Copyright from '../Copyright/Copyright/Copyright'
import Sidebar from '../../Components/Sidebar';
import Nutshellscroll from '../../Pages/Home/NutShell/Nutshellscroll';
import Startforfreeold from './Startforfree/Startforfree-old';
import Positionfix from '../../Demopages/positionfix/Positionfix';
import Dialogform from '../Home/Dialog/Dialogform';
import CustomerResponsive from '../Home/Customer/CustomerResponsive';
import { Link } from 'react-router-dom';
import Homebanner from "../Startup/Startupfirst/Homebanner";
import Form_demo from '../../Demopages/Form_demo/Form_demo';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (

        <>

            <Sidebar />
            <Container style={{ paddingTop: "150px" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <br className='display-none' />
                            <TypingEffect />
                            <section className='normalfont' >
                                <div>
                                    <p>
                                        <p style={{ fontWeight: "bold" }}>
                                            Easy and Simple solution for your end-to-end HR process </p><br />
                                        Employee Onboarding, Document Management, Attendance Management, Leave Management, Exit Process, Payroll & much more…
                                    </p></div></section>

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ margin: "auto", textAlign: "right" }}>
                            <Item className="display-none-form">
                                {/* <BookLiveForm /> */}
                                <h1>form...................</h1>
                                <h1>form...................</h1>
                                <h1>form...................</h1>
                                <h1>form...................</h1>
                                <h1>form...................</h1>
                            </Item>
                            <item className="display-show">
                                <Stack direction="row" spacing={2} style={{ margin: "auto", textAlign: "center" }} >

                                    <h1 style={{ paddingTop: "10%" }}> <Dialogform /></h1>
                                    <Button style={{ backgroundColor: "#25D366", margin: "auto" }} variant="contained" startIcon={<WhatsAppIcon />}>
                                        WhatsApp
                                    </Button>
                                </Stack>
                            </item>

                        </Grid>
                    </Grid>
                    <section className=''>
                        <div>
                            <h1 className='textaligncenter'>New Era of HR Management</h1>
                            <p className='textsizeparagraph'>

                                Easy and Simple Tool to manage your team, right from Onboarding to Exit process and Attendance to one click Salary Generation
                            </p>
                            {/* <p className='textsizeparagraph'>  In publishing and graphic </p> */}

                        </div>
                    </section>
                    {/* <section className='aligncenter'>
                        <div >
                        <span style={{ display: "flex" }}>  <img style={{ height: "40px", textAlign: "center" }} src={Logo} />
                            <h1 style={{ marginLeft: "20px", }} className="Startup">for Startups</h1> </span>
                        </div>
                    </section> */}
                </Box>
            </Container>

            {/* <StartupSection /> */}
            <Container style={{ margin: "auto", textAlign: "center" }}>

                <section className='aligncenter'>
                    <span style={{ display: "flex" }}>  <img className="logohrverse" src={Logo} />
                        <h1 style={{ marginLeft: "20px", marginTop: "-6px" }} className="Startup">At a Glance </h1> </span>
                </section>

            </Container>
            {/* <NutShell /> */}
            {/* <Nutshellscroll /> */}
            <Container style={{ margin: "auto", textAlign: "center" }}>
                <section className='aligncenter'>
                    <span style={{ display: "flex" }}>  <img className="logohrverse" src={Logo} />
                        <h1 style={{ marginLeft: "20px", marginTop: "-6px" }} className="Startup">for Startups</h1> </span>
                </section>
            </Container>
            {/* <StartupSection /> */}
            {/* <Homebanner /> */}
            <Container style={{ margin: "auto", textAlign: "center" }}>

                <section className='aligncenter ' style={{ marginBottom: "-60px" }}>
                    {/* <div > */}
                    <span style={{ display: "flex" }}>  <img className="logohrverse" src={Logo} />
                        <h1 style={{ marginLeft: "20px", marginTop: "-6px" }} className="Startup">Key Features</h1> </span>
                    {/* </div> */}
                </section>

            </Container>
            <Recrument />

            <Container style={{ margin: "auto", textAlign: "center" }}>

                <section className=''>
                    <div>
                        <h1 className='toiler'> BEST FIT    <span className='spancolor'>For Your Industry</span> </h1>
                    </div>
                </section>
                <Industry />
                {/* <Form_demo /> */}

            </Container>

            <br />


            <Positionfix />
            {/* <div className='Startforfreecontainer'  > */}
            {/* <Startforfree /> */}
            {/* <Hoverjumpdemo /> */}
            {/* <Startforfreeold /> */}
            {/* </div> */}
            <br />
            {/* <Container className=''> */}
            {/* <hr className='horizontalline' /> */}
            {/* </Container> */}
            <div className='display-none-form'>
                {/* <Customer /> */}
            </div>
            <div className='display-show'>
                {/* <CustomerResponsive /> */}
            </div>
            {/* <Container className=''> */}
            {/* <hr className='horizontalline2 horizontallinebottom' /> */}
            <hr className='horizontallinebottom' />
            {/* </Container> */}

            <div className='display-none-form'>
                <Footer />
                {/* <Container className=''> */}
                <hr className='horizontallinebottom' />
                {/* </Container> */}
                <Copyright />
            </div>
            <div className='display-show'>
                <FooterMobile />
            </div>
        </>
    );
}






