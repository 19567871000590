import { red } from "@material-ui/core/colors";
import { Apple } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
// const iconcolor = "#f0ffff";
const primary = "#fff";

// const secondary = "#0C2D48";
/// hover sidebar strip color secondary
const secondary = "#0043bf";
// 


export default makeStyles(theme => ({
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      // backgroundColor: theme.palette.background.light,
      backgroundColor: secondary,
    },
  },
  externalLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    // backgroundColor: "#adcae4",


  },

  linkActive: {
    backgroundColor: theme.palette.background.light,
  },

  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      // backgroundColor: "#FFFFFF",
      // backgroundColor: secondary,


    },
  },
  linkIcon: {
    // marginRight: theme.spacing(1),
    // color: theme.palette.text.secondary + "99",
    // transition: theme.transitions.create("color"),
    // backgroundColor: "iconcolor",
    color: primary,

    width: 24,

    display: "flex",
    justifyContent: "center",
  },
  linkIconActive: {
    // color: theme.palette.primary.main,
    color: primary,

  },
  linkText: {
    padding: 0,
    // color: theme.palette.text.red + "CC",
    // transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
    color: primary,
    fontWeight: 100,
    lineHeight: 0.2,

    // fontfamily: Arial, Helvetica, sansserif,
    // fontFamily: arial, Helvetica, sansserif,

  },
  linkTextActive: {
    color: theme.palette.text.primary,
    color: "iconcolor",
  },
  linkTextHidden: {
    opacity: 0,
  },

  // nestedList: {
  //   paddingLeft: theme.spacing(1) + 5,
  // },


  // sectionTitle: {
  //   marginLeft: theme.spacing(4.5),
  //   marginTop: theme.spacing(2),
  //   marginBottom: theme.spacing(2),
  // },


  sectionTitle: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },


  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    // backgroundColor: "#D8D8D880",
    // backgroundColor: "#00FF00",
  },
}));





