// import { makeStyles } from "@mui/styles";
import { withStyles } from "@material-ui/core/styles";
// import { withStyles } from “@material-ui/styles”;



const pentaonal = (theme) => ({

    breadcrumbslink: {
        // color: "#2C3E50",
        color: "#64728c",
        // color: "red",
        fontSize: "11px",
        // fontFamily: 'robot',
        fontWeight: "100",
        display: 'flex',
        alignItems: 'center',


    },


    breadcrumbslinkHightlight: {
        // color: '#2C3E50',
        color: "#64728c",
        fontSize: '13px',
        fontFamily: 'sans-serif',
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',

    },



    labelform: {
        padding: "10px",
        fontFamily: "sans-serif",
        color: "#64728c",
        fontSize: "12px",
        fontWeight: "600",

    },

    inputform: {
        width: "100%",
        border: '1px solid #e8eaef',
        borderRadius: '4px',
        padding: "10px",
        height: "30px",
        marginTop: "4px",
        fontFamily: "sans-serif",
        color: "#64728c",
        fontSize: "12px"

    },

    selectform: {
        width: "100%",
        border: '1px solid #e8eaef',
        borderRadius: '4px',
        height: "30px",
        fontFamily: "sans-serif",
        marginTop: "4px",
        fontSize: "12px",
        color: "#64728c",

    },
    buttonform: {
        borderRadius: 6,
        backgroundColor: "#007bff",
        padding: "2px 12px",
        fontSize: "14px",
        borderColor: "#007bff",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            backgroundColor: '#026fe3'
        }
    },

    tabletittle: {
        fontSize: "14px",
        // fontFamily: "sans-serif",
        // fontWeight: "700",
        // color: "#64728c"
    },

    tablelink: {
        color: "#64728c",
        paddingRight: "3px",
        paddingLeft: "3px"

    },

    tableicon: {
        color: "#808080",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },

    pagetittle: {
        fontSize: "14px",
        fontFamily: "sans-serif",
        fontWeight: "700",
        color: "#00000099"

    },

    detaillabel: {
        fontSize: "16px",
        fontWeight: "600",
    },

    detailinfo: {
        fontSize: "14px",
        paddingLeft: "5px",
        // color:"red",
    },

    buttoncard: {
        // margin: theme.spacing(1),
        backgroundColor: "red"

    },


    // ####################### card style dashboard 
    pageheading: {
        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "20px",
        fontWeight: "700",
    },

    pagetittlestyle: {
        paddingBottom: "5px !important",
    },

    horizontalline: {
        marginTop: "-3px !important",
    },

    pagetittle_container: {
        paddingTop: "4px !important",
    },

    pagetittle_position: {
        position: "absolute",
        paddingTop: "20px !important",
    },
    amountweight: {
        fontWeight: "100 !important"
    },


});




export default pentaonal(withStyles);





