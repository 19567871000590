import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const MyDataTable = () => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false); // State to track select all checkbox
    const [selectedExecutive, setSelectedExecutive] = useState('');
    const [data, setData] = useState([]);
    const [executives, setExecutives] = useState([]); // State for storing executives


    useEffect(() => {
        // Fetch user data from the API
        axios.get('https://jsonplaceholder.typicode.com/users')
            .then((response) => {
                setData(response.data);

                // Extract names from user data for the select options
                const names = response.data.map(user => user.name);
                setExecutives(names);
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    }, []);

    const columns = [
        {
            name: 'selectRow',
            label: (
                <label>
                    <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={() => {
                            if (selectAll) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows(data.map((_, index) => index));
                            }
                            setSelectAll(!selectAll);
                        }}
                    />

                </label>
            ),
            options: {
                filter: false,
                sort: false,
                customBodyRender: (_, tableMeta) => (
                    <input
                        type="checkbox"
                        checked={selectedRows.includes(tableMeta.rowIndex)}
                        onChange={() => handleRowSelect(tableMeta.rowIndex)}
                    />
                ),
            },
        },
        {
            name: 'name',
            label: 'Name',
        },
        {
            name: 'email',
            label: 'Email',
        },
    ];

    const handleRowSelect = (rowIndex) => {
        if (selectedRows.includes(rowIndex)) {
            setSelectedRows(selectedRows.filter((index) => index !== rowIndex));
        } else {
            setSelectedRows([...selectedRows, rowIndex]);
        }
    };

    const handleCheckAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(data.map((_, index) => index));
        }
        setSelectAll(!selectAll);
    };

    const handleExecutiveSelect = (event) => {
        setSelectedExecutive(event.target.value);
    };

    const handleSubmit = () => {
        const selectedData = selectedRows.map((rowIndex) => data[rowIndex]);

        // Create the data object to send to the API
        const requestData = {
            selectedData,
            selectedExecutive,
        };

        // Send the data to the specified API URL
        axios.post('http://localhost:3003/users', requestData)
            .then((response) => {
                console.log('Data submitted:', response.data);
                // Reset the selection
                setSelectedRows([]);
                setSelectAll(false);
            })
            .catch((error) => {
                console.error('Error submitting data:', error);
            });
    };

    return (
        <div>
            <select
                id="executives"
                name="executives"
                form="executiveform"
                style={{ width: '200px', marginRight: '10px' }}
                onChange={handleExecutiveSelect}
                value={selectedExecutive}
            >
                <option value="">Select Executive</option>
                {executives.map((executive, index) => (
                    <option key={index} value={executive}>
                        {executive}
                    </option>
                ))}
            </select>

            {/* <Autocomplete
                id="executives"
                options={executives}
                value={selectedExecutive}
                onChange={handleExecutiveSelect}
                renderInput={(params) => <TextField {...params} label="Select Executive" />}
            /> */}

            <MUIDataTable
                data={data}
                columns={columns}
                options={{
                    selectableRows: 'multiple',
                }}
            />
            <button onClick={handleSubmit}>Submit Selected Data</button>
            <input
                type="checkbox"
                checked={selectAll}
                onChange={handleCheckAll}
            />
        </div>
    );
};

export default MyDataTable;
