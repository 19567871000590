import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MuidatatableCreateaform from "./MuidatatableCreateaform";
import Addform from "./Addform";
import AddformUpdate from "./AddformUpdate";
import AddformUpdate2 from "./AddformUpdate2";
import Autocompletselectoption from "./Autocompletselectoption";
import AutocompletselectoptionTableRow from "./AutocompletselectoptionTableRow.js";
import axios from "axios";
import { customTheme } from "../../../../Sidebar/Style/Styledatatable.js";
import useStyles from "../../../../Sidebar/Style/StyleFunctionComponent.js";

import ShowhideProduct from "./ShowhideProduct";
import Displaydata from "./Displaydata";
import NewTractorBrandAPI from "./NewTractorBrandAPI";
import TractorBrandsandModels from "./TractorBrandsandModels";
import Muisettingicontoggle from "./muisettingicontoggle";
import Muiexportdata from "./muiexportdata";
import Muinesteddata from "./muinesteddata";
import Muicheckform from "./Muicheckform";
import BearstokenAPI from "./BearstokenAPI";
import Datatablejwt from "./Datatablejwt";
import Login from "../mui/LoginJWT/login";
import Home from "../mui/LoginJWT/home.js";




const ListTable = () => {
    const [data, setData] = useState([]);
    const { id, name, email, phone, city, } = useParams();
    // const { id,  } = useParams();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:3003/users");
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:3003/users/${id}`);
            fetchData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const columns = [
        {
            name: "id",
            label: "ID",
        },
        {
            name: "name",
            label: "Name",
        },
        {
            name: "email",
            label: "Email",
        },
        {
            name: "phone",
            label: "Phone",
        },

        {
            name: "city",
            label: "address",
        },


        {
            name: "actions",
            label: "Actions",
            options: {
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowData = data[rowIndex];
                    return (
                        <>

                            {/* <Link style={{ color: "red" }} to={`/app/update/${rowData.id}`}>
                                Update
                            </Link>

                            <Link style={{ color: "red", padding: "5px" }} to={`/app/view/${rowData.id}`}>
                                view
                            </Link>
                            <button onClick={() => handleDelete(rowData.id)}>
                                Delete
                            </button> */}


                            <div style={{ display: "flex", }} >
                                <Link to={`/app/QuotationBookReportView/${rowData.id}`} > <VisibilityIcon className={classes.tableicon} /> Q</Link>
                                <Link to={`/app/view/${rowData.id}`} > <VisibilityIcon className={classes.tableicon} /></Link>
                                <Link to={`/app/update/${rowData.id}`} className={classes.tablelink}><EditIcon fontSize='small' className={classes.tableicon} /> </Link>
                                <Link onClick={() => handleDelete(rowData.id)} > <DeleteIcon className={classes.tableicon} /></Link>
                            </div>
                        </>
                    );
                },
            },
        },
    ];

    const options = {
        selectableRows: "none",
    };
    const currentTheme = customTheme(true);
    const classes = useStyles();
    return (
        <div>
            <Login />
            <Datatablejwt />
            <br />
            <br />
            {/* <BearstokenAPI /> */}
            {/* <Home /> */}
            <Muicheckform />
            {/* <Muisettingicontoggle /> */}
            <Muinesteddata />
            <Muiexportdata />
            {/* <Addform /> */}
            <br />
            <br />
            {/* <AddformUpdate /> */}
            <br />
            {/* <MuidatatableCreateaform /> */}
            <h2>MUI DataTable</h2>
            {/* <MuiThemeProvider theme={customTheme()}>
                <MUIDataTable
                    title={"Data Table"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider> */}
            {/* <br />
            <br />
            <br />
            <Autocompletselectoption />
            <br />
            <br />
            <AddformUpdate2 />
            <br />
            <br />
            <AutocompletselectoptionTableRow /> */}


            <ShowhideProduct />
            <br />
            <br />
            <br />

            <Displaydata />
            <NewTractorBrandAPI />

            <TractorBrandsandModels />

        </div>
    );
};

export default ListTable;


