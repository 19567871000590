import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import classNames from "classnames";
import { Breadcrumbs, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { style } from '@mui/system';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@mui/icons-material/Home';
import GrainIcon from '@mui/icons-material/Grain';
import SearchIcon from '@material-ui/icons/Search';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import ClearIcon from '@material-ui/icons/Clear';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { BorderLeft } from '@material-ui/icons';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import DateRangeIcon from '@material-ui/icons/DateRange';
import useStyles from '../../../../StyleComponents/StyleFunctionComponent';

import { toWords } from 'number-to-words-en';
import { useHistory } from "react-router-dom";





const App = () => {

    const [words, setWords] = useState('');

    // ############### axios start
    const [user, setUser] = useState({
        // array1: [], array2: [], array3: [],
        contacts: [
            {
                type: "",
                value: "",
                id: "",
                name: "",
                age: "",
            },
        ],
        usernajme: "",
        name: "",
        username: "",
        email: "",
        phone: "",
        webiste: "",
        address: {
            geo: {
                lat: "",
            }
        }

    });

    const history = useHistory();

    const rowData = user;

    const { id } = useParams();

    useEffect(() => {
        loadUser();
    }, []);
    const loadUser = async () => {
        const res = await axios.get(`http://localhost:3003/users/${id}`);
        setUser(res.data);
    };


    // ############### axios end


    // useEffect(() => {
    //     axios.get(`http://localhost:3003/users/${id}`)
    //         .then(response => {
    //             setUser(response.data);
    //         })
    //         .catch(error => console.error('Error fetching data:', error));
    // }, [id]);




    const classes = useStyles();

    const [formFields, setFormFields] = useState([
        { name: '', age: '' },
    ])

    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
    }

    const submit = (e) => {
        e.preventDefault();
        console.log(formFields)
    }

    // const addFields = () => {
    //     let object = {
    //         name: '',
    //         age: ''
    //     }

    //     setFormFields([...formFields, object])
    // }


    const addFields = () => {
        const object = {
            name: '',
            age: ''
        }

        setFormFields([...formFields, object])
    }



    // const handleAddField = () => {
    //     const newFieldName = `field${Object.keys(formData).length + 1}`;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [newFieldName]: '',
    //     }));
    // };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`http://localhost:3003/users/${id}`);
            // window.location.reload();
            history.push("/app/QuotationBookReport");
        } catch (error) {
            console.error("Error deleting data:", error);
        }

    };


    const removeFields = (index) => {
        let data = [...formFields];
        data.splice(index, 1)
        setFormFields(data)
    }


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // ################## words s
    useEffect(() => {
        if (user && user.address.geo.lng) {
            setWords(toWords(user.address.geo.lng));
        }
    }, [user]);

    // ################## words end


    return (
        <>

            <Grid container spacing={1}>
                <Grid item xs={12} lg={3}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={6} lg={6}>
                                    <Typography className={classes.tabletittle} >
                                        Customer Profile
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} lg={6} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                                    {/* <AddUpdateUserEditForm /> */}
                                </Grid>
                            </Grid>
                            <hr />
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                {/* adjective : {formData.title} */}
                                Name : {user.name}
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                State : Bihar
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                City : Bhopal
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Taluka: Mau
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Village : Pachore
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Pincode : {user.address.geo.lng}
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Mobile No: {user.address.zipcode}
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Email :
                            </Typography>
                            <Typography className={classes.breadcrumbslinkHightlight}>
                                Registered on: 18-Sep-2023 05:26:27 PM
                            </Typography>


                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={4} lg={4}>
                                    <Typography className={classes.tabletittle} >
                                        Update-FollowUp Information
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} lg={8} style={{ margin: "auto", padding: "auto", textAlign: "right" }}>
                                    <Button
                                        style={{ height: "20px", fontSize: "12px", }}
                                        className={classes.buttonform}
                                        variant="outlined" >
                                        <span style={{ fontWeight: "bold" }}>  Enquiry ID: 863961 Date : 19-Sep-2023 </span>

                                    </Button>
                                </Grid>
                            </Grid>
                            <hr />


                            <Grid container rowSpacing={0} columnSpacing={{ xs: 12, sm: 2, md: 3 }}>
                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                    <p>Status: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={6} md={6}>
                                    <p>Executive: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> Enquiry Source: <span className={classes.breadcrumbslinkHightlight}>{user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p> Enquiry For: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Model: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Occupation: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Enquiry Type: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <hr />
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4} md={4}>
                                    <p>State: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4} md={4}>
                                    <p>District: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4} md={4}>
                                    <p>Taluka: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Village: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Pincode: <span className={classes.breadcrumbslinkHightlight}> {user.address.zipcode} </span></p>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <hr />
                                </Grid>
                                <Grid item xs={6} sm={4} lg={4} md={4}>
                                    <p> Your Farm Land: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid item xs={8} sm={8} lg={8} md={8}>
                                    <p>Farm Unit: <span className={classes.breadcrumbslinkHightlight}> {user.address.zipcode} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Tractor Owner: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Crops: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Loan: <span className={classes.breadcrumbslinkHightlight}> {user.name}</span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Downpayment: <span className={classes.breadcrumbslinkHightlight}> {user.address.zipcode}  </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={8} sm={8} lg={8} md={8}>
                                    <p>Purpose: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>WhatsApp Number: <span className={classes.breadcrumbslinkHightlight}> {user.address.zipcode}  </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={8} sm={8} lg={8} md={8}>
                                    <p>Telegram Number: <span className={classes.breadcrumbslinkHightlight}> {user.address.zipcode}  </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Exchange ?: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={6} sm={4} lg={4} md={4}>
                                    <p>Exchange To: <span className={classes.breadcrumbslinkHightlight}> {user.name} </span></p>
                                </Grid>
                                <Grid style={mystyle} item xs={12} sm={12} lg={12} md={12}>
                                    <p>Enter Remark: <span className={classes.breadcrumbslinkHightlight}> {user.name} {user.address.zipcode}  </span></p>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} >
                                    <hr />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={3}>

                    <Card >
                        <CardContent>
                            <Grid container spacing={1} style={{ marginTop: "-10px" }}>
                                <Grid item xs={6} lg={6}>
                                    <Typography className={classes.tabletittle} >
                                        FollowUp History
                                    </Typography>
                                </Grid>
                            </Grid>
                            <hr />

                            <Card variant="outlined" style={{ padding: "10px" }}>
                                <Typography className={classes.breadcrumbslinkHightlight}>
                                    <DateRangeIcon style={{ marginTop: "-10px", paddingTop: "8px" }} />   18-Sep-2023 06:40:25 PM
                                </Typography>
                                <hr />
                                <p>Status: Interested
                                </p>
                                <Typography className={classes.breadcrumbslinkHightlight}>
                                    Date: 10-Sep-2023
                                </Typography>
                                <Typography className={classes.breadcrumbslinkHightlight}>
                                    Executive: Harish Nikole
                                </Typography>

                            </Card>
                            <br />
                        </CardContent>
                    </Card>



                </Grid>

            </Grid>


        </>



    );
};

export default App;









const mystyle = {
    marginTop: "-10px"
};


