import React, { Component, useState, Fragment, useEffect, useRef, useMemo } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import LightModeIcon from '@mui/icons-material/LightMode';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, FormControl, Drawer, Menu, Paper, Select, MenuItem, TableRow } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Card from '@material-ui/core/Card';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { PostAddOutlined } from '@mui/icons-material';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';
import ApprovalIcon from '@mui/icons-material/Approval';
import FilterListIcon from '@mui/icons-material/FilterAlt';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import AddUpdateUser from "../../AddUpdateUser/AddUpdateUser";
// import SearchUserform from "../../SearchUser/SearchUserform";
import useStyles from '../../../StyleComponents/StyleFunctionComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccordionSummary from '@mui/material/AccordionSummary';
import SettingsIcon from '@mui/icons-material/Settings';

import Skeleton from '@material-ui/lab/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';

import { API_BASE_URL, token, userLevel, adminId } from '../../../../constants';
import { TableFooter, TableCell } from "@material-ui/core";




const QuotationBookReport = () => {
    const history = useHistory();
    const [openSidebar, setOpenSidebar] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [perPage, setPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 30);

    const [fromDate, setFromDate] = useState(sevenDaysAgo.toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(currentDate.toISOString().split('T')[0]);
    const [enquirySource, setEnquirySource] = useState([]);

    const [selectedExecutive, setSelectedExecutive] = useState(null);
    const [executiveOptions, setExecutiveOptions] = useState([]);
    const [selectedState, setSelectedState] = useState();
    const [stateOptions, setStateOptions] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState();
    const [brandOptions, setBrandOptions] = useState([]);




    useEffect(() => {
        const fetchDatabrand = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/brand-list`, config);

                const statelist = response.data.data.map(item => ({
                    id: item.brand_id,
                    title: item.brand_name,
                }));

                setBrandOptions(statelist);

            } catch (error) {
                console.error('Error fetching state:', error);
            }
        };

        fetchDatabrand();
    }, []);




    useEffect(() => {
        const fetchDatastate = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/state-list`, config);
                const statelist = response.data.data.map(item => ({
                    id: item.state_id,
                    title: item.state_name,
                }));

                setStateOptions(statelist);

            } catch (error) {
                console.error('Error fetching state:', error);
            }
        };

        fetchDatastate();
    }, []);

    useEffect(() => {
        const fetchDatalist = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity, // Request will never time out
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/executive-list`, config);
                const mappedData = response.data.data.map(item => ({
                    id: item.admin_id,
                    title: item.username,
                }));
                // Include an option for null or empty value
                const optionsWithNull = [
                    { id: null, title: 'Select Executive' },
                    ...mappedData,
                ];

                setExecutiveOptions(optionsWithNull);
                // setExecutiveOptions(mappedData);

            } catch (error) {
                console.error('Error fetching executives:', error);
            }
        };

        fetchDatalist();
    }, []);

    const handleExecutiveChange = (event, newValue) => {
        setSelectedExecutive(newValue);
    };

    const handleStateChange = (event, newValue) => {
        setSelectedState(newValue);
    };

    const handleBrandChange = (event, newValue) => {
        setSelectedBrand(newValue);
    };



    const totalSumPriceColumn = data.reduce((acc, item) => acc + (item.price_count || 0), 0);
    const totalSumDemoColumn = data.reduce((acc, item) => acc + (item.demo_count || 0), 0);
    const totalSumBookingColumn = data.reduce((acc, item) => acc + (item.booking_count || 0), 0);

    // const totalSumTotalColumn = data.reduce((acc, item) => acc + (total || 0), 0);

    const totalSumTotalColumn = data.reduce((acc, item) => acc + (item.total || 0), 0);

    const fetchData = async (from, to, pageNumber, searchQuery,) => {
        setIsLoading(true);
        setError(null); // Reset error state

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `${token}`,
                },
                timeout: Infinity, // Request will never time out

            };

            const requestData = {
                start_date: fromDate,
                end_date: toDate,
                pagination: perPage,
                page: page + 1,
                search: search,
                user_level: userLevel,
                admin_id: adminId,

            };

            const response = await axios.post(`${API_BASE_URL}/api/admin/v2/harvester-brand-stat-report`, requestData, config);

            const mappedData = response.data.data.data.map(item => {
                const price = item.price_count || 0;
                const demo = item.demo_count || 0;
                const booking = item.booking_count || 0;

                const total = price + demo + booking;

                return {
                    id: item.brand_id,
                    brand_name: item.brand_name,
                    price_count: item.price_count,
                    demo_count: item.demo_count,
                    booking_count: item.booking_count,
                    total: total,


                };
            });

            const sortedData = mappedData.sort((a, b) => b.id - a.id);

            setData(sortedData);
            setTotalCount(response.data.data.total);
            setIsLoading(false);

        } catch (error) {
            console.error("Error fetching data:", error);

            if (axios.isCancel(error)) {
                console.error("Request canceled:", error.message);
                // Handle canceled request (if needed)
            } else {
                if (error.code === 'ECONNABORTED') {
                    setError('The request timed out. Please try again.');
                } else if (error.response) {
                    console.error("Server Error Details:", error.response.data);
                    setError(`Error: ${error.response.status} - ${error.response.statusText}`);
                } else {
                    setError('An error occurred while fetching data.');
                }
            }

        } finally {
            setIsLoading(false);
        }
    };
    const source = axios.CancelToken.source();

    // useEffect(() => {
    //     fetchData(perPage, page, search, totalCount, enquirySource);
    //     // Cleanup function
    //     return () => {
    //         source.cancel('Request canceled by cleanup');
    //     };
    // }, [perPage, page, search, totalCount, enquirySource]);

    const sourceRef = useRef(null);

    useEffect(() => {
        // Create a new cancel token source
        sourceRef.current = axios.CancelToken.source();

        fetchData(
            perPage,
            page,
            search,
            totalCount,
            selectedExecutive,
            selectedBrand,
            selectedState,
            enquirySource,
            sourceRef.current
        );

        // Cleanup function
        return () => {
            // Cancel the request when the component unmounts
            sourceRef.current.cancel('Request canceled by cleanup');
        };
    }, [perPage, page,], 2000); // Empty dependency array



    const handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fromDate') {
            setFromDate(value);
        } else if (name === 'toDate') {
            setToDate(value);
        }
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
    };

    const handlePrevious = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };

    const handleNext = () => {
        setPage(page + 1);
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setPage(0);
    //     fetchData(fromDate, toDate, selectedExecutive, selectedBrand, selectedState,);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        // fetchData(fromDate, toDate,);
        fetchData(perPage, page, search, totalCount, selectedExecutive, selectedBrand, selectedState, enquirySource);

    };


    const clearForm = () => {
        window.location.reload();
        setSearch(''); // Reset search state to empty
    };


    const columns = [
        {
            name: 'id',
            label: 'S/N',
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                }),

            },
        },
        {
            name: 'brand_name', options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
            label: "Brand",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Customize the rendering of the cell content here
                    return (<>
                        <Link to={`/app/HarvesterModelsStatReports/${tableMeta.rowData[0]}`} >
                            {value}
                        </Link>
                    </>
                    );
                },
            },
        },

        {
            name: "price_count",
            label: "On Road Price",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "demo_count",
            label: "Demo",
        },

        {
            name: "booking_count",
            label: "Book Now ",
        },
        {
            name: "total",
            label: "Total",
        },




    ];

    const CustomToolbarAction = ({ toggleFilter }) => (
        <IconButton aria-label="Filter list" onClick={toggleFilter}>
            <FilterListIcon onClick={toggleSidebar} />
        </IconButton>
    );


    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.shiftKey && event.key === 'F') {
                // Shift + D is pressed, open the sidebar
                setOpenSidebar(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };



    const options = {
        rowsPerPage: perPage,
        selectableRows: 'none',
        page: page,
        serverSide: true,
        count: totalCount,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setPerPage(tableState.rowsPerPage);
                    setPage(0);
                    break;
                default:
                    break;
            }
        },

        customToolbar: () => <CustomToolbarAction />,
        filter: false,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 1000, 10000, { value: totalCount, label: 'All' }],
        customFilterDialogFooter: (currentFilter, applyNewFilter) => (
            <div>
                <TextField
                    label="Filter Data"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    fullWidth
                />
                <Button onClick={applyNewFilter} variant="contained" color="primary">
                    Apply Filter
                </Button>
                <Button onClick={() => setOpenSidebar(false)} variant="contained" color="secondary">
                    Cancel
                </Button>
            </div>
        ),

        customTableBodyFooterRender: () => {
            return (
                <>
                    <TableFooter className={classes.footerCell}>
                        <TableRow>
                            <TableCell colspan="2">
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                {totalSumPriceColumn}
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                {totalSumDemoColumn}
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                {totalSumBookingColumn}
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                {totalSumTotalColumn}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </>
            );
        }


    };
    const currentTheme = customTheme(true);
    const classes = useStyles();
    const memoizedData = useMemo(() => data, [data]);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                {/* <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Add Bulk Enquiries
                </Typography> */}
                <Grid container>
                    <Grid item xs={6} style={{ marginTop: "8px" }}>
                        <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                            Harvester Brands Stat Reports
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ margin: "auto", padding: "auto", textAlign: "right", marginBottom: "5px" }}>

                        {/* <AddBulkUploadform /> */}
                    </Grid>
                </Grid>
                <hr className={classes.horizontalline} />
            </Box>
            <MuiThemeProvider theme={currentTheme}>
                {
                    isLoading ? (
                        <LinearProgress />

                    ) : (
                        ""
                    )
                }

                <MUIDataTable
                    title={
                        isLoading ? (
                            <Skeleton animation="wave" />

                        ) : (
                            <Typography className={classes.tabletittle} >
                                Harvester Brands Stat Reports
                            </Typography>
                        )
                    }
                    data={memoizedData}
                    columns={columns}
                    options={options}
                />

            </MuiThemeProvider>

            {/* <MuiThemeProvider theme={currentTheme}>
                {isLoading ? (
                    <div className={classes.progress}>
                        <div
                            className={classes.loadingSpinner}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '20vh',
                            }}
                        >
                            <CircularProgress style={{ color: 'red' }} />
                        </div>
                    </div>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <MUIDataTable
                        title={
                            <Typography className={classes.tabletittle} >
                                Interested
                            </Typography>
                        }
                        data={data}
                        columns={columns}
                        options={options}
                    />
                )}
            </MuiThemeProvider> */}
            <Drawer anchor="right" open={openSidebar}

                PaperProps={{
                    style: {
                        width: "100%",
                        maxWidth: 300,
                        padding: "10px"
                    }
                }}

                onClose={() => setOpenSidebar(false)}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2} lg={12} style={{ textAlign: "right", cursor: "pointer", }}>

                            <ClearIcon style={{ color: '#c0392b' }} onClick={() => setOpenSidebar(false)} />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={12} >
                            <br />
                            <Typography className={classes.tabletittle} >
                                Filter
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.root} style={{ paddingRight: "22px", paddingLeft: "22px" }}>
                        <div className={classes.root}>
                            <Grid container spacing={1}>
                                <Grid item xs={4} sm={2} lg={12} >
                                    <input className={classes.inputform} type="date" name="fromDate" value={fromDate} onChange={handleDateChange} />
                                </Grid>
                                <Grid item xs={4} sm={2} lg={12}>
                                    <input className={classes.inputform} type="date" name="toDate" value={toDate} onChange={handleDateChange} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <br />
                    <Grid >
                        <Grid item xs={4} sm={2} lg={12} style={{ margin: "auto", padding: "auto", textAlign: "center" }}>
                            {/* <button className={classes.buttonform} type="submit">Fetch Data</button> */}
                            <Button
                                type='submit'
                                style={{ marginRight: "10px" }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ApprovalIcon />}>
                                Apply
                            </Button>
                            <Button
                                type="button" onClick={clearForm}
                                style={{ backgroundColor: "#dc3545", borderColor: '#dc3545' }}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>

                        </Grid>
                    </Grid>
                    <br />
                </form>
            </Drawer >

        </>
    );
};

export default QuotationBookReport;








