import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Card,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// styles
import useStyles from "./styles";

// logo HRVerse@2x.png
// import logo from "./logo.svg";
import logo from "./HRVerse@2x.png";

import google from "../../images/google.svg";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { BorderColor } from "@material-ui/icons";
import { margin } from "@mui/system";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  // var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  // var [loginValue, setLoginValue] = useState("admin@flatlogic.com");
  // var [passwordValue, setPasswordValue] = useState("password");






  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://khetigaadi.com/admin/api/v1/login', {
        // username: loginValue,
        email: loginValue,
        password: passwordValue,
      });

      if (response.data.error) {
        setError(response.data.message);
      } else {
        const newToken = response.data.data.token;
        const userlevel = response.data.data.user_level;
        const adminid = response.data.data.admin_id;
        const adminemail = response.data.data.admin_email;
        const userName = response.data.data.username;

        localStorage.setItem('token', newToken);
        sessionStorage.setItem('token', newToken);

        localStorage.setItem('user_level', userlevel);
        sessionStorage.setItem('user_level', userlevel);

        localStorage.setItem('admin_id', adminid);
        sessionStorage.setItem('admin_id', adminid);

        localStorage.setItem('admin_email', adminemail);
        sessionStorage.setItem('admin_email', adminemail);

        localStorage.setItem('username', userName);
        sessionStorage.setItem('username', userName);



        console.log('Admin Logged In Successfully');
        history.push('/app/dashboard');
        window.location.reload();

      }
    } catch (error) {
      console.error('An error occurred while logging in:', error);
    }
  };


  return (



    <Grid container className={classes.container} style={{ backgroundColor: "#4db8ff", color: "#FFFFFF" }}>
      <div className={classes.logotypeContainer} style={{ borderRadius: "0px 40% 30px 0px", backgroundImage: "linear-gradient(to bottom right,#FFFFFF,  #FFFFFF)" }} >
        {/* <img src={logo} alt="logo" className={classes.logotypeImage} /> */}
        <Typography className={classes.logotypeText} style={{ color: "#1C6BFE", fontSize: "60px" }}>
          Admin Panel Login
        </Typography>
        <img src="https://media.istockphoto.com/id/1281150061/vector/register-account-submit-access-login-password-username-internet-online-website-concept.jpg?s=612x612&w=0&k=20&c=9HWSuA9IaU4o-CK6fALBS5eaO1ubnsM08EOYwgbwGBo="
          alt="logo"
          // className={classes.logotypeImage}
          style={{ width: "auto", height: "65%", objectFit: "contain" }}
        />

      </div>

      <div className={classes.formContainer} >
        <div className={classes.form} style={{ backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px" }}>

          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="Secondary"
            // textColor="Secondary"
            style={{ color: "#444444" }}
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
            <Tab label="New User" classes={{ root: classes.tab }} />
          </Tabs>


          {activeTabId === 0 && (
            <React.Fragment>
              {/* Your existing JSX code... */}
              <form onSubmit={handleLogin}>
                {/* Your existing form fields... */}
                <input
                  required
                  id="email"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={loginValue}
                  onChange={(e) => setLoginValue(e.target.value)}
                  margin="normal"
                  placeholder="Email Address"
                  type="text"
                  fullWidth
                  style={mystyle}
                />
                <input
                  required
                  id="password"
                  InputProps={{
                    classes: {
                      underline: classes.textFieldUnderline,
                      input: classes.textField,
                    },
                  }}
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  margin="normal"
                  placeholder="Password"
                  type="password"
                  fullWidth
                  style={mystyle}
                />
                {/* Your existing login button... */}
                <br />
                <br />

                <Button
                  disabled={loginValue.length === 0 || passwordValue.length === 0}
                  onClick={handleLogin}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#008acc", color: "#fff" }}
                >
                  Login
                </Button>
              </form>
              {error && <p style={{ color: "red" }}>  Error: {error}</p>}
            </React.Fragment>
          )}

          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h6" className={classes.subGreeting}>
                Welcome!     Create your account
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <input
                id="name"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                margin="normal"
                required
                placeholder="First Name "
                type="text"
                fullWidth
                style={mystyle}

              />
              <input
                id="name"
                required
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                margin="normal"
                placeholder="Last Name "
                type="text"
                fullWidth
                style={mystyle}
              />
              <input
                id="name"
                required
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={nameValue}
                onChange={e => setNameValue(e.target.value)}
                margin="normal"
                placeholder="Mobile "
                type="number"
                fullWidth
                style={mystyle}

              />
              <input
                id="email"
                required
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
                style={mystyle}
              />
              {/* <TextField */}
              <input
                id="password"
                required
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
                style={mystyle}
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (

                  <Button
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    disabled={
                      loginValue.length === 0 ||
                      passwordValue.length === 0 ||
                      nameValue.length === 0
                    }
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                    style={{ backgroundColor: "#008acc", color: "#fff", }}
                  >
                    Create your account
                  </Button>
                )}
              </div>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div>
            </React.Fragment>
          )}
        </div>

        <Typography color="primary" className={classes.copyright}>
          © 2015-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="#" rel="noopener noreferrer" target="_blank">BIZVERSE  Software</a>. All rights reserved.
        </Typography>
      </div>

    </Grid>
  );
}

export default withRouter(Login);





const mystyle = {
  color: "grey",
  padding: "10px",
  fontFamily: "Arial",
  width: "100%",
  borderRadius: 6,
  borderColor: 'grey.500',
  border: '1px solid grey',
  marginTop: 10,

};




