import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { API_BASE_URL, token } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import AddUpdateUser from "../AddUpdateUser/AddUpdateUser";
import useStyles from "../../../StyleComponents/StyleFunctionComponent";

const AutocompleteSearch = () => {
    const history = useHistory();
    const classes = useStyles();

    const [options, setOptions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        let debounceTimer;

        const searchUser = async () => {
            try {
                setLoading(true);

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `${token}`,
                    },
                    timeout: Infinity,
                };

                const payload = {
                    "mobile": searchValue,
                };

                const response = await axios.post(`${API_BASE_URL}/api/admin/v2/user-search`, payload, config);

                const userData = response.data.data;

                const userList = userData.map(user => ({
                    id: user.user_id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    usermobile: user.mobile,
                }));

                setOptions(userList);
            } catch (error) {
                setError(error.message || 'Error fetching users');
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        // Debounce function to limit API calls
        const debounceSearch = () => {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                searchUser();
            }, 6000); // Adjust the delay as needed
        };

        if (searchValue.trim() !== '') {
            debounceSearch();
        } else {
            setOptions([]);
        }

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [searchValue]);

    const handleSelectChange = (event, selectedOption) => {
        setSelectedItem(selectedOption);

        // Redirect to a new page with the selected user_id
        if (selectedOption) {
            // history.push(`/app/AddNewEnquiry/${selectedOption.id}`);
            history.push(`/app/UserPending/${selectedOption.id}`);
        }
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={3} sm={3}>
                    <Autocomplete
                        classes={{
                            option: classes.autocompleteoption,
                        }}
                        options={options}
                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.usermobile}`}
                        value={selectedItem}
                        onChange={handleSelectChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                onChange={(e) => setSearchValue(e.target.value)}
                                error={!!error}
                                helperText={error}
                                placeholder='Global Search'
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
                        loading={loading}
                        // noOptionsText="No data available"
                        noOptionsText="No User Found 🥺"
                        loadingText="Loading Please wait 🤚..."


                    />
                </Grid>
                <Grid item xs={4} sm={2} lg={1} >
                    <div style={{ paddingTop: "8px" }}>
                        <AddUpdateUser />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default AutocompleteSearch;

