import React, { useState, useEffect } from 'react';

const Apps = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Function to fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch("https://hub.dummyapis.com/employee?noofRecords=10&idStarts=1000000");
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const fetchedData = await response.json();
                localStorage.setItem('jsonPlaceholderData', JSON.stringify(fetchedData));
                setData(fetchedData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        // Check if data is cached in localStorage
        const cachedData = localStorage.getItem('jsonPlaceholderData');

        if (cachedData) {
            // If cached data exists, use it
            setData(JSON.parse(cachedData));
            setIsLoading(false);

            // Fetch new data in the background and update the cache
            fetchData();
        } else {
            // If no cached data, fetch data from the API
            fetchData();
        }
    }, []);

    return (
        <div>
            <h1>JSONPlaceholder Data</h1>
            {isLoading ? (
                <p>Loading data...</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Last Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.lastName}</td>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.address}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Apps;
