import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { red } from "@mui/material/colors";

export default makeStyles(theme => ({



    typestrip: {
        // color: 'white',
        border: 'none',
        borderRadius: '6px',
        padding: '2px 6px',
        cursor: 'pointer',
        width: "80px",
        fontWeight: "bold"

    },

    brandstrip: {
        // color: 'white',
        border: 'none',
        borderRadius: '6px',
        padding: '2px 6px',
        // cursor: 'pointer', 
        width: "130px",
        fontWeight: "bold"

    },


    breadcrumbslink: {
        // color: "#00000099",
        color: "#64728c",
        // color: "red",
        fontSize: "11px",
        // fontFamily: 'robot',
        fontWeight: "100",

    },


    popoverdetail: {
        color: '#00000099',
        // color: "#64728c",
        fontSize: '13px',
        fontFamily: 'sans-serif',
        fontWeight: '600'
    },
    breadcrumbslinkHightlight: {
        // color: '#00000099',
        color: "#64728c",
        fontSize: '13px',
        fontFamily: 'sans-serif',
        fontWeight: '500'
    },
    labelform: {
        padding: "10px",
        fontFamily: "sans-serif",
        // color: "#64728c",
        fontSize: "12px",
        fontWeight: "600",
        color: "#37474F",

    },

    inputform: {
        width: "100%",
        border: '1px solid #e8eaef',
        borderRadius: '4px',
        padding: "10px",
        height: "35px",
        marginTop: "4px",
        fontFamily: "sans-serif",
        color: "#64728c",
        fontSize: "12px",

    },

    selectform: {
        width: "100%",
        border: '1px solid #e8eaef',
        borderRadius: '4px',
        height: "35px",
        fontFamily: "sans-serif",
        marginTop: "4px",
        fontSize: "12px",
        color: "#64728c",

    },
    listbox: {
        width: "100%",
        fontFamily: "sans-serif",
        color: "#64728c",
        fontSize: "12px",
        margin: 0,
        padding: 0,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        // backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
        overflow: 'auto',
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li.Mui-focused': {
            backgroundColor: '#4a8df6',
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },

    },






    bridheader: {
        paddingLeft: "20px",
        paddingTop: "10px"

    },

    bridheaderfirst: {
        paddingTop: "10px"

    },


    buttonform: {
        borderRadius: 6,
        backgroundColor: "#007bff",
        padding: "2px 12px",
        fontSize: "14px",
        borderColor: "#007bff",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            backgroundColor: '#026fe3'
        },
        height: "30px"
    },



    buttonred: {
        borderRadius: 6,
        backgroundColor: "#dc3545",
        padding: "2px 12px",
        fontSize: "14px",
        borderColor: "#dc3545",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            borderColor: "#ca1c2d",
            backgroundColor: '#ca1c2d',
        }
    },
    muitablebutton: {
        borderRadius: 6,
        backgroundColor: "#fff",
        padding: "2px 12px",
        fontSize: "14px",
        borderColor: "#dc3545",
        color: "#dc3545",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            borderColor: "#dc3545",
            backgroundColor: '#dc3545',
            color: "#fff",
        }
    },
    buttongreen: {
        // backgroundColor: "#dc3545",
        padding: "2px 10px",
        fontSize: "12px",
        // borderColor: "#dc3545",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",


        borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '0px',
        borderTopRightRadius: '0px',




    },
    buttongrey: {
        borderBottomRightRadius: '6px',
        borderTopRightRadius: '6px',
        // backgroundColor: "#dc3545",
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
        padding: "2px 10px",
        fontSize: "12px",
        // borderColor: "#dc3545",
        color: "#fff",
        textTransform: "capitalize",
        fontWeight: "300",
        fontFamily: "sans-serif",
        marginTop: "4px",

    },

    tabletittle: {
        fontSize: "14px",
        // opacity: "0.9",
        // fontFamily: "sans-serif",
        // fontWeight: "700",
        // color: "#64728c"
    },

    tablelink: {
        color: "#64728c",
        paddingRight: "3px",
        paddingLeft: "3px"

    },

    muifooterfont: {
        textAlign: "right",
        // color: "rgba(0, 0, 0, 0.87)",
        fontSize: "14px",
        fontWeight: "bold"
    },

    fontblue: {
        color: "#007bff",
    },
    fontred: {
        color: "#dc3545",
    },
    tableicon: {
        color: "#808080",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },
    tableiconred: {
        color: "#dc3545",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },
    tableiconedit: {
        color: "#007bff",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },
    tableiconview: {
        color: "green",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },

    pagetittle: {
        fontSize: "14px",
        fontFamily: "sans-serif",
        fontWeight: "700",
        color: "#00000099"
    },

    detaillabel: {
        fontSize: "16px",
        fontWeight: "600",
    },

    detailinfo: {
        fontSize: "14px",
        paddingLeft: "5px"
    },

    buttoncard: {

        backgroundColor: "#007bff",
        // backgroundColor: "red",
        fontSize: "12px",
        color: "#fff",
        fontWeight: "600",
        fontFamily: "sans-serif",
        marginTop: "4px",
        "&:hover": {
            backgroundColor: '#026fe3'
        },
        // textTransform: "lowercase",
        textTransform: "uppercase",
        marginTop: "-20px",
        // opacity: "0.9",

    },


    cardtittle: {
        fontSize: "16px",
        // color: "red",
        fontWeight: 500,
        color: "#00000099",
        marginTop: "-10px",
        // opacity: "0.9",


    },


    cardparagraph: {
        fontSize: "13px",
        // color: "red",
        fontWeight: 500,
        color: "#00000099",
        marginTop: "-5px",
        opacity: "0.9",


    },

    // ####################### card style dashboard 
    pageheading: {
        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "20px",
        fontWeight: "700",

        // color: "#2c2c2c",
        // fontFamily: "sans-serif",
        // fontSize: "20px",
        // fontWeight: "500",

    },
    cardContent: {
        margin: "auto",
        padding: "auto",
        textAlign: "center",
        // backgroundColor: "red",

    },

    cardtittlecenter: {
        // color: "#4A4A4A",
        // fontFamily: "sans-serif",
        // fontSize: "22px",
        // fontWeight: "600",

        // color: "red",

        color: "#2c2c2c",
        fontSize: "25px",
        fontWeight: 500,
        fontFamily: "sans-serif",
        marginTop: "-10px",

    },

    rupeesymboll: {
        color: "#4A4A4A",
        // color: "red",
        fontSize: "12px",
        fontFamily: "sans-serif",

    },
    cardsubtittle: {
        // color: "red",
        // color: "#4A4A4A",
        // fontFamily: "sans-serif",
        // fontSize: "12px",
        // fontWeight: "500",

        color: "#2c2c2c",
        fontSize: "12px",
        fontFamily: "sans-serif",
        fontWeight: 300,
        opacity: "0.7",

    },


    detailmystyle: {
        color: "#4A4A4A",
        // color: "red",

        fontFamily: "sans-serif",
        fontSize: "13px",
        fontWeight: "500",
    },


    amontmystyle: {

        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "13px",
        fontWeight: "500",
        textAlign: "end"
    },

    amountittle: {
        color: "#4A4A4A",

        fontSize: "15px",
        fontWeight: "500",

    },
    amountstyle: {
        color: "#4A4A4A",
        fontSize: "15px",
        fontWeight: "500",
        textAlign: "end",

        // color: "red",

    },
    sectiontittle: {
        color: "#4A4A4A",
        fontFamily: "sans-serif",
        fontSize: "20px",
        fontWeight: "500",
    },

    middleboldtittle: {
        color: "#0F72CF",
        // color: "red",
        fontSize: "15px",
        fontFamily: "sans-serif",
        fontWeight: 600,
        opacity: "0.7"
    },

    // sale manu section Style 


    table_th: {
        verticalAlign: "middle",
        textAlign: "center",
        color: "#222222",
    },

    table_tr: {
        textAlign: "center",
        backgroundColor: "#8593ad !important",

    },

    table_td: {
        padding: "5px",
        height: "10px",
        textAlign: "center",

    },
    table_inter: {
        textAlign: "center",
        color: "#222222",

    },

    table_icon: {
        cursor: "pointer",
        color: "#222222"
    },

    table_footer: {
        textAlign: "center",
        color: "#222222",
        fontWeight: "700",
        // padding: "4px",
    },

    inputform_bg: {
        height: "92px !important",
        backgroundColor: "#e8f4fc",
        cursor: "not-allowed",
    },

    cursor_notallowed: {
        backgroundColor: "#e8f4fc",
        cursor: "not-allowed",
    },

    pagetittlestyle: {
        paddingBottom: "5px !important",
    },
    horizontalline: {
        marginTop: "-3px !important",
    },
    pagetittle_container: {
        paddingTop: "4px !important",
    },

    pagetittle_position: {
        position: "absolute",
        paddingTop: "20px !important",
    },

    amountweight: {
        fontWeight: "100 !important"
    },


    simpletable_tr: {
        fontWeight: "700",
        fontFamily: "sans-serif",
        fontSize: "14px",
        opacity: 0.9,
        borderRight: "none",
        borderLeft: "none",
    },

    simpletable_td: {
        fontSize: "14px",
        fontWeight: "300",
        fontFamily: "sans-serif",
        opacity: 0.9,
        borderRight: "none",
        borderLeft: "none",
    },


    menustyle: {
        cursor: 'pointer',
        marginTop: "10px",
        padding: "2px",
        color: "#34495e",
    },

    settingpaper: {
        backgroundColor: "#2c3e50",
        color: "#ecf0f1",
        width: "150px",
        marginTop: "-8px",
        marginBottom: "-8px"
    },

    settingdiv: {
        backgroundColor: "#2c3e50",
        color: "#ecf0f1"
    },



    settingdelete: {
        color: "#e74c3c",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },

    settingvisible: {
        color: "#f1c40f",
        fontSize: "20px",
        fontFamily: "sans-serif",

    },

    settingfont: {
        fontFamily: "sans-serif",
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#ecf0f1'

    },


    autocompleteoption: {
        '&:hover': {
            backgroundColor: 'lightblue', // Customize background color on hover
        },
        width: "100%",

        fontFamily: "sans-serif",
        fontSize: "12px",
        color: "#64728c",
        // color: "red",
        display: "flex"
    },
    message: {
        color: '#B80000',
        margin: 'auto',
        padding: 'auto',
        textAlign: 'center',
        position: 'absolute',
        top: '120px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textShadow: '0 0 5px rgba(184, 0, 0, 0.5)' // Glow effect
    },

}));

