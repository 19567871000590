import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './FooterMobile.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import Logo from '../../Components/Image/HRVerse@2x.png'
import { Link } from 'react-router-dom';
const div = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAh6gn: 'center',
    color: theme.palette.text.secondary,
}));

export default function FullWidthGrid() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid spacing={2} style={{ margin: "5%" }}>
                <Grid xs={12} md={12}>
                    <div style={{ marginLeft: "30%", textAlign: "center", alignItems: "center" }}>
                        <img src={Logo} style={{ width: "50%", height: "auto", objectFit: "contain", textAlign: "center" }} />
                    </div>
                </Grid>
                <Grid xs={12} md={12} style={{ paddingTop: "5%" }}>
                    <div className='mob-footer-heading'>Useful links</div>
                    {/* <h6 className='mob-footer-font'> Home</h6>
                    <h6 className='mob-footer-font'> About Us</h6>
                    <h6 className='mob-footer-font'> Startups</h6> */}
                    <div style={{ display: "flex", }}>
                        <div>
                            <h6 className='mob-footer-font'><Link to='/'> Home</Link> </h6>
                            <h6 className='mob-footer-font'> About Us</h6>
                            <h6 className='mob-footer-font'><Link to='/'>Startups </Link> </h6>
                        </div>
                        <div style={{ paddingLeft: "30%" }}>
                            <h6 className='mob-footer-font'><Link to='/'>Pricing </Link> </h6>
                            <h6 className='mob-footer-font'> <Link to='/'> Features</Link> </h6>
                            <h6 className='mob-footer-font'><Link to='/'> Blogs</Link> </h6>
                        </div>
                    </div>
                </Grid>
                {/* <Grid div xs={6} md={6} style={{ paddingTop: "10%" }}>
                    <div style={{ display: "none" }}>Useful h6nks</div>
                    <h6 className='mob-footer-font'> Pricing</h6>
                    <h6 className='mob-footer-font'> Features</h6>
                    <h6 className='mob-footer-font'> Blogs</h6>
                </Grid> */}
                <Grid div xs={12} md={12} style={{ paddingTop: "5%", margin: "auto" }}>
                    <div style={{ textAlign: "center" }} className='mob-footer-heading'>Contact Us</div>

                </Grid>
                <Grid div xs={12} md={12} style={{ textAlign: "center" }}>
                    <h6 className='mob-footer-font'><a to='mailto:connect@hrverse.com'>connect@hrverse.com</a> </h6>
                    <div style={{ textAlign: "center" }}>
                        <a to="#"> <FacebookIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                        <a to="#"> <InstagramIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                        <a to="#"> <YouTubeIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a>
                        <a to="#"> <LinkedInIcon style={{ color: "#1C6BFE", fontSize: "30px" }} /> </a></div>
                </Grid>

                <Grid div xs={4} md={6} style={{ paddingTop: "5%" }}>
                    <div className='mob-footer-heading'>Newsletter</div>

                </Grid>
                <Grid div xs={12} md={12} style={{ paddingTop: "5%" }}>

                    <form action=''>
                        <div style={{ display: "flex", margin: "auto", fontFamily: "lato" }}>
                            <input type="email" placeholder='Enter your email' style={{ backgroundColor: "", width: "100%", borderRadius: "4px", paddingLeft: "10px", display: "inline-block", border: "1px solid #707070" }} />
                            <div style={{ paddingLeft: "15px" }}>
                                <button
                                    style={{
                                        backgroundColor: "#0F89FE",
                                        color: "#FFFFFF", textAlign: "center",
                                        paddingLeft: "20px", paddingRight: "20px",
                                        borderRadius: "4px", height: "35px",
                                        fontFamily: "lato",
                                        cursor: "pointer"
                                    }}
                                    type="submit">Subscribe</button>
                            </div>

                        </div>
                    </form>
                </Grid>


                <Grid div xs={12} md={12} style={{ paddingTop: "5%", margin: "", textAlign: "" }}>
                    <h6 className='mob-footer-font' style={{ textAlign: "center" }}> Copyright ©2023 hrverse made with 🤍 in India.</h6>

                    <h6 className='mob-footer-font' style={{ textAlign: "center" }}>Privacy Policy | Terms of Service</h6>

                </Grid>
            </Grid>
        </Box>
    );
}


