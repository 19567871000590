import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import MobileUserProfile from './MobileUserProfile/MobileUserProfile';
// import SettingsIcon from '@mui/icons-material/Settings';


import './Sidebar.css';

import {
  Wallpaper as WallpaperIcon,
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  Settings as MasterIcon,
  EqualizerOutlined as EqualizerOutlinedIcon,
  Receipt as ReceiptIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Dashboard as DashboardIcon,
  Report as ReportIcon,
  Functions as FunctionsIcon,
  Timeline as TimelineIcon,
  Apps as AppsIcon,
  AccountBalance as AccountBalanceIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
// import SidebarLink from "./components/SidebarLink/SidebarLink_sub";
// import SidebarLink from "./components/SidebarLink/Sidebardropdown";
import Dot from "./components/Dot";
// import BadgeSharpIcon from '@mui/icons-material/BadgeSharp';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../../context/LayoutContext";

const structure = [

  // { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },

  {
    id: 0,
    label: "Dashboard",
    icon: <HomeIcon />,
    link: "/infra/dashboard"
  },
  {
    id: 1,
    label: "Earth-Movers",
    icon: <HomeIcon />,
    link: ""
  },

  {
    id: 2,
    label: "Enquiry Management",
    label_sub: "label_sub",
    icon: <TimelineIcon />,
    iconsub: <HomeIcon />,

    children: [
      { label: "Construction Equipment Enquiries", link: "/infra/allEnquiries" },
      { label: "Contact Dealer Enquiries", link: "/infra/ffdg" },
      {
        label: "Finance Enquiries", link: "/",
      },
    ],
    children_sub: [
      { label: " children_sub Enquiries", link: "" },
      { label: " children_sub adsdf", link: "" },
      { label: " chsdfsdf", link: "" },
    ],

  },

  {
    id: 3,
    label: "Advertise",
    label_sub: "label_sub Advertise",
    icon: <TimelineIcon />,
    children: [
      { label: "Display Banner Ads", link: "" },
      { label: "Active Campian Report", link: "" },
      { label: "Banner Size Ad Report", link: "" },
      { label: "Web-Pages Ad Report", link: "" },
      { label: "De-active Campian Report", link: "" },
      {
        label: "De-active Campian sadsdsd", link: "",
        children_sub: [
          { label: " children_sub Enquiries", link: "" },

        ],
      },

    ],
    children_sub: [
      { label: " children_sub Enquiries", link: "" },

    ],
  },

  {
    id: 4,
    label: "Video Management",
    icon: <FunctionsIcon />,
    link: ""
  },

  {
    id: 4,
    label: " FAQ Management ",
    icon: <FunctionsIcon />,
    link: ""
  },

  {
    id: 4,
    label: "Reviews",
    icon: <FunctionsIcon />,
    link: ""
  },

  // 1111111111
  {
    id: '5',
    label: 'Documents',
    children: [
      {
        id: '10',
        label: 'OSS',
        link: '/oss',
        icon: <FunctionsIcon />,
      },
      {
        id: '6',
        label: 'MUI',
        children: [
          {
            id: '8',
            label: 'index.js',
            link: '/mui-index',
            icon: <FunctionsIcon />,
          },
        ],
      },
    ],
  },

  // ##############################


];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (

    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>

          <ArrowBackIcon
            style={{ color: "#ffffff" }}
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List >

        {/* <List className={classes.sidebarList} >
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List> */}


        {/* <div className="sidebar_profile" style={{ marginTop: "-35px" }}>
          <MobileUserProfile />
          <List className={classes.sidebarList} >
            {master.map(link => (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            ))}
          </List>

          <hr />

        </div> */}

        <List className={classes.sidebarList}
          style={{
            paddingLeft: "5px",
            paddingTop: "1px",
            marginTop: "-15px",
            marginLeft: "-25px",
            // ####

          }} >
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={!isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      </List>
    </Drawer>
  );

  // ##################################################################

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }

}

export default withRouter(Sidebar);


const master = [
  {
    id: 1,
    label: "Master Department",
    icon: <MasterIcon />,
    children: [
      { label: " Company Master ", link: "/app/companyMaster", icon: <WallpaperIcon />, },
      { label: " Department Master ", link: "/app/departmentmaster" },
      { label: " Designation Master ", link: "/app/designationMaster" },
      { label: " Employee Master  ", link: "/app/EmployeeMaster" },
      { label: " Cupboard Master ", link: "/app/CupboardMaster" },
      { label: " Expense Master ", link: "/app/expenseMaster" },
      { label: " System Master ", link: "/app/SystemSetting" },
      { label: " Rule Management ", link: "/app/RuleManagement" },
      { label: " Earnings Deductions ", link: "/app/CompanyEarnings" },
    ],
  },
];









