import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from "../../../StyleComponents/StyleFunctionComponent";
import { customTheme } from '../../../StyleComponents/Styledatatable';
import classNames from 'classnames';

import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import { AppBar, Toolbar, Typography, Button, IconButton, Modal, Grid, Box, TextField, TableFooter, TableRow, TableCell } from "@material-ui/core";





const CommentTable = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, [id]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `https://jsonplaceholder.typicode.com/comments?postId=${id}`
            );
            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const columns = [

        {
            name: "id",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "center",
                })
            },
            label: "S/N",
        },


        {
            name: "name",
            label: "Brand Model",
            // options: {
            //     filter: true,
            //     setCellProps: () => ({
            //         align: "left",
            //     })
            // },
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Customize the rendering of the cell content here
                    return (
                        // <Link className={classes.fontblue} to="/">
                        <Link to={`/app/NewTractorEnquiries/${tableMeta.rowData[0]}`} >
                            {value}
                        </Link>
                    );
                },
            },
        },
        {
            name: "id",
            label: "On Road Price",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "id",
            label: "Demo",
            options: {
                filter: true,
                setCellProps: () => ({
                    align: "left",
                })
            },
        },
        {
            name: "id",
            label: "Book Now",
        },

        {
            name: "id", // The name of the column
            label: "Total", // The label to display in the table header
            // label: (
            //     <span style={{ color: "#dc3545" }} >Total</span> // Apply the custom CSS class here
            // ),
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // Customize the rendering of the cell content here
                    return (
                        <span className={classes.fontred}>
                            {value}
                        </span>
                    );
                },
            },
        },


        // {
        //     name: 'id',
        //     label: 'ID',
        // },
        // {
        //     name: 'name',
        //     label: 'Name',
        // },
        // {
        //     name: 'email',
        //     label: 'Email',
        // },
        // {
        //     name: 'body',
        //     label: 'Comment',
        //     options: {
        //         customBodyRender: (value, tableMeta, updateValue) => {
        //             return (
        //                 <span className={classes.fontred}>
        //                     {value}
        //                 </span>
        //             );
        //         },
        //     },
        // },
    ];




    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        fixedSelectColumn: true,
        // tableBodyHeight: "350px",
        // maxHeight: "100px",
        selectableRows: "none",
        overflowy: "auto",
        overflowx: "auto",
        rowsPerPageOptions: [10, 50, 100, 9999, 99999],
        customTableBodyFooterRender: () => {
            return (
                <>
                    <TableFooter className={classes.footerCell}>
                        <TableRow>
                            <TableCell colspan="2">
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                8666.3
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                5433.00
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                30.0044
                            </TableCell>
                            <TableCell className={classNames(classes.fontred, classes.muifooterfont)}>
                                550.00
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </>
            );
        }
    };

    const theme = createTheme();
    const classes = useStyles();


    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Typography className={classNames(classes.pagetittle, classes.pagetittlestyle)}>
                    Tractor Models Stat Reports Tractor Brands Stat Reports
                </Typography>
                <hr className={classes.horizontalline} />
            </Box>
            <div className={classes.root}>
                <div className={classes.root}  >
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={2} lg={2} >
                            <label for="birthday" className={classes.labelform} >Start Date</label><br />
                            <input
                                placeholder='date'
                                type="date"
                                className={classes.inputform}
                            // value={startDate}
                            // onChange={(e) => setStartDate(e.target.value)}
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            />
                        </Grid>

                        <Grid item xs={4} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>End Date</label><br />
                            <input
                                className={classes.inputform}
                                type="date"
                            // value={endDate}
                            // onChange={(e) => setEndDate(e.target.value)}
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} lg={5}>
                            <br />
                            <Button
                                // onClick={applyDateFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                            <Button
                                style={{ marginLeft: "10px" }}
                                // onClick={applyDateFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<ClearIcon />}>
                                Clear
                            </Button>
                        </Grid>

                        <Grid item xs={4} sm={2} lg={2}>
                            <label for="birthday" className={classes.labelform}>Search </label><br />
                            <input
                                placeholder='Type To Search'
                                className={classes.inputform}
                                type="text"
                                label="Search"
                            // value={searchText}
                            // onChange={(e) => setSearchText(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sm={1} lg={1}>
                            <br />
                            <Button
                                // onClick={applySearchFilter}
                                className={classes.buttonform}
                                variant="outlined" startIcon={<SearchIcon />}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <br />

            <MuiThemeProvider theme={customTheme}>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <MUIDataTable
                        // title={`Comments for Post ID ${id}`}
                        title={
                            <Typography className={classes.tabletittle} >
                                Tractor Models Stat Reports Tractor Brands Stat Reports
                                {/* {` ${id}`} */}
                            </Typography>
                        }
                        data={data}
                        columns={columns}
                        options={options}
                    />
                )}
            </MuiThemeProvider>
        </div>
    );
};

export default CommentTable;
