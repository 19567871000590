import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import MobileUserProfile from './MobileUserProfile/MobileUserProfile';
// import SettingsIcon from '@mui/icons-material/Settings';
import './Sidebar.css';

import {
  Wallpaper as WallpaperIcon,
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  Settings as MasterIcon,
  EqualizerOutlined as EqualizerOutlinedIcon,
  Receipt as ReceiptIcon,
  AddShoppingCart as AddShoppingCartIcon,
  Dashboard as DashboardIcon,
  Report as ReportIcon,
  Functions as FunctionsIcon,
  Timeline as TimelineIcon,
  Apps as AppsIcon,
  AccountBalance as AccountBalanceIcon,
  Description as DescriptionIcon,
  Work as WorkIcon,
  Subject as SubjectIcon,
  Keyboard as KeyboardIcon,
  NotificationsActive as NotificationsActiveIcon,
  Dns as DnsIcon,
  Person as PersonIcon,
  PersonPin as PersonPinIcon,
  LiveTv as LiveTvIcon,
  MobileScreenShare as MobileScreenShareIcon,
  DesktopMac as DesktopMacIcon,
  RateReview as RateReviewIcon,
  Business as BusinessIcon,
  YouTube as YouTubeIcon,
  EmojiObjects as EmojiObjectsIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Commute as CommuteIcon,
  DriveEta as DriveEtaIcon,
  Wc as WcIcon,
  Accessibility as AccessibilityIcon,


} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
// import SidebarLink from "./components/SidebarLink/SidebarLink";
import SidebarLink from "./components/SidebarLink/SidebarLink_sub";
// import SidebarLink from "./components/SidebarLink/Sidebardropdown";
import Dot from "./components/Dot";
// import BadgeSharpIcon from '@mui/icons-material/BadgeSharp';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../../context/LayoutContext";

const structure = [

  // { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },

  {
    id: 0,
    label: "Dashboard",
    icon: <HomeIcon />,
    link: "/app/dashboard"
  },


  // 1111111111






  {
    id: 1,

    label: "CRM",
    label_sub: "label_sub",
    icon: <TimelineIcon />,
    children: [
      { label: "All Enquiries", link: "/app/allEnquiries" },
      { label: "Interested Enquiries", link: "/app/interestedEnquiries" },
      { label: "All Pending Enquiries", link: "/app/pendingEnquiriesAll" },
      { label: "Search User", link: "/app/searchUser" },
      { label: "Bulk Upload", link: "/app/bulkUpload" },
    ],
    children_sub: [
      { label: " children_sub Enquiries", link: "" },

    ],
  },

  // {
  //   id: 2,
  //   label: "Enquiry Management",
  //   icon: <AppsIcon />,
  //   children: [
  //     { label: "new", link: "" },
  //     { label: "Shop", link: "" },
  //     { label: "Order Booking", link: "" },
  //     { label: "Expert Advice", link: "" },
  //     { label: "Expert Crop Advice", link: "" },
  //     { label: "Old", link: "" },
  //     { label: "Tractor Contact Seller", link: "" },
  //     { label: "Rent", link: "" },
  //     { label: "Rent Tractor Enquires", link: "" },
  //     { label: "Dealers", link: "" },
  //     { label: "Contact Dealers", link: "" },
  //     { label: "Dealership Enquiry", link: "" },
  //     { label: "Tyre", link: "" },
  //     { label: "Contact Us", link: "" },
  //     { label: "Loan", link: "" },
  //     { label: "Allied Clients", link: "" },
  //     { label: "Escorts Responses", link: "" },
  //     { label: "CNH Responses", link: "" },
  //     { label: "JCB Responses", link: "" },
  //     { label: "Carnot Responses", link: "" },
  //     { label: "Authorized Dealer Listing Enquiries", link: "" },
  //     { label: "JCB Enquiries", link: "" },
  //   ],
  // },
  // #################### sss
  {
    id: '2',
    icon: <AppsIcon />,
    label: "Enquiry Management",
    children: [
      {
        id: '10',
        label: 'New',
        link: '/',
        icon: <DriveEtaIcon />,
        children: [
          {
            id: '8',
            label: 'Tractor',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Tractor Not Interested Enquiries',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Implement',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Harvester',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Earth Mover',
            link: '/',
            icon: <FunctionsIcon />,
          },
        ],
      },

      {
        id: '6',
        label: 'Shop ',
        icon: <AddShoppingCartIcon />,
        children: [
          {
            id: '8',
            label: 'Order Booking',
            link: '/',
          },
          {
            id: '8',
            label: 'Expert Advice',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Expert Crop Advice',
            link: '/',
            icon: <FunctionsIcon />,
          },
        ],
      },
      {
        id: '6',
        label: 'Old ',
        icon: <CommuteIcon />,
        children: [
          {
            id: '8',
            label: 'Tractor Contact Seller',
            link: '/',
          },

        ],
      },
      {
        id: '6',
        label: 'Rent ',
        icon: <EmojiTransportationIcon />,
        children: [
          {
            id: '8',
            label: 'Rent Tractor Enquires',
            link: '/',
          },

        ],
      },
      {
        id: '6',
        label: 'Dealers ',
        icon: <WcIcon />,
        children: [
          {
            id: '8',
            label: 'Dealers',
            link: '/',
          },
          {
            id: '8',
            label: 'Contact Dealers',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Dealership Enquiry',
            link: '/',
            icon: <FunctionsIcon />,
          },

        ],
      },
      {
        id: '11',
        label: 'Tyre',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Contact Us',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Loan',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Allied Clients',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Escorts Responses',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'CNH Responses',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'JCB Responses',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Carnot Responses',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Authorized Dealer Listing Enquiries',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'JCB Enquiries',
        link: '/',
        icon: <FunctionsIcon />,
      },

    ],
  },

  // #################### eee






  {
    id: 3,
    label: "Ambassador",
    icon: <EqualizerOutlinedIcon />,
    children: [
      { label: "Ambassador Registration", link: "/app/ambassadorregistration" },
      { label: "Ambassador Enquiries", link: "/app/ambassadorsenquiries" },
      { label: "Ambassadors", link: "/app/ambassadors" },
      { label: "KYC Verfication", link: "/app/ambassadorskyc" },
      { label: "Complaints", link: "/app/ambassadorComplaints" },
      { label: "Wallet Transactions", link: "/app/ambassadorWallet" },
      { label: " Withdrawal Request", link: "/app/allwithdrawalrequests" },
      { label: "Ambassadors Schemes", link: "/app/ambassadorSchemes" },
      { label: "Ambassadors Banners", link: "/app/ambassadorBanners" },
    ],
  },




  {
    id: '5',
    icon: <AddShoppingCartIcon />,
    label: "Seller Zone",
    children: [
      {
        id: '10',
        label: 'Dealer',
        link: '/',
        icon: <WcIcon />,
        children: [
          {
            id: '8',
            label: 'Dealer Registration',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'View Registered Dealers',
            link: '/',
            icon: <FunctionsIcon />,
          },
        ],
      },

      {
        id: '6',
        label: 'Trust seal',
        icon: <AccessibilityIcon />,
        children: [
          {
            id: '8',
            label: 'Plan',
            link: '/',
            icon: <AccessibilityIcon />,
          },
        ],
      },
      {
        id: '11',
        label: 'Subscription plans',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Recharge',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Paid listing',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Bulk Dealership Upload',
        link: '/',
        icon: <FunctionsIcon />,
      },
      {
        id: '11',
        label: 'Business KYC Requests',
        link: '/',
        icon: <FunctionsIcon />,
      },
    ],
  },








  {
    id: 4,
    label: "New",
    icon: <DriveEtaIcon />,
    children: [
      { label: "Tractor", link: "/app/newTractors" },
      { label: "Implement", link: "/app/newimplements" },
      { label: "Harvester", link: "/app/newHarvesters" },
      { label: "Tyre", link: "/app/newtyres" },
    ],
  },

  {
    id: 5,
    label: "Old",
    icon: <CommuteIcon />,
    children: [
      { label: "Tractor", link: "/app/oldTractors" },
      { label: "Implement", link: "/app/oldimplements" },
      { label: "Harvester", link: "/app/oldHarvesters" },
      { label: "Commercial Vehicle", link: "/app/commercialvehicles" },

    ],
  },



  {
    id: 6,
    label: "Rent",
    icon: <EmojiTransportationIcon />,
    children: [
      { label: "Tractor", link: "/app/rentTractors" },
      { label: "Implement", link: "/app/rentimplements" },
      { label: "Harvester", link: "/app/rentHarvesters" },

    ],
  },

  {
    id: 7,
    label: "Compare",
    icon: <ReportIcon />,
  },

  {
    id: 8,
    label: "Brands",
    icon: <EmojiObjectsIcon />,
    children: [

      { label: "Add Brands", link: "" },
      { label: "View Brands", link: "" },
    ],
  },

  {
    id: 9,
    label: "Video Management",
    icon: <YouTubeIcon />,
  },




  {
    id: 10, label: "FAQ Management",
    // link: "/app/MuiDataTableCrud",
    link: "",
    icon: <BusinessIcon />
  },
  {
    id: 11, label: "Reviews",
    // link: "/app/MuiDataTableCrud",
    link: "",
    icon: <RateReviewIcon />
  },




  // @@@@@@@@@@@@@@@@@@@@@ sssss

  {
    id: '11',
    icon: <LiveTvIcon />,
    label: "Advertise",
    children: [
      {
        id: '10',
        icon: <MobileScreenShareIcon />,
        label: 'Mobile App Ads',
        children: [
          {
            id: '8',
            label: 'Display Mobile Banner Ads',
            link: '/',
            icon: <MobileScreenShareIcon />,
          },

        ],
      },
      {
        id: '10',
        label: 'Website Ads',
        link: '/',
        icon: <DesktopMacIcon />,

        children: [
          {
            id: '8',
            label: 'Display Banner Ads',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Active Campian Report',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Banner Size Ad Report ',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'Web-Pages Ad Report ',
            link: '/',
            icon: <FunctionsIcon />,
          },
          {
            id: '8',
            label: 'De-active Campian Report ',
            link: '/',
            icon: <FunctionsIcon />,
          },

        ],
      },



    ],
  },


  // @@@@@@@@@@@@@@@@@@@@@ eeeeeeeend


  {
    id: 11, label: "User Roles",
    link: "",
    icon: <PersonPinIcon />,
    children: [

      { label: "All Users", link: "" },
    ],
  },
  {
    id: 11, label: "Reports",
    link: "",
    icon: <DnsIcon />,
    children: [
      { label: "Mech. Calling Stat Report", link: "" },
      { label: "Tractor Owner Report", link: "" },
      { label: "Interested Attemptwise Report", link: "" },
      { label: "Not Interested Attemptwise Report", link: "" },
      { label: "Not Contact Enquiries Report", link: "" },
      { label: "Count Report", link: "" },
    ],
  },
  {
    id: 11, label: "Blacklist Users",
    link: "",
    icon: <PersonIcon />,
  },
  {
    id: 11, label: "Notification",
    link: "",
    icon: <NotificationsActiveIcon />,
  },
  {
    id: 11, label: "Keywords",
    link: "",
    icon: <KeyboardIcon />,
  },
  {
    id: 11, label: "Statistics Reports",
    link: "",
    icon: <SubjectIcon />,
    children: [
      { label: "Tractor Stat Report", link: "/app/TractorStatReport" },
      { label: "Implement Stat Report", link: "/app/ImplementStatReport" },
      { label: "Harvester Stat Report", link: "/app/harvesterStatReport" },
      { label: "Tyre Stat Report", link: "/app/TyreStatReport" },
      { label: "Shop Order Reports", link: "/app/ShopOrderReports" },

    ],
  },
  {
    id: 11, label: "Khetigaadi Career",
    link: "",
    icon: <WorkIcon />,
    children: [
      { label: "Job Openings", link: "" },
      { label: "Applications", link: "" },

    ],
  },

  {
    id: 12, label: "demo_testing",
    // link: "/app/MuiDataTableCrud",
    link: "/app/listTable",
    icon: <FunctionsIcon />
  },


  // ##############################


];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (

    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>

          <ArrowBackIcon
            style={{ color: "#ffffff" }}
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List >

        {/* <List className={classes.sidebarList} >
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List> */}


        <div className="sidebar_profile" style={{ marginTop: "-35px" }}>
          <MobileUserProfile />
          <List className={classes.sidebarList} >
            {master.map(link => (
              <SidebarLink
                key={link.id}
                location={location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            ))}
          </List>

          <hr />

        </div>

        <List className={classes.sidebarList}
          style={{
            paddingLeft: "5px",
            paddingTop: "1px",
            marginTop: "-15px",
            marginLeft: "-25px",
            // ####

          }} >
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={!isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      </List>
    </Drawer>
  );

  // ##################################################################

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }

}

export default withRouter(Sidebar);


const master = [
  {
    id: 1,
    label: "Master Department",
    icon: <MasterIcon />,
    children: [
      { label: " Company Master ", link: "/app/companyMaster", icon: <WallpaperIcon />, },
      { label: " Department Master ", link: "/app/departmentmaster" },
      { label: " Designation Master ", link: "/app/designationMaster" },
      { label: " Employee Master  ", link: "/app/EmployeeMaster" },
      { label: " Cupboard Master ", link: "/app/CupboardMaster" },
      { label: " Expense Master ", link: "/app/expenseMaster" },
      { label: " System Master ", link: "/app/SystemSetting" },
      { label: " Rule Management ", link: "/app/RuleManagement" },
      { label: " Earnings Deductions ", link: "/app/CompanyEarnings" },
    ],
  },
];







