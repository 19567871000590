import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,

  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import SidebarExecutive from "../Sidebar/SidebarExecutive";
import Sidebarshop from "../Sidebar/Sidebarshop";

// pages
import Dashboard from "../../pages/dashboard";
import AllEnquiries from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/AllEnquiries";
import TodayPending from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/TodayPending/TodayPending";
import Interested from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/Interested/Interested";
import InterestedView from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/Interested/InterestedView";
import AlreadyPurchased from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/AlreadyPurchased/AlreadyPurchased";
import AlreadyPurchasedview from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/AlreadyPurchased/View/AlreadyPurchasedview";
import NotConnected from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/NotConnected/NotConnected";
import NotConnectedView from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/NotConnected/View/NotConnectedView";

import YesterdayPending from "../../Khetigaadi/Sidebar/CRM/PendingEnquiriesAll/YesterdayPending/YesterdayPending";
import AllPending from "../../Khetigaadi/Sidebar/CRM/PendingEnquiriesAll/AllPending/AllPending";
import Weekpending from "../../Khetigaadi/Sidebar/CRM/PendingEnquiriesAll/Weekpending/Weekpending";

import BulkUpload from "../../Khetigaadi/Sidebar/CRM/BulkUpload/BulkUpload";

import Bulkupdate from "../../Khetigaadi/Sidebar/CRM/BulkUpload/bulkupdate";
import InterestedEnquiries from "../../Khetigaadi/Sidebar/CRM/InterestedEnquiries/InterestedEnquiries";
import PendingEnquiriesAll from "../../Khetigaadi/Sidebar/CRM/PendingEnquiriesAll/PendingEnquiriesAll";
import SearchUser from "../../Khetigaadi/Sidebar/CRM/SearchUser/SearchUser";
import SearchUserform from "../../Khetigaadi/Sidebar/CRM/SearchUser/SearchUserform";
import SearchUserProfile from "../../Khetigaadi/Sidebar/CRM/SearchUser/SearchUserProfile/SearchUserProfile";

import UserEnquiries from "../../Khetigaadi/Sidebar/CRM/SearchUser/UserEnquiries/UserEnquiries";
import UserPending from "../../Khetigaadi/Sidebar/CRM/SearchUser/UserPending/UserPending";
import UserInterested from "../../Khetigaadi/Sidebar/CRM/SearchUser/UserInterested/UserInterested";
import AddNewEnquiry from "../../Khetigaadi/Sidebar/CRM/SearchUser/AddNewEnquiry/AddNewEnquiry";
import UpdateFollowUpEnquiry from "../../Khetigaadi/Sidebar/CRM/AllEnquiries/UpdateFollowUpEnquiry/UpdateFollowUpEnquiry";


import NewHarvesters from "../../Khetigaadi/Sidebar/New/NewHarvesters/NewHarvesters";
import NewHarvestersForm from "../../Khetigaadi/Sidebar/New/NewHarvesters/NewHarvestersForm/NewHarvestersForm";

import Newimplements from "../../Khetigaadi/Sidebar/New/Newimplements/Newimplements";
import NewimplementsForm from "../../Khetigaadi/Sidebar/New/Newimplements/NewimplementsForm/NewimplementsForm";
import NewTractors from "../../Khetigaadi/Sidebar/New/NewTractors/NewTractors";
import AddNewTractors from "../../Khetigaadi/Sidebar/New/NewTractors/Form/AddNewTractors";

import Newtyres from "../../Khetigaadi/Sidebar/New/Newtyres/Newtyres";
import NewtyresForm from "../../Khetigaadi/Sidebar/New/Newtyres/NewtyresForm/NewtyresForm";

import Commercialvehicles from "../../Khetigaadi/Sidebar/Old/Commercialvehicles/Commercialvehicles";
import UsedCommercialVehicleEnquiries from "../../Khetigaadi/Sidebar/Old/Commercialvehicles/View/UsedCommercialVehicleEnquiries";
import OldHarvesters from "../../Khetigaadi/Sidebar/Old/OldHarvesters/OldHarvesters";
import Oldimplements from "../../Khetigaadi/Sidebar/Old/Oldimplements/Oldimplements";
import OldTractors from "../../Khetigaadi/Sidebar/Old/OldTractors/OldTractors";
import OldTractorEnquiries from "../../Khetigaadi/Sidebar/Old/OldTractors/View/OldTractorEnquiries";

import UsedImplementEnquiries from "../../Khetigaadi/Sidebar/Old/Oldimplements/View/UsedImplementEnquiries";
import UsedHarvesterEnquiries from "../../Khetigaadi/Sidebar/Old/OldHarvesters/View/UsedHarvesterEnquiries";

import RentTractors from "../../Khetigaadi/Sidebar/Rent/RentTractors/RentTractors";
import RentHarvesters from "../../Khetigaadi/Sidebar/Rent/RentHarvesters/RentHarvesters";
import Rentimplements from "../../Khetigaadi/Sidebar/Rent/Rentimplements/Rentimplements";


import AmbassadorWallet from "../../Khetigaadi/Sidebar/Ambassador/AmbassadorWallet/AmbassadorWallet";
import Allwithdrawalrequests from "../../Khetigaadi/Sidebar/Ambassador/Allwithdrawalrequests/Allwithdrawalrequests";
import AmbassadorBanners from "../../Khetigaadi/Sidebar/Ambassador/AmbassadorBanners/AmbassadorBanners";
import AmbassadorComplaints from "../../Khetigaadi/Sidebar/Ambassador/AmbassadorComplaints/AmbassadorComplaints";
import Ambassadorregistration from "../../Khetigaadi/Sidebar/Ambassador/Ambassadorregistration/Ambassadorregistration";
import Ambassadors from "../../Khetigaadi/Sidebar/Ambassador/Ambassadors/Ambassadors";
import AmbassadorSchemes from "../../Khetigaadi/Sidebar/Ambassador/AmbassadorSchemes/AmbassadorSchemes";
import Ambassadorsenquiries from "../../Khetigaadi/Sidebar/Ambassador/Ambassadorsenquiries/Ambassadorsenquiries";
import Ambassadorskyc from "../../Khetigaadi/Sidebar/Ambassador/Ambassadorskyc/Ambassadorskyc";

import HarvesterStatReport from "../../Khetigaadi/Sidebar/StatisticsReports/HarvesterStatReport/HarvesterStatReport";
import HarvesterModelsStatReports from "../../Khetigaadi/Sidebar/StatisticsReports/HarvesterStatReport/HarvesterModelsStatReports";

import TyreStatReport from "../../Khetigaadi/Sidebar/StatisticsReports/TyreStatReport/TyreStatReport";
import TractorStatReport from "../../Khetigaadi/Sidebar/StatisticsReports/TractorStatReport/TractorStatReport";
import ShopOrderReports from "../../Khetigaadi/Sidebar/StatisticsReports/ShopOrderReports/ShopOrderReports";
import ImplementStatReport from "../../Khetigaadi/Sidebar/StatisticsReports/ImplementStatReport/ImplementStatReport";
import ImplementModelsStatReports from "../../Khetigaadi/Sidebar/StatisticsReports/ImplementStatReport/ImplementModelsStatReports";
import TractorModelsTractorBrandsStatReports from "../../Khetigaadi/Sidebar/StatisticsReports/TractorStatReport/TractorModelsTractorBrandsStatReports";
import NewTractorEnquiries from "../../Khetigaadi/Sidebar/StatisticsReports/TractorStatReport/NewTractorEnquiries";

import VideoManagement from "../../Khetigaadi/Sidebar/VideoManagement/VideoManagement";
import Reviews from "../../Khetigaadi/Sidebar/Reviews/Reviews";
import Keywords from "../../Khetigaadi/Sidebar/Keywords/Keywords";
import AllUsers from "../../Khetigaadi/Sidebar/UserRoles/AllUsers/AllUsers";
import Userlogin from "../../Khetigaadi/Sidebar/UserRoles/AllUsers/userlogin";
import Applications from "../../Khetigaadi/Sidebar/KhetigaadiCareers/Applications/Applications";
import JobOpenings from "../../Khetigaadi/Sidebar/KhetigaadiCareers/JobOpenings/JobOpenings";
import Notifications from "../../Khetigaadi/Sidebar/Notifications/Notifications";


import Mechcallingstatreport from "../../Khetigaadi/Sidebar/Reports/Mechcallingstatreport/Mechcallingstatreport";
import CountReport from "../../Khetigaadi/Sidebar/Reports/CountReport/CountReport";
import InterestedAttemptwiseReport from "../../Khetigaadi/Sidebar/Reports/InterestedAttemptwiseReport/InterestedAttemptwiseReport";
import NotContactEnquiriesReport from "../../Khetigaadi/Sidebar/Reports/NotContactEnquiriesReport/NotContactEnquiriesReport";
import TractorOwnerReport from "../../Khetigaadi/Sidebar/Reports/TractorOwnerReport/TractorOwnerReport";
import NotInterestedAttemptwiseReport from "../../Khetigaadi/Sidebar/Reports/NotInterestedAttemptwiseReport/NotInterestedAttemptwiseReport";
import monthlyreport from "../../Khetigaadi/Sidebar/Reports/monthlyreport/monthlyreport";
import Notinterestedreport from "../../Khetigaadi/Sidebar/Reports/notinterestedreport/notinterestedreport";

import Oldtractoreport from "../../Khetigaadi/Sidebar/Reports/oldtractoreport/oldtractoreport";

import Suppliedreport from "../../Khetigaadi/Sidebar/Reports/suppliedreport/suppliedreport";

import AlliedClientsEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/AlliedClientsEnquiry/AlliedClientsEnquiry";
import AuthorizedDealer from "../../Khetigaadi/Sidebar/EnquiryManagement/AuthorizedDealer/AuthorizedDealer";
import CarnotResponses from "../../Khetigaadi/Sidebar/EnquiryManagement/CarnotResponses/CarnotResponses";
import CNHAPIResponse from "../../Khetigaadi/Sidebar/EnquiryManagement/CNHAPIResponse/CNHAPIResponse";
import ContactUsEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/ContactUsEnquiries/ContactUsEnquiries";
import EscortAPIResponse from "../../Khetigaadi/Sidebar/EnquiryManagement/EscortAPIResponse/EscortAPIResponse";
import JCBAPIResponse from "../../Khetigaadi/Sidebar/EnquiryManagement/JCBAPIResponse/JCBAPIResponse";
import JCBEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/JCBEnquiries/JCBEnquiries";
import Loan from "../../Khetigaadi/Sidebar/EnquiryManagement/Loan/Loan";
import TyreEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/TyreEnquiries/TyreEnquiries";

import NewEarthMover from "../../Khetigaadi/Sidebar/EnquiryManagement/New/NewEarthMover";
import NewHarvesterEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/New/NewHarvesterEnquiries";
import NewImplementEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/New/NewImplementEnquiries";
import NewTractorEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/New/NewTractorEnquiry";
import NewTractorNotInterestedEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/New/NewTractorNotInterestedEnquiries";

import ShopExpertAdviceCropEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/Shop/ShopExpertAdviceCropEnquiry";
import ShopExpertAdviceEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/Shop/ShopExpertAdviceEnquiry";
import ShopProductEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/Shop/ShopProductEnquiry";

import OldContactSeller from "../../Khetigaadi/Sidebar/EnquiryManagement/Old/OldContactSeller";

import RentTractorEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/Rent/RentTractorEnquiry";

import ContactDealerEnquiry from "../../Khetigaadi/Sidebar/EnquiryManagement/Dealers/ContactDealerEnquiry";
import Dealers from "../../Khetigaadi/Sidebar/EnquiryManagement/Dealers/Dealers";
import DealershipEnquiries from "../../Khetigaadi/Sidebar/EnquiryManagement/Dealers/DealershipEnquiries";

import AddingBrand from "../../Khetigaadi/Sidebar/Brand/AddingBrand/AddingBrand";
import AllBrands from "../../Khetigaadi/Sidebar/Brand/AllBrands/AllBrands";
import AddTractor from "../../Khetigaadi/Sidebar/CRM/AddTractor/AddTractor";
import AddUpdateUserEditForm from "../../Khetigaadi/Sidebar/CRM/AddUpdateUser/AddUpdateUserEditForm";

// context AssetGroup DepartmentsReports  ViewResignEmpReport  
import { useLayoutState } from "../../context/LayoutContext";
import ListTable from "../../BizzVerse/component/src/components/mui/ListTable";



function Layout(props) {


  const GreetingComponent = ({ userLevel }) => {
    if (userLevel === 'super') {
      return <div>  <Sidebar /> </div>;
    } else if (userLevel === 'lead-manager') {
      return <div> <Sidebar /> </div>;
    } else if (userLevel === 'executive') {
      return <div> <SidebarExecutive /> </div>;
    } else if (userLevel === 'shop') {
      return <div> <Sidebarshop /></div>;
    } else {
      return null;
    }
  };
  // Retrieve user level from localStorage or any other source
  const userLevel = localStorage.getItem('user_level') || 'super';
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (

    <div className={classes.root}>
      <>
        <Header history={props.history} />

        <div>
          {/* Other components */}
          <GreetingComponent userLevel={userLevel} />
          {/* Other components */}
        </div>

        {/* <Sidebar /> */}



        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/allEnquiries" component={AllEnquiries} />
            <Route path="/app/bulkUpload" component={BulkUpload} />

            <Route path="/app/bulkupdate/:id/bulk" component={Bulkupdate} />

            <Route path="/app/interestedEnquiries" component={InterestedEnquiries} />
            <Route path="/app/pendingEnquiriesAll" component={PendingEnquiriesAll} />

            <Route path="/app/searchUser/:id" component={SearchUser} />
            <Route path="/app/AddTractor/:id" component={AddTractor} />
            <Route path="/app/SearchUserform/" component={SearchUserform} />
            <Route path="/app/searchUser" component={SearchUser} />

            <Route path="/app/SearchUserProfile/" component={SearchUserProfile} />
            {/* <Route path="/app/SearchUserProfile/:id" component={SearchUserProfile} /> */}
            <Route path="/app/UserInterested/:id" component={UserInterested} />
            <Route path="/app/UserPending/:id" component={UserPending} />
            <Route path="/app/UserEnquiries/:id" component={UserEnquiries} />
            <Route path="/app/AddNewEnquiry/:id" component={AddNewEnquiry} />
            <Route path="/app/UpdateFollowUpEnquiry/:id/crm" component={UpdateFollowUpEnquiry} />
            <Route path="/app/AddUpdateUserEditForm/:id" component={AddUpdateUserEditForm} />

            <Route path="/app/newHarvesters" component={NewHarvesters} />
            <Route path="/app/newimplements" component={Newimplements} />
            <Route path="/app/NewimplementsForm" component={NewimplementsForm} />
            <Route path="/app/newTractors" component={NewTractors} />
            <Route path="/app/AddNewTractors" component={AddNewTractors} />
            <Route path="/app/newtyres" component={Newtyres} />
            <Route path="/app/NewtyresForm" component={NewtyresForm} />
            <Route path="/app/Newtyresupdate/:userId" component={NewtyresForm} />

            <Route path="/app/commercialvehicles" component={Commercialvehicles} />
            <Route path="/app/UsedCommercialVehicleEnquiries" component={UsedCommercialVehicleEnquiries} />
            <Route path="/app/oldHarvesters" component={OldHarvesters} />
            <Route path="/app/oldimplements" component={Oldimplements} />
            <Route path="/app/oldTractors" component={OldTractors} />
            <Route path="/app/OldTractorEnquiries" component={OldTractorEnquiries} />
            <Route path="/app/UsedImplementEnquiries" component={UsedImplementEnquiries} />
            <Route path="/app/UsedHarvesterEnquiries" component={UsedHarvesterEnquiries} />

            <Route path="/app/rentTractors" component={RentTractors} />
            <Route path="/app/rentHarvesters" component={RentHarvesters} />
            <Route path="/app/rentHarvestersupdate/:userId" component={RentHarvesters} />
            <Route path="/app/rentimplements" component={Rentimplements} />

            <Route path="/app/ambassadorWallet" component={AmbassadorWallet} />
            <Route path="/app/allwithdrawalrequests" component={Allwithdrawalrequests} />
            <Route path="/app/ambassadorBanners" component={AmbassadorBanners} />
            <Route path="/app/ambassadorComplaints" component={AmbassadorComplaints} />
            <Route path="/app/ambassadorregistration" component={Ambassadorregistration} />
            <Route path="/app/edit/:userId" component={AddNewTractors} />
            <Route path="/app/newimplementsupdate/:userId" component={NewimplementsForm} />
            <Route path="/app/NewHarvestersupdate/:userId" component={NewHarvestersForm} />
            <Route path="/app/NewHarvestersForm" component={NewHarvestersForm} />


            {/*   <Route path="/app/edit/:userId" component={Ambassadorregistration} /> for testingpurpus  newimplementsform  */}
            <Route path="/app/ambassadors" component={Ambassadors} />
            <Route path="/app/ambassadorSchemes" component={AmbassadorSchemes} />
            <Route path="/app/ambassadorsenquiries" component={Ambassadorsenquiries} />
            <Route path="/app/ambassadorskyc" component={Ambassadorskyc} />

            <Route path="/app/harvesterStatReport" component={HarvesterStatReport} />
            <Route path="/app/HarvesterModelsStatReports/:id" component={HarvesterModelsStatReports} />
            <Route path="/app/TyreStatReport" component={TyreStatReport} />
            <Route path="/app/TractorStatReport" component={TractorStatReport} />
            <Route path="/app/ShopOrderReports" component={ShopOrderReports} />
            <Route path="/app/ImplementStatReport" component={ImplementStatReport} />
            <Route path="/app/ImplementModelsStatReports/:id" component={ImplementModelsStatReports} />
            <Route path="/app/TractorModelsTractorBrandsStatReports/:id" component={TractorModelsTractorBrandsStatReports} />
            <Route path="/app/NewTractorEnquiries/:userId" component={NewTractorEnquiries} />

            <Route path="/app/VideoManagement" component={VideoManagement} />
            <Route path="/app/Reviews" component={Reviews} />
            <Route path="/app/Keywords" component={Keywords} />
            <Route path="/app/AllUsers" component={AllUsers} />
            <Route path="/app/userlogin" component={Userlogin} />
            <Route path="/app/applications" component={Applications} />
            <Route path="/app/JobOpenings" component={JobOpenings} />
            <Route path="/app/Notifications" component={Notifications} />

            <Route path="/app/TodayPending" component={TodayPending} />
            <Route path="/app/Interested" component={Interested} />
            <Route path="/app/InterestedView/:id" component={InterestedView} />

            <Route path="/app/AlreadyPurchased" component={AlreadyPurchased} />
            <Route path="/app/AlreadyPurchasedview/:id" component={AlreadyPurchasedview} />
            <Route path="/app/NotConnected" component={NotConnected} />
            <Route path="/app/NotConnectedView/:id" component={NotConnectedView} />

            <Route path="/app/YesterdayPending" component={YesterdayPending} />
            <Route path="/app/AllPending" component={AllPending} />
            <Route path="/app/Weekpending" component={Weekpending} />


            <Route path="/app/Mechcallingstatreport" component={Mechcallingstatreport} />
            <Route path="/app/CountReport" component={CountReport} />
            <Route path="/app/InterestedAttemptwiseReport" component={InterestedAttemptwiseReport} />
            <Route path="/app/NotContactEnquiriesReport" component={NotContactEnquiriesReport} />
            <Route path="/app/TractorOwnerReport" component={TractorOwnerReport} />
            <Route path="/app/NotInterestedAttemptwiseReport" component={NotInterestedAttemptwiseReport} />
            <Route path="/app/monthlyreport" component={monthlyreport} />

            <Route path="/app/notinterestedreport" component={Notinterestedreport} />
            <Route path="/app/oldtractoreport" component={Oldtractoreport} />
            <Route path="/app/suppliedreport" component={Suppliedreport} />



            <Route path="/app/AlliedClientsEnquiry" component={AlliedClientsEnquiry} />
            <Route path="/app/AuthorizedDealer" component={AuthorizedDealer} />
            <Route path="/app/CarnotResponses" component={CarnotResponses} />
            <Route path="/app/CNHAPIResponse" component={CNHAPIResponse} />
            <Route path="/app/ContactUsEnquiries" component={ContactUsEnquiries} />
            <Route path="/app/EscortAPIResponse" component={EscortAPIResponse} />
            <Route path="/app/JCBAPIResponse" component={JCBAPIResponse} />
            <Route path="/app/JCBEnquiries" component={JCBEnquiries} />
            <Route path="/app/Loan" component={Loan} />
            <Route path="/app/TyreEnquiries" component={TyreEnquiries} />

            <Route path="/app/NewEarthMover" component={NewEarthMover} />
            <Route path="/app/NewHarvesterEnquiries" component={NewHarvesterEnquiries} />
            <Route path="/app/NewImplementEnquiries" component={NewImplementEnquiries} />
            <Route path="/app/NewTractorEnquiry" component={NewTractorEnquiry} />
            <Route path="/app/NewTractorNotInterestedEnquiries" component={NewTractorNotInterestedEnquiries} />

            <Route path="/app/ShopExpertAdviceCropEnquiry" component={ShopExpertAdviceCropEnquiry} />
            <Route path="/app/ShopProductEnquiry" component={ShopProductEnquiry} />
            <Route path="/app/ShopExpertAdviceEnquiry" component={ShopExpertAdviceEnquiry} />

            <Route path="/app/OldContactSeller" component={OldContactSeller} />
            <Route path="/app/RentTractorEnquiry" component={RentTractorEnquiry} />

            <Route path="/app/ContactDealerEnquiry" component={ContactDealerEnquiry} />
            <Route path="/app/Dealers" component={Dealers} />
            <Route path="/app/DealershipEnquiries" component={DealershipEnquiries} />

            <Route path="/app/AddingBrand" component={AddingBrand} />
            <Route path="/app/AllBrands" component={AllBrands} />


            {/* demo for testing  */}
            <Route path="/app/listTable" component={ListTable} />

          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);


