import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Import useHistory from react-router-dom

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [token, setToken] = useState('');

    const history = useHistory(); // Create a history object to manipulate routes

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://khetigaadi.com/admin/api/v1/login', {
                username,
                password,
            });

            if (response.data.error) {
                setError(response.data.message);
            } else {
                const newToken = response.data.data.token;
                setToken(newToken);
                localStorage.setItem('token', newToken);
                sessionStorage.setItem('token', newToken);
                console.log('Admin Logged In Successfully');
                history.push('/app/NewHarvesterEnquiries');
            }
        } catch (error) {
            console.error('An error occurred while logging in:', error);
        }
    };

    return (
        <div>
            <h1>Login</h1>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Username:</label>
                    <input
                        required
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        required
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit">Login</button>
            </form>
            {error && <p>Error: {error}</p>}
        </div>
    );
};

export default Login;




