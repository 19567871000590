// Step1.js
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, Grid } from '@material-ui/core';

import useStyles from '../../../../../StyleComponents/StyleFunctionComponent';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // 

const Step1 = ({ formData, handleInputChange }) => {

    const [editorHtml, setEditorHtml] = useState('');

    const handleEditorChange = (html) => {
        setEditorHtml(html);
    };

    const classes = useStyles();

    return (
        <>
            {/* <div>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                />
            </div> */}
            <br />
            {/* <div>
                <h2>Rich Text Editor</h2>
                <ReactQuill
                    className={classes.labelform}
                    theme="snow" // or "bubble" for a different theme
                    value={editorHtml}
                    onChange={handleEditorChange}
                />
                <div>
                    <h3>Editor Output:</h3>
                    <div dangerouslySetInnerHTML={{ __html: editorHtml }} />
                </div>
            </div> */}
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Overview</label><br />
                    <ReactQuill
                        className={classes.labelform}
                        theme="snow" // or "bubble" for a different theme
                        value={editorHtml}
                        onChange={handleEditorChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Overview In Marathi</label><br />
                    <ReactQuill
                        className={classes.labelform}
                        theme="snow" // or "bubble" for a different theme
                        value={editorHtml}
                        onChange={handleEditorChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} lg={12} >
                    <label for="birthday" className={classes.labelform}>Overview In Hindi</label><br />
                    <ReactQuill
                        className={classes.labelform}
                        theme="snow" // or "bubble" for a different theme
                        value={editorHtml}
                        onChange={handleEditorChange}
                    />
                </Grid>
              
            </Grid >
        </>

    );
};

export default Step1;
